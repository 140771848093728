import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { unique } from 'underscore';

import { transformLeaveTypeToSelectOption } from '../../../../@paco/compositions/@inputs/LeaveTypeSelectInput/LeaveTypeSelectInput';
import trans from '../../../../@paco/helpers/trans';
import { PzLeaveType } from '../../../../@paco/types/leaveType';
import ItemsList from '../../../../components/ItemsList/ItemsList';
import Icon from '../../../../components/style/Icon/Icon';
import TableContainer from '../../../../components/Table/TableContainer';
import TableFilter from '../../../../components/Table/TableFilter';
import { TYPE_ABSENCE, TYPE_LEAVE_OF_ABSENCE } from '../../../../constants';
import { checkPermission, getObjProperty } from '../../../../helpers';
import { formatDate } from '../../../../helpers/date';
import { transformEndDateOrStringToFormDate } from '../../../../helpers/date/transformStartDateOrStringToFormDate';
import { getPermissionToEdit } from '../../../../helpers/permissions/getPermissionToAction';
import { translate } from '../../../../helpers/translations/translator';
import { LeaveType } from '../../../../models';
import doesAbsenceHaveComments from '../../helpers/doesAbsenceHaveComments';
import AbsenceCommentsModal from '../AbsenceCommentsModal/AbsenceCommentsModal';
import ShowCommentsButton from '../ShowCommentsButton/ShowCommentsButton';
import { filterAbsenceByType } from './helpers';
import LeaveOfAbsenceSaldi from './subcomponents/LeaveOfAbsenceSaldi/LeaveOfAbsenceSaldi';

import './Absences.scss';

function generateDate(absence) {
    const startDate = formatDate(absence.start, 'dd-MM-yyyy');
    const endDate = absence.end
        ? formatDate(transformEndDateOrStringToFormDate(new Date(absence.end)), 'dd-MM-yyyy')
        : null;

    if (startDate === endDate) {
        return endDate;
    }

    if (!endDate) {
        return `${translate('pages.absences.from')} ${startDate}`;
    }

    return `${startDate} ${translate('common.until')} ${endDate}`;
}

function generateType(item) {
    if (item.type === 'leaveOfAbsences' && item.leaveOfAbsenceHours?.length) {
        return trans(`compositions.leaveTypeSelectInput.${item.leaveOfAbsenceHours[0].leaveType}`);
    }

    if (item.type === 'leaveOfAbsences') {
        return translate('pages.absences.leaveOfAbsenceWithoutLeaveType');
    }

    return <div className="absences-table-absence">{translate('pages.absences.absence')}</div>;
}

class Absences extends Component {
    state = {
        type: null,
        year: null,
        commentModalIsOpen: false,
        activeCommentItem: null,
    };

    setTableFilter = (prop, value) => {
        this.setState({
            [prop]: value,
        });
    }

    onShowCommentsButtonClick = (item) => {
        this.setState({
            commentModalIsOpen: true,
            activeCommentItem: item,
        });
    }

    renderShowCommentsButton = (item) => {
        if (doesAbsenceHaveComments(item)) {
            return (
                <ShowCommentsButton onClick={
                    () => this.onShowCommentsButtonClick(item)
                }
                />
            );
        }

        return null;
    }

    renderTypeFilterButton = (canViewAllLeaveTypes) => {
        const pzLeaveTypeArray = Object.values(PzLeaveType);
        const leaveTypeArray = Object.values(LeaveType);
        const leaveOptions = canViewAllLeaveTypes ? pzLeaveTypeArray : leaveTypeArray;

        const options = [
            {
                value: TYPE_ABSENCE,
                label: translate('pages.absences.absence'),
            },
            ...leaveOptions.map(transformLeaveTypeToSelectOption),
        ];

        return (
            <TableFilter
                placeholder="Type"
                id="type-filter"
                options={options}
                onChange={value => this.setTableFilter('type', value)}
            />
        );
    }

    renderYearFilterButton = (absences) => {
        const years = unique(
            absences
                .reduce((total, absence) => {
                    const yearStart = formatDate(absence.start, 'yyyy');
                    const yearEnd = absence.end && formatDate(absence.end, 'yyyy');
                    return [...total, yearStart, yearEnd];
                }, [])
                .filter(absence => absence),
        );
        const options = years
            .sort((a, b) => parseInt(b, 10) - parseInt(a, 10))
            .map(year => ({
                value: year,
                label: year,
            }));

        return (
            <TableFilter
                placeholder={translate('common.year')}
                id="year-filter"
                options={options}
                onChange={value => this.setTableFilter('year', value)}
            />
        );
    }

    generateButtons(item) {
        const {
            currentUser,
            permissions,
            onAbsenceClick,
        } = this.props;
        const canEdit = item.type === TYPE_LEAVE_OF_ABSENCE
            ? getPermissionToEdit(currentUser?.role, getObjProperty(item, 'user.roles'), permissions, 'leave-of-absences')
            : getPermissionToEdit(currentUser?.role, getObjProperty(item, 'user.roles'), permissions, 'absences');

        if (!canEdit) {
            return null;
        }

        return (
            <button type="button" onClick={() => onAbsenceClick(item)} className="clear-button">
                <Icon color="light-blue" kind="edit" />
            </button>
        );
    }

    render() {
        const {
            absences,
            leaveOfAbsences,
            userLoketHours,
            permissions,
        } = this.props;
        const {
            type,
            year,
            commentModalIsOpen,
            activeCommentItem,
        } = this.state;
        const totalEntries = [...leaveOfAbsences, ...absences];
        const filteredEntries = totalEntries
            .filter(absence => !type || filterAbsenceByType(absence, type))
            .filter(absence => !year || formatDate(absence.start, 'yyyy') === year || formatDate(absence.end, 'yyyy') === year)
            .sort((a, b) => new Date(b.start) - new Date(a.start));
        const canViewAllLeaveTypes = checkPermission(permissions, 'view-all-leave-types', 'absences');

        const tableAttributes = [
            {
                label: this.renderTypeFilterButton(canViewAllLeaveTypes),
                customAction: item => generateType(item),
                className: 'absences-table-td-type',
            },
            {
                label: this.renderYearFilterButton(totalEntries),
                customAction: item => generateDate(item),
                className: 'absences-table-td-year',
            },
            {
                label: translate('common.notes'),
                customAction: item => this.renderShowCommentsButton(item),
                className: 'absences-table-td-notes',
            },
            {
                label: translate('pages.shifts.status'),
                customAction: item => (item.status ? translate(`common.${item.status}`) : '-'),
                className: 'absences-table-td-status',
            },
            {
                label: '',
                customAction: item => this.generateButtons(item),
                className: 'td-edit',
            },
        ];

        return (
            <>
                <div className="user-absences">
                    <LeaveOfAbsenceSaldi loketHours={userLoketHours} className="user-absences__leave-of-absence-saldi" />
                    <div className="user-absences__absences-table">
                        <div className="user-absences__title">
                            {translate('pages.management.leaveOfAbsenceAndAbsenceRegistration')}
                        </div>
                        <ItemsList
                            itemsLength={totalEntries.length}
                            emptyMessage={translate('pages.management.emptyAbsences')}
                        >
                            <TableContainer
                                className="absences-table"
                                attributes={tableAttributes}
                                data={filteredEntries}
                            />
                        </ItemsList>
                    </div>
                </div>

                <AbsenceCommentsModal
                    isOpen={commentModalIsOpen}
                    onClose={() => this.setState({ commentModalIsOpen: false })}
                    absence={activeCommentItem}
                />
            </>
        );
    }
}

Absences.propTypes = {
    absences: PropTypes.array,
    permissions: PropTypes.array,
    leaveOfAbsences: PropTypes.array,
    userLoketHours: PropTypes.object,
    onAbsenceClick: PropTypes.func.isRequired,
};

Absences.defaultProps = {
    absences: [],
    permissions: [],
    leaveOfAbsences: [],
    userLoketHours: null,
};

export default Absences;
