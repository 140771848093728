import React from 'react';

import PropTypes from 'prop-types';

import { DepartmentBadge } from '../../@paco/components';
import { ConnectedUserContractHours } from '../../@paco/connectors';
import UserPageLink from '../UserPageLink/UserPageLink';

import './UserProfileInfo.scss';

const UserProfileInfo = ({
    name,
    experience,
    department,
    group,
    userId,
    date,
    className,
}) => (
    <div className={`user-profile ${className}`}>
        <div className={`user-profile-info ${!department && 'user-profile-info--no-department'}`}>
            <div className="user-profile-name">
                <UserPageLink className="user-profile-name-link" name={name} id={userId} />
                {experience && (
                    <div className="badge-level user-profile-level">
                        {experience}
                    </div>
                )}

                <ConnectedUserContractHours
                    userId={userId}
                    date={date}
                    className="user-profile__contract-hours"
                />
            </div>
            {department && (
                <DepartmentBadge
                    name={department}
                    groupName={group}
                />
            )}
        </div>
    </div>
);

UserProfileInfo.propTypes = {
    className: PropTypes.string,
    name: PropTypes.string.isRequired,
    group: PropTypes.string,
    experience: PropTypes.string,
    userId: PropTypes.string,
    date: PropTypes.object,
    department: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null]).isRequired]),
};

UserProfileInfo.defaultProps = {
    className: '',
    group: null,
    department: null,
    experience: null,
    date: null,
    userId: null,
};

export default UserProfileInfo;
