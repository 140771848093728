import React, { FC, ReactElement, useState } from 'react';

import { Modal } from 'reactstrap';

import { ConnectedDepartmentsFilter } from '../../../../../@paco/connectors';
import ButtonAuthenticate from '../../../../../components/ButtonAuthenticate/ButtonAuthenticate';
import FilterBlock from '../../../../../components/Filters/subcomponents/FilterBlock/FilterBlock';
import { BaseScheduleFormData } from '../../../../../entities/BaseSchedule/BaseSchedule';
import { translate } from '../../../../../helpers/translations/translator';
import BaseScheduleForm from '../../../forms/BaseScheduleForm/BaseScheduleForm';

interface BaseScheduleSidebarProps {
    canAddBaseSchedules: boolean;
    onBaseScheduleSubmit: (formData: BaseScheduleFormData) => void;
    className?: string;
}

const BaseSchedulesSidebar: FC<BaseScheduleSidebarProps> = ({
    canAddBaseSchedules,
    onBaseScheduleSubmit,
    className = '',
}): ReactElement => {
    const [baseScheduleModalIsActive, setBaseScheduleModalIsActive] = useState(false);

    const handleBaseScheduleSubmit = (formData: BaseScheduleFormData) => {
        setBaseScheduleModalIsActive(false);
        onBaseScheduleSubmit(formData);
    };

    const handleOpenBaseScheduleModal = () => setBaseScheduleModalIsActive(true);
    const handleCloseBaseScheduleModal = () => setBaseScheduleModalIsActive(false);

    return (
        <div className={`base-schedules-sidebar ${className}`}>
            {canAddBaseSchedules && (
                <FilterBlock hideMobile>
                    <ButtonAuthenticate
                        text={translate('common.addEntity', { entity: translate('common.schedule') })}
                        onClick={handleOpenBaseScheduleModal}
                    />
                </FilterBlock>
            )}

            <FilterBlock>
                <ConnectedDepartmentsFilter />
            </FilterBlock>

            {canAddBaseSchedules && (
                <Modal
                    size="small"
                    isOpen={baseScheduleModalIsActive}
                    className="base-schedules-sidebar__add-modal"
                >
                    <BaseScheduleForm
                        title={translate('forms.baseScheduleForm.title')}
                        onSubmit={handleBaseScheduleSubmit}
                        onCancel={handleCloseBaseScheduleModal}
                        className="base-schedules-sidebar__add-form"
                    />
                </Modal>
            )}
        </div>
    );
};

export default BaseSchedulesSidebar;
