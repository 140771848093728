import { FC, useRef } from 'react';

import { version } from '../../../../../../package.json';
import useHandleClickOutside from '../../../../helpers/hooks/useHandleClickOutside';
import { UserMenuButtons } from '../index';

import './UserMenu.scss';

interface UserMenuProps {
    userId?: string;
    onClose: () => void;
    onLogoutClick: () => void;
    className?: string;
}

const UserMenu: FC<UserMenuProps> = ({
    userId,
    onClose,
    onLogoutClick,
    className = '',
}) => {
    const userMenuRef = useRef<HTMLDivElement>(null);
    useHandleClickOutside(userMenuRef, onClose);

    return (
        <div ref={userMenuRef} className={`user-menu ${className}`}>
            <div className="user-menu__version-number">
                {`v${version}`}
            </div>
            <UserMenuButtons
                userId={userId}
                onNavLinkClick={onClose}
                onLogoutClick={onLogoutClick}
            />
        </div>
    );
};

export default UserMenu;
