import React, { FC, useState } from 'react';

import { Modal } from 'reactstrap';

import ButtonAuthenticate from '../../../../components/ButtonAuthenticate/ButtonAuthenticate';
import ItemsList from '../../../../components/ItemsList/ItemsList';
import Icon from '../../../../components/style/Icon/Icon';
import TableContainer from '../../../../components/Table/TableContainer';
import { WEEKDAYS } from '../../../../constants';
import { checkPermission } from '../../../../helpers';
import { formatDate } from '../../../../helpers/date';
import { translate } from '../../../../helpers/translations/translator';
import { Permission, UnavailableToWorkTimeSlot } from '../../../../models';
import AddOrEditAvailabilityTime from '../../forms/AddOrEditAvailabilityTime/AddOrEditAvailabilityTime';

import './Availability.scss';

export interface AvailabilityProps {
    permissions: Permission[];
    unavailableToWorkTimeSlots: UnavailableToWorkTimeSlot[];
    onSetPlanningPreference: (
        weekday: number,
        fromDate: string,
        toDate: string,
        comment: string,
        planningPreferenceId: string | null,
    ) => void;
    onDeletePlanningPreference: (planningPreferenceId: string) => void;
}

const Availability: FC<AvailabilityProps> = ({
    permissions,
    unavailableToWorkTimeSlots,
    onSetPlanningPreference,
    onDeletePlanningPreference,
}) => {
    const [formData, setFormData] = useState<UnavailableToWorkTimeSlot | null>(null);
    const [addForm, setAddForm] = useState(false);

    const toggleForm = () => {
        setAddForm(!addForm);
    };

    const onEditDocumentClick = (planningPreference: UnavailableToWorkTimeSlot) => {
        setFormData(planningPreference);
        setAddForm(true);
    };

    const onDeleteDocumentClick = (planningPreferenceId: string) => {
        onDeletePlanningPreference(planningPreferenceId);
    };

    const onCancelForm = () => {
        toggleForm();
        setFormData(null);
    };

    const onSubmitForm = (weekday: number, fromDate: string, toDate: string, comment: string) => {
        toggleForm();
        onSetPlanningPreference(weekday, fromDate, toDate, comment, formData ? formData.id : null);
        setFormData(null);
    };

    const renderButtons = (planningPreference: UnavailableToWorkTimeSlot) => {
        const canEditSlots = checkPermission(permissions, 'edit-unavailable-to-work-time-slots', 'availability');
        const canDeleteSlots = checkPermission(permissions, 'delete-unavailable-to-work-time-slots', 'availability');

        return (
            <div className="availability__action-buttons">
                { canEditSlots && (
                    <button type="button" onClick={() => onEditDocumentClick(planningPreference)} className="clear-button">
                        <Icon color="light-blue" kind="edit" />
                    </button>
                )}
                { canDeleteSlots && (
                    <button type="button" onClick={() => onDeleteDocumentClick(planningPreference.id)} className="clear-button">
                        <Icon color="light-blue" kind="trash" />
                    </button>
                )}
            </div>
        );
    };

    const tableAttributes = [
        {
            label: translate('common.weekDay'),
            customAction: (item: UnavailableToWorkTimeSlot) => translate(`common.weekDays.${WEEKDAYS[item.weekday - 1]}`),
            className: 'availability-table-td-weekday',
        },
        {
            label: translate('pages.tracks.startTime'),
            customAction: (item: UnavailableToWorkTimeSlot) => formatDate(item.start, 'HH:mm'),
            className: 'availability-table-td-time',
        },
        {
            label: translate('pages.tracks.endTime'),
            customAction: (item: UnavailableToWorkTimeSlot) => formatDate(item.end, 'HH:mm'),
            className: 'availability-table-td-time',
        },
        {
            label: translate('common.startDate'),
            customAction: (item: UnavailableToWorkTimeSlot) => formatDate(item.start),
            className: 'availability-table-td-date',
        },
        {
            label: translate('common.endDate'),
            customAction: (item: UnavailableToWorkTimeSlot) => formatDate(item.end),
            className: 'availability-table-td-date',
        },
        {
            label: translate('common.description'),
            customAction: (item: UnavailableToWorkTimeSlot) => item.comment,
            className: 'availability-table-td-comment',
        },
        {
            label: '',
            customAction: (item: UnavailableToWorkTimeSlot) => renderButtons(item),
            className: 'availability-table-td-comment',
        },
    ];

    return (
        <div className="availability">
            <div className="availability-top">
                <ButtonAuthenticate
                    text={translate('pages.management.newTime')}
                    onClick={toggleForm}
                    responsive={false}
                />
            </div>
            <ItemsList
                itemsLength={unavailableToWorkTimeSlots.length}
                emptyMessage={translate('pages.management.emptyNonAvailability')}
            >
                <TableContainer
                    className="availability-table"
                    attributes={tableAttributes}
                    data={unavailableToWorkTimeSlots}
                />
            </ItemsList>
            <Modal size="lg" isOpen={addForm} className="form-add-time">
                <AddOrEditAvailabilityTime
                    data={formData}
                    permissions={permissions}
                    onCancel={onCancelForm}
                    onSubmit={onSubmitForm}
                />
            </Modal>
        </div>
    );
};

export default Availability;
