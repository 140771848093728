import React, { FC, useMemo } from 'react';

import AbsencesList from '../../../../../Absences/components/Absence/AbsencesList';
import DayContent from '../../../../../Absences/components/Days/DayContent';
import DayHeader from '../../../../../Absences/components/Days/DayHeader';
import UnavailableSlotList from '../UnavailableSlotList/UnavailableSlotList';
import UserShiftAndTracksList from '../UserShiftAndTracksList/UserShiftAndTracksList';
import {
    filterResourceByDays,
    filterShiftsByDays,
    filterTracksByDays,
    getHeader,
} from './helpers';
import { DayBasis } from './models';

import './Day.scss';


interface DayProps extends DayBasis {
    day: Date;
    index: number;
    last: boolean;
    outOfRange: boolean;
    showDate: boolean;
    onAbsenceMouseEnter: (id: string) => void;
    onAbsenceMouseLeave: (id: string) => void;
}

const Day: FC<DayProps> = ({
    day,
    absences,
    leaveOfAbsences,
    shifts,
    tracks,
    unavailableToWorkTimeSlots,
    showHeader,
    index,
    outOfRange,
    showDate,
    last,
    userId,
    onItemClick,
    onAbsenceMouseEnter,
    onAbsenceMouseLeave,
}) => {
    const filteredAbsences = useMemo(() => filterResourceByDays(absences, day), [absences, day]);
    const filteredLeaveOfAbsences = useMemo(() => filterResourceByDays(leaveOfAbsences, day), [leaveOfAbsences, day]);
    const filteredShifts = useMemo(() => filterShiftsByDays(shifts, day), [shifts, day]);
    const filteredTracks = useMemo(() => filterTracksByDays(tracks, day), [tracks, day]);

    const allFilteredAbsences = [...filteredAbsences, ...filteredLeaveOfAbsences];

    const header = getHeader(day);

    return (
        <div className={`day user-calendar-day ${outOfRange ? 'day--out-of-range' : ''}`} style={{ zIndex: (40 - index) }}>
            { showHeader && <DayHeader day={header} /> }
            <DayContent day={day} showHeader={showDate}>
                {allFilteredAbsences.length > 0 && (
                    <AbsencesList
                        day={day}
                        dayIndex={index}
                        absences={[...filteredAbsences, ...filteredLeaveOfAbsences]}
                        last={last}
                        filter={['date', 'type']}
                        onAbsenceClick={onItemClick}
                        onAbsenceMouseEnter={onAbsenceMouseEnter}
                        onAbsenceMouseLeave={onAbsenceMouseLeave}
                    />
                )}
                {!!(filteredShifts.length || filteredTracks.length) && (
                    <UserShiftAndTracksList
                        userId={userId}
                        shifts={filteredShifts}
                        tracks={filteredTracks}
                    />
                )}
                {unavailableToWorkTimeSlots.length > 0 && (
                    <UnavailableSlotList
                        day={day}
                        slots={unavailableToWorkTimeSlots}
                        onItemClick={onItemClick}
                    />
                )}
            </DayContent>
        </div>
    );
};

export default Day;
