import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { AllCheckerCheckbox as GroupedAllCheckerCheckbox } from '../../@paco/compositions/GroupedCheckboxes';
import Icon from '../style/Icon/Icon';

class AllCheckerCheckbox extends Component {
    state = {
        expanded: false,
    };

    onFormClick = () => {
        if (!this.props.expandable) {
            return;
        }
        const { expanded } = this.state;
        this.setState({
            expanded: !expanded,
        });
    }

    render() {
        const {
            id, value, label, disabled, expandable, checked,
        } = this.props;
        const { expanded } = this.state;

        const className = `form-check form-check-inline custom-checkbox
            ${expandable ? 'form-check--expandable' : ''}
            ${expanded ? 'form-check--expanded' : ''}
        `;

        return (
            <div
                key={id}
                className={className}
            >
                <GroupedAllCheckerCheckbox
                    type="checkbox"
                    className="form-check-input custom-control-input"
                    id={id || `checkbox_${value}`}
                    value={value}
                    disabled={disabled}
                    checked={checked}
                />
                <label
                    className="form-check-label custom-control-label"
                    htmlFor={id || `checkbox_${value}`}
                >
                    {label}
                    { expandable && (
                        <button className="form-check-button-expand" onClick={this.onFormClick} type="button">
                            <Icon color="grey" kind="keyboard_arrow_down" fontSize={24} />
                        </button>
                    ) }
                </label>
            </div>
        );
    }
}

AllCheckerCheckbox.propTypes = {
    id: PropTypes.string.isRequired,
    expandable: PropTypes.bool,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    value: PropTypes.string.isRequired,
    checked: PropTypes.bool,
};

AllCheckerCheckbox.defaultProps = {
    expandable: false,
    label: '',
    disabled: false,
    checked: false,
};

export default AllCheckerCheckbox;
