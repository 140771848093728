import {
    FC,
    FormEvent,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from 'react';

import { Icon } from '../../../components';
import {
    AddressInput,
    CompanyMedicSelectInput,
    ContractHoursInput,
    DepartmentsInput,
    EmergencyContactInput,
    EmploymentDatesInput,
    EmploymentTypeSelectInput,
    ExperienceSelectInput,
    FormActionButtons,
    IdentificationSelectInput,
    NationalitySelectInput,
    PersonalInfoInput,
    RoleSelectInput,
    TextInput,
    WorkdaysSelectInput,
    YesOrNoSelectInput,
} from '../../../compositions';
import DepartmentInput from '../../../compositions/@inputs/DepartmentInput/DepartmentInput';
import { AddressFormData } from '../../../entities/Address/Address';
import { ContractHours } from '../../../entities/ContractHours/ContractHours';
import { updateContractHoursWithEmployment } from '../../../entities/ContractHours/ContractHoursHelpers';
import { Department } from '../../../entities/Department/Department';
import { EmergencyContactFormData } from '../../../entities/EmergencyContact/EmergencyContact';
import { EmploymentType } from '../../../entities/EmploymentType/EmploymentType';
import { Experience } from '../../../entities/Experience/Experience';
import { FullLoketUser } from '../../../entities/LoketUser/LoketUser';
import { PayrollPeriod } from '../../../entities/PayrollPeriod/PayrollPeriod';
import { Role } from '../../../entities/Role/Role';
import { getRoleById, getRoleDescription } from '../../../entities/Role/RoleHelpers';
import { AddUserFormData, EmploymentDatesFormData, PersonalInfoFormData } from '../../../entities/User/User';
import { formatDate, isDateStringValid } from '../../../helpers/date';
import useCheckPermission from '../../../helpers/hooks/useCheckPermission';
import trans from '../../../helpers/trans';
import { transformIdentificationTypeToSelectOption } from '../../../helpers/types/identificationType';
import { transformNationalityToSelectOption } from '../../../helpers/types/selectOption';
import { IdentificationType, SelectOption, WorkdayType } from '../../../types';
import { DateFormat } from '../../../types/dateFormatTypes';
import { ModalFormProps } from '../../../types/modalFormTypes';
import { DepartmentOption } from '../../../types/selectOptionTypes';
import { generateAddUserFormDataEmptyValues } from './helpers/generateAddUserFormDataEmptyValues';
import { transformFullLoketUserToAddUserFormData } from './helpers/transformFullLoketUserToAddUserFormData';
import {
    AddPersonalInfoFormErrors,
    AddUserFormErrors,
    EmploymentDatesFormErrors,
    validateAddUserFormData,
    validateEmploymentDatesInputFormData,
    validatePersonalInfoFormData,
} from './validation/addUserFormValidation';

import './AddUserForm.scss';

interface AddUserFormProps extends ModalFormProps<AddUserFormData> {
    isDepartmentsLoading: boolean;
    departments: Department[];
    experiences: Experience[];
    employmentTypes: EmploymentType[];
    fullLoketUser?: FullLoketUser;
    payrollPeriods: PayrollPeriod[];
    roles: Role[];
    onSubmit: (userFormData: AddUserFormData) => void;
}

const AddUserForm: FC<AddUserFormProps> = ({
    isDepartmentsLoading,
    isLoading,
    departments,
    experiences,
    employmentTypes,
    fullLoketUser,
    payrollPeriods,
    roles,
    onCancel,
    onSubmit,
}): ReactElement => {
    const [formData, setFormData] = useState<AddUserFormData>(fullLoketUser ? transformFullLoketUserToAddUserFormData(fullLoketUser) : generateAddUserFormDataEmptyValues());
    const [formErrors, setFormErrors] = useState<AddUserFormErrors>({});
    const [personalInfoFormErrors, setPersonalInfoFormErrors] = useState<AddPersonalInfoFormErrors>({});
    const [employmentDatesFormErrors, setEmploymentDatesFormErrors] = useState<EmploymentDatesFormErrors>({});

    const [addressFormData, setAddressFormData] = useState<AddressFormData>(formData.address || {});
    const [emergencyContactFormData, setEmergencyContactFormData] = useState<EmergencyContactFormData>({});
    const [personalInfoFormData, setPersonalInfoFormData] = useState<PersonalInfoFormData>(formData.personalInfo);
    const [employmentDatesFormData, setEmploymentDatesFormData] = useState<EmploymentDatesFormData | undefined>(formData.employmentDates);

    const [identificationExpirationDate, setIdentificationExpirationDate] = useState<Date>(formData.person.identificationExpirationDate);
    const [contractHours, setContractHours] = useState<ContractHours[] | undefined>(formData.contractHours);
    const [shouldTrackTime, setShouldTrackTime] = useState<boolean | undefined>(formData.shouldTrackTime);
    const [isPaidBasedOnTrackedTime, setIsPaidBasedOnTrackedTime] = useState<boolean | undefined>(formData.isPaidBasedOnTrackedTime);
    const [isEligibleForTimeForTime, setIsEligibleForTimeForTime] = useState<boolean | undefined>(formData.isEligibleForTimeForTime);
    const [hasSundayAllowance, setHasSundayAllowance] = useState<boolean>(formData.person.hasSundayAllowance);
    const [companyMedic, setCompanyMedic] = useState<number>(formData.person.companyMedic);

    const [selectedMainDepartment, setSelectedMainDepartment] = useState<SelectOption>({ label: '', value: '' });
    const [selectedDepartments, setSelectedDepartments] = useState<DepartmentOption[]>([]);
    const [selectedWorkdays, setSelectedWorkdays] = useState<SelectOption<WorkdayType>[]>([]);
    const [experience, setExperience] = useState<SelectOption>({ label: experiences[0].name, value: experiences[0].id });
    const [employmentType, setEmploymentType] = useState<SelectOption>({ label: trans(`common.${employmentTypes[0].slug}`), value: employmentTypes[0].id });
    const defaultRole = roles.find(roleSelection => roleSelection.slug === 'employee');
    const [role, setRole] = useState<SelectOption>({ label: trans('entities.roles.name.employee'), value: defaultRole ? defaultRole.id : '' });
    const chosenRole = getRoleById(roles, role.value);
    const [identificationType, setIdentificationType] = useState<SelectOption<IdentificationType>>(transformIdentificationTypeToSelectOption(formData.person.identificationType));
    const [nationality, setNationality] = useState<SelectOption>(transformNationalityToSelectOption(formData.person.nationality));

    const formRef = useRef<HTMLFormElement>(null);

    const canAddAddresses = useCheckPermission('add-new-addresses', 'add-user-form');
    const canAddEmergencyContacts = useCheckPermission('add-new-emergency-contacts', 'add-user-form');
    const canAddEmployments = useCheckPermission('add-new-employments', 'add-user-form');
    const canEditAllUserProfileFields = useCheckPermission('edit-all-user-profile-fields', 'add-user-form');
    const canAddContractHours = useCheckPermission('add-new-periodical-contract-hours', 'edit-user-form');
    const canViewContractHours = useCheckPermission('view-all-periodical-contract-hours', 'edit-user-form');

    const handlePersonalInfoChange = (newPersonalInfoFormData: Partial<PersonalInfoFormData>): void => {
        setPersonalInfoFormData({ ...personalInfoFormData, ...newPersonalInfoFormData });
    };

    const handleAddressChange = (newAddressFormData: AddressFormData): void => {
        setAddressFormData({ ...addressFormData, ...newAddressFormData });
    };

    const handleEmergencyContactChange = (newEmergencyContactFormData: EmergencyContactFormData): void => {
        setEmergencyContactFormData({ ...emergencyContactFormData, ...newEmergencyContactFormData });
    };

    const handleShouldTrackTime = (shouldTrackTimeState: boolean): void => {
        if (!shouldTrackTimeState) {
            setIsPaidBasedOnTrackedTime(false);
            setIsEligibleForTimeForTime(false);
        }

        setShouldTrackTime(shouldTrackTimeState);
    };

    const handleEmploymentDatesChange = (newEmploymentDatesFormData: EmploymentDatesFormData): void => {
        setEmploymentDatesFormData(newEmploymentDatesFormData);
    };

    const handleIdentificationExpirationDate = (date: string): void => {
        if (isDateStringValid(date)) {
            setIdentificationExpirationDate(new Date(date));
        }
    };

    const handleSubmit = (e: FormEvent<HTMLFormElement>): void => {
        e.preventDefault();

        const defaultFormDataToSubmit = {
            personalInfo: personalInfoFormData,
            person: {
                hasSundayAllowance,
                companyMedic,
                identificationType: identificationType.value,
                identificationExpirationDate,
                workdays: selectedWorkdays.map(selectedWorkday => selectedWorkday.value),
                nationality: nationality.value,
            },
            mainDepartment: selectedMainDepartment.value,
            departments: selectedDepartments.filter(selectedDepartment => !selectedDepartment.isGroup).map(department => department.value),
            departmentGroups: selectedDepartments.filter(selectedDepartment => selectedDepartment.isGroup).map(department => department.value),
            experienceId: experience.value,
            employmentTypeId: employmentType.value,
            roleId: role.value,
        };

        const addAddressesFormData = canAddAddresses ? {
            address: addressFormData,
        } : {};

        const addEmploymentDatesFormData = canAddEmployments ? {
            employmentDates: canAddEmployments ? employmentDatesFormData : {},
        } : {};

        const addEmergencyContactFormData = canAddEmergencyContacts ? {
            emergencyContact: emergencyContactFormData,
        } : {};

        const addContractHoursFormData = canAddContractHours ? {
            contractHours,
        } : {};

        const editAllUserProfileFieldFormData = canEditAllUserProfileFields ? {
            shouldTrackTime,
            isPaidBasedOnTrackedTime,
            isEligibleForTimeForTime,
        } : {};

        const completeFormData: AddUserFormData = {
            ...defaultFormDataToSubmit,
            ...editAllUserProfileFieldFormData,
            ...addAddressesFormData,
            ...addContractHoursFormData,
            ...addEmergencyContactFormData,
            ...addEmploymentDatesFormData,
        };

        setFormData(completeFormData);

        const [newFormErrors, hasUserFormErrors] = validateAddUserFormData(completeFormData);
        const [newPersonalInfoFormErrors, hasPersonInfoFormErrors] = validatePersonalInfoFormData(completeFormData.personalInfo);
        const [newEmploymentDatesFormErrors, hasEmploymentDatesFormErrors] = validateEmploymentDatesInputFormData(completeFormData.employmentDates);

        setFormErrors(newFormErrors);
        setPersonalInfoFormErrors(newPersonalInfoFormErrors);
        setEmploymentDatesFormErrors(newEmploymentDatesFormErrors);

        if (!hasUserFormErrors && !hasPersonInfoFormErrors && !hasEmploymentDatesFormErrors) {
            onSubmit(completeFormData);
        }
    };

    useEffect((): void => {
        const updatedContractHours = updateContractHoursWithEmployment(contractHours, employmentDatesFormData);

        setContractHours(updatedContractHours);
    }, [employmentDatesFormData]);

    return (
        <form ref={formRef} onSubmit={handleSubmit} className="add-user-form">
            <PersonalInfoInput
                canEditEmail
                errors={personalInfoFormErrors}
                personalInfoFormData={personalInfoFormData}
                onChange={handlePersonalInfoChange}
            />
            {canAddEmployments && (
                <EmploymentDatesInput
                    formData={employmentDatesFormData}
                    payrollPeriods={payrollPeriods}
                    errors={employmentDatesFormErrors}
                    onChange={handleEmploymentDatesChange}
                />
            )}
            <div className="add-user-form__row">
                <div className="add-user-form__col">
                    <DepartmentInput
                        isLoading={isDepartmentsLoading}
                        departments={departments}
                        selectedDepartment={selectedMainDepartment}
                        error={formErrors.mainDepartment}
                        label={trans('common.mainDepartment')}
                        onDepartmentChange={setSelectedMainDepartment}
                        className="add-user-form__department-input"
                    />
                </div>
            </div>
            <div className="add-user-form__row">
                <div className="add-user-form__col">
                    <DepartmentsInput
                        isLoading={isDepartmentsLoading}
                        departments={departments.filter(department => department.id !== selectedMainDepartment.value)}
                        label={trans('common.otherDepartments')}
                        selectedDepartments={selectedDepartments.filter(department => department.value !== selectedMainDepartment.value)}
                        onDepartmentsChange={setSelectedDepartments}
                    />
                </div>
            </div>
            {canAddAddresses && (
                <AddressInput
                    addressFormData={addressFormData}
                    onChange={handleAddressChange}
                />
            )}
            <div className="add-user-form__row">
                <div className="add-user-form__col">
                    <NationalitySelectInput
                        nationality={nationality}
                        onChange={setNationality}
                    />
                </div>
                <div className="add-user-form__col">
                    <CompanyMedicSelectInput
                        companyMedic={companyMedic}
                        onChange={setCompanyMedic}
                    />
                </div>
            </div>
            {canAddEmergencyContacts && (
                <EmergencyContactInput
                    emergencyContactFormData={emergencyContactFormData}
                    onChange={handleEmergencyContactChange}
                />
            )}
            {canAddEmployments && (
                <div className="add-user-form__row">
                    <div className="add-user-form__col">
                        <EmploymentTypeSelectInput
                            employmentTypes={employmentTypes}
                            employmentType={employmentType}
                            onChange={setEmploymentType}
                        />
                    </div>
                    <div className="add-user-form__col" />
                </div>
            )}
            <div className="add-user-form__row">
                <div className="add-user-form__col">
                    <RoleSelectInput
                        roles={roles}
                        role={role}
                        onChange={setRole}
                    />
                    {chosenRole && (
                        <div className="add-user-form__role-description">
                            <Icon name="info-circle" className="add-user-form__info-circle-icon" />
                            {getRoleDescription(chosenRole.slug)}
                        </div>
                    )}
                </div>
                <div className="add-user-form__col">
                    <ExperienceSelectInput
                        experiences={experiences}
                        experience={experience}
                        onChange={setExperience}
                    />
                </div>
            </div>

            {canViewContractHours && (
                <ContractHoursInput
                    isDisabled={!canAddContractHours}
                    contractHours={contractHours}
                    error={formErrors.contractHours}
                    onChange={setContractHours}
                />
            )}

            <div className="add-user-form__row">
                <div className="add-user-form__col">
                    <IdentificationSelectInput
                        identificationType={identificationType}
                        onChange={setIdentificationType}
                    />
                </div>
                <div className="add-user-form__col">
                    <TextInput
                        error={formErrors.identificationExpirationDate}
                        label={trans('common.identificationExpirationDate')}
                        type="date"
                        value={identificationExpirationDate && formatDate(identificationExpirationDate, DateFormat.inputDate)}
                        onChange={handleIdentificationExpirationDate}
                    />
                </div>
            </div>
            <div className="add-user-form__row">
                <div className="add-user-form__col">
                    <WorkdaysSelectInput
                        selectedWorkdays={selectedWorkdays}
                        onChange={setSelectedWorkdays}
                    />
                </div>
            </div>
            {canEditAllUserProfileFields && (
                <>
                    <div className="add-user-form__row">
                        <div className="add-user-form__col">
                            <YesOrNoSelectInput
                                booleanValue={shouldTrackTime}
                                label={trans('common.shouldTrackTime')}
                                name="shouldTrackTime"
                                onChange={handleShouldTrackTime}
                            />
                        </div>
                        <div className="add-user-form__col">
                            <YesOrNoSelectInput
                                booleanValue={isPaidBasedOnTrackedTime}
                                disabled={!shouldTrackTime}
                                label={trans('common.isPaidBasedOnTrackedTime')}
                                name="isPaidBasedOnTrackedTime"
                                onChange={setIsPaidBasedOnTrackedTime}
                            />
                        </div>
                    </div>
                    <div className="add-user-form__row">
                        <div className="add-user-form__col">
                            <YesOrNoSelectInput
                                booleanValue={isEligibleForTimeForTime}
                                disabled={!shouldTrackTime}
                                label={trans('common.isEligibleForTimeForTime')}
                                name="isEligibleForTimeForTime"
                                onChange={setIsEligibleForTimeForTime}
                            />
                        </div>
                        <div className="add-user-form__col">
                            <YesOrNoSelectInput
                                booleanValue={hasSundayAllowance}
                                label={trans('common.hasSundayAllowance')}
                                name="hasSundayAllowance"
                                onChange={setHasSundayAllowance}
                            />
                        </div>
                    </div>
                </>
            )}
            <FormActionButtons
                isLoading={isLoading}
                onCancelClick={onCancel}
            />
        </form>
    );
};

export default AddUserForm;
