import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { LoadingSpinner } from '../../@paco/components';
import { ConnectedHelpTooltip } from '../../@paco/connectors';
import { delay } from '../../@paco/helpers/delay';
import trans from '../../@paco/helpers/trans';
import SidebarPage from '../../components/SidebarPage/SidebarPage';
import { translate } from '../../helpers/translations/translator';
import { getSettings, setSettings } from '../../redux/settings/settingsActions';
import SettingsSection from './components/Settings/SettingsSection';
import SidebarNav from './components/SidebarNav/SidebarNav';

import './Settings.scss';

class SettingsPage extends Component {
    state = {
        isDummyLoading: false,
        activeDepartmentId: undefined,
    };

    componentDidMount() {
        this.getSettings();
    }

    getSettings = () => {
        this.props.dispatch(getSettings());
    }

    onLinkClick = async (id) => {
        this.setState({
            isDummyLoading: true,
        });

        await delay(250);
        this.setState({
            isDummyLoading: false,
            activeDepartmentId: id || undefined,
        });
    }

    dispatchSubmitSettings = (settings) => {
        this.props.dispatch(setSettings(settings));
    }

    renderSidebarContent = () => {
        const { activeDepartmentId } = this.state;
        const { departments } = this.props;

        return (
            <div className="settings-sidebar">
                <SidebarNav
                    title={translate('pages.settings.general')}
                    activeLink={activeDepartmentId}
                    departments={[{ name: translate('pages.settings.general'), id: null }]}
                    onLinkClick={this.onLinkClick}
                />
                <SidebarNav
                    title={translate('common.departments')}
                    activeLink={activeDepartmentId}
                    departments={departments}
                    onLinkClick={this.onLinkClick}
                />
            </div>
        );
    };

    renderSettings = () => {
        const {
            loading,
            departments,
            settings,
            page,
            permissions,
        } = this.props;
        const { activeDepartmentId, isDummyLoading } = this.state;

        const activeDepartment = departments.find(department => department.id === activeDepartmentId);

        if (!settings.length) {
            return (
                <LoadingSpinner />
            );
        }

        return (
            <>
                <ConnectedHelpTooltip
                    index={0}
                    route={page}
                    subTitle={trans(`help.settings.${page}.title`)}
                    text={trans(`help.settings.${page}.text`)}
                    title={trans('help.settings.title')}
                    className={`settings__${page}-page-help-tooltip`}
                />
                <SettingsSection
                    key={activeDepartmentId}
                    page={page}
                    settings={settings}
                    department={activeDepartment}
                    loading={loading || isDummyLoading}
                    permissions={permissions}
                    onSubmit={this.dispatchSubmitSettings}
                />
            </>
        );
    }

    render() {
        return (
            <div className="settings">
                <SidebarPage
                    sidebarContent={(this.renderSidebarContent())}
                >
                    <div className="settings-content">
                        {this.renderSettings()}
                    </div>
                </SidebarPage>
            </div>
        );
    }
}

SettingsPage.propTypes = {
    dispatch: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    settings: PropTypes.array.isRequired,
    page: PropTypes.string.isRequired,
    departments: PropTypes.array.isRequired,
    permissions: PropTypes.array.isRequired,
};

export default SettingsPage;
