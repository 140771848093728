import React, { Component } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Tooltip from 'react-tooltip-lite';

import {
    AbsenceBadge,
    LeaveOfAbsenceBadge,
    PreferToWorkTimeSlotBadge,
    UnavailableToWorkTimeSlotBadge,
} from '../../@paco/components';
import { CompanyMedicType } from '../../@paco/types';
import {
    transformLegacyPreferToWorkTimeSlotToPreferToWorkTimeSlot,
} from '../../entities/PreferToWorkTimeSlot/PreferToWorkTimeSlotTransformers';
import { transformLegacyUnavailableToWorkTimeSlotToUnavailableToWorkTimeSlot } from '../../entities/UnavailableToWorkTimeSlot/UnavailableToWorkTimeSlotTransformer';
import {
    getAvailablePreferences,
    getFirstLetterOfEmploymentType,
    getNotAvailablePreferences,
    getUserFullName,
} from '../../helpers';
import UpToAndIncludingDate from '../../helpers/date/UpToAndIncludingDate';
import { translate } from '../../helpers/translations/translator';
import { doesUserHaveShiftPlanningsInLast11h } from '../../pages/Schedule/helpers/doesUserHaveShiftPlanningsInLast11h';
import { isUserAgeBelow16AndShiftIsAfter19h } from '../../pages/Schedule/helpers/isUserAgeBelow16AndShiftIsAfter19h';
import { isUserAgeBelow18AndShiftIsAfter23h } from '../../pages/Schedule/helpers/isUserAgeBelow18AndShiftIsAfter23h';
import Icon from '../style/Icon/Icon';
import CaoBadge from '../UserBadge/CaoBadge/CaoBadge';
import UserColorPicker from '../UserColorPicker/UserColorPicker';
import UserPageLink from '../UserPageLink/UserPageLink';

import './PlannedUserCard.scss';

class PlannedUserCard extends Component {
    state = {
        userHasShiftPlanningInLast11h: false,
        userAgeIsBelow16AndShiftIsAfter19h: false,
        userAgeIsBelow18AndShiftIsAfter23h: false,
    };

    componentDidMount() {
        this.getSettings();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.shiftPlanningStart !== this.props.shiftPlanningStart) {
            this.getUserShiftPlanningData();
        }

        if (prevProps.shiftPlanningEnd !== this.props.shiftPlanningEnd) {
            this.getUserAgeAndShiftTime();
        }

        if (prevProps.settings !== this.props.settings) {
            this.getUserCaoData();
        }
    }

    getSettings() {
        this.getUserCaoData();
    }

    async getUserCaoData() {
        this.getUserShiftPlanningData();
        this.getUserAgeAndShiftTime();
    }

    async getUserShiftPlanningData() {
        const {
            settings,
            unplanned,
            userId,
            shiftPlanningStart,
        } = this.props;

        if (!shiftPlanningStart || unplanned) {
            return;
        }

        const userHasShiftPlanningInLast11h = settings && await doesUserHaveShiftPlanningsInLast11h(settings, userId, new Date(shiftPlanningStart));

        this.setState({
            userHasShiftPlanningInLast11h,
        });
    }

    getUserAgeAndShiftTime() {
        const { userBirthDay, shiftPlanningEnd, settings } = this.props;

        if (!userBirthDay || !shiftPlanningEnd) {
            return;
        }

        const userAgeIsBelow16AndShiftIsAfter19h = settings && isUserAgeBelow16AndShiftIsAfter19h(
            settings,
            new Date(userBirthDay),
            new UpToAndIncludingDate(shiftPlanningEnd),
            new Date(),
        );

        const userAgeIsBelow18AndShiftIsAfter23h = settings && isUserAgeBelow18AndShiftIsAfter23h(
            settings,
            new Date(userBirthDay),
            new UpToAndIncludingDate(shiftPlanningEnd),
            new Date(),
        );


        this.setState({
            userAgeIsBelow16AndShiftIsAfter19h,
            userAgeIsBelow18AndShiftIsAfter23h: userAgeIsBelow16AndShiftIsAfter19h ? false : userAgeIsBelow18AndShiftIsAfter23h,
        });
    }

    render() {
        const {
            onEditClick,
            onDeleteClick,
            onAddClick,
            onEmployeeColorSelect,
            name,
            experience,
            comments,
            companyMedic,
            employmentType,
            time,
            hours,
            unplanned,
            userId,
            disabled,
            absence,
            leaveOfAbsence,
            preferToWorkTimeSlots,
            unavailableToWorkTimeSlots,
            userType,
            color,
            shiftPlanningId,
            shift,
        } = this.props;
        const { userHasShiftPlanningInLast11h, userAgeIsBelow16AndShiftIsAfter19h, userAgeIsBelow18AndShiftIsAfter23h } = this.state;

        const availablePrefs = getAvailablePreferences(preferToWorkTimeSlots, shift.start);
        const notAvailablePrefs = getNotAvailablePreferences(unavailableToWorkTimeSlots, shift.start, shift.end);
        const comment = (comments && comments.length) ? comments[comments.length - 1] : undefined;
        const wrapperClassNames = classNames('planned-user-card', {
            'planned-user-card--unplanned': unplanned,
            'planned-user-card--disabled': disabled,
            'planned-user-card--is-company-medic': companyMedic === CompanyMedicType.yes,
        });

        return (
            <div className={wrapperClassNames}>
                <div className="planned-user-card-text">
                    <div className="planned-user-card-first-row">
                        {onEmployeeColorSelect && (
                            <UserColorPicker
                                id={`color-picker-${userId}`}
                                userType={userType}
                                color={color}
                                className="planned-user-card__user-color-picker"
                                onColorSelect={onEmployeeColorSelect}
                                userId={userId}
                                shiftPlanningId={shiftPlanningId}
                            />
                        )}
                        <UserPageLink
                            name={name}
                            id={userType === 'users' ? userId : null}
                            type={userType}
                            className="planned-user-card__name"
                        />
                        <Tooltip
                            className="list-view-user__tooltip"
                            content={
                                employmentType ? translate(`common.${employmentType.slug}`)
                                    : translate('common.temporary-worker')
                            }
                            arrowSize={6}
                            padding={7}
                            zIndex={2000}
                        >
                            <div className="planned-user-card__employment-type badge-level">
                                {getFirstLetterOfEmploymentType(employmentType?.slug)}
                            </div>
                        </Tooltip>
                        {experience && <div className="badge-level">{experience}</div>}
                        {hours && <div className="planned-user-card-hours">{hours}</div>}
                    </div>
                    {time && <div className="planned-user-card-time">{time}</div>}
                    {comment && (
                        <div key={comment.id} className="planned-user-card-remarks">
                            {`${getUserFullName(comment.owner)}: ${comment.body}`}
                        </div>
                    )}
                </div>
                <div className="planned-user-card-buttons-and-label">
                    {absence && (<AbsenceBadge absence={absence} />)}
                    {leaveOfAbsence && (<LeaveOfAbsenceBadge leaveOfAbsence={leaveOfAbsence} />)}
                    {!!availablePrefs.length && (
                        <PreferToWorkTimeSlotBadge
                            preferToWorkTimeSlot={transformLegacyPreferToWorkTimeSlotToPreferToWorkTimeSlot(availablePrefs[0])}
                        />
                    )}
                    {!!notAvailablePrefs.length && (
                        <UnavailableToWorkTimeSlotBadge
                            unavailableToWorkTimeSlot={transformLegacyUnavailableToWorkTimeSlotToUnavailableToWorkTimeSlot(notAvailablePrefs[0])}
                        />
                    )}
                    {userHasShiftPlanningInLast11h && <CaoBadge userHasShiftPlanningInLast11h />}
                    {userAgeIsBelow16AndShiftIsAfter19h && <CaoBadge userAgeIsBelow16AndShiftIsAfter19h />}
                    {userAgeIsBelow18AndShiftIsAfter23h && <CaoBadge userAgeIsBelow18AndShiftIsAfter23h />}
                    {onAddClick && (
                        <button type="button" className="planned-user-card-button-add" onClick={onAddClick}>
                            {`+ ${translate('common.add')}`}
                        </button>
                    )}
                    {onEditClick && (
                        <button
                            type="button"
                            className="planned-user-card-button planned-user-card-button-edit"
                            onClick={onEditClick}
                        >
                            <Icon color="light-blue" kind="edit" fontSize={20} />
                        </button>
                    )}
                    {onDeleteClick && (
                        <button type="button" className="planned-user-card-button" onClick={onDeleteClick}>
                            <Icon color="light-blue" kind="trash" fontSize={20} />
                        </button>
                    )}
                </div>
            </div>
        );
    }
}

PlannedUserCard.propTypes = {
    onEditClick: PropTypes.func,
    onDeleteClick: PropTypes.func,
    onAddClick: PropTypes.func,
    userId: PropTypes.string,
    shiftPlanningId: PropTypes.string,
    shiftPlanningStart: PropTypes.string,
    shift: PropTypes.object,
    name: PropTypes.string,
    experience: PropTypes.string,
    comments: PropTypes.array,
    employmentType: PropTypes.object,
    companyMedic: PropTypes.string,
    time: PropTypes.string,
    hours: PropTypes.string,
    absence: PropTypes.object,
    leaveOfAbsence: PropTypes.object,
    preferToWorkTimeSlots: PropTypes.array,
    unavailableToWorkTimeSlots: PropTypes.array,
    unplanned: PropTypes.bool,
    disabled: PropTypes.bool,
    userType: PropTypes.string,
    color: PropTypes.string,
    settings: PropTypes.array,
};

PlannedUserCard.defaultProps = {
    onEditClick: null,
    onDeleteClick: null,
    onAddClick: null,
    userId: null,
    shiftPlanningId: null,
    shiftPlanningStart: null,
    shift: {},
    experience: null,
    comments: null,
    companyMedic: null,
    employmentType: null,
    name: '',
    time: null,
    hours: null,
    absence: null,
    leaveOfAbsence: null,
    preferToWorkTimeSlots: [],
    unavailableToWorkTimeSlots: [],
    unplanned: false,
    disabled: false,
    userType: null,
    color: null,
    settings: [],
};

function mapStateToProps(state) {
    return {
        settings: state.pacoSettingsReducer.settings,
    };
}

export default connect(mapStateToProps)(PlannedUserCard);
