import React from 'react';

import PropTypes from 'prop-types';

import Absence from './Absence';

const AbsencesList = (props) => {
    const {
        absences, dayIndex, day, last, onAbsenceClick, onAbsenceMouseEnter, onAbsenceMouseLeave,
        focusAbsenceId, filter,
    } = props;

    const renderAbsences = () => absences.map(absence => (
        <Absence
            key={absence.id}
            focus={absence.id === focusAbsenceId}
            dayIndex={dayIndex}
            day={day}
            last={last}
            absence={absence}
            onClick={onAbsenceClick}
            onMouseEnter={onAbsenceMouseEnter}
            onMouseLeave={onAbsenceMouseLeave}
            filter={filter}
        />
    ));

    return (
        <div className="calendar-absences-list">
            {renderAbsences()}
        </div>
    );
};

AbsencesList.propTypes = {
    absences: PropTypes.array.isRequired,
    dayIndex: PropTypes.number.isRequired,
    day: PropTypes.any.isRequired,
    last: PropTypes.bool.isRequired,
    focusAbsenceId: PropTypes.string,
    onAbsenceClick: PropTypes.func.isRequired,
    onAbsenceMouseEnter: PropTypes.func.isRequired,
    onAbsenceMouseLeave: PropTypes.func.isRequired,
    filter: PropTypes.array,
};

AbsencesList.defaultProps = {
    focusAbsenceId: null,
    filter: [],
};

export default AbsencesList;
