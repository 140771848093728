import { authorizedFetch } from '../../helpers/authorizedFetch';
import trans from '../../helpers/trans';
import { generateApiUrl } from '../../helpers/url';
import getIncluded from '../../japi/getIncluded';
import getMultipleIncluded from '../../japi/getMultipleIncluded';
import isResourceDocument from '../../japi/guards/isResourceDocument';
import { JapiDocument } from '../../japi/types/Document';
import { DepartmentResource } from '../Department/Department';
import { transformToDepartment } from '../Department/DepartmentTransformers';
import { DepartmentGroupResource } from '../DepartmentGroup/DepartmentGroup';
import { FetchResult, FetchResultType } from '../FetchResult';
import { ShiftPlanningResource } from '../ShiftPlanning/ShiftPlanning';
import { getShiftPlanningResourceIncludesAndTransformToShiftPlanning } from '../ShiftPlanning/ShiftPlanningService';
import { TemporaryWorkerResource } from '../TemporaryWorker/TemporaryWorker';
import { getTemporaryWorkerResourceIncludesAndTransformToTemporaryWorker } from '../TemporaryWorker/TemporaryWorkerHelpers';
import { EditShiftConceptTimeFormData, ShiftConcept, ShiftConceptResource } from './ShiftConcept';
import { transformEditShiftConceptTimeFormDataToEditShiftConceptRequest, transformToShiftConcept } from './ShiftConceptTransformers';

export const editShiftConceptTimeApiCall = async (
    shiftFormData: EditShiftConceptTimeFormData,
    shiftConceptId: string,
): Promise<FetchResult<ShiftConcept, string>> => {
    const apiData = transformEditShiftConceptTimeFormDataToEditShiftConceptRequest(shiftFormData, shiftConceptId);

    try {
        const includes = [
            'department',
            'shiftConceptPlannings',
            'shiftConceptPlannings.user',
            'shiftConceptPlannings.user.person',
            'shiftConceptPlannings.user.employmentType',
            'shiftConceptPlannings.user.experience',
            'shiftConceptPlannings.comments',
            'shiftConceptPlannings.comments.owner',
            'temporaryConceptWorkers',
            'temporaryConceptWorkers.comments',
            'temporaryConceptWorkers.comments.owner',
        ];

        const url = generateApiUrl({
            endpoint: `/shift/concepts/${shiftConceptId}`,
            queryParams: {
                include: includes.join(','),
            },
        });

        const response = await authorizedFetch(url, {
            method: 'PATCH',
            body: JSON.stringify({ data: apiData }),
        });

        const doc: JapiDocument = await response.json();

        if (!isResourceDocument<ShiftConceptResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const shiftConceptResource = doc.data;
        const departmentResource = getIncluded(doc, shiftConceptResource, 'department') as DepartmentResource;
        const departmentGroupResource = getIncluded(doc, departmentResource, 'group') as DepartmentGroupResource;
        const department = transformToDepartment(departmentResource, departmentGroupResource);
        const shiftPlanningsResource = getMultipleIncluded(doc, shiftConceptResource, 'shiftConceptPlannings') as ShiftPlanningResource[];
        const shiftPlannings = shiftPlanningsResource
            .map(shiftPlanningResource => getShiftPlanningResourceIncludesAndTransformToShiftPlanning(doc, shiftPlanningResource));

        const temporaryWorkersResource = getMultipleIncluded(doc, shiftConceptResource, 'temporaryConceptWorkers') as TemporaryWorkerResource[];
        const temporaryWorkers = temporaryWorkersResource
            .map(temporaryWorkerResource => getTemporaryWorkerResourceIncludesAndTransformToTemporaryWorker(doc, temporaryWorkerResource, shiftConceptResource));

        const shift = transformToShiftConcept(
            shiftConceptResource,
            department,
            shiftPlannings,
            temporaryWorkers,
        );

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: shift,
        };
    } catch (error) {
        console.error('[editShiftConceptTimeApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const deleteShiftConceptApiCall = async (shiftConceptId: string): Promise<FetchResult<boolean, string>> => {
    try {
        const url = generateApiUrl({ endpoint: `/shift/concepts/${shiftConceptId}` });

        const response = await authorizedFetch(url, { method: 'DELETE' });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: true,
        };
    } catch (error) {
        console.error('[deleteShiftConceptApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
