import { useMemo } from 'react';

import { Department } from '../../../../entities/Department/Department';
import useCheckPermission from '../../../../helpers/hooks/useCheckPermission';

const useCheckDepartmentsPermissions = (currentUserDepartments: Department[], userDepartments: Department[]): boolean => {
    const canAssignAssignedDepartments = useCheckPermission('assign-assigned-departments', 'edit-user-form');
    const departments = userDepartments.filter(department => !department.deletedAt);

    if (departments.length === 0) {
        return true;
    }

    if (!canAssignAssignedDepartments) {
        return false;
    }

    const currentUserDepartmentIds = useMemo(() => currentUserDepartments.map(department => department.id), [currentUserDepartments]);
    const userDepartmentIds = useMemo(() => departments.map(department => department.id), [departments]);

    return useMemo(() => userDepartmentIds.every(id => currentUserDepartmentIds.includes(id)), [currentUserDepartmentIds, userDepartmentIds]);
};

export default useCheckDepartmentsPermissions;
