import React, { FC, useMemo } from 'react';

import { Route, Routes } from 'react-router-dom';

import { LoadingSpinner } from '../../@paco/components';
import { ConnectedToasts } from '../../@paco/connectors';
import { Layout } from '../../@paco/containers';
import { DashboardPage } from '../../@paco/pages';
import { NavRoute } from '../../@paco/routes/routes';
import Absences from '../../pages/Absences/Absences';
import ErrorPage from '../../pages/ErrorPage';
import LogsConnector from '../../pages/Logs/LogsConnector';
import LoketSSO from '../../pages/Management/LoketSSO';
import Management from '../../pages/Management/Management';
import PZ from '../../pages/PZ/PZ';
import Schedule from '../../pages/Schedule/Schedule';
import Settings from '../../pages/Settings/Settings';
import Tracks from '../../pages/Tracks/Tracks';
import Toasts from '../Toasts/Toasts';
import { PrivateRouteGroupRoutes } from './PrivateRouteGroupRoutes';

interface PrivateRouteGroupProps {
    permittedUserRoutes: NavRoute[];
}

const PrivateRouteGroup: FC<PrivateRouteGroupProps> = ({ permittedUserRoutes }) => {
    const permittedUserRouteKeys = useMemo(() => permittedUserRoutes.map(route => route.key), [permittedUserRoutes]);

    return (
        <>
            <Layout>
                <Routes>
                    {permittedUserRouteKeys.includes('shifts') && <Route path={`${PrivateRouteGroupRoutes.shifts()}/*`} element={<Schedule />} />}
                    {permittedUserRouteKeys.includes('tracks') && <Route path={`${PrivateRouteGroupRoutes.tracks()}/*`} element={<Tracks />} />}
                    {permittedUserRouteKeys.includes('users') && <Route path={`${PrivateRouteGroupRoutes.management()}/*`} element={<Management />} />}
                    {permittedUserRouteKeys.includes('settings') && <Route path={`${PrivateRouteGroupRoutes.settings()}/*`} element={<Settings />} />}
                    {permittedUserRouteKeys.includes('absences') && <Route path={`${PrivateRouteGroupRoutes.absences()}/*`} element={<Absences />} />}
                    {permittedUserRouteKeys.includes('pz') && <Route path={`${PrivateRouteGroupRoutes.pz()}/*`} element={<PZ />} /> }
                    <Route path={PrivateRouteGroupRoutes.logs()} element={<LogsConnector />} />
                    <Route path={PrivateRouteGroupRoutes.loketSSO()} element={<LoketSSO />} />
                    <Route path={PrivateRouteGroupRoutes.dashboard()} element={<DashboardPage />} />
                    <Route path="/*" element={permittedUserRouteKeys.length ? <ErrorPage errorCode={404} /> : <LoadingSpinner />} />
                </Routes>
            </Layout>
            <Toasts />
            <ConnectedToasts />
        </>
    );
};

export default PrivateRouteGroup;
