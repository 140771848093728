import { Permission } from '../../../@paco/entities/Permission/Permission';
import { differenceInCalendarDays } from '../../../@paco/helpers/date';
import { checkPermission } from '../../../helpers';
import {
    compareAsc,
    differenceInDays,
    formatDate,
} from '../../../helpers/date';
import { translate } from '../../../helpers/translations/translator';
import { Absence, LeaveOfAbsence } from '../../../models';

function getEndDateOutput(absence: Absence | LeaveOfAbsence): string {
    const absenceEnd = absence.end ? formatDate(absence.end, 'yyyy-MM-dd') : null;
    const absenceStart = formatDate(absence.start, 'yyyy-MM-dd');

    if (!absenceEnd || differenceInDays(absenceEnd, absenceStart) === 0) {
        return '';
    }

    if (absence.end && differenceInDays(absenceEnd, absenceStart) > 0) {
        return `${translate('common.until')} ${formatDate(absence.end, 'dd-MM-yyyy HH:mm')}`;
    }

    return '';
}

export function getAbsenceDateString(absence: Absence | LeaveOfAbsence): string {
    const from = !absence.end ? `${translate('pages.absences.from')} ` : '';
    const startDate = formatDate(absence.start, 'dd-MM-yyyy HH:mm');
    const endDate = getEndDateOutput(absence);
    return `${from}${startDate} ${endDate}`;
}

export const isStartDateLaterThanEndDate = (
    startDate: string | null,
    startTime: string,
    endDate: string | null,
    endTime: string,
): boolean => {
    if (!startDate || !endDate || !startTime.length || !endTime.length) {
        return false;
    }

    return compareAsc(`${startDate} ${startTime}`, `${endDate} ${endTime}`) === 1;
};

export function getTotalAbsenceHours(absence: Absence, permissions: Permission[], featureSlug?: string): number | string | null {
    const canViewAbsenceHours = checkPermission(permissions, 'view-all-absence-hours', featureSlug);
    if (!canViewAbsenceHours) {
        return null;
    }

    if (!absence.absenceHours || !absence.absenceHours.length) {
        return translate('pages.absences.noAbsenceHoursFound');
    }

    const totalAbsenceHours = absence.absenceHours.reduce((total, hours) => total + (hours.hours || 0) + (hours.waitingDayHours || 0), 0);

    return Math.round(totalAbsenceHours * 100) / 100;
}

export function getAbsenceDurationInDays(absence: Absence): string {
    const difference = Math.ceil(
        differenceInCalendarDays(absence.end ? new Date(absence.end) : new Date(), new Date(absence.start)) + 1,
    );

    return `${difference} ${difference > 1 ? translate('common.days').toLowerCase()
        : translate('common.day').toLowerCase()}`;
}
