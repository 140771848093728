import React from 'react';

import PropTypes from 'prop-types';
import {
    Button,
    Col,
    CustomInput,
    FormGroup,
    Input,
    Label,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
} from 'reactstrap';

import { ConnectedMultiUsersSelect } from '../../../@paco/connectors';
import GroupedCheckboxes from '../../../components/CheckboxGroup/GroupedCheckboxes';
import { RadioButtonsList } from '../../../components/RadioButtonsList/RadioButtonsList';
import { translate } from '../../../helpers/translations/translator';

import './Forms.scss';

function generateSubjectFromDepartments(departments) {
    if (!departments || !departments.length) {
        return translate('pages.shifts.newScheduleChanges');
    }

    let segments = departments
        .sort((a, b) => !!b.departmentGroup - !!a.departmentGroup)
        .map((department) => {
            const { name, departmentGroup } = department;
            return `${name}${departmentGroup ? ` (${departmentGroup.name})` : ''}`;
        });

    if (segments.length > 1) {
        segments = segments.map((segment, index) => {
            if (index < segments.length - 2) {
                return `${segment},`;
            }
            return segment;
        });
        segments.splice(segments.length - 1, 0, translate('common.and').toLowerCase());
    }
    return translate('pages.shifts.scheduleUpdateForDepartments', { departments: segments.join(' ') });
}

class NotifyEmployees extends React.Component {
    state = {
        tab: 'departments',
        id: new Date().getTime(),
        subject: '',
        body: '',
        selectedDepartments: [],
        selectedEmployees: [],
    };

    componentDidMount() {
        if (this.props.getEmployees) {
            this.props.getEmployees();
        }

        const { subjectDepartmentsDefault, bodyDefault } = this.props;

        this.setState({
            subject: subjectDepartmentsDefault,
            body: bodyDefault,
        });
    }

    getDepartmentsFromIds = ids => ids.map(id => this.props.departments.find(b => id === b.id))

    onDepartmentsChange = (departments) => {
        const { subject } = this.state;
        const { generateNewSubjectAfterDepartmentChange: generateNewSubject } = this.props;
        const newSubject = generateNewSubject
            ? generateSubjectFromDepartments(this.getDepartmentsFromIds(departments)) : subject;

        this.setState({
            subject: newSubject,
            selectedDepartments: departments,
        });
    };

    onEmployeesChange = (employees) => {
        this.setState({
            selectedEmployees: (employees || []).map(employee => employee.id),
        });
    };

    onTitleChange = (e) => {
        const val = e.target.value;
        this.setState({
            subject: val,
        });
    }

    onBodyChange = (e) => {
        const val = e.target.value;
        this.setState({
            body: val,
        });
    }

    onRadioButtonClick = (val) => {
        this.changeTab(val ? 'employees' : 'departments');
    }

    changeTab = (tab) => {
        const { subjectEmployeesDefault, subjectDepartmentsDefault } = this.props;
        const subject = tab === 'departments' ? subjectDepartmentsDefault : subjectEmployeesDefault;
        this.setState({
            tab,
            subject,
            selectedDepartments: [],
            id: new Date().getTime(),
        });
    }

    onSubmit = (e) => {
        e.preventDefault();

        const {
            tab, selectedDepartments, selectedEmployees, subject, body,
        } = this.state;
        if (tab === 'departments') {
            return this.props.onSubmit({ subject, body, departments: selectedDepartments });
        }

        return this.props.onSubmit({ subject, body, employees: selectedEmployees });
    };

    render() {
        const {
            tab, selectedDepartments, selectedEmployees, subject, id, body,
        } = this.state;
        const {
            title,
            onCancel,
            departments,
        } = this.props;
        const canSubmit = tab === 'departments' ? selectedDepartments.length > 0 : selectedEmployees.length > 0;

        return (
            <form onSubmit={this.onSubmit}>
                <ModalHeader>{title}</ModalHeader>
                <ModalBody>
                    <div className="form-notify-tabs">
                        <RadioButtonsList
                            buttons={[
                                { label: translate('common.departments') },
                                { label: translate('common.employees') },
                            ]}
                            onRadioChange={this.onRadioButtonClick}
                        />
                    </div>
                    <div className="form-notify-content">
                        { tab === 'departments' ? (
                            // Setting a new ID makes <DepartmentCheckboxes> re-init itself.
                            <GroupedCheckboxes
                                expandable
                                key={id}
                                onChange={this.onDepartmentsChange}
                                departments={departments}
                                selectedIds={selectedDepartments}
                            />
                        ) : (
                            <ConnectedMultiUsersSelect
                                closeMenuOnSelect={false}
                                isMulti
                                onSelect={this.onEmployeesChange}
                            />
                        )}
                    </div>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label>{translate('common.title')}</Label>
                                <CustomInput
                                    onChange={this.onTitleChange}
                                    type="text"
                                    className="form-control"
                                    id="name"
                                    name="name"
                                    autoComplete="off"
                                    value={subject}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label>{translate('common.description')}</Label>
                                <Input
                                    onChange={this.onBodyChange}
                                    type="textarea"
                                    rows="3"
                                    className="form-control"
                                    id="name"
                                    name="name"
                                    autoComplete="off"
                                    value={body}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button type="button" color="link" id="modal-close" onClick={onCancel}>{translate('common.cancel')}</Button>
                    <Button type="submit" color="orange" disabled={!canSubmit}>{translate('pages.shifts.sendNotification')}</Button>
                </ModalFooter>
            </form>
        );
    }
}

NotifyEmployees.propTypes = {
    title: PropTypes.string.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    subjectEmployeesDefault: PropTypes.string,
    // eslint-disable-next-line react/no-unused-prop-types
    subjectDepartmentsDefault: PropTypes.string,
    // eslint-disable-next-line react/no-unused-prop-types
    bodyDefault: PropTypes.string,
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func.isRequired,
    departments: PropTypes.array.isRequired,
    generateNewSubjectAfterDepartmentChange: PropTypes.bool,
};

NotifyEmployees.defaultProps = {
    onSubmit: null,
    subjectEmployeesDefault: '',
    subjectDepartmentsDefault: '',
    bodyDefault: '',
    generateNewSubjectAfterDepartmentChange: true,
};

export default NotifyEmployees;
