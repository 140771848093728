import React, {
    FC,
    ReactElement,
    useEffect,
    useMemo,
    useState,
} from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Modal } from 'reactstrap';

import { addYears, startOfYear } from '../../../../@paco/helpers/date';
import { setDeletedBaseScheduleShiftId, setIsDeleteBaseScheduleShiftSuccessful } from '../../../../@paco/redux/baseScheduleShift/baseScheduleShiftReducer';
import { useTypedDispatch, useTypedSelector } from '../../../../@paco/redux/store';
import SidebarPage from '../../../../components/SidebarPage/SidebarPage';
import { BaseScheduleViewModel } from '../../../../entities/BaseSchedule/BaseSchedule';
import { BaseScheduleShiftViewModel } from '../../../../entities/BaseScheduleShift/BaseScheduleShift';
import addDatesToBaseScheduleShift from '../../../../entities/BaseScheduleShift/helpers/addDatesToBaseScheduleShift';
import { checkPermission } from '../../../../helpers';
import { addWeeks, getDaysOfWeek } from '../../../../helpers/date';
import { translate } from '../../../../helpers/translations/translator';
import { AddShiftFormData } from '../../../../models';
import { fetchBaseSchedule } from '../../../../redux/@toolkit/baseSchedule/baseScheduleActions';
import { setBaseSchedule, setIsSuccessful, setLastModifiedBaseScheduleShiftId } from '../../../../redux/@toolkit/baseSchedule/baseScheduleReducer';
import { copyBaseScheduleShift, createBaseScheduleShift } from '../../../../redux/@toolkit/baseScheduleShift/baseScheduleShiftActions';
import { setIsSuccessful as setBaseScheduleShiftIsSuccessful } from '../../../../redux/@toolkit/baseScheduleShift/baseScheduleShiftReducer';
import { fetchDepartments } from '../../../../redux/@toolkit/departments/departmentsActions';
import { Reducers } from '../../../../redux/reducers';
import BaseScheduleCalendar from '../../containers/BaseSchedule/BaseScheduleCalendar/BaseScheduleCalendar';
import BaseScheduleSidebar from '../../containers/BaseSchedule/BaseScheduleSidebar/BaseScheduleSidebar';
import AddShift from '../../forms/AddShiftForm/AddShiftForm';

const ConnectedBaseSchedule: FC = (): ReactElement => {
    const dispatch = useDispatch();
    const typedDispatch = useTypedDispatch();
    const { id: baseScheduleIdParam } = useParams<{ id?: string }>();

    const [addShiftsModalIsActive, setAddShiftsModalIsActive] = useState(false);
    const [addShiftDate, setAddShiftDate] = useState<Date>(addWeeks(new Date(), 1));

    const selectedDays = useMemo(() => getDaysOfWeek(startOfYear(addYears(new Date(), 1))), []);

    const {
        isLoading,
        isSuccessful,
        lastModifiedBaseScheduleShiftId,
        baseSchedule,
    } = useSelector((state: Reducers) => state.baseScheduleReducer);
    const { isLoading: isBaseScheduleShiftLoading, isDeleteBaseScheduleShiftSuccessful, deletedBaseScheduleShiftId } = useTypedSelector(state => state.pacoBaseScheduleShiftReducer);
    const { isLoading: isDepartmentsLoading, departments } = useSelector((state: Reducers) => state.departmentsReducer);
    const { isSuccessful: baseScheduleShiftIsSuccessful, isLoading: baseScheduleShiftIsLoading } = useSelector((state: Reducers) => state.baseScheduleShiftReducer);
    const filter = useSelector((state: Reducers) => state.filterReducer.filter);
    const { permissions } = useTypedSelector(state => state.authenticatedUserReducer);

    const canAddBaseScheduleShifts = checkPermission(permissions, 'add-new-base-schedule-shifts', 'connected-base-schedule');
    const canEditBaseScheduleShifts = checkPermission(permissions, 'edit-all-base-schedule-shifts', 'connected-base-schedule');

    const shiftsWithDates = useMemo(
        () => (baseSchedule?.shifts || [])
            .map((shift) => addDatesToBaseScheduleShift(shift, selectedDays)),
        [baseSchedule],
    );

    const handleCopyShiftsSubmit = async (shift: BaseScheduleShiftViewModel) => {
        // @ts-ignore
        dispatch(copyBaseScheduleShift(shift));
    };

    const handleOpenAddShiftsModal = (shiftDate: Date) => {
        setAddShiftDate(shiftDate);
        setAddShiftsModalIsActive(true);
    };

    const handleCloseAddShiftsModal = () => setAddShiftsModalIsActive(false);

    const handleAddShiftSubmit = (formData: AddShiftFormData) => {
        setAddShiftsModalIsActive(false);
        // @ts-ignore
        dispatch(createBaseScheduleShift(formData));
    };

    useEffect((): void => {
        window.scrollTo(0, 0);

        if (!departments.length) {
            typedDispatch(fetchDepartments());
        }
    }, []);

    useEffect(() => {
        if (baseSchedule) {
            setAddShiftDate(selectedDays[0]);
        }
    }, [baseSchedule]);

    useEffect(() => {
        if ((baseScheduleShiftIsSuccessful || isSuccessful) && baseScheduleIdParam) {
            // @ts-ignore
            dispatch(fetchBaseSchedule(baseScheduleIdParam));
        }

        handleCloseAddShiftsModal();
        dispatch(setIsSuccessful(false));
        dispatch(setBaseScheduleShiftIsSuccessful(false));
    }, [baseScheduleShiftIsSuccessful, isSuccessful]);

    useEffect(() => {
        if (baseScheduleIdParam) {
            // @ts-ignore
            dispatch(fetchBaseSchedule(baseScheduleIdParam));
        }
    }, [baseScheduleIdParam, baseScheduleShiftIsSuccessful]);

    useEffect(() => () => {
        dispatch(setBaseSchedule(undefined));
    }, []);

    useEffect(() => {
        dispatch(setLastModifiedBaseScheduleShiftId(undefined));
    }, [filter]);

    useEffect((): void => {
        if (isDeleteBaseScheduleShiftSuccessful && baseSchedule) {
            const updatedBaseSchedule: BaseScheduleViewModel = {
                ...baseSchedule,
                shifts: baseSchedule.shifts?.filter(shift => shift.id !== deletedBaseScheduleShiftId),
            };

            dispatch(setBaseSchedule(updatedBaseSchedule));
            dispatch(setDeletedBaseScheduleShiftId(undefined));
            dispatch(setIsDeleteBaseScheduleShiftSuccessful(false));
        }
    }, [isDeleteBaseScheduleShiftSuccessful]);

    return (
        <SidebarPage
            showLegend
            sidebarContent={(
                <BaseScheduleSidebar
                    addShiftDate={selectedDays[0]}
                    canAddBaseScheduleShifts={canAddBaseScheduleShifts}
                    onAddShift={handleOpenAddShiftsModal}
                />
            )}
        >
            <BaseScheduleCalendar
                isLoading={isLoading || baseScheduleShiftIsLoading || isDepartmentsLoading || isBaseScheduleShiftLoading}
                showEmployees={filter.shifts.showEmployees}
                baseSchedule={baseSchedule}
                selectedDays={selectedDays}
                shifts={shiftsWithDates}
                highlightShiftId={lastModifiedBaseScheduleShiftId}
                canAddBaseScheduleShifts={canAddBaseScheduleShifts}
                canEditBaseScheduleShifts={canEditBaseScheduleShifts}
                onAddShift={handleOpenAddShiftsModal}
                onCopyShiftsSubmit={handleCopyShiftsSubmit}
            />

            <Modal
                size="lg"
                isOpen={addShiftsModalIsActive}
                className="concepts-calendar-overview__add-shift-form"
            >
                <AddShift
                    selectedDays={selectedDays}
                    title={translate('pages.shifts.addShift')}
                    date={addShiftDate}
                    departments={departments}
                    onSubmit={handleAddShiftSubmit}
                    onCancel={handleCloseAddShiftsModal}
                />
            </Modal>
        </SidebarPage>
    );
};

export default ConnectedBaseSchedule;
