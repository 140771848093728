import { FC, useMemo } from 'react';

import { DashboardWelcomeCard, DashboardWelcomeCardSkeleton } from '../../components';
import { ConnectedHelpTooltip } from '../../connectors';
import { Notifications } from '../../entities/Notifications/Notifications';
import { PayrollPeriod } from '../../entities/PayrollPeriod/PayrollPeriod';
import { Permission } from '../../entities/Permission/Permission';
import trans from '../../helpers/trans';
import { DashboardSettingType } from '../../types';
import { getSmallWidgetsData } from './helpers';
import { DashboardSmallWidget, DashboardSmallWidgetSkeleton } from './subcomponents';

import './DashboardSmallWidgets.scss';

interface DashboardSmallWidgetsProps {
    notificationsIsLoading: boolean;
    userIsLoading: boolean;
    notifications: Notifications;
    payrollPeriods: PayrollPeriod[];
    permissions: Permission[];
    userFullName?: string;
    widgets: DashboardSettingType[];
    className?: string;
}

const DashboardSmallWidgets: FC<DashboardSmallWidgetsProps> = ({
    notificationsIsLoading,
    userIsLoading,
    notifications,
    payrollPeriods,
    permissions,
    userFullName,
    widgets,
    className = '',
}) => {
    const smallWidgetsData = useMemo(() => getSmallWidgetsData(
        widgets,
        notifications,
        payrollPeriods,
        permissions,
    ), [
        widgets,
        notifications,
        payrollPeriods,
        permissions,
    ]);

    if (!widgets.length && userIsLoading) {
        return (
            <div className={`dashboard-small-widgets ${className}`}>
                <DashboardWelcomeCardSkeleton />
                <DashboardSmallWidgetSkeleton />
                <DashboardSmallWidgetSkeleton />
                <DashboardSmallWidgetSkeleton />
                <DashboardSmallWidgetSkeleton />
                <DashboardSmallWidgetSkeleton />
                <DashboardSmallWidgetSkeleton />
            </div>
        );
    }

    return (
        <div className={`dashboard-small-widgets ${className}`}>
            <DashboardWelcomeCard userFullName={userFullName} />
            {smallWidgetsData.map(smallWidget => (
                <DashboardSmallWidget
                    key={smallWidget.subTitle}
                    isLoading={notificationsIsLoading}
                    icon={smallWidget.icon}
                    path={smallWidget.path}
                    subTitle={smallWidget.subTitle}
                    subTitleAmount={smallWidget.subTitleAmount}
                    title={smallWidget.title}
                />
            ))}
            <ConnectedHelpTooltip
                index={2}
                route="dashboard"
                subTitle={trans('help.dashboard.widgets.title')}
                text={trans('help.dashboard.widgets.text')}
                title={trans('help.dashboard.title')}
                className="dashboard-small-widgets__help-tooltip"
            />
        </div>
    );
};

export default DashboardSmallWidgets;
