import { FC, ReactElement } from 'react';

import classnames from 'classnames';

import { ContractHoursFormData } from '../../../../../entities/ContractHours/ContractHours';
import trans from '../../../../../helpers/trans';
import { DateInput, IconButton, NumberInput } from '../../../..';

import './ContractHoursAndDateInput.scss';

interface ContractHoursAndDateInputProps {
    isDisabled?: boolean;
    hideDate?: boolean;
    hideDeleteButton?: boolean;
    hideLabel?: boolean;
    contractHours: ContractHoursFormData;
    onChange: (formData: ContractHoursFormData) => void;
    onDeleteButtonClick?: (id: string) => void;
    onBlur?: () => void;
    className?: string;
}

const ContractHoursAndDateInput: FC<ContractHoursAndDateInputProps> = ({
    isDisabled,
    hideDate = false,
    hideDeleteButton,
    hideLabel = false,
    contractHours,
    onChange,
    onDeleteButtonClick,
    onBlur,
    className = '',
}): ReactElement => {
    const handleDeleteButtonClick = (): void => {
        if (onDeleteButtonClick) {
            onDeleteButtonClick(contractHours.id);
        }
    };

    const handleHoursChange = (value: number): void => {
        onChange({ ...contractHours, hours: value });
    };

    const handleStartDateChange = (value: Date): void => {
        const period = { ...contractHours.period, start: value };

        onChange({ ...contractHours, period });
    };

    const elementClassName = classnames('contract-hours-and-date-input', {
        'contract-hours-and-date-input--hide-delete-buttons': hideDeleteButton,
    }, className);

    return (
        <div className={elementClassName}>
            <div className="contract-hours-and-date-input__hours-col">
                <NumberInput
                    disabled={isDisabled}
                    hideLabel={hideLabel}
                    label={trans('compositions.contractHoursInput.contractHoursPerWeek')}
                    type="text"
                    min={0}
                    value={contractHours.hours}
                    onBlur={onBlur}
                    onChange={handleHoursChange}
                />
            </div>

            {!hideDate ? (
                <div className="contract-hours-and-date-input__date-col">
                    <DateInput
                        disabled={isDisabled}
                        hideLabel={hideLabel}
                        label={trans('compositions.contractHoursInput.mutationDate')}
                        value={contractHours.period.start}
                        onBlur={onBlur}
                        onChange={handleStartDateChange}
                        className="contract-hours-and-date-input__date-input"
                    />

                    {!isDisabled && (
                        <IconButton
                            hideLabel
                            icon="trash"
                            text={trans('common.delete')}
                            onClick={handleDeleteButtonClick}
                            className="contract-hours-and-date-input__delete-button"
                        />
                    )}
                </div>
            ) : (
                <div className="contract-hours-and-date-input__date-col" />
            )}
        </div>
    );
};

export default ContractHoursAndDateInput;
