import { AxiosResponse } from 'axios';
import { Dispatch } from 'redux';

import { getCheckedFormOptionsValue } from '../../../@paco/helpers/types/formOption';
import {
    LEAVE_OF_ABSENCES_REQUEST_APPROVED,
    LEAVE_OF_ABSENCES_REQUEST_CLOSED,
    LEAVE_OF_ABSENCES_REQUEST_OPEN,
    PAGE_SIZES,
    TOAST_TYPE_FAIL,
    TOAST_TYPE_PASS,
    TOAST_TYPE_WARNING,
} from '../../../constants';
import { PreferToWorkTimeSlotResource } from '../../../entities/PreferToWorkTimeSlot/PreferToWorkTimeSlot';
import { PublishStatusViewModel } from '../../../entities/PublishStatus/PublishStatus';
import { PublishBaseScheduleFormData, ShiftConceptViewModelOld } from '../../../entities/ShiftConcept/ShiftConcept';
import {
    bulkCopyShiftConceptsApiCall,
    bulkDeleteShiftConceptsApiCall,
    getShiftConceptsApiCall,
    postShiftConceptApiCall,
    publishBaseScheduleApiCall,
    publishShiftConceptsApiCall,
} from '../../../entities/ShiftConcept/ShiftConceptService';
import { transformAddShiftFormData, transformPublishStatus } from '../../../entities/ShiftConcept/ShiftConceptTransformers';
import { getShiftConceptPlanningsApiCall, postShiftConceptPlanningApiCall } from '../../../entities/ShiftConceptPlanning/ShiftConceptPlanningService';
import { transformAddShiftConceptRequest } from '../../../entities/ShiftConceptPlanning/ShiftConceptPlanningTransformers';
import { getUnavailableWorkTimeSlots, getUnavailableWorkTimeSlotsLegacy } from '../../../entities/UnavailableToWorkTimeSlot/UnavailableToWorkTimeSlotService';
import { checkPermission, getUserFullName } from '../../../helpers';
import { getPreferToWorkTimeSlots, postNotifyDepartments } from '../../../helpers/api/shiftsApi';
import { getAbsencesInDateRange, getAbsencesInDateRangeLegacy } from '../../../helpers/api-ts/absences';
import { getLeaveOfAbsences, getLeaveOfAbsencesLegacy, leaveOfAbsenceStatus } from '../../../helpers/api-ts/leaveOfAbsence';
import { getShifts } from '../../../helpers/api-ts/shift';
import { getShiftPlannings } from '../../../helpers/api-ts/shiftPlanning';
import { getUsers } from '../../../helpers/api-ts/userWithAvailability';
import { formatDate, getEndOfWorkDay, getStartOfWorkDay } from '../../../helpers/date';
import { translate } from '../../../helpers/translations/translator';
import {
    Absence,
    AddShiftFormData,
    BasicUser,
    DataWithPagination,
    Filter,
    LeaveOfAbsenceResource,
    UnavailableToWorkTimeSlot,
} from '../../../models';
import compareShiftPlanningUsersAlphabetically from '../../../services/ShiftPlanningService/compareShiftPlanningUsersAlphabetically';
import { deductOneSecondFromLOARequests } from '../../absences-ts/absencesHelpers';
import { setAppToast } from '../../app/appActions';
import { Reducers } from '../../reducers';
import { setError, setShiftConcept } from '../shiftConcept/shiftConceptReducer';
import transformListViewDataToUserWithConceptAvailability from './helpers/transformListViewDataToUserWithConceptAvailability';
import {
    setAvailabilityData,
    setIsLoading,
    setIsPublishShiftConceptsSuccessful,
    setIsShiftsLoading,
    setIsSuccessful,
    setLastModifiedShiftConceptId,
    setShiftConcepts,
    setShiftConceptsToPublish,
    setShifts,
    setUsersListViewData,
} from './shiftConceptsReducer';

export const fetchShiftConcepts = (
    startDate: Date,
    endDate: Date,
    filter: Filter,
    departments: string[],
) => async (dispatch: Dispatch): Promise<void> => {
    dispatch(setIsLoading(true));

    try {
        const justifiedStartDate = getStartOfWorkDay(startDate);
        const justifiedEndDate = getEndOfWorkDay(endDate);
        const response = await getShiftConceptsApiCall(
            justifiedStartDate,
            justifiedEndDate,
            filter,
            departments,
            [
                'shiftConceptPlannings',
                'shiftConceptPlannings.user',
                'shiftConceptPlannings.user.person',
                'shiftConceptPlannings.user.employmentType',
                'shiftConceptPlannings.comments',
                'temporaryConceptWorkers',
                'temporaryConceptWorkers.comments',
                'department',
                'department.group',
            ],
        );
        dispatch(setShiftConcepts(response));
    } catch (error) {
        console.error('[fetchShiftConcepts]', error);

        return;
    }

    dispatch(setIsLoading(false));
};

export const fetchShiftConceptsToPublish = (
    startDate: Date,
    endDate: Date,
    departments: string[],
) => async (dispatch: Dispatch): Promise<void> => {
    dispatch(setIsLoading(true));

    try {
        const response = await getShiftConceptsApiCall(
            startDate,
            endDate,
            {},
            departments,
            [
                'shiftConceptPlannings',
                'shiftConceptPlannings.user',
                'shiftConceptPlannings.user.person',
                'shiftConceptPlannings.user.employmentType',
                'shiftConceptPlannings.comments',
                'temporaryConceptWorkers',
                'temporaryConceptWorkers.comments',
                'department',
                'department.group',
            ],
        );
        dispatch(setShiftConceptsToPublish(response));
    } catch (error) {
        console.error('[fetchShiftConceptsToPublish]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const fetchShifts = (
    startDate: Date,
    endDate: Date,
    filter: Filter,
    departments: string[],
) => async (dispatch: Dispatch): Promise<void> => {
    dispatch(setIsShiftsLoading(true));

    try {
        const justifiedStartDate = getStartOfWorkDay(startDate);
        const justifiedEndDate = getEndOfWorkDay(endDate);
        const response = await getShifts({
            includes: [
                'shiftPlannings',
                'shiftPlannings.user',
                'shiftPlannings.user.person',
                'shiftPlannings.user.employmentType',
                'previouslyPlannedUsers.user.employmentType',
                'department',
                'department.group',
                'temporaryWorkers',
            ],
            startDate: justifiedStartDate,
            endDate: justifiedEndDate,
            filter,
            departments,
        });

        dispatch(setShifts(response));
    } catch (error) {
        console.error('[fetchShiftPlannings]', error);
    } finally {
        dispatch(setIsShiftsLoading(false));
    }
};

export const fetchAvailabilityData = (
    startDate: Date,
    endDate: Date,
) => async (dispatch: Dispatch): Promise<void> => {
    try {
        const [
            absences,
            leaveOfAbsences,
            unavailableWorkTimeSlots,
        ] = await Promise.all([
            getAbsencesInDateRange(
                getStartOfWorkDay(startDate),
                getEndOfWorkDay(endDate),
                undefined,
                undefined,
                ['user'],
            ),
            getLeaveOfAbsences({
                startDate: getStartOfWorkDay(startDate),
                endDate: getEndOfWorkDay(endDate),
                status: [1, 2],
                includes: ['user'],
            }),
            getUnavailableWorkTimeSlots(
                getStartOfWorkDay(startDate),
                getEndOfWorkDay(endDate),
                [],
                ['user'],
            ),
        ]);

        dispatch(setAvailabilityData({
            absences,
            leaveOfAbsences,
            unavailableWorkTimeSlots,
        }));
    } catch (error) {
        console.error('[fetchAvailability]', error);
    }
};

export const addShiftConcept = (
    formData: AddShiftFormData,
    userId: string,
    shiftConcepts: ShiftConceptViewModelOld[],
) => async (dispatch: Dispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const request = transformAddShiftFormData(formData, userId);
        const response = await postShiftConceptApiCall(request);
        dispatch(setShiftConcepts([...shiftConcepts, response]));
        dispatch(setAppToast(translate('pages.shifts.addShiftConceptSuccess'), TOAST_TYPE_PASS));
        dispatch(setLastModifiedShiftConceptId(response.id));
    } catch (error) {
        console.error('[addShiftConcept]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const copyShiftConcepts = (
    shiftConceptIds: string[],
    withUsers: boolean,
    dateOffset: number,
) => async (dispatch: Dispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        await bulkCopyShiftConceptsApiCall(shiftConceptIds, withUsers, dateOffset);
        dispatch(setIsSuccessful(true));
        dispatch(setAppToast(translate('pages.shifts.copyShiftConceptsSuccess', { amount: shiftConceptIds.length }), TOAST_TYPE_PASS));
    } catch (error) {
        console.error('[copyShiftConcepts]', error);
        dispatch(setIsLoading(false));
    }
};

export const deleteShiftConcepts = (shiftConceptIds: string[]) => async (dispatch: Dispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        await bulkDeleteShiftConceptsApiCall(shiftConceptIds);
        dispatch(setIsSuccessful(true));
        dispatch(setAppToast(translate('pages.shifts.deleteShiftConceptsSuccess', { amount: shiftConceptIds.length }), TOAST_TYPE_PASS));
    } catch (error) {
        console.error('[copyShiftConcepts]', error);
        dispatch(setIsLoading(false));
    }
};

const setAppFailedToPublishToasts = (
    status: PublishStatusViewModel,
    dispatch: Dispatch,
) => {
    const date = status.shiftStart ? formatDate(status.shiftStart, 'EEEEEE d MMM yyyy') : '';
    const text = translate(
        'pages.shifts.publishShiftConceptsConflict',
        {
            date,
            department: status.department,
            message: status.message,
        },
    );
    dispatch(setAppToast(text, status.status === 'error' ? TOAST_TYPE_FAIL : TOAST_TYPE_WARNING));
};

export const publishShiftConcepts = (
    departments: string[],
    notificationTitle?: string,
    notificationBody?: string,
) => async (dispatch: Dispatch, getState: () => Reducers): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    const { shiftConceptsReducer } = getState();
    const { shiftConceptsToPublish } = shiftConceptsReducer;

    try {
        const response = await publishShiftConceptsApiCall(shiftConceptsToPublish.map(shiftConcept => shiftConcept.id));

        const failedOrWarningStatuses = response.filter((status) => status.status !== 'success');
        const publishedAmount = response.length - failedOrWarningStatuses.length;

        dispatch(setAppToast(translate('pages.shifts.publishShiftConceptsSuccess', { amount: publishedAmount }), TOAST_TYPE_PASS));
        failedOrWarningStatuses.forEach((status) => {
            setAppFailedToPublishToasts(status, dispatch);
        });

        if (notificationTitle && notificationBody) {
            await postNotifyDepartments({ departments, subject: notificationTitle, body: notificationBody });
            dispatch(setAppToast(translate('pages.shifts.sendNotificationsSuccess'), TOAST_TYPE_PASS));
        }

        dispatch(setIsPublishShiftConceptsSuccessful(true));
    } catch (error) {
        console.error('[publishShiftConcepts]', error);
        dispatch(setIsLoading(false));
    }
};

export const publishBaseSchedule = (
    formData: PublishBaseScheduleFormData,
) => async (dispatch: Dispatch): Promise<void> => {
    dispatch(setIsLoading(false));
    dispatch(setError(''));

    try {
        const { startDate, endDate, baseScheduleId } = formData;

        const baseScheduleResult = await publishBaseScheduleApiCall(startDate, endDate, baseScheduleId);
        const baseScheduleStatusResults = baseScheduleResult.attributes.baseScheduleStatuses.map(baseScheduleStatus => transformPublishStatus(baseScheduleStatus, baseScheduleResult.id));
        const failedOrWarningStatuses = baseScheduleStatusResults.filter(publishStatus => publishStatus.status !== 'success');

        dispatch(setAppToast(translate('pages.shifts.publishBaseSchedulesSuccess', { amount: baseScheduleResult.attributes.publishedCount }), TOAST_TYPE_PASS));
        failedOrWarningStatuses.forEach((status) => {
            setAppFailedToPublishToasts(status, dispatch);
        });

        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[publishBaseSchedule]', error);
        dispatch(setIsLoading(false));
    }
};

export const fetchShiftConceptPlannings = (pageNumber: number) => async (dispatch: Dispatch, getState: () => Reducers): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const {
            weekNavigatorReducer,
            filterReducer,
            shiftConceptsReducer,
            globalFiltersReducer,
        } = getState();
        const { startDate, endDate } = weekNavigatorReducer;
        const { filter } = filterReducer;
        const { departmentOptions } = globalFiltersReducer;
        const {
            availability,
            employeeSearch,
            onlyShowMainDepartment,
        } = filter;
        const { sortByExperience } = availability;

        const departments = getCheckedFormOptionsValue(departmentOptions);

        const { data: users, pagination } = await getUsers({
            sortByExperience,
            workweek: startDate,
            filter: { departments, onlyShowMainDepartment, search: employeeSearch },
            pagination: { number: pageNumber, size: PAGE_SIZES.SHIFTS.LIST_VIEW },
            includes: ['person', 'departments', 'mainDepartment', 'experience', 'employmentType'],
        }) as unknown as DataWithPagination<BasicUser[]>;
        const userIds = users.map((user: BasicUser) => user.id);

        const shiftConceptPlannings = await (
            getShiftConceptPlanningsApiCall(
                getStartOfWorkDay(startDate),
                getEndOfWorkDay(endDate),
                { departments, userIds },
                ['user', 'shiftConcept', 'shiftConcept.department', 'shiftConcept.department.group'],
            )
        );

        const { usersWithConceptAvailability } = shiftConceptsReducer;

        const transformedUserWithConceptAvailability = users
            .map((user: BasicUser) => {
                const userWithConceptAvailability = usersWithConceptAvailability.find(a => a.id === user.id);

                return transformListViewDataToUserWithConceptAvailability(
                    user,
                    userWithConceptAvailability?.absences || [],
                    userWithConceptAvailability?.leaveOfAbsences || [],
                    userWithConceptAvailability?.preferToWorkTimeSlots || [],
                    userWithConceptAvailability?.unavailableToWorkTimeSlots || [],
                    shiftConceptPlannings.data,
                );
            });

        dispatch(setUsersListViewData({ userWithConceptAvailability: transformedUserWithConceptAvailability, availabilityPaginationNumber: pagination }));
    } catch (error) {
        console.error('[fetchShiftConceptPlannings]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const getUsersListViewData = () => async (dispatch: Dispatch, getState: () => Reducers): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const {
            authenticatedUserReducer,
            filterReducer,
            shiftConceptsReducer,
            weekNavigatorReducer,
            globalFiltersReducer,
        } = getState();
        const { startDate, endDate } = weekNavigatorReducer;
        const { number: availabilityPageNumber } = shiftConceptsReducer.availabilityPagination;
        const { filter } = filterReducer;
        const {
            availability,
            employeeSearch,
            onlyShowMainDepartment,
            shifts,
        } = filter;
        const {
            available,
            unavailable,
            approvedLeaveOfAbsence,
            openLeaveOfAbsence,
            deniedLeaveOfAbsence,
            absence,
            sortByExperience,
        } = availability;
        const { permissions } = authenticatedUserReducer;
        const { departmentOptions } = globalFiltersReducer;
        const departments = getCheckedFormOptionsValue(departmentOptions);

        const canViewAbsences = checkPermission(permissions, 'view-all-absences', 'get-users-list-view-data');
        const canViewLeaveOfAbsences = checkPermission(permissions, 'view-all-leave-of-absences', 'get-users-list-view-data');
        const canViewAllPreferToWorkTimeSlots = checkPermission(permissions, 'view-all-prefer-to-work-time-slots', 'get-users-list-view-data');
        const canViewUnavailableToWorkTimeSlots = checkPermission(permissions, 'view-unavailable-to-work-time-slots', 'get-users-list-view-data');

        const { showPublishedShifts } = shifts;

        const { data: users, pagination } = await getUsers({
            sortByExperience,
            workweek: startDate,
            filter: { departments, onlyShowMainDepartment, search: employeeSearch },
            pagination: { number: availabilityPageNumber, size: PAGE_SIZES.SHIFTS.LIST_VIEW },
            includes: [
                'departments',
                'mainDepartment',
                'experience',
                'employmentType',
                'person',
            ],
        }) as unknown as DataWithPagination<BasicUser[]>;
        const userIds = users.map((user: BasicUser) => user.id);

        const status = [
            ...(openLeaveOfAbsence ? [LEAVE_OF_ABSENCES_REQUEST_OPEN] : []),
            ...(approvedLeaveOfAbsence ? [LEAVE_OF_ABSENCES_REQUEST_APPROVED] : []),
            ...(deniedLeaveOfAbsence ? [LEAVE_OF_ABSENCES_REQUEST_CLOSED] : []),
        ] as leaveOfAbsenceStatus[];

        const [
            preferToWorkTimeSlots,
            unavailableToWorkTimeSlots,
            absences,
            leaveOfAbsences,
            shiftConceptPlannings,
            shiftPlannings,
        ] = await Promise.all([
            (available && canViewAllPreferToWorkTimeSlots) ? getPreferToWorkTimeSlots(startDate, endDate, userIds) : { data: [] } as unknown as AxiosResponse<PreferToWorkTimeSlotResource[]>,
            (unavailable && canViewUnavailableToWorkTimeSlots) ? getUnavailableWorkTimeSlotsLegacy(
                getStartOfWorkDay(startDate),
                getEndOfWorkDay(endDate),
                userIds,
                ['user'],
            ) : { data: [] } as unknown as AxiosResponse<UnavailableToWorkTimeSlot[]>,
            (absence && canViewAbsences) ? getAbsencesInDateRangeLegacy(
                getStartOfWorkDay(startDate),
                getEndOfWorkDay(endDate),
                { departments, userIds },
                undefined,
                [
                    'user',
                    'user.experience',
                    'user.roles',
                    'absenceHours',
                    'absenceHours.payrollPeriod',
                ],
            ) : { data: [] } as unknown as AxiosResponse<Absence[]>,
            (status.length && canViewLeaveOfAbsences) ? getLeaveOfAbsencesLegacy({
                startDate: getStartOfWorkDay(startDate),
                endDate: getEndOfWorkDay(endDate),
                filter: { departments, userIds },
                status,
                includes: [
                    'user',
                    'user.experience',
                    'user.roles',
                    'comments',
                    'comments.owner.person',
                    'comments.owner.roles',
                    'reviewedByUser',
                    'leaveOfAbsenceHours',
                    'leaveOfAbsenceHours.payrollPeriod',
                ],
            }) : { data: [] } as unknown as AxiosResponse<LeaveOfAbsenceResource[]>,
            getShiftConceptPlanningsApiCall(
                getStartOfWorkDay(startDate),
                getEndOfWorkDay(endDate),
                { departments, userIds },
                [
                    'user',
                    'shiftConcept',
                    'shiftConcept.department',
                    'shiftConcept.department.group',
                ],
            ),
            showPublishedShifts ? getShiftPlannings({
                startDate: getStartOfWorkDay(startDate),
                endDate: getEndOfWorkDay(endDate),
                filter: { departments, userIds },
                includes: [
                    'user',
                    'shift',
                    'shift.department',
                    'shift.department.group',
                    'comments',
                ],
            }) : [],
        ]);

        const userWithConceptAvailability = users
            .map((user: BasicUser) => transformListViewDataToUserWithConceptAvailability(
                user,
                absences.data,
                // @ts-ignore
                deductOneSecondFromLOARequests(leaveOfAbsences.data),
                preferToWorkTimeSlots.data,
                unavailableToWorkTimeSlots.data,
                shiftConceptPlannings.data,
                shiftPlannings,
            ));

        dispatch(setUsersListViewData({ userWithConceptAvailability, availabilityPaginationNumber: pagination }));
    } catch (error) {
        console.error('[getUsersListViewData]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const planAvailabilityUserToShiftConcept = (
    userId: string,
    shiftConcept: ShiftConceptViewModelOld,
) => async (dispatch: Dispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setIsSuccessful(false));
    dispatch(setError(''));

    try {
        const addShiftConceptPlanningRequest = transformAddShiftConceptRequest(shiftConcept.id, userId);
        const response = await postShiftConceptPlanningApiCall(addShiftConceptPlanningRequest);
        const updatedShiftConcept: ShiftConceptViewModelOld = {
            ...shiftConcept,
            shiftPlannings: [
                ...shiftConcept.shiftPlannings,
                response,
            ].sort(compareShiftPlanningUsersAlphabetically),
        };
        const userName = getUserFullName(response.user);

        dispatch(setAppToast(translate('pages.shifts.userPlannedOnShiftConcept', { userName }), TOAST_TYPE_PASS));
        dispatch(setIsSuccessful(true));
        dispatch(setShiftConcept(updatedShiftConcept));
    } catch (error) {
        console.error('[planAvailabilityUserToShiftConcept]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
