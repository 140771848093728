import { useMemo } from 'react';

import { useTypedSelector } from '../../redux/store';
import { PermissionSlug } from '../../types/permissionTypes';
import checkPermission from '../permissions/checkPermission';

const useCheckPermission = (slug: PermissionSlug, featureSlug?: string): boolean => {
    const { user } = useTypedSelector(state => state.authenticatedUserReducer);
    const permissions = user?.permissions || [];

    return useMemo(() => checkPermission(permissions, slug, featureSlug), [permissions, slug]);
};

export default useCheckPermission;
