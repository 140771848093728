import React, { Component } from 'react';

import PropTypes from 'prop-types';
import Select from 'react-select';
import {
    Button,
    Col,
    CustomInput,
    FormGroup,
    Label,
    ModalBody,
    ModalFooter,
    Row,
} from 'reactstrap';

import { WaitingDayHoursInput } from '../../../@paco/compositions';
import { doesDateOverlapWithLockedPayrollPeriod } from '../../../@paco/entities/PayrollPeriod/PayrollPeriodHelpers';
import { transformLegacyRoleToRole } from '../../../@paco/entities/Role/RoleTransformers';
import FormDatePicker from '../../../components/FormDatePicker/FormDatePicker';
import ModalHeader from '../../../components/ModelHeader/ModalHeader';
import WeekdayHoursInputs from '../../../components/WeekHoursInputs/WeekHoursInputs';
import { USER_ROLE_EMPLOYEE } from '../../../constants';
import { checkPermission, getUserFullName } from '../../../helpers';
import { formatDate, isValid } from '../../../helpers/date';
import UpToAndIncludingDate from '../../../helpers/date/UpToAndIncludingDate';
import { getPermissionToAdd } from '../../../helpers/permissions/getPermissionToAction';
import { translate } from '../../../helpers/translations/translator';
import transformWeeksWithHoursToPayrollPeriods
    from '../../../services/WeekWithHours/transformWeeksWithHoursToPayrollPeriods';
import SelectEmployeeLabel from '../components/SelectEmployeeLabel/SelectEmployeeLabel';
import getAbsencesForEmployeeInLast4Weeks from '../helpers/getAbsencesForEmployeeInLast4Weeks';

import './Forms.scss';


class AddAbsenceForm extends Component {
    formRef = React.createRef();

    state = {
        employeeId: null,
        startDate: new Date(),
        startTime: formatDate(new Date(), 'HH:mm:00'),
        payrollPeriodError: false,
        canSubmit: false,
        waitingDayHours: null,
        weeksWithHours: [],
        userHasWaitingDayHours: false,
        userHasWaitingDayHoursLoading: false,
        canAddAbsenceHours: false,
    };

    static getDerivedStateFromProps(props, state) {
        const { pacoPayrollPeriods } = props;
        const {
            startDate,
            startTime,
            employeeId,
            userHasWaitingDayHoursLoading,
        } = state;
        const date = `${formatDate(startDate, 'yyyy-MM-dd')} ${startTime}`;

        const payrollPeriodError = doesDateOverlapWithLockedPayrollPeriod(new Date(date), pacoPayrollPeriods);

        const canSubmit = !!employeeId && !!startTime && !userHasWaitingDayHoursLoading && !payrollPeriodError;

        return {
            ...state,
            canSubmit,
            payrollPeriodError,
        };
    }

    doesUserHaveWaitingDayHours = async () => {
        const { startDate, startTime, employeeId } = this.state;
        const date = `${formatDate(startDate, 'yyyy-MM-dd')}T${startTime}`;
        if ((!isValid(new Date(date)) || !employeeId)) {
            return;
        }

        this.setState({
            userHasWaitingDayHoursLoading: true,
        });

        const absences = await getAbsencesForEmployeeInLast4Weeks(employeeId, date);

        this.setState({
            userHasWaitingDayHoursLoading: false,
            userHasWaitingDayHours: !absences.length,
        });
    }

    onFormSubmit = (e) => {
        e.preventDefault();
        const { payrollPeriods } = this.props;
        const {
            canAddAbsenceHours,
            employeeId,
            startDate,
            startTime,
            waitingDayHours,
            weeksWithHours,
        } = this.state;
        const date = `${formatDate(startDate, 'yyyy-MM-dd')} ${startTime}`;
        const payrollPeriodsWithHours = transformWeeksWithHoursToPayrollPeriods(weeksWithHours, payrollPeriods);

        this.props.onSubmit(
            employeeId,
            date,
            canAddAbsenceHours ? payrollPeriodsWithHours : [],
            canAddAbsenceHours ? weeksWithHours : [],
            waitingDayHours || 0,
        );
    };

    onSelectChange = (id) => {
        this.setState({
            employeeId: id,
        }, this.doesUserHaveWaitingDayHours);

        this.setCanAddAbsenceHours(id);
    }

    onChangeDate = (val) => {
        this.setState({
            startDate: val,
        }, this.doesUserHaveWaitingDayHours);
    }

    onChangeTime = (val) => {
        this.setState({
            startTime: val.target.value,
        }, this.doesUserHaveWaitingDayHours);
    }

    onChangeWaitingDayHours = (value) => {
        this.setState({
            waitingDayHours: value === undefined ? null : value,
        });
    }

    onWeekdayHoursInputsChange = (weeksWithHours) => {
        this.setState({
            weeksWithHours,
        });
    }

    getEmployees = () => {
        const { permissions, employees } = this.props;

        if (checkPermission(permissions, 'add-new-absences', 'add-absence-form')) {
            return employees;
        }

        const canAddEmployeeAbsences = checkPermission(permissions, 'add-new-employee-absences', 'add-absence-form');
        return employees.filter(user => (canAddEmployeeAbsences
                && user.roles.find(role => role.slug === USER_ROLE_EMPLOYEE)));
    }

    setCanAddAbsenceHours = (employeeId) => {
        const { currentUserRole, employees, permissions } = this.props;

        const selectedEmployee = employees.find(employee => employee.id === employeeId);

        if (!selectedEmployee) {
            this.setState({
                canAddAbsenceHours: false,
            });
        }

        const canAddAbsenceHours = getPermissionToAdd(
            currentUserRole,
            selectedEmployee.roles.map(transformLegacyRoleToRole),
            permissions,
            'absence-hours',
        );

        this.setState({
            canAddAbsenceHours,
        });
    }

    render() {
        const {
            canSubmit,
            startDate,
            payrollPeriodError,
            waitingDayHours,
            weeksWithHours,
            userHasWaitingDayHours,
            userHasWaitingDayHoursLoading,
            canAddAbsenceHours,
        } = this.state;
        const { onCancel, pacoPayrollPeriods, payrollPeriods } = this.props;
        const employees = this.getEmployees();
        const options = employees.map(employee => ({
            label: <SelectEmployeeLabel user={employee} />,
            value: getUserFullName(employee),
            id: employee.id,
        }));

        return (
            <form ref={this.formRef} onSubmit={this.onFormSubmit}>
                <ModalHeader
                    title={translate('pages.absences.addAbsence')}
                    isLoading={userHasWaitingDayHoursLoading}
                />
                <ModalBody>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label>{translate('common.employee')}</Label>
                                <Select
                                    classNamePrefix="react-select"
                                    placeholder={translate('common.searchEmployee')}
                                    id="employee-search"
                                    noOptionsMessage={() => translate('pages.shifts.noEmployeeFound')}
                                    options={options}
                                    onChange={e => this.onSelectChange(e.id)}
                                    isDisabled={employees.length === 0}
                                    maxMenuHeight={175}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label>{translate('pages.absences.addAbsenceFrom')}</Label>
                                <FormDatePicker
                                    invalid={payrollPeriodError}
                                    feedback={translate('common.datesOverlapWithLockedPayrollPeriod')}
                                    selected={startDate || new Date()}
                                    onChange={this.onChangeDate}
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <FormGroup>
                                    <Label>{translate('pages.tracks.startTime')}</Label>
                                    <CustomInput
                                        type="time"
                                        className="form-control"
                                        id="start-time"
                                        onChange={val => this.onChangeTime(val)}
                                        defaultValue={this.state.startTime}
                                    />
                                </FormGroup>
                            </FormGroup>
                        </Col>
                    </Row>
                    { userHasWaitingDayHours && (
                        <Row>
                            <Col>
                                <WaitingDayHoursInput
                                    isDisabled={!canAddAbsenceHours}
                                    value={waitingDayHours === null ? undefined : waitingDayHours}
                                    onChange={this.onChangeWaitingDayHours}
                                />
                            </Col>
                            <Col />
                        </Row>
                    )}
                    <Row>
                        <Col>
                            <WeekdayHoursInputs
                                disabled={!canAddAbsenceHours}
                                pacoPayrollPeriods={pacoPayrollPeriods}
                                payrollPeriods={payrollPeriods}
                                weekWithHours={weeksWithHours}
                                type="absences"
                                startDate={startDate}
                                endDate={new UpToAndIncludingDate(startDate)}
                                onChange={this.onWeekdayHoursInputsChange}
                            />
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button type="button" color="link" id="modal-close" onClick={onCancel}>
                        {translate('common.cancel')}
                    </Button>
                    <Button type="submit" disabled={!canSubmit} color="orange">{translate('common.save')}</Button>
                </ModalFooter>
            </form>
        );
    }
}

AddAbsenceForm.propTypes = {
    employees: PropTypes.array.isRequired,
    currentUserRole: PropTypes.object.isRequired,
    pacoPayrollPeriods: PropTypes.array.isRequired,
    payrollPeriods: PropTypes.array.isRequired,
    permissions: PropTypes.array.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default AddAbsenceForm;
