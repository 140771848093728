import {
    FC,
    useEffect,
    useMemo,
    useState,
} from 'react';

import { Button, LoadingSpinner } from '../../components';
import { DistrictDivision } from '../../entities/DistrictDivision/DistrictDivision';
import { DistrictDivisionDistrictPlanning } from '../../entities/DistrictDivisionDistrictPlanning/DistrictDivisionDistrictPlanning';
import { Permission } from '../../entities/Permission/Permission';
import { Setting } from '../../entities/Setting/Setting';
import checkPermission from '../../helpers/permissions/checkPermission';
import trans from '../../helpers/trans';
import { EmptyState } from '../index';
import { DistrictDivisionComment, DistrictDivisionDistrictItem } from './subcomponents';

import './DistrictDivisionPlanner.scss';

interface DistrictDivisionPlannerProps {
    isLoading: boolean;
    isDistrictDivisionCommentLoading: boolean;
    isDistrictDivisionCommentSuccessful: boolean;
    isDraggingDistrictDivisionDistrictPlanning: boolean;
    isDraggingShiftPlanning: boolean;
    districtDivision: DistrictDivision;
    districtDivisionComment?: string;
    permissions: Permission[];
    settings: Setting[];
    onDistrictDivisionCommentBlur: () => void;
    onDistrictDivisionCommentChange: (comment?: string) => void;
    onDistrictDivisionDistrictPlanningAddCommentButtonClick: (districtDivisionDistrictPlanning: DistrictDivisionDistrictPlanning) => void;
    onDistrictDivisionDistrictPlanningDeleteButtonClick: (districtDivisionDistrictPlanningId: string, type: string) => void;
    onDistrictDivisionDistrictPlanningDragEnd: () => void;
    onDistrictDivisionDistrictPlanningDragStart: (districtDivisionDistrictPlanning: DistrictDivisionDistrictPlanning) => void;
    onDistrictDivisionDistrictPlanningDrop: (districtDivisionDistrictId: string, index: number) => void;
    className?: string;
}

const DistrictDivisionPlanner: FC<DistrictDivisionPlannerProps> = ({
    isLoading,
    isDistrictDivisionCommentLoading,
    isDistrictDivisionCommentSuccessful,
    isDraggingDistrictDivisionDistrictPlanning,
    isDraggingShiftPlanning,
    districtDivision,
    districtDivisionComment,
    permissions,
    settings,
    onDistrictDivisionCommentBlur,
    onDistrictDivisionCommentChange,
    onDistrictDivisionDistrictPlanningAddCommentButtonClick,
    onDistrictDivisionDistrictPlanningDeleteButtonClick,
    onDistrictDivisionDistrictPlanningDragEnd,
    onDistrictDivisionDistrictPlanningDragStart,
    onDistrictDivisionDistrictPlanningDrop,
    className = '',
}) => {
    const [activeGroupIndex, setActiveGroupIndex] = useState(0);
    const activeGroup = districtDivision.districtDivisionGroups[activeGroupIndex];

    const canDeleteDistrictDivisionDistrictPlannings = useMemo(() => checkPermission(permissions, 'delete-all-district-division-district-plannings'), [permissions]);
    const canAddDistrictDivisionDistrictPlannings = useMemo(() => checkPermission(permissions, 'add-new-district-division-district-plannings'), [permissions]);

    useEffect(() => {
        setActiveGroupIndex(0);
    }, [districtDivision.id]);

    return (
        <div className={`district-division-planner ${className}`}>
            <div className="district-division-planner__group-buttons">
                {districtDivision.districtDivisionGroups.map((group, index) => (
                    <Button
                        key={group.id}
                        text={group.name}
                        onClick={() => setActiveGroupIndex(index)}
                        className={`district-division-planner__group-button ${group.id === activeGroup?.id ? 'district-division-planner__group-button--is-active' : ''}`}
                    />
                ))}
            </div>

            {!!activeGroup?.districtDivisionDistricts.length && (
                <ul className="district-division-planner__plannings">
                    {activeGroup.districtDivisionDistricts.map(district => (
                        <li key={district.id} className="district-division-planner__plannings-list-item">
                            <DistrictDivisionDistrictItem
                                isDraggingDistrictDivisionDistrictPlanning={isDraggingDistrictDivisionDistrictPlanning}
                                isDraggingShiftPlanning={isDraggingShiftPlanning}
                                districtDivisionDistrict={district}
                                showDistrictDivisionDistrictPlanningAddButton={canAddDistrictDivisionDistrictPlannings}
                                showDistrictDivisionDistrictPlanningDeleteButton={canDeleteDistrictDivisionDistrictPlannings}
                                settings={settings}
                                onDistrictDivisionDistrictPlanningAddCommentButtonClick={onDistrictDivisionDistrictPlanningAddCommentButtonClick}
                                onDistrictDivisionDistrictPlanningDeleteButtonClick={onDistrictDivisionDistrictPlanningDeleteButtonClick}
                                onDistrictDivisionDistrictPlanningDragEnd={onDistrictDivisionDistrictPlanningDragEnd}
                                onDistrictDivisionDistrictPlanningDragStart={onDistrictDivisionDistrictPlanningDragStart}
                                onDistrictDivisionDistrictPlanningDrop={onDistrictDivisionDistrictPlanningDrop}
                            />
                        </li>
                    ))}
                </ul>
            )}

            {!districtDivision.districtDivisionGroups.length && (
                <EmptyState
                    title={trans('compositions.districtDivisionPlanner.groupsEmptyStateTitle')}
                    intro={trans('compositions.districtDivisionPlanner.groupsEmptyStateIntro', { templateName: districtDivision.name })}
                    className="district-division-planner__groups-empty-state"
                />
            )}

            <DistrictDivisionComment
                isLoading={isDistrictDivisionCommentLoading}
                isSuccessful={isDistrictDivisionCommentSuccessful}
                comment={districtDivisionComment}
                onBlur={onDistrictDivisionCommentBlur}
                onChange={onDistrictDivisionCommentChange}
                className="district-division-planner__comment"
            />

            {isLoading && <LoadingSpinner isSmall className="district-division-planner__loader" />}
        </div>
    );
};

export default DistrictDivisionPlanner;
