import {
    FC,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from 'react';

import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useToggle } from 'react-use';
import { Button, Modal } from 'reactstrap';

import {
    DepartmentsList,
    IconButton,
    ModalContent,
    PacoModal,
} from '../../@paco/compositions';
import { ConnectedAddUserForm, ConnectedEditUserForm, ConnectedHelpTooltip } from '../../@paco/connectors';
import { transformLegacyDepartmentToDepartment } from '../../@paco/entities/Department/DepartmentTransformers';
import { transformLegacyEmploymentTypeResourceToEmploymentType } from '../../@paco/entities/EmploymentType/EmploymentTypeTransformers';
import { Experience } from '../../@paco/entities/Experience/Experience';
import { transformLegacyRoleToRole } from '../../@paco/entities/Role/RoleTransformers';
import { AuthenticatedUser, FullUser } from '../../@paco/entities/User/User';
import useCheckPermission from '../../@paco/helpers/hooks/useCheckPermission';
import trans from '../../@paco/helpers/trans';
import { fetchExperiences } from '../../@paco/redux/experiences/experiencesActions';
import { useTypedDispatch, useTypedSelector } from '../../@paco/redux/store';
import { getFullUser } from '../../@paco/redux/user/userActions';
import { setPage } from '../../@paco/redux/usersOverview/usersOverviewReducer';
import { CompanyMedicType } from '../../@paco/types';
import { RoleType } from '../../@paco/types/roleTypes';
import ButtonAuthenticate from '../../components/ButtonAuthenticate/ButtonAuthenticate';
import NewFilters from '../../components/Filters/Filters';
import ItemsList from '../../components/ItemsList/ItemsList';
import LegacySkeleton from '../../components/LegacySkeleton/LegacySkeleton';
import { PrivateRouteGroupRoutes } from '../../components/PrivateRouteGroup/PrivateRouteGroupRoutes';
import SearchBar from '../../components/SearchBar/SearchBar';
import SidebarPage from '../../components/SidebarPage/SidebarPage';
import Icon from '../../components/style/Icon/Icon';
import TableContainer from '../../components/Table/TableContainer';
import { USERS_SORT_EMPLOYEE_NUMBER, USERS_SORT_FIRST_NAME, USERS_SORT_LAST_NAME } from '../../constants';
import {
    checkPermission,
    getObjProperty,
    getUserAffixAndLastName,
    hasPermissionToEditUser,
} from '../../helpers';
import { translate } from '../../helpers/translations/translator';
import {
    EmploymentType,
    Pagination,
    Permission,
    Role,
    User,
    UserResource,
    UsersSortType,
} from '../../models';
import { setEmployeeSearch, setFilter } from '../../redux/filter/filterActions';
import { AppFilter } from '../../redux/filter-ts/filterModels';
import {
    exportUsersCSV,
    getUsersQuickly,
    linkUsersToLoket,
    setUsersSorting,
} from '../../redux/management/managementActions';
import { UsersSorting } from '../../redux/management-ts/managementModels';
import { Reducers } from '../../redux/reducers';
import LinkUsersToLoket from './forms/LinkUsersToLoket';
import getLastNumberOfPhone from './helpers/getLastNumberOfPhone';
import { ManagementRoutes } from './ManagementRoutes';

import './Users.scss';

interface UsersProps {
    filter: AppFilter,
    loading: boolean;
    page: number;
    pagination: Pagination,
    users: UserResource[],
    sorting: UsersSorting,
    employmentTypes: EmploymentType[],
    roles: Role[],
    experiences: Experience[],
    permissions: Permission[],
    exportCSVRequest: boolean,
    currentUser?: AuthenticatedUser,
}

const Users: FC<UsersProps> = ({
    filter,
    loading,
    page,
    pagination,
    users,
    sorting,
    employmentTypes,
    roles,
    experiences,
    permissions,
    exportCSVRequest,
    currentUser,
}): ReactElement => {
    const dispatch = useDispatch();
    const typedDispatch = useTypedDispatch();
    const navigate = useNavigate();

    const { fullUser, isFullUserLoading, isFullUserSuccessful } = useSelector((state: Reducers) => state.pacoUserReducer);
    const { departmentOptions } = useTypedSelector(state => state.globalFiltersReducer);

    const tableRef = useRef<HTMLTableElement>(null);

    const [addUserFormIsOpen, toggleAddUserFormIsOpen] = useToggle(false);
    const [editUserFormIsOpen, toggleEditUserFormIsOpen] = useToggle(false);
    const [editFullUserData, setEditFullUserData] = useState<FullUser>();
    const [linkUsersToLoketModal, setLinkUsersToLoketModal] = useState(false);
    const [isMounted, setIsMounted] = useState(false);

    const canAddNewPeople = checkPermission(permissions, 'add-new-people', 'users');
    const canAssignARole = roles.some(role => role.assignable);
    const canCreateNewUsers = checkPermission(permissions, 'create-new-users', 'users');
    const canAddUser = canAddNewPeople && canCreateNewUsers && canAssignARole;

    const getUsers = (newPage = 1): void => {
        dispatch(getUsersQuickly(newPage));
    };

    const handleItemsListPagination = (newPage: number) => {
        dispatch(setPage(newPage));
        getUsers(newPage);
    };

    const handleAfterEditUserSuccess = (): void => {
        getUsers(page);
    };

    const onTableRowClick = (id: string) => {
        navigate(`${PrivateRouteGroupRoutes.management()}/${ManagementRoutes.user(id)}`);
    };

    useEffect((): void => {
        if (isFullUserSuccessful) {
            setEditFullUserData(fullUser);
        }
    }, [isFullUserSuccessful]);

    useEffect((): void => {
        getUsers(isMounted ? undefined : page);

        if (!experiences.length) {
            typedDispatch(fetchExperiences());
        }

        setIsMounted(true);
    }, [departmentOptions]);

    const dispatchSetFilters = (newFilter: any) => {
        dispatch(setFilter(newFilter, getUsers));
    };

    const onSearchBarChange = (value: string) => {
        dispatch(setEmployeeSearch(value));
        getUsers();
    };

    const onEditUserClick = (user: User): void => {
        setEditFullUserData(undefined);
        typedDispatch(getFullUser(user.id));
        toggleEditUserFormIsOpen(true);
    };

    const onCloseUserForm = (): void => {
        toggleEditUserFormIsOpen(false);
    };

    const dispatchExportCSV = () => {
        dispatch(exportUsersCSV());
    };

    const dispatchLinkLoket = () => {
        setLinkUsersToLoketModal(false);
        dispatch(linkUsersToLoket());
    };

    const onSortButtonClick = (type: UsersSortType) => {
        const ascending = type === sorting.active
            ? !sorting[type].ascending : sorting[type].ascending;
        dispatch(setUsersSorting(type, ascending));
    };

    const renderUserExperience = (employee: any) => {
        const experience = getObjProperty(employee, 'person.experience.name');

        if (!experience) {
            return (
                <LegacySkeleton
                    itemWidthRem={1.5}
                />
            );
        }

        return experience;
    };

    const renderDepartments = (employee: any) => {
        const {
            departments: employeeDepartments,
            mainDepartment,
            phone,
            id,
        } = employee;
        const itemCount = 2 + Math.round((getLastNumberOfPhone(phone) || 6) / 2);

        return (
            <div className="management-table-departments">
                {
                    (!employeeDepartments && !mainDepartment) ? (
                        <LegacySkeleton
                            key={id}
                            itemWidthRem={2}
                            itemWidthVariation={2}
                            itemCount={itemCount}
                        />
                    ) : (
                        <DepartmentsList
                            departments={employeeDepartments.map(transformLegacyDepartmentToDepartment)}
                            mainDepartment={mainDepartment ? transformLegacyDepartmentToDepartment(mainDepartment) : undefined}
                        />
                    )
                }
            </div>
        );
    };

    const renderUserFirstName = (user: User): ReactElement => {
        const name = user.firstname;
        const isCompanyMedic = user?.person?.isCompanyMedic === CompanyMedicType.yes;
        const divClassName = classnames('management-table__firstname', {
            'management-table__firstname--is-company-medic': isCompanyMedic,
        });

        return (
            <div data-row-id={user.id} className={divClassName}>
                {name}
            </div>
        );
    };

    const renderUserLastName = (user: User): ReactElement => {
        const name = getUserAffixAndLastName(user);
        const isCompanyMedic = user?.person?.isCompanyMedic === CompanyMedicType.yes;
        const divClassName = classnames('management-table__lastname', {
            'management-table_lastname--is-company-medic': isCompanyMedic,
        });

        return (
            <div data-row-id={user.id} className={divClassName}>
                {name}
            </div>
        );
    };

    const renderEditButton = (user: any) => {
        const className = `clear-button ${isFullUserLoading ? 'disabled' : ''}`;
        if (!hasPermissionToEditUser(user, permissions, currentUser?.id)) {
            return null;
        }

        return (
            <button type="button" onClick={() => onEditUserClick(user)} className={className}>
                <Icon color="light-blue" kind="edit" />
            </button>
        );
    };

    const renderTable = () => (
        <TableContainer
            data={users}
            onRowClick={onTableRowClick}
            className="management-table"
            attributes={
                [
                    {
                        customAction: (employee: any) => employee.employeeNumber,
                        label: '#',
                        type: 'sort',
                        ascending: sorting[USERS_SORT_EMPLOYEE_NUMBER].ascending,
                        active: sorting.active === USERS_SORT_EMPLOYEE_NUMBER,
                        callback: () => onSortButtonClick(USERS_SORT_EMPLOYEE_NUMBER),
                        className: 'users-list__td-employee-number',
                    },
                    {
                        customAction: (employee: any) => renderUserFirstName(employee),
                        label: translate('common.firstName'),
                        type: 'sort',
                        ascending: sorting[USERS_SORT_FIRST_NAME].ascending,
                        active: sorting.active === USERS_SORT_FIRST_NAME,
                        callback: () => onSortButtonClick(USERS_SORT_FIRST_NAME),
                        className: 'users-list__td-first-name',
                    },
                    {
                        customAction: (employee: any) => renderUserLastName(employee),
                        label: translate('common.lastName'),
                        type: 'sort',
                        ascending: sorting[USERS_SORT_LAST_NAME].ascending,
                        active: sorting.active === USERS_SORT_LAST_NAME,
                        callback: () => onSortButtonClick(USERS_SORT_LAST_NAME),
                        className: 'users-list__td-last-name',
                    },
                    {
                        customAction: (employee: any) => employee.email,
                        label: translate('common.email'),
                        className: 'users-list__td-email',
                    },
                    {
                        customAction: (employee: any) => employee.phone,
                        label: translate('common.phone'),
                        className: 'users-list__td-phone',
                    },
                    {
                        customAction: (employee: any) => renderUserExperience(employee),
                        label: translate('common.level'),
                        className: 'users-list__td-experience',
                    },
                    {
                        customAction: (employee: any) => renderDepartments(employee),
                        label: translate('common.departments'),
                        className: 'users-list__td-departments',
                    },
                    {
                        isButton: true,
                        customAction: (employee: any) => renderEditButton(employee),
                        className: 'td-edit',
                    },
                ]
            }
        />
    );

    const renderUsers = () => {
        const { employeeSearch } = filter;
        const searchPlaceholder = translate('common.searchEmployee');
        const roleIsAdminPZOrVesma = currentUser?.role ? [RoleType.admin, RoleType.humanResources, RoleType.establishmentManager].includes(currentUser.role.slug) : false;
        const canExportSv4 = useCheckPermission('export-sv4', 'users');

        return (
            <>
                <div className="users-list-top">
                    <div className="users-list-top-buttons">
                        {roleIsAdminPZOrVesma && (
                            <Button className="button-export calendar-button-loket" onClick={() => setLinkUsersToLoketModal(true)} color="orange">
                                <Icon color="white" kind="cloud_upload" />
                                {translate('common.loket')}
                            </Button>
                        )}
                        <ConnectedHelpTooltip
                            index={4}
                            route="users-overview"
                            subTitle={trans('help.users.exportUsers.title')}
                            text={trans('help.users.exportUsers.text')}
                            title={trans('help.users.title')}
                            className="users__export-users-help-tooltip"
                        />
                        <Button className="button-export calendar-button-export" onClick={dispatchExportCSV} disabled={!canExportSv4 || exportCSVRequest} color="orange">
                            <Icon color="white" kind="arrow_downward" />
                            <span>
                                {trans('containers.users.exportUsersList')}
                            </span>
                        </Button>
                    </div>
                    <ConnectedHelpTooltip
                        index={5}
                        route="users-overview"
                        subTitle={trans('help.users.searchUsers.title')}
                        text={trans('help.users.searchUsers.text')}
                        title={trans('help.users.title')}
                        className="users__search-users-help-tooltip"
                    />
                    <SearchBar
                        placeholder={searchPlaceholder}
                        startVal={employeeSearch}
                        onChange={onSearchBarChange}
                    />
                </div>
                <ItemsList
                    loading={loading}
                    itemsLength={users.length}
                    pagination={{ ...pagination, number: page }}
                    emptyMessage={translate('pages.management.emptyList')}
                    onPagination={handleItemsListPagination}
                >
                    {renderTable()}
                </ItemsList>
            </>
        );
    };

    return (
        <>
            <SidebarPage
                sidebarContent={(
                    <NewFilters
                        filter={filter}
                        roles={roles}
                        employmentTypes={employmentTypes}
                        button={canAddUser ? (
                            <IconButton
                                icon="plus"
                                text={translate('common.user')}
                                onClick={toggleAddUserFormIsOpen}
                                className="users__add-user-button"
                            />
                        ) : null}
                        filterBlocks={[
                            'user-types',
                            'show-inactive-users',
                            'main-department',
                            'users-loket',
                        ]}
                        onChange={dispatchSetFilters}
                    />
                )}
            >
                <ConnectedHelpTooltip
                    index={0}
                    route="users-overview"
                    subTitle={trans('help.users.addUser.title')}
                    text={trans('help.users.addUser.text')}
                    title={trans('help.users.title')}
                    className="users__add-user-help-tooltip"
                />
                <ConnectedHelpTooltip
                    index={1}
                    route="users-overview"
                    subTitle={trans('help.users.filters.title')}
                    text={trans('help.users.filters.text')}
                    title={trans('help.users.title')}
                    showMobileInfoWarning
                    className="users__filters-help-tooltip"
                />
                <ConnectedHelpTooltip
                    index={2}
                    route="users-overview"
                    subTitle={trans('help.users.userOverview.title')}
                    text={trans('help.users.userOverview.text')}
                    title={trans('help.users.title')}
                    className="users__user-overview-help-tooltip"
                />
                <ConnectedHelpTooltip
                    index={3}
                    route="users-overview"
                    subTitle={trans('help.users.userDetails.title')}
                    text={trans('help.users.userDetails.text')}
                    title={trans('help.users.title')}
                    className="users__user-details-help-tooltip"
                />
                <div className="management-list users-list users" ref={tableRef}>
                    {renderUsers()}
                </div>
            </SidebarPage>
            {addUserFormIsOpen && (
                <PacoModal>
                    <ModalContent title={trans('containers.forms.addUserForm.addUser')}>
                        <ConnectedAddUserForm
                            experiences={experiences}
                            roles={roles.map(transformLegacyRoleToRole).filter(role => role.assignable)}
                            employmentType={employmentTypes.map(transformLegacyEmploymentTypeResourceToEmploymentType)}
                            onClose={toggleAddUserFormIsOpen}
                        />
                    </ModalContent>
                </PacoModal>
            )}
            {(editUserFormIsOpen && editFullUserData) && (
                <PacoModal>
                    <ModalContent title={trans('common.editUser')}>
                        <ConnectedEditUserForm
                            experiences={experiences}
                            roles={roles.map(transformLegacyRoleToRole).filter(role => role.assignable)}
                            employmentTypes={employmentTypes.map(transformLegacyEmploymentTypeResourceToEmploymentType)}
                            fullUser={editFullUserData}
                            currentUserId={currentUser?.id}
                            afterEditUserSuccess={handleAfterEditUserSuccess}
                            onClose={onCloseUserForm}
                        />
                    </ModalContent>
                </PacoModal>
            )}
            <Modal isOpen={linkUsersToLoketModal}>
                <LinkUsersToLoket
                    onSubmit={dispatchLinkLoket}
                    onCancel={() => setLinkUsersToLoketModal(false)}
                />
            </Modal>
            <ButtonAuthenticate
                mobile
                onClick={toggleAddUserFormIsOpen}
            />
        </>
    );
};

export default Users;
