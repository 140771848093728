import React, { Component } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { FormGroup, Input, Label } from 'reactstrap';

import { translate } from '../../helpers/translations/translator';
import Icon from '../style/Icon/Icon';

import './SearchBar.scss';


let timeoutId = null;

class SearchBar extends Component {
    state = {
        value: null,
    };

    throttleOnChange = (callback, value) => {
        const { noThrottle } = this.props;
        if (!noThrottle) {
            clearInterval(timeoutId);
            timeoutId = setTimeout(callback, 500, value);
        } else {
            callback(value);
        }

        this.setState({
            value,
        });
    }

    render() {
        const {
            onChange,
            placeholder,
            startVal,
            disabled,
            id,
            className,
        } = this.props;
        const { value } = this.state;

        const searchBarClassNames = classNames('search-bar', {
            'search-bar--disabled': disabled,
        }, className);

        return (
            <FormGroup className={searchBarClassNames}>
                <Label className="search-bar-label" for="search-box">
                    <Icon kind="search" color="primary" fontSize={20} />
                </Label>
                <Input
                    className="search-bar-input"
                    type="text"
                    name="search-box"
                    id={id || 'search-box'}
                    value={value !== null ? value : startVal}
                    onChange={e => this.throttleOnChange(onChange, e.target.value)}
                    placeholder={placeholder || translate('commmon.search')}
                    autoComplete="off"
                />
            </FormGroup>
        );
    }
}

SearchBar.propTypes = {
    startVal: PropTypes.string,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    noThrottle: PropTypes.bool,
    disabled: PropTypes.bool,
    id: PropTypes.string,
    className: PropTypes.string,
};
SearchBar.defaultProps = {
    startVal: '',
    onChange: null,
    placeholder: null,
    noThrottle: false,
    disabled: false,
    id: null,
    className: '',
};

export default SearchBar;
