import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Modal } from 'reactstrap';

import { ConnectedHelpTooltip } from '../../@paco/connectors';
import trans from '../../@paco/helpers/trans';
import AbsencesTable from '../../components/AbsencesTable/AbsencesTable';
import NewFilters from '../../components/Filters/Filters';
import ItemsList from '../../components/ItemsList/ItemsList';
import SearchBar from '../../components/SearchBar/SearchBar';
import SidebarPage from '../../components/SidebarPage/SidebarPage';
import Icon from '../../components/style/Icon/Icon';
import { ABSENCES_OVERVIEW, TIME_MODES } from '../../constants';
import { getRangeFromDate } from '../../helpers/date';
import { translate } from '../../helpers/translations/translator';
import {
    deleteAbsence,
    editAbsence,
    exportCSV,
    getAbsencesInDateRange,
} from '../../redux/absences-ts/absencesActions';
import { setEmployeeSearch, setFilter } from '../../redux/filter/filterActions';
import { setStartAndEndDate, setTimeMode } from '../../redux/weekNavigator/weekNavigatorActions';
import CalendarToolbar from '../Schedule/components/CalendarToolbar/CalendarToolbar';
import EditAbsenceForm from './forms/EditAbsenceForm/EditAbsenceForm';

class AbsencesOverview extends Component {
    state = {
        editAbsenceForm: false,
        activeAbsence: null,
    };

    componentDidMount() {
        this.getAbsences();
    }

    componentDidUpdate(prevProps) {
        const activeDepartmentOptions = this.props.departmentOptions.filter(option => option.isChecked);
        const prevActiveDepartmentOptions = prevProps.departmentOptions.filter(option => option.isChecked);

        if (activeDepartmentOptions.length !== prevActiveDepartmentOptions.length) {
            this.getAbsences();
        }
    }

    componentWillUnmount() {
        // If timeMode is year then set it back to month (year view is only used on this page)
        if (this.props.timeMode === TIME_MODES.YEAR) {
            const days = getRangeFromDate(new Date(), TIME_MODES.MONTH);
            this.props.dispatch(setStartAndEndDate(days[0], days[0], days[days.length - 1]));
            this.props.dispatch(setTimeMode(TIME_MODES.MONTH));
        }
    }

    dispatchExportTracks = () => {
        this.props.dispatch(exportCSV());
    }

    dispatchSetFilters = (filter) => {
        this.props.dispatch(setFilter(filter, this.getAbsences));
    }

    dispatchEditAbsence = (
        absence,
        startDate,
        endDate,
        periodsWithHours,
        weeksWithHours,
        waitingDayHours,
    ) => {
        this.props.dispatch(editAbsence(
            absence,
            startDate,
            endDate,
            periodsWithHours,
            weeksWithHours,
            waitingDayHours,
            ABSENCES_OVERVIEW,
        ));
        this.setState({ editAbsenceForm: false });
    }

    dispatchDeleteAbsence = (absenceId) => {
        this.props.dispatch(deleteAbsence(absenceId, ABSENCES_OVERVIEW));
        this.setState({ editAbsenceForm: false });
    }

    setStartAndEndDate = (selectedDate, startDate, endDate) => {
        this.props.dispatch(setStartAndEndDate(selectedDate, startDate, endDate));
        this.getAbsences();
    }

    getAbsences = () => {
        this.props.dispatch(getAbsencesInDateRange());
    }

    onSearchBarChange = (value) => {
        this.props.dispatch(setEmployeeSearch(value));
        this.getAbsences();
    }

    addEditAbsenceForm = (absence) => {
        this.setState({
            editAbsenceForm: true,
            activeAbsence: absence,
        });
    }

    renderAbsences() {
        const {
            loading,
            pagination,
            absencesInDateRange: absences,
            permissions,
            currentUser,
        } = this.props;

        return (
            <ItemsList
                loading={loading}
                itemsLength={absences.length}
                pagination={pagination}
                emptyMessage={translate('pages.absences.emptyAbsencesList')}
                onPagination={this.getAbsences}
            >
                <AbsencesTable
                    absences={absences}
                    permissions={permissions}
                    currentUserRole={currentUser.role}
                    onAbsenceClick={this.addEditAbsenceForm}
                />
            </ItemsList>
        );
    }

    render() {
        const {
            departments,
            filter,
            roles,
            currentUser,
            employmentTypes,
            timeMode,
            pacoPayrollPeriods,
            payrollPeriods,
            permissions,
        } = this.props;
        const { editAbsenceForm, activeAbsence } = this.state;
        const { employeeSearch } = filter;

        return (
            <>
                <SidebarPage
                    sidebarContent={(
                        <NewFilters
                            departments={departments}
                            filter={filter}
                            roles={roles}
                            employmentTypes={employmentTypes}
                            filterBlocks={[
                                'user-types',
                                'contract-types',
                            ]}
                            onChange={this.dispatchSetFilters}
                        />
                    )}
                >
                    <div className="absences-list">
                        <div className="absences-list-top">
                            <CalendarToolbar
                                timeMode={timeMode}
                                payrollPeriods={payrollPeriods}
                                setStartAndEndDate={this.setStartAndEndDate}
                                afterDateChange={this.getAbsences}
                                yearMode
                            />
                            <div className="absences-list-top-right">
                                <Button className="button-export absences-overview-button-export" onClick={this.dispatchExportTracks} color="orange">
                                    <Icon color="white" kind="arrow_downward" />
                                    {translate('common.export')}
                                </Button>
                                <SearchBar placeholder={translate('common.searchEmployee')} startVal={employeeSearch} onChange={this.onSearchBarChange} />
                            </div>
                        </div>
                        {this.renderAbsences()}
                        <ConnectedHelpTooltip
                            index={0}
                            route="absences-overview"
                            subTitle={trans('help.absences.absencesOverview.title')}
                            text={trans('help.absences.absencesOverview.text')}
                            title={trans('help.absences.title')}
                            className="absences__absences-overview-help-tooltip"
                        />
                        <ConnectedHelpTooltip
                            index={1}
                            route="absences-overview"
                            subTitle={trans('help.absences.absencesOverviewFilters.title')}
                            text={trans('help.absences.absencesOverviewFilters.text')}
                            title={trans('help.absences.title')}
                            showMobileInfoWarning
                            className="absences__filters-help-tooltip"
                        />
                        <ConnectedHelpTooltip
                            index={2}
                            route="absences-overview"
                            subTitle={trans('help.absences.exportAbsences.title')}
                            text={trans('help.absences.exportAbsences.text')}
                            title={trans('help.absences.title')}
                            className="absences__export-absences-help-tooltip"
                        />
                    </div>
                </SidebarPage>
                <Modal size="lg" isOpen={editAbsenceForm} className="form-edit-absence">
                    <EditAbsenceForm
                        data={activeAbsence}
                        permissions={permissions}
                        pacoPayrollPeriods={pacoPayrollPeriods}
                        payrollPeriods={payrollPeriods}
                        currentUserRole={currentUser.role}
                        onSubmit={this.dispatchEditAbsence}
                        onDelete={this.dispatchDeleteAbsence}
                        onCancel={() => this.setState({ editAbsenceForm: false })}
                    />
                </Modal>
            </>
        );
    }
}

AbsencesOverview.propTypes = {
    dispatch: PropTypes.func.isRequired,
    departments: PropTypes.array.isRequired,
    pacoPayrollPeriods: PropTypes.array.isRequired,
    payrollPeriods: PropTypes.array.isRequired,
    employmentTypes: PropTypes.array.isRequired,
    permissions: PropTypes.array.isRequired,
    filter: PropTypes.object.isRequired,
    roles: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    timeMode: PropTypes.string.isRequired,
    pagination: PropTypes.object.isRequired,
    absencesInDateRange: PropTypes.array.isRequired,
};

export default connect()(AbsencesOverview);
