import { ManagementRoutes } from '../../../pages/Management/ManagementRoutes';
import trans from '../../helpers/trans';
import { SubNavRoute } from '../routes';

export type UserNavigationRouteKey = 'users-overview';

const userNavigationRoutes: SubNavRoute[] = [
    {
        key: 'users-overview',
        path: ManagementRoutes.users(),
        label: trans('routes.users.users.label'),
        permissions: ['view-all-user-profiles'],
    },
];

export default userNavigationRoutes;
