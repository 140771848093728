import {
    all,
    call,
    put,
    select,
    takeLatest,
} from 'redux-saga/effects';

import { getCheckedFormOptionsValue } from '../../@paco/helpers/types/formOption';
import { getStartDate } from '../../components/WeekNavigator/Controls/Controls';
import {
    ABSENCES_OVERVIEW,
    AVAILABILITY_CALENDAR,
    CONCEPTS_LIST_VIEW,
    EXCHANGES,
    LEAVE_OF_ABSENCE_REQUESTS_CALENDAR,
    LEAVE_OF_ABSENCES_REQUEST_APPROVED,
    LEAVE_OF_ABSENCES_REQUEST_OPEN,
    LIST_VIEW,
    PAGE_SIZES,
    TOAST_TYPE_FAIL,
    TOAST_TYPE_PASS,
    USER,
} from '../../constants';
import { checkPermission, generateDownloadURI, getObjProperty } from '../../helpers';
import {
    deleteAbsence,
    deleteExchange,
    getAbsences,
    getAbsencesInDateRange,
    getAbsencesInDateRangeCSV,
    getLeaveOfAbsences,
    patchAbsence,
    patchLeaveOfAbsence,
    postAbsence,
    postExchangeLink,
    postLeaveOfAbsence,
    postLeaveOfAbsenceAccept,
    postLeaveOfAbsenceDecline,
    postLeaveOfAbsenceHours,
    postLeaveOfAbsencesToLoket,
} from '../../helpers/api/absencesApi';
import { postComment } from '../../helpers/api/commentsApi';
import { delUserFromShift } from '../../helpers/api/shiftsApi';
import { getExchangesInDateRange } from '../../helpers/api-ts/exchange';
import { getShiftExchangesInDateRange } from '../../helpers/api-ts/shiftExchange';
import { postShiftPlanning } from '../../helpers/api-ts/shiftPlanning';
import { formatDate } from '../../helpers/date';
import { translate } from '../../helpers/translations/translator';
import { getUsersListViewData } from '../@toolkit/shiftConcepts/shiftConceptsActions';
import { deductOneSecondFromLOARequests, updateAbsenceHours, updateLeaveOfAbsenceHours } from '../absences-ts/absencesHelpers';
import { approveShiftExchange, declineShiftExchange, getAndApplyLeaveOfAbsenceLoketLeaveBalances } from '../absences-ts/absencesSaga';
import {
    ABSENCES_ADD_ABSENCE_FAILURE,
    ABSENCES_ADD_ABSENCE_REQUEST,
    ABSENCES_ADD_ABSENCE_SUCCESS,
    ABSENCES_ADD_LEAVE_OF_ABSENCE_REQUEST_FAILURE,
    ABSENCES_ADD_LEAVE_OF_ABSENCE_REQUEST_REQUEST,
    ABSENCES_ADD_LEAVE_OF_ABSENCE_REQUEST_SUCCESS,
    ABSENCES_APPROVE_EXCHANGE_REQUEST_FAILURE,
    ABSENCES_APPROVE_EXCHANGE_REQUEST_REQUEST,
    ABSENCES_APPROVE_EXCHANGE_REQUEST_SUCCESS,
    ABSENCES_DECLINE_EXCHANGE_REQUEST_FAILURE,
    ABSENCES_DECLINE_EXCHANGE_REQUEST_REQUEST,
    ABSENCES_DECLINE_EXCHANGE_REQUEST_SUCCESS,
    ABSENCES_DELETE_ABSENCE_FAILURE,
    ABSENCES_DELETE_ABSENCE_REQUEST,
    ABSENCES_DELETE_ABSENCE_SUCCESS,
    ABSENCES_EDIT_ABSENCE_FAILURE,
    ABSENCES_EDIT_ABSENCE_REQUEST,
    ABSENCES_EDIT_ABSENCE_SUCCESS,
    ABSENCES_EXPORT_CSV_FAILURE,
    ABSENCES_EXPORT_CSV_SUCCESS,
    ABSENCES_GET_ABSENCES_FAILURE,
    ABSENCES_GET_ABSENCES_IN_DATE_RANGE_FAILURE,
    ABSENCES_GET_ABSENCES_IN_DATE_RANGE_REQUEST,
    ABSENCES_GET_ABSENCES_IN_DATE_RANGE_SUCCESS,
    ABSENCES_GET_ABSENCES_REQUEST,
    ABSENCES_GET_ABSENCES_SUCCESS,
    ABSENCES_GET_CALENDAR_LEAVE_OF_ABSENCE_REQUESTS_SUCCESS,
    ABSENCES_GET_DATA_FOR_SHIFTS_AND_USERS_FAILURE,
    ABSENCES_GET_DATA_FOR_SHIFTS_AND_USERS_REQUEST,
    ABSENCES_GET_DATA_FOR_SHIFTS_AND_USERS_SUCCESS,
    ABSENCES_GET_EXCHANGE_REQUESTS_FAILURE,
    ABSENCES_GET_EXCHANGE_REQUESTS_REQUEST,
    ABSENCES_GET_EXCHANGE_REQUESTS_SUCCESS,
    ABSENCES_GET_LEAVE_OF_ABSENCE_REQUESTS_FAILURE,
    ABSENCES_GET_LEAVE_OF_ABSENCE_REQUESTS_REQUEST,
    ABSENCES_GET_OPEN_LEAVE_OF_ABSENCE_REQUESTS_SUCCESS,
    ABSENCES_HANDLE_LEAVE_OF_ABSENCE_FAILURE,
    ABSENCES_HANDLE_LEAVE_OF_ABSENCE_REQUEST,
    ABSENCES_HANDLE_LEAVE_OF_ABSENCE_SUCCESS,
    ABSENCES_REPLACE_USER_IN_SHIFT_FAILURE,
    ABSENCES_REPLACE_USER_IN_SHIFT_REQUEST,
    ABSENCES_REPLACE_USER_IN_SHIFT_SUCCESS,
    ABSENCES_SAGA_ACCEPT_LEAVE_OF_ABSENCE,
    ABSENCES_SAGA_ADD_ABSENCE,
    ABSENCES_SAGA_ADD_LEAVE_OF_ABSENCE_REQUEST,
    ABSENCES_SAGA_APPROVE_EXCHANGE_REQUEST,
    ABSENCES_SAGA_APPROVE_SHIFT_EXCHANGE,
    ABSENCES_SAGA_DECLINE_EXCHANGE_REQUEST,
    ABSENCES_SAGA_DECLINE_LEAVE_OF_ABSENCE,
    ABSENCES_SAGA_DECLINE_SHIFT_EXCHANGE,
    ABSENCES_SAGA_DELETE_ABSENCE,
    ABSENCES_SAGA_EDIT_ABSENCE,
    ABSENCES_SAGA_EDIT_LEAVE_OF_ABSENCE,
    ABSENCES_SAGA_EXPORT_CSV,
    ABSENCES_SAGA_GET_ABSENCES,
    ABSENCES_SAGA_GET_ABSENCES_IN_DATE_RANGE,
    ABSENCES_SAGA_GET_EXCHANGE_REQUESTS,
    ABSENCES_SAGA_GET_LEAVE_OF_ABSENCE_REQUESTS,
    ABSENCES_SAGA_REPLACE_USER_IN_SHIFT,
    ABSENCES_SAGA_RESOLVE_ABSENCE,
    ABSENCES_SAGA_SYNC_LEAVE_OF_ABSENCES_TO_LOKET,
    ABSENCES_SYNC_LEAVE_OF_ABSENCES_TO_LOKET_FAILURE,
    ABSENCES_SYNC_LEAVE_OF_ABSENCES_TO_LOKET_REQUEST,
    ABSENCES_SYNC_LEAVE_OF_ABSENCES_TO_LOKET_SUCCESS,
    APP_SAGA_ADD_TOAST,
} from '../actionTypes';
import { getCurrentUserFromStore } from '../app/appHelpers';
import { setPagination } from '../app/appSaga';
import { fetchUser } from '../management-ts/managementSaga';
import { getUsersWithAvailability } from '../shifts-ts/shiftsActions';
import { getAvailability } from '../shifts-ts/shiftsSaga';

export function* fetchLeaveOfAbsenceRequests(action) {
    yield put({ ...action, type: ABSENCES_GET_LEAVE_OF_ABSENCE_REQUESTS_REQUEST });

    try {
        const state = yield select();
        const { startDate, endDate } = state.weekNavigatorReducer;
        const { leaveOfAbsencesStatus: status, leaveOfAbsencesPage: page } = state.absencesReducer;
        const { filter } = state.filterReducer;
        const { departmentOptions } = state.globalFiltersReducer;
        const departments = getCheckedFormOptionsValue(departmentOptions);

        const response = yield call(() => getLeaveOfAbsences(
            startDate,
            endDate,
            status,
            departments,
            filter,
            page,
            state.paginationReducer,
        ));

        yield call(() => setPagination(response.meta));
        const leaveOfAbsencesRequests = deductOneSecondFromLOARequests(response.data);

        yield put({
            type: page !== LEAVE_OF_ABSENCE_REQUESTS_CALENDAR
                ? ABSENCES_GET_OPEN_LEAVE_OF_ABSENCE_REQUESTS_SUCCESS
                : ABSENCES_GET_CALENDAR_LEAVE_OF_ABSENCE_REQUESTS_SUCCESS,
            leaveOfAbsencesRequests,
        });

        yield getAndApplyLeaveOfAbsenceLoketLeaveBalances();
    } catch (error) {
        console.error(error);
        yield put({ type: ABSENCES_GET_LEAVE_OF_ABSENCE_REQUESTS_FAILURE });
    }
}

export function* fetchAbsencesDataForShiftsAndUsers() {
    const state = yield select();
    const { startDate, endDate } = state.weekNavigatorReducer;
    const currentUser = yield getCurrentUserFromStore();
    const { permissions } = currentUser;
    yield put({ type: ABSENCES_GET_DATA_FOR_SHIFTS_AND_USERS_REQUEST, startDate, endDate });

    try {
        const status = [LEAVE_OF_ABSENCES_REQUEST_OPEN, LEAVE_OF_ABSENCES_REQUEST_APPROVED];
        const canViewAllAbsences = checkPermission(permissions, 'view-all-absences', 'absences-saga');

        const responses = yield all([
            getLeaveOfAbsences(startDate, endDate, status),
            canViewAllAbsences ? getAbsencesInDateRange(startDate, endDate) : { data: [] },
        ]);

        const leaveOfAbsencesRequests = responses[0];
        const absences = responses[1];

        yield put({
            type: ABSENCES_GET_DATA_FOR_SHIFTS_AND_USERS_SUCCESS,
            leaveOfAbsencesRequests: leaveOfAbsencesRequests.data,
            absences: absences.data,
        });
    } catch (error) {
        console.error(error);
        yield put({ type: ABSENCES_GET_DATA_FOR_SHIFTS_AND_USERS_FAILURE });
    }
}

function* fetchExchangeRequests() {
    yield put({ type: ABSENCES_GET_EXCHANGE_REQUESTS_REQUEST });

    try {
        const state = yield select();
        const { filter } = state.filterReducer;
        const { departmentOptions } = state.globalFiltersReducer;
        const pagination = state.paginationReducer;
        const departmentIds = getCheckedFormOptionsValue(departmentOptions);

        const [exchangeResponse, shiftExchangeResponse] = yield all([
            getExchangesInDateRange(
                new Date(),
                undefined,
                filter,
                departmentIds,
                {
                    number: pagination.number,
                    size: PAGE_SIZES.ABSENCES[EXCHANGES],
                },
                [
                    'replacementUsers',
                    'owner',
                    'owner.experience',
                    'logs',
                    'shift',
                    'shift.shiftPlannings',
                    'shift.shiftPlannings.user',
                    'shift.shiftPlannings.user.person',
                    'shift.department',
                    'shift.department.group',
                ],
            ),
            yield getShiftExchangesInDateRange(
                new Date(),
                undefined,
                filter,
                departmentIds,
                {
                    number: pagination.number,
                    size: PAGE_SIZES.ABSENCES[EXCHANGES],
                },
                [
                    'shiftPlanning1',
                    'shiftPlanning2',
                    'shiftPlanning1.user',
                    'shiftPlanning2.user',
                    'shiftPlanning1.user.person',
                    'shiftPlanning2.user.person',
                    'shiftPlanning1.shift',
                    'shiftPlanning2.shift',
                    'shiftPlanning1.shift.shiftPlannings',
                    'shiftPlanning2.shift.shiftPlannings',
                    'shiftPlanning1.shift.department',
                    'shiftPlanning2.shift.department',
                    'shiftPlanning1.shift.department.group',
                    'shiftPlanning2.shift.department.group',
                ],
            ),
        ]);

        yield put({
            type: ABSENCES_GET_EXCHANGE_REQUESTS_SUCCESS,
            exchangeRequests: exchangeResponse.data,
            shiftExchanges: shiftExchangeResponse,
        });
    } catch (error) {
        console.error(error);
        yield put({ type: ABSENCES_GET_EXCHANGE_REQUESTS_FAILURE });
    }
}

function* fetchAbsences() {
    yield put({ type: ABSENCES_GET_ABSENCES_REQUEST });

    try {
        const state = yield select();
        const { filter } = state.filterReducer;
        const { departmentOptions } = state.globalFiltersReducer;
        const departmentIds = getCheckedFormOptionsValue(departmentOptions);

        const response = yield call(() => getAbsences(filter, departmentIds, state.paginationReducer));

        yield call(() => setPagination(response.meta));

        yield put({ type: ABSENCES_GET_ABSENCES_SUCCESS, absences: response.data });
    } catch (error) {
        yield put({ type: ABSENCES_GET_ABSENCES_FAILURE });
    }
}

export function* fetchAbsencesInDateRange() {
    yield put({ type: ABSENCES_GET_ABSENCES_IN_DATE_RANGE_REQUEST });

    try {
        const state = yield select();
        const { startDate, endDate } = state.weekNavigatorReducer;
        const { filter } = state.filterReducer;
        const { departmentOptions } = state.globalFiltersReducer;
        const departments = getCheckedFormOptionsValue(departmentOptions);

        const response = yield call(() => getAbsencesInDateRange(startDate, endDate, filter, departments, state.paginationReducer));

        yield call(() => setPagination(response.meta));

        yield put({ type: ABSENCES_GET_ABSENCES_IN_DATE_RANGE_SUCCESS, absences: response.data });
    } catch (error) {
        yield put({ type: ABSENCES_GET_ABSENCES_IN_DATE_RANGE_FAILURE });
    }
}

function* approveExchangeRequest(action) {
    yield put({ type: ABSENCES_APPROVE_EXCHANGE_REQUEST_REQUEST });

    try {
        yield call(() => postExchangeLink(action.requestId, action.userId));
        yield put({ type: ABSENCES_APPROVE_EXCHANGE_REQUEST_SUCCESS });
        yield put({ type: ABSENCES_SAGA_GET_EXCHANGE_REQUESTS });
        yield put({ type: APP_SAGA_ADD_TOAST, toast: translate('pages.absences.approveExchangeSuccess'), toastType: TOAST_TYPE_PASS });
    } catch (error) {
        yield put({ type: APP_SAGA_ADD_TOAST, toast: getObjProperty(error, 'meta.message'), toastType: TOAST_TYPE_FAIL });
        yield put({ type: ABSENCES_APPROVE_EXCHANGE_REQUEST_FAILURE });
    }
}

function* declineExchangeRequest(action) {
    yield put({ type: ABSENCES_DECLINE_EXCHANGE_REQUEST_REQUEST });

    try {
        yield call(() => deleteExchange(action.requestId));
        yield put({ type: ABSENCES_DECLINE_EXCHANGE_REQUEST_SUCCESS });
        yield put({ type: ABSENCES_SAGA_GET_EXCHANGE_REQUESTS });
        yield put({ type: APP_SAGA_ADD_TOAST, toast: translate('pages.absences.declineExchangeSuccess'), toastType: TOAST_TYPE_PASS });
    } catch (error) {
        yield put({ type: APP_SAGA_ADD_TOAST, toast: getObjProperty(error, 'meta.message'), toastType: TOAST_TYPE_FAIL });
        yield put({ type: ABSENCES_DECLINE_EXCHANGE_REQUEST_FAILURE });
    }
}

function* loadLeaveOfAbsencesAfterPatchOrDelete(page) {
    if (page === USER) {
        yield call(fetchUser);
    } else if (page === LIST_VIEW) {
        yield put(getUsersWithAvailability());
    } else if (page === CONCEPTS_LIST_VIEW) {
        yield put(getUsersListViewData());
    } else if (page === AVAILABILITY_CALENDAR) {
        yield call(getAvailability);
    } else {
        yield call(fetchLeaveOfAbsenceRequests);
    }
}

function* loadAbsencesAfterPatchOrDelete(page) {
    if (page === USER) {
        yield call(fetchUser);
    } else if (page === ABSENCES_OVERVIEW) {
        yield call(fetchAbsencesInDateRange);
    } else if (page === AVAILABILITY_CALENDAR) {
        yield call(getAvailability);
    } else if (page === LIST_VIEW) {
        yield put(getUsersWithAvailability());
    } else if (page === CONCEPTS_LIST_VIEW) {
        yield put(getUsersListViewData());
    } else {
        yield call(fetchAbsences);
    }
}

function* handleLeaveOfAbsence(action) {
    yield put({ type: ABSENCES_HANDLE_LEAVE_OF_ABSENCE_REQUEST });
    const {
        editType,
        weeksWithHours = [],
        leaveOfAbsence,
        declineComment,
        data,
        leaveType,
    } = action;

    let toast = translate('pages.absences.editLOASuccess');

    try {
        if (editType === 'accept') {
            toast = translate('pages.absences.acceptLOARequest');
            yield all([
                ...[data && call(patchLeaveOfAbsence, leaveOfAbsence.id, data)],
                call(postLeaveOfAbsenceAccept, leaveOfAbsence.id),
            ]);
        } else if (editType === 'deny') {
            toast = translate('pages.absences.refuseLOARequest');
            yield call(postComment, leaveOfAbsence.id, declineComment, 'leaveOfAbsence', 'leaveOfAbsences');
            yield call(postLeaveOfAbsenceDecline, leaveOfAbsence.id);
        } else {
            yield call(patchLeaveOfAbsence, leaveOfAbsence.id, data);
        }

        yield updateLeaveOfAbsenceHours(
            leaveOfAbsence.id,
            leaveOfAbsence.leaveOfAbsenceHours,
            weeksWithHours,
            leaveType || 'normal',
        );

        // This saga can be called from the user, availability or from leave of absences calendar
        yield loadLeaveOfAbsencesAfterPatchOrDelete(action.page);

        yield put({
            type: ABSENCES_HANDLE_LEAVE_OF_ABSENCE_SUCCESS,
            leaveOfAbsenceId: leaveOfAbsence.id,
        });

        yield put({ type: APP_SAGA_ADD_TOAST, toastType: TOAST_TYPE_PASS, toast });
    } catch (error) {
        yield put({ type: ABSENCES_HANDLE_LEAVE_OF_ABSENCE_FAILURE });
    }
}

function* editAbsence(action) {
    yield put({ type: ABSENCES_EDIT_ABSENCE_REQUEST });
    try {
        const {
            weekdays,
            absence,
            startDate,
            endDate,
            waitingDayHours,
        } = action;

        yield all([
            yield patchAbsence(absence.id, startDate, endDate),
            yield updateAbsenceHours(
                absence.id,
                absence.absenceHours || [],
                weekdays,
                waitingDayHours,
            ),
        ]);

        // This saga can be called from the user, availability or resolve absences page
        yield loadAbsencesAfterPatchOrDelete(action.page);

        yield put({ type: ABSENCES_EDIT_ABSENCE_SUCCESS });
        const toast = translate(`pages.absences.${action.type === ABSENCES_SAGA_RESOLVE_ABSENCE
            ? 'resolveSuccess' : 'editAbsenceSuccess'}`);

        yield put({ type: APP_SAGA_ADD_TOAST, toast, toastType: TOAST_TYPE_PASS });
    } catch (error) {
        yield put({ type: ABSENCES_EDIT_ABSENCE_FAILURE });
    }
}

function* removeAbsence(action) {
    yield put({ type: ABSENCES_DELETE_ABSENCE_REQUEST });
    try {
        yield call(() => deleteAbsence(action.absenceId));

        // This saga can be called from the user, availability or resolve absences page
        yield loadAbsencesAfterPatchOrDelete(action.page);

        yield put({ type: ABSENCES_DELETE_ABSENCE_SUCCESS });
        yield put({ type: APP_SAGA_ADD_TOAST, toast: translate('pages.absences.deleteAbsenceSuccess'), toastType: TOAST_TYPE_PASS });
    } catch (error) {
        yield put({ type: ABSENCES_DELETE_ABSENCE_FAILURE });
    }
}

function* addAbsence(action) {
    yield put({ type: ABSENCES_ADD_ABSENCE_REQUEST });
    try {
        const {
            startDate,
            userId,
            waitingDayHours,
            weekdays,
        } = action;
        const response = yield call(() => postAbsence(startDate, userId, waitingDayHours));
        yield updateAbsenceHours(
            response.data.id,
            [],
            weekdays,
            waitingDayHours,
        );
        yield put({ type: ABSENCES_SAGA_GET_ABSENCES });
        yield put({ type: ABSENCES_ADD_ABSENCE_SUCCESS });
        yield put({ type: APP_SAGA_ADD_TOAST, toast: translate('pages.absences.addAbsenceSuccess'), toastType: TOAST_TYPE_PASS });
    } catch (error) {
        yield put({ type: ABSENCES_ADD_ABSENCE_FAILURE });
    }
}

function* addLeaveOfAbsence(action) {
    yield put({ type: ABSENCES_ADD_LEAVE_OF_ABSENCE_REQUEST_REQUEST });

    const {
        data, comment, weeksWithHours, userId, leaveType,
    } = action;

    try {
        const response = yield call(() => postLeaveOfAbsence(data, userId));

        yield all([
            ...[comment && call(() => postComment(response.data.id, comment, 'leaveOfAbsence', 'leaveOfAbsences'))],
            ...weeksWithHours.map(
                weekday => call(() => postLeaveOfAbsenceHours(
                    response.data.id,
                    weekday.payrollPeriod ? weekday.payrollPeriod.id : undefined,
                    weekday.hours,
                    weekday.weekNumber,
                    leaveType,
                )),
            ),
        ]);

        yield call(() => fetchLeaveOfAbsenceRequests());
        yield put({ type: ABSENCES_ADD_LEAVE_OF_ABSENCE_REQUEST_SUCCESS });
        yield put({ type: APP_SAGA_ADD_TOAST, toast: translate('pages.absences.addLeaveOfAbsenceSuccess'), toastType: TOAST_TYPE_PASS });
    } catch (error) {
        yield put({ type: ABSENCES_ADD_LEAVE_OF_ABSENCE_REQUEST_FAILURE });
    }
}

function* replaceUserInShift(action) {
    yield put({ type: ABSENCES_REPLACE_USER_IN_SHIFT_REQUEST });
    try {
        if (action.addUserId) {
            yield call(() => postShiftPlanning(
                { userId: action.addUserId, shiftId: action.shiftId },
            ));
        }
        yield call(() => delUserFromShift(action.shiftId, action.delUserId));
        yield put({ type: ABSENCES_SAGA_GET_ABSENCES });
        yield put({ type: ABSENCES_REPLACE_USER_IN_SHIFT_SUCCESS });
        yield put({ type: APP_SAGA_ADD_TOAST, toast: translate('pages.absences.replaceUserSuccess'), toastType: TOAST_TYPE_PASS });
    } catch (error) {
        yield put({ type: ABSENCES_REPLACE_USER_IN_SHIFT_FAILURE });
    }
}

function* exportCSV() {
    yield put({ type: ABSENCES_EXPORT_CSV_SUCCESS });
    try {
        const state = yield select();
        const { startDate, endDate, mode } = state.weekNavigatorReducer;
        const start = getStartDate(startDate, mode);
        const { filter } = state.filterReducer;
        const { departmentOptions } = state.globalFiltersReducer;
        const departmentIds = getCheckedFormOptionsValue(departmentOptions);

        const response = yield call(() => getAbsencesInDateRangeCSV(start, endDate, departmentIds, filter));
        const filename = `${translate('pages.absences.absences').toLowerCase()}-${formatDate(start, 'dd-MM-yyyy')}-${formatDate(endDate, 'dd-MM-yyyy')}.csv`;
        generateDownloadURI(`data:text/html,${response.data}`, filename);
        yield put({ type: ABSENCES_EXPORT_CSV_SUCCESS });
    } catch (error) {
        yield put({ type: ABSENCES_EXPORT_CSV_FAILURE });
    }
}

function* syncLeaveOfAbsencesToLoket(action) {
    yield put({ type: ABSENCES_SYNC_LEAVE_OF_ABSENCES_TO_LOKET_REQUEST });
    try {
        yield call(() => postLeaveOfAbsencesToLoket(action.startDate, action.endDate));
        yield put({ type: ABSENCES_SYNC_LEAVE_OF_ABSENCES_TO_LOKET_SUCCESS });
        yield put({ type: APP_SAGA_ADD_TOAST, toast: translate('common.syncLoketSuccess', { type: 'Verloven' }), toastType: TOAST_TYPE_PASS });
    } catch (error) {
        yield put({ type: ABSENCES_SYNC_LEAVE_OF_ABSENCES_TO_LOKET_FAILURE });
        yield put({ type: APP_SAGA_ADD_TOAST, toast: getObjProperty(error, 'meta.message'), toastType: TOAST_TYPE_FAIL });
    }
}

export default function* absencesWatcher() {
    yield takeLatest(ABSENCES_SAGA_GET_LEAVE_OF_ABSENCE_REQUESTS, fetchLeaveOfAbsenceRequests);
    yield takeLatest(ABSENCES_SAGA_GET_EXCHANGE_REQUESTS, fetchExchangeRequests);
    yield takeLatest(ABSENCES_SAGA_APPROVE_SHIFT_EXCHANGE, approveShiftExchange);
    yield takeLatest(ABSENCES_SAGA_DECLINE_SHIFT_EXCHANGE, declineShiftExchange);
    yield takeLatest(ABSENCES_SAGA_GET_ABSENCES, fetchAbsences);
    yield takeLatest(ABSENCES_SAGA_GET_ABSENCES_IN_DATE_RANGE, fetchAbsencesInDateRange);
    yield takeLatest(ABSENCES_SAGA_APPROVE_EXCHANGE_REQUEST, approveExchangeRequest);
    yield takeLatest(ABSENCES_SAGA_DECLINE_EXCHANGE_REQUEST, declineExchangeRequest);
    yield takeLatest(ABSENCES_SAGA_DECLINE_LEAVE_OF_ABSENCE, handleLeaveOfAbsence);
    yield takeLatest(ABSENCES_SAGA_ACCEPT_LEAVE_OF_ABSENCE, handleLeaveOfAbsence);
    yield takeLatest(ABSENCES_SAGA_EDIT_LEAVE_OF_ABSENCE, handleLeaveOfAbsence);
    yield takeLatest(ABSENCES_SAGA_RESOLVE_ABSENCE, editAbsence);
    yield takeLatest(ABSENCES_SAGA_EDIT_ABSENCE, editAbsence);
    yield takeLatest(ABSENCES_SAGA_DELETE_ABSENCE, removeAbsence);
    yield takeLatest(ABSENCES_SAGA_ADD_ABSENCE, addAbsence);
    yield takeLatest(ABSENCES_SAGA_ADD_LEAVE_OF_ABSENCE_REQUEST, addLeaveOfAbsence);
    yield takeLatest(ABSENCES_SAGA_REPLACE_USER_IN_SHIFT, replaceUserInShift);
    yield takeLatest(ABSENCES_SAGA_EXPORT_CSV, exportCSV);
    yield takeLatest(ABSENCES_SAGA_SYNC_LEAVE_OF_ABSENCES_TO_LOKET, syncLeaveOfAbsencesToLoket);
}
