import { FC, ReactElement } from 'react';

import { DistrictDivisionTemplatesFilterContainer } from '../../../containers';
import usePermissions from '../../../helpers/hooks/usePermissions';

interface ConnectedDistrictDivisionTemplatesFilterProps {
    className?: string;
}

const ConnectedDistrictDivisionTemplatesFilter: FC<ConnectedDistrictDivisionTemplatesFilterProps> = ({
    className = '',
}): ReactElement => {
    const permissions = usePermissions();

    return (
        <DistrictDivisionTemplatesFilterContainer permissions={permissions} className={className} />
    );
};

export default ConnectedDistrictDivisionTemplatesFilter;
