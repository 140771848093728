import { FC, ReactElement } from 'react';

import { Checkbox, InputLabel } from '../../../components';
import trans from '../../../helpers/trans';

import './ShowEmploymentTypesFilterContainer.scss';

interface ShowEmploymentTypesFilterContainerProps {
    showEmploymentTypes: boolean;
    onChange: (show: boolean) => void;
    className?: string;
}

const ShowEmploymentTypesFilterContainer: FC<ShowEmploymentTypesFilterContainerProps> = ({
    showEmploymentTypes,
    onChange,
    className = '',
}): ReactElement => (
    <div className="show-employment-types-filter-container">
        <InputLabel text={trans('common.employmentType')} />
        <Checkbox
            label={trans('containers.filters.ShowEmploymentTypesFilterContainer')}
            checked={showEmploymentTypes}
            onChange={onChange}
            className={`show-employment-types-filter-container__check ${className}`}
        />
    </div>
);

export default ShowEmploymentTypesFilterContainer;
