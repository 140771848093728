import { FC, ReactElement } from 'react';

import { useToggle } from 'react-use';

import LogsModal from '../../../components/Logs/LogsModal';
import { PrivateRouteGroupRoutes } from '../../../components/PrivateRouteGroup/PrivateRouteGroupRoutes';
import { checkPermission, hasPermissionToEditUser } from '../../../helpers';
import { Log } from '../../../models';
import { ManagementRoutes } from '../../../pages/Management/ManagementRoutes';
import { LoadingSpinner } from '../../components';
import {
    IconButton,
    IconNavLink,
    ModalContent,
    PacoModal,
    UserDetailCards,
} from '../../compositions';
import {
    ConnectedDeactivateUserForm,
    ConnectedDeleteUserForm,
    ConnectedEditUserForm,
    ConnectedLegacyUserDetail,
    ConnectedReactivateUserForm,
} from '../../connectors';
import { EmploymentType } from '../../entities/EmploymentType/EmploymentType';
import { Experience } from '../../entities/Experience/Experience';
import { FullLoketUser } from '../../entities/LoketUser/LoketUser';
import { Permission } from '../../entities/Permission/Permission';
import { getPersonFullName } from '../../entities/Person/PersonHelpers';
import { Role } from '../../entities/Role/Role';
import { AuthenticatedUser, UserProfile } from '../../entities/User/User';
import { transformUserProfileV3ToFullUser } from '../../entities/User/UserTransformers';
import trans from '../../helpers/trans';
import { UserStatus } from '../../types/userStatus';
import { DeactivatedUserBar } from './subcomponents';

import './UserDetail.scss';

interface UserDetailProps {
    isLoading: boolean;
    isLogsLoading: boolean;
    isUserFormDataLoading: boolean;
    isLoketUserLoading: boolean;
    currentUser: AuthenticatedUser;
    employmentTypes: EmploymentType[];
    experiences: Experience[];
    logs: Log[];
    permissions: Permission[];
    roles: Role[];
    user: UserProfile;
    loketUser?: FullLoketUser;
    onAfterEditUserSuccess: () => void;
    onHistoryButtonClick: () => void;
    onLogsModalClose: () => void;
    onResendRegistrationMailButtonClick: () => void;
    onSendResetPasswordAndPinButtonClick: () => void;
    className?: string;
}

const UserDetail: FC<UserDetailProps> = ({
    isLoading,
    isLogsLoading,
    isUserFormDataLoading,
    isLoketUserLoading,
    currentUser,
    employmentTypes,
    experiences,
    logs,
    permissions,
    roles,
    user,
    loketUser,
    onAfterEditUserSuccess,
    onHistoryButtonClick,
    onLogsModalClose,
    onResendRegistrationMailButtonClick,
    onSendResetPasswordAndPinButtonClick,
    className = '',
}): ReactElement => {
    const [isEditUserFormOpen, toggleIsEditUserFormOpen] = useToggle(false);
    const [isDeactivateUserFormOpen, toggleIsDeactivateUserFormOpen] = useToggle(false);
    const [isReactivateUserFormOpen, toggleIsReactivateUserFormOpen] = useToggle(false);
    const [isDeleteUserFormOpen, toggleIsDeleteUserFormOpen] = useToggle(false);

    const userFullName = getPersonFullName(user.person);
    const canEditUserStatus = checkPermission(permissions, 'edit-user-status', 'user');
    const canEditUser = hasPermissionToEditUser(user, permissions, currentUser?.id);
    const canViewLogs = checkPermission(permissions, 'view-all-logs', 'user');
    const canViewLoketData = checkPermission(permissions, 'manage-loket-data', 'user');
    const isSameUserAsCurrentUser = currentUser.id === user.id;

    return (
        <div className={`user-detail ${className}`}>
            {user.status !== UserStatus.active && (
                <DeactivatedUserBar
                    showButtons={canEditUserStatus && !isSameUserAsCurrentUser}
                    userFullName={userFullName}
                    userStatus={user.status}
                    onDeleteButtonClick={toggleIsDeleteUserFormOpen}
                    onReactivateButtonClick={toggleIsReactivateUserFormOpen}
                />
            )}
            <div className="user-detail__main">
                <div className="user-detail__main-top-bar">
                    <IconNavLink
                        icon="reset"
                        text={trans('common.back')}
                        to={`${PrivateRouteGroupRoutes.management()}/${ManagementRoutes.users()}`}
                        className="user-detail__back-button"
                        iconClassName="user-detail__back-button-icon"
                    />
                    {canEditUser && (
                        <IconButton
                            disabled={isUserFormDataLoading}
                            icon="edit"
                            text={trans('common.edit')}
                            onClick={toggleIsEditUserFormOpen}
                            className="user-detail__edit-button"
                        />
                    )}
                </div>
                <UserDetailCards
                    canViewLogs={canViewLogs}
                    canViewLoketData={canViewLoketData}
                    isLoketUserLoading={isLoketUserLoading}
                    user={user}
                    loketUser={loketUser}
                    onDeactivateUserButtonClick={toggleIsDeactivateUserFormOpen}
                    onHistoryButtonClick={onHistoryButtonClick}
                    onResendRegistrationMailButtonClick={onResendRegistrationMailButtonClick}
                    onSendResetPasswordAndPinButtonClick={onSendResetPasswordAndPinButtonClick}
                    className="user-detail__cards"
                />
                <ConnectedLegacyUserDetail
                    className="user-detail__legacy-user-detail"
                />
            </div>
            {isLoading && <LoadingSpinner className="user-detail__loader" />}
            {isEditUserFormOpen && (
                <PacoModal>
                    <ModalContent title={trans('common.editUser')}>
                        <ConnectedEditUserForm
                            experiences={experiences}
                            roles={roles}
                            employmentTypes={employmentTypes}
                            fullUser={transformUserProfileV3ToFullUser(user)}
                            currentUserId={currentUser.id}
                            afterEditUserSuccess={onAfterEditUserSuccess}
                            onClose={toggleIsEditUserFormOpen}
                        />
                    </ModalContent>
                </PacoModal>
            )}
            {isDeactivateUserFormOpen && (
                <PacoModal>
                    <ModalContent title={trans('common.deactivate')}>
                        <ConnectedDeactivateUserForm
                            user={user}
                            onClose={toggleIsDeactivateUserFormOpen}
                        />
                    </ModalContent>
                </PacoModal>
            )}
            {isReactivateUserFormOpen && (
                <PacoModal>
                    <ModalContent title={trans('common.reactivate')}>
                        <ConnectedReactivateUserForm
                            user={user}
                            onClose={toggleIsReactivateUserFormOpen}
                        />
                    </ModalContent>
                </PacoModal>
            )}
            {isDeleteUserFormOpen && (
                <PacoModal>
                    <ModalContent title={trans('common.delete')}>
                        <ConnectedDeleteUserForm
                            user={user}
                            onClose={toggleIsDeleteUserFormOpen}
                        />
                    </ModalContent>
                </PacoModal>
            )}
            <LogsModal
                loading={isLogsLoading}
                isOpen={isLogsLoading || !!logs}
                title={trans('containers.userDetail.userLog')}
                logs={logs}
                onClose={onLogsModalClose}
            />
        </div>
    );
};

export default UserDetail;
