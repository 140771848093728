/* eslint-disable prefer-promise-reject-errors */
import { configureRefreshFetch } from 'refresh-fetch';

// @ts-ignore
import { version } from '../../../package.json';
import { fetchRefreshToken } from '../entities/Authentication/AuthenticationService';
import { isFetchResultSuccessful } from '../entities/FetchResult';

export const localStorageRefreshTokenKey = 'apiRefreshToken';
export const localStorageTokenKey = 'apiToken';

interface RequestInitExtraOptions extends RequestInit {
    disableContentType?: boolean;
}

const fetchWithToken = async (url: string, options?: RequestInitExtraOptions): Promise<Response> => {
    const optionsWithToken: RequestInit = {
        ...options,
        headers: {
            Accept: 'application/vnd.api+json',
            ...(!options?.disableContentType && { 'Content-Type': 'application/vnd.api+json' }),
            ...options?.headers,
        },
    };

    const apiToken = localStorage.getItem(localStorageTokenKey);

    if (apiToken) {
        optionsWithToken.headers = {
            ...optionsWithToken.headers,
            Authorization: `Bearer ${apiToken}`,
            TENANT: process.env.REACT_APP_TENANT || window.location.hostname,
            App: 'manager',
            'App-Version': version,
        };
    }

    const response = await fetch(url, optionsWithToken);

    if (response.status === 401) {
        return Promise.reject({
            response,
        });
    }

    return response;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const shouldRefreshToken = (error: any): boolean => error?.response?.status === 401;

const updateRefreshToken = async (): Promise<void> => {
    const apiRefreshToken = localStorage.getItem(localStorageRefreshTokenKey);

    if (!apiRefreshToken) {
        throw new Error('No token found');
    }

    const refreshResponse = await fetchRefreshToken(apiRefreshToken);

    if (!isFetchResultSuccessful(refreshResponse)) {
        localStorage.removeItem(localStorageRefreshTokenKey);
        localStorage.removeItem(localStorageTokenKey);
        window.location.href = '/';

        throw new Error('Refresh token was invalid');
    }

    localStorage.setItem(localStorageTokenKey, refreshResponse.data.token);
    localStorage.setItem(localStorageRefreshTokenKey, refreshResponse.data.refresh_token);
};

export const authorizedFetch = configureRefreshFetch({
    fetch: fetchWithToken,
    refreshToken: updateRefreshToken,
    shouldRefreshToken,
});
/* eslint-enable prefer-promise-reject-errors */
