import { ShiftIndex } from '../../../../entities/Shift/Shift';
import { ShiftPlanning } from '../../../../entities/ShiftPlanning/ShiftPlanning';
import { isBefore } from '../../../../helpers/date';
import trans from '../../../../helpers/trans';
import { ShiftStatusType } from '../CalendarShift';

const getCalendarShiftStatus = (shift: ShiftIndex): ShiftStatusType => {
    if (isBefore(shift.period.start, new Date())) {
        return ShiftStatusType.past;
    }

    const plannedShiftPlannings = shift.shiftPlannings.filter(shiftPlanning => shiftPlanning.planned);

    if ((plannedShiftPlannings.length + shift.temporaryWorkers.length) >= shift.peopleLimit) {
        return ShiftStatusType.full;
    }

    return ShiftStatusType.available;
};

const getRegisteredShiftPlanningsText = (shiftPlannings: ShiftPlanning[]): string | undefined => {
    const registeredShiftPlannings = shiftPlannings.filter(shiftPlanning => !shiftPlanning.planned);

    if (registeredShiftPlannings.length === 1) {
        return trans('compositions.calendarShift.oneSignup');
    }

    if (registeredShiftPlannings.length > 1) {
        return trans('compositions.calendarShift.multipleSignups', { amount: registeredShiftPlannings.length.toString() });
    }

    return undefined;
};

export {
    getCalendarShiftStatus,
    getRegisteredShiftPlanningsText,
};
