import { FC, ReactElement, useEffect } from 'react';

import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { PrivateRouteGroupRoutes } from '../../../components/PrivateRouteGroup/PrivateRouteGroupRoutes';
import { ScheduleRoutes } from '../../../pages/Schedule/ScheduleRoutes';
import { clearResourceLogs, getResourceLogs } from '../../../redux/logs/logsActions';
import { LoadingSpinner } from '../../components';
import { ShiftDetailForm } from '../../containers';
import { EditShiftFormData } from '../../entities/Shift/Shift';
import { ShiftPlanning } from '../../entities/ShiftPlanning/ShiftPlanning';
import { WorkweekUser } from '../../entities/User/User';
import usePermissions from '../../helpers/hooks/usePermissions';
import trans from '../../helpers/trans';
import { getShiftSnapshot } from '../../redux/@forms/shiftSnapshotForm/shiftSnapshotActions';
import { Reducers } from '../../redux/reducers';
import { editShift } from '../../redux/shift/shiftActions';
import { setIsEditShiftSuccessful } from '../../redux/shift/shiftReducer';
import { getRecentShiftPlannings, getShiftDetail, getSimilarShifts } from '../../redux/shiftDetail/shiftDetailActions';
import { reset as resetShiftDetail } from '../../redux/shiftDetail/shiftDetailReducer';
import { addShiftPlanning, editShiftPlanningColor, planUnplannedShiftPlanning } from '../../redux/shiftPlanning/shiftPlanningActions';
import {
    setError as setShiftPlanningError,
    setIsAddSuccessful as setIsAddShiftPlanningSuccessful,
    setIsPlannedSuccessful,
} from '../../redux/shiftPlanning/shiftPlanningReducer';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';
import { editTemporaryWorkerColor } from '../../redux/temporaryWorker/temporaryWorkerActions';
import { setIsSuccessful as setIsAddTemporaryWorkerSuccessful } from '../../redux/temporaryWorker/temporaryWorkerReducer';
import { setToast } from '../../redux/toasts/toastsReducer';
import {
    getWorkweekUsers,
    getWorkweekUsersAbsences,
    getWorkweekUsersLeaveOfAbsences,
    getWorkweekUsersPreferToWorkTimeSlots,
    getWorkweekUsersUnavailableToWorkTimeSlots,
} from '../../redux/workweekUsers/workweekUsersActions';
import { ToastType } from '../../types';

const ConnectedShiftDetail: FC = (): ReactElement => {
    const dispatch = useTypedDispatch();
    const navigate = useNavigate();
    const { id } = useParams<{ id: string }>();
    const permissions = usePermissions();

    const { settings } = useTypedSelector(state => state.pacoSettingsReducer);
    const {
        isLoading: isLoadingWorkweekUsers,
        absences,
        leaveOfAbsences,
        preferToWorkTimeSlots,
        unavailableToWorkTimeSlots,
        workweekUsers,
    } = useTypedSelector(state => state.workweekUsersReducer);
    const {
        isLoadingShift,
        shift,
        similarShifts,
        recentShiftPlannings,
    } = useTypedSelector(state => state.shiftDetailReducer);
    const {
        isAddSuccessful: isAddShiftPlanningSuccessful,
        isPlannedSuccessful,
        isLoading: isShiftPlanningLoading,
        error: shiftPlanningError,
        lastAddedShiftPlanning,
        shiftPlanning: lastEditedShiftPlanning,
    } = useTypedSelector(state => state.shiftPlanningReducer);
    const { shiftSnapshot } = useTypedSelector(state => state.shiftSnapshotFormReducer);
    const { isLoading: isDeleting, isEditShiftSuccessful } = useTypedSelector(state => state.shiftReducer);
    const { isLoading: isTemporaryWorkerLoading, isSuccessful: isTemporaryWorkerSuccessful } = useTypedSelector(state => state.temporaryWorkerReducer);
    const { loading: isLogsLoading, logsResourceId, logs } = useSelector((state: Reducers) => state.logsReducer);
    const shiftLogs = (shift && logsResourceId === shift?.id) ? logs || [] : [];

    const isLoading = isLoadingShift
        || isDeleting
        || isShiftPlanningLoading
        || isTemporaryWorkerLoading;

    const handleAddShiftPlanningClick = (shiftPlanning: ShiftPlanning): void => {
        dispatch(planUnplannedShiftPlanning(shiftPlanning.id));
    };

    const handleCloseLogsModal = (): void => {
        dispatch(clearResourceLogs());
    };

    const handleHistoryButtonClick = (): void => {
        if (shift) {
            dispatch(getResourceLogs(shift.id, 'shifts'));
        }
    };

    const handleSelectWorkweekUser = (user: WorkweekUser): void => {
        if (shift) {
            dispatch(addShiftPlanning(shift.id, user.id));
        }
    };

    const handleSubmitEditShiftForm = (formData: EditShiftFormData): void => {
        if (shift) {
            dispatch(editShift(formData, shift));
        }
    };

    const handleShiftPlanningColorSelect = (shiftPlanningId: string, color?: string): void => {
        dispatch(editShiftPlanningColor(shiftPlanningId, color));
    };

    const handleTemporaryWorkerColorSelect = (temporaryWorkerId: string, color?: string): void => {
        dispatch(editTemporaryWorkerColor(temporaryWorkerId, color));
    };

    useEffect(() => () => {
        dispatch(resetShiftDetail());
    }, []);

    useEffect((): void => {
        if (id) {
            dispatch(getShiftDetail(id));
            dispatch(getShiftSnapshot(id));
        }
    }, [id]);

    useEffect((): void => {
        if (!shift) {
            return;
        }

        const { period, department, shiftPlannings } = shift;

        dispatch(getWorkweekUsers({
            departments: [department.id],
            workweek: period.start,
        }));

        dispatch(getWorkweekUsersAbsences({
            departments: [department.id],
            period,
        }));

        dispatch(getWorkweekUsersLeaveOfAbsences(period));
        dispatch(getWorkweekUsersUnavailableToWorkTimeSlots(period));
        dispatch(getWorkweekUsersPreferToWorkTimeSlots(period));
        dispatch(getSimilarShifts(shift.id));
        dispatch(getRecentShiftPlannings(shift.period.start, shiftPlannings));
    }, [shift]);

    useEffect((): void => {
        if (isEditShiftSuccessful) {
            dispatch(setToast({
                type: ToastType.pass,
                shouldFadeOutAutomatically: true,
                text: trans('containers.forms.shiftDetailForm.editShiftSuccess'),
            }));
            dispatch(setIsEditShiftSuccessful(false));

            navigate(`${PrivateRouteGroupRoutes.shifts()}/${ScheduleRoutes.calendar()}`);
        }
    }, [isEditShiftSuccessful]);

    useEffect((): void => {
        if (isAddShiftPlanningSuccessful && lastAddedShiftPlanning) {
            const { fullName } = lastAddedShiftPlanning.user;

            dispatch(setToast({
                type: ToastType.pass,
                shouldFadeOutAutomatically: true,
                text: trans('containers.forms.shiftDetailForm.postShiftPlanningSuccess', { fullName }),
            }));

            dispatch(setIsAddShiftPlanningSuccessful(false));
        }
    }, [isAddShiftPlanningSuccessful, lastAddedShiftPlanning]);

    useEffect(() => {
        if (isPlannedSuccessful && lastEditedShiftPlanning) {
            const { fullName } = lastEditedShiftPlanning.user;

            dispatch(setToast({
                type: ToastType.pass,
                shouldFadeOutAutomatically: true,
                text: trans('containers.forms.shiftDetailForm.postShiftPlanningSuccess', { fullName }),
            }));

            dispatch(setIsPlannedSuccessful(false));
        }
    }, [isPlannedSuccessful, lastEditedShiftPlanning]);

    useEffect((): void => {
        if (isTemporaryWorkerSuccessful) {
            dispatch(setIsAddTemporaryWorkerSuccessful(false));
        }
    }, [isTemporaryWorkerSuccessful]);

    useEffect((): void => {
        if (shiftPlanningError) {
            dispatch(setToast({
                type: ToastType.fail,
                shouldFadeOutAutomatically: true,
                text: shiftPlanningError,
            }));
            dispatch(setShiftPlanningError(''));
        }
    }, [shiftPlanningError]);

    if (!shift) {
        return (
            <LoadingSpinner />
        );
    }

    return (
        <ShiftDetailForm
            isLoadingLogs={isLogsLoading}
            isLoadingShift={isLoading}
            isLoadingUsers={isLoadingWorkweekUsers}
            absences={absences}
            leaveOfAbsences={leaveOfAbsences}
            logs={shiftLogs}
            permissions={permissions}
            preferToWorkTimeSlots={preferToWorkTimeSlots}
            recentShiftPlannings={recentShiftPlannings}
            settings={settings}
            shift={shift}
            shiftSnapshot={shiftSnapshot}
            similarShifts={similarShifts}
            unavailableToWorkTimeSlots={unavailableToWorkTimeSlots}
            users={workweekUsers}
            onAddShiftPlanningClick={handleAddShiftPlanningClick}
            onCloseLogsModal={handleCloseLogsModal}
            onHistoryButtonClick={handleHistoryButtonClick}
            onSelectWorkweekUser={handleSelectWorkweekUser}
            onShiftPlanningColorSelect={handleShiftPlanningColorSelect}
            onSubmitEditShiftForm={handleSubmitEditShiftForm}
            onTemporaryWorkerColorSelect={handleTemporaryWorkerColorSelect}
        />
    );
};

export default ConnectedShiftDetail;
