import React, { FC } from 'react';

import { NavLink } from 'react-router-dom';

import { PrivateRouteGroupRoutes } from '../../../../../../components/PrivateRouteGroup/PrivateRouteGroupRoutes';
import { translate } from '../../../../../../helpers/translations/translator';
import { UserWithHourBalances } from '../../../../../../models/UserWithHourBalances';
import { ManagementRoutes } from '../../../../../Management/ManagementRoutes';

type UsersWithLoketBalancesTableProps = UserWithHourBalances;

const UsersWithLoketBalancesTable: FC<UsersWithLoketBalancesTableProps> = ({
    userId,
    userName,
    contractHours,
    workedHours,
    plannedHours,
    absenceHours,
    leaveOfAbsenceHours,
    usedTvtHours,
    mutatedTvtHours,
    leaveOfAbsenceBalance,
    tvtBalance,
}) => {
    const hour = translate('common.hour').toLowerCase();
    return (
        <tr key={userId} className="list-view-user">
            <td className="list-view-user__user-name-and-hours">
                <NavLink to={`${PrivateRouteGroupRoutes.management()}/${ManagementRoutes.user(userId)}`}>
                    {userName}
                </NavLink>

                <span className="list-view-user__hours">
                    {`${plannedHours} / ${contractHours} ${hour}`}
                </span>
            </td>

            <td className="list-view-user__worked-hours">
                {`${workedHours} ${hour}`}
            </td>

            <td className="list-view-user__absence-hours">
                {`${absenceHours} ${hour}`}
            </td>

            <td className="list-view-user__leave-of-absence-hours">
                {`${leaveOfAbsenceHours} ${hour}`}
            </td>

            <td className="list-view-user__used-tvt-hours">
                {`${usedTvtHours} ${hour}`}
            </td>

            <td className="list-view-user__mutated-tvt-hours">
                {`${mutatedTvtHours} ${hour}`}
            </td>

            <td className="list-view-user__leave-of-absence-hours">
                {`${leaveOfAbsenceBalance} ${hour}`}
            </td>

            <td className="list-view-user__tvt-balance">
                {`${tvtBalance} ${hour}`}
            </td>
        </tr>
    );
};

export default UsersWithLoketBalancesTable;
