import React, { Component } from 'react';

import serialize from 'form-serialize';
import PropTypes from 'prop-types';
import Select from 'react-select';
import {
    Button, Col, CustomInput, FormGroup, FormText,
    Input, Label, ModalBody, ModalFooter, ModalHeader, Row,
} from 'reactstrap';

import { getObjProperty } from '../../../helpers';
import { getBase64 } from '../../../helpers/file/getBase64';
import getFileNameExtension from '../../../helpers/file/getFileNameExtension';
import { validateDocumentFileType } from '../../../helpers/file/validateDocumentFileType';
import { translate } from '../../../helpers/translations/translator';

import './Forms.scss';

class AddOrEditDocument extends Component {
    ref = React.createRef();

    state = {
        data: {},
        canSubmit: false,
        visibleToEmployee: false,
        file: null,
        base64: null,
        documentType: null,
        fileTypeNotAllowed: false,
        fileSizeTooLarge: false,
    };

    componentDidMount() {
        const { documentTypes, document: doc } = this.props;
        this.setState({
            documentType: doc ? getObjProperty(doc, 'documentType.id') : (documentTypes[0] && documentTypes[0].id),
            visibleToEmployee: doc ? doc.userCanView : false,
        });

        const buttonUpload = this.ref.current.getElementsByClassName('form-add-document-input-file')[0];

        if (buttonUpload) buttonUpload.addEventListener('onChange', this.onFileChange);
    }

    componentWillUnmount() {
        const buttonUpload = this.ref.current.getElementsByClassName('form-add-document-input-file')[0];

        if (buttonUpload) buttonUpload.removeEventListener('onChange', this.onFileChange);
    }

    static getDerivedStateFromProps(props, state) {
        const { document } = props;
        const {
            file, data, fileSizeTooLarge, fileTypeNotAllowed,
        } = state;
        const { name } = data;
        const fileCheck = document || (!!file && !fileSizeTooLarge && !fileTypeNotAllowed);

        return {
            ...state,
            canSubmit: (!!name && fileCheck),
        };
    }

    setStateProp = (value, prop) => {
        this.setState({
            [prop]: value,
        });
        this.serializeData();
    }

    onChange = (e) => {
        if (e.target.getAttribute('type') === 'checkbox') {
            return;
        }

        e.preventDefault();
        this.serializeData();
    };

    serializeData = () => {
        const data = serialize(this.ref.current, { hash: true });
        this.setState({ data });
    }

    onFileChange = (e) => {
        const file = e.target.files && e.target.files[0];
        if (!file) {
            return false;
        }

        if (!validateDocumentFileType(file.type)) {
            return this.setState({ fileTypeNotAllowed: true });
        }

        if (file.size > 5000000) {
            return this.setState({ fileSizeTooLarge: true });
        }


        this.setState({ fileTypeNotAllowed: false, fileSizeTooLarge: false });

        const getBase64Promise = getBase64(file);
        getBase64Promise.then(base64 => this.setStateProp(base64.split(',')[1], 'base64'));
        return this.setStateProp(file, 'file');
    }

    onSubmit = (e) => {
        e.preventDefault();
        const { document } = this.props;
        const {
            data, documentType, visibleToEmployee, base64, file,
        } = this.state;
        data.documentType = documentType;
        data.visibleToEmployee = visibleToEmployee;
        data.base64 = base64;
        data.file = file;
        // eslint-disable-next-line prefer-destructuring
        data.ext = document ? getFileNameExtension(document.fileName) : getFileNameExtension(file.name);
        data.id = document && document.id;
        this.props.onSubmit(data);
    }

    render() {
        const { onCancel, documentTypes, document: doc } = this.props;
        const {
            canSubmit, visibleToEmployee, fileTypeNotAllowed, fileSizeTooLarge, file,
        } = this.state;

        const documentTypeOptions = documentTypes.map(type => ({
            label: (translate(`pages.management.${type.name.toLowerCase()}`)),
            value: type.name,
            id: type.id,
        }));

        const defaultDocumentType = doc ? {
            label: doc.documentType.name,
            value: doc.documentType.name,
            id: doc.documentType.id,
        } : documentTypeOptions[0];

        return (
            <form ref={this.ref} onSubmit={this.onSubmit} onChange={this.onChange} className="form-add-document">
                <ModalHeader>{translate('pages.management.addFile')}</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label>{translate('pages.management.fileName')}</Label>
                                <CustomInput
                                    type="text"
                                    className="form-control"
                                    id="name"
                                    name="name"
                                    autoComplete="off"
                                    defaultValue={doc && doc.displayName}
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            { !doc && (
                                <FormGroup>
                                    <div className="form-add-document-button-file">
                                        <span>{translate('pages.management.file')}</span>
                                        <Label className="btn btn-orange" for="file">
                                            {translate('pages.management.chooseFile')}
                                        </Label>
                                        <Input
                                            className="form-add-document-input-file"
                                            type="file"
                                            name="file"
                                            id="file"
                                            onChange={this.onFileChange}
                                        />
                                    </div>
                                    { fileTypeNotAllowed
                                    && (
                                        <FormText className="form-add-document-text-red">
                                            {translate('pages.management.fileTypeNotAllowed')}
                                        </FormText>
                                    )}
                                    { fileSizeTooLarge
                                    && (
                                        <FormText className="form-add-document-text-red">
                                            {translate('pages.management.fileSizeTooLarge')}
                                        </FormText>
                                    )}
                                    {(!fileTypeNotAllowed && !fileSizeTooLarge && !file)
                                    && (
                                        <FormText className="form-add-document-text-grey">
                                            {translate('pages.management.fileDescription')}
                                        </FormText>
                                    )}
                                    {(!fileTypeNotAllowed && !fileSizeTooLarge && file)
                                    && (
                                        <FormText className="form-add-document-text-black">
                                            {file.name}
                                        </FormText>
                                    )}
                                </FormGroup>
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label>{translate('pages.management.fileType')}</Label>
                                <Select
                                    id="select-document"
                                    options={documentTypeOptions}
                                    defaultValue={defaultDocumentType}
                                    onChange={e => this.setStateProp(e.id, 'documentType')}
                                />
                            </FormGroup>
                        </Col>
                        <Col />
                    </Row>
                    <Row>
                        <Col className="form-add-document-visible-to-employee">
                            <FormGroup check>
                                <Label>
                                    <CustomInput
                                        id="visible-to-employee-checkbox"
                                        type="checkbox"
                                        label={translate('pages.management.visibleToEmployee')}
                                        checked={visibleToEmployee}
                                        onChange={e => this.setStateProp(e.target.checked, 'visibleToEmployee')}
                                    />
                                </Label>
                            </FormGroup>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button type="button" color="link" id="modal-close" onClick={onCancel}>{translate('common.cancel')}</Button>
                    <Button type="submit" color="orange" disabled={!canSubmit}>{translate('common.add')}</Button>
                </ModalFooter>
            </form>
        );
    }
}

AddOrEditDocument.propTypes = {
    document: PropTypes.object,
    documentTypes: PropTypes.array.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

AddOrEditDocument.defaultProps = {
    document: null,
};

export default AddOrEditDocument;
