import { FC, ReactElement } from 'react';

import { useNavigate } from 'react-router-dom';

import { PrivateRouteGroupRoutes } from '../../../../components/PrivateRouteGroup/PrivateRouteGroupRoutes';
import { SettingsRoutes } from '../../../../pages/Settings/SettingsRoutes';
import {
    Button,
    LoadingSpinner,
    TableCell,
    TableRow,
} from '../../../components';
import { EmptyState, ValkTable } from '../../../compositions';
import IconButton from '../../../compositions/@buttons/IconButton/IconButton';
import { DistrictDivisionTemplate } from '../../../entities/DistrictDivisionTemplate/DistrictDivisionTemplate';
import { Permission } from '../../../entities/Permission/Permission';
import checkPermission from '../../../helpers/permissions/checkPermission';
import trans from '../../../helpers/trans';
import { getDistrictDivisionDistrictTemplateLabel } from './helpers';
import DistrictDivisionTemplatesTableSkeleton from './subcomponents/DistrictDivisionTemplatesTableSkeleton';

import './DistrictDivisionTemplatesTable.scss';

const tableHeader = [
    trans('common.name'),
    trans('containers.tables.districtDivisionTemplatesTable.amountOfGroups'),
    trans('containers.tables.districtDivisionTemplatesTable.amountOfDistrictDivisions'),
];

interface DistrictDivisionTemplatesTableProps {
    isLoading?: boolean;
    districtDivisionTemplates: DistrictDivisionTemplate[];
    permissions: Permission[];
    onAddDistrictDivisionTemplateClick: () => void;
    onEditDistrictDivisionTemplateClick: (districtDivisionTemplate: DistrictDivisionTemplate) => void;
    onDeleteDistrictDivisionTemplateClick: (districtDivisionTemplateId: string) => void;
    onDistrictDivisionTemplateClick: (districtDivisionTemplate: DistrictDivisionTemplate) => void;
    className?: string;
}

const DistrictDivisionTemplatesTable: FC<DistrictDivisionTemplatesTableProps> = ({
    isLoading,
    districtDivisionTemplates,
    permissions,
    onAddDistrictDivisionTemplateClick,
    onEditDistrictDivisionTemplateClick,
    onDeleteDistrictDivisionTemplateClick,
    onDistrictDivisionTemplateClick,
    className = '',
}): ReactElement => {
    const navigate = useNavigate();

    const canAddDistrictDivisionTemplates = checkPermission(permissions, 'add-new-district-division-templates');

    const handleDistrictDivisionTemplateClick = (districtDivisionTemplate: DistrictDivisionTemplate): void => {
        navigate(`${PrivateRouteGroupRoutes.settings()}/${SettingsRoutes.districtDivisionTemplates()}/${districtDivisionTemplate.id}`);

        onDistrictDivisionTemplateClick(districtDivisionTemplate);
    };

    if (!isLoading && !districtDivisionTemplates.length) {
        return (
            <EmptyState
                showButton={canAddDistrictDivisionTemplates}
                buttonText={trans('containers.tables.districtDivisionTemplatesTable.addDistrictDivision')}
                intro={canAddDistrictDivisionTemplates ? trans('containers.tables.districtDivisionTemplatesTable.nullState.description') : undefined}
                title={trans('containers.tables.districtDivisionTemplatesTable.nullState.title')}
                onAddButtonClick={onAddDistrictDivisionTemplateClick}
                className="district-division-templates-table__empty-state"
            />
        );
    }

    return (
        <div className={`district-division-templates-table ${className}`}>
            {isLoading && (
                <>
                    <LoadingSpinner />
                    <DistrictDivisionTemplatesTableSkeleton
                        tableHeader={tableHeader}
                    />
                </>
            )}

            {!!districtDivisionTemplates.length && !isLoading && (
                <ValkTable<DistrictDivisionTemplate>
                    rows={districtDivisionTemplates}
                    tableHeaders={tableHeader}
                    tableClassName="district-division-templates-table__table"
                >
                    {(districtDivisionTemplateRows): JSX.Element[] => districtDivisionTemplateRows.map(districtDivisionTemplate => (
                        <TableRow hasHoverState key={districtDivisionTemplate.id}>
                            <TableCell>
                                {districtDivisionTemplate.name}
                            </TableCell>
                            <TableCell>
                                {districtDivisionTemplate.districtDivisionGroupTemplates.length === 1
                                    ? trans('common.groupCount')
                                    : trans('common.groupsCount', { amount: districtDivisionTemplate.districtDivisionGroupTemplates.length.toString() })}
                            </TableCell>
                            <TableCell>
                                {getDistrictDivisionDistrictTemplateLabel(districtDivisionTemplate.districtDivisionGroupTemplates)}
                            </TableCell>
                            <TableCell className="district-division-templates-table__cell">
                                <IconButton
                                    hideLabel
                                    text="edit"
                                    icon="edit"
                                    onClick={() => onEditDistrictDivisionTemplateClick(districtDivisionTemplate)}
                                    iconClassName="district-division-templates-table__edit-icon"
                                />
                                <IconButton
                                    hideLabel
                                    text="delete"
                                    icon="trash"
                                    onClick={() => onDeleteDistrictDivisionTemplateClick(districtDivisionTemplate.id)}
                                    className="district-division-templates-table__delete"
                                    iconClassName="district-division-templates-table__delete-icon"
                                />
                            </TableCell>
                            <TableCell className="district-division-templates-table__hidden-cell">
                                <Button
                                    hideLabel
                                    text={trans('common.goToDetail')}
                                    onClick={() => handleDistrictDivisionTemplateClick(districtDivisionTemplate)}
                                    className="district-division-templates-table__redirect-button"
                                />
                            </TableCell>
                        </TableRow>
                    ))}
                </ValkTable>
            )}
        </div>
    );
};

export default DistrictDivisionTemplatesTable;
