import { authorizedFetch } from '../../helpers/authorizedFetch';
import trans from '../../helpers/trans';
import { generateApiUrl } from '../../helpers/url';
import getIncluded from '../../japi/getIncluded';
import getMultipleIncluded from '../../japi/getMultipleIncluded';
import isResourceDocument from '../../japi/guards/isResourceDocument';
import { JapiDocument } from '../../japi/types/Document';
import { BaseScheduleShiftPlanningResource } from '../BaseScheduleShiftPlanning/BaseScheduleShiftPlanning';
import { DepartmentResource } from '../Department/Department';
import { transformToDepartment } from '../Department/DepartmentTransformers';
import { DepartmentGroupResource } from '../DepartmentGroup/DepartmentGroup';
import { FetchResult, FetchResultType } from '../FetchResult';
import { getShiftPlanningResourceIncludesAndTransformToShiftPlanning } from '../ShiftPlanning/ShiftPlanningService';
import { BaseScheduleShift, BaseScheduleShiftResource, EditBaseScheduleShiftTimeFormData } from './BaseScheduleShift';
import { transformEditBaseScheduleShiftTimeFormDataToEditBaseScheduleShiftRequest, transformToBaseScheduleShift } from './BaseScheduleShiftTransformers';

export const editBaseScheduleShiftTimeApiCall = async (
    baseScheduleShiftFormData: EditBaseScheduleShiftTimeFormData,
    baseScheduleShiftId: string,
): Promise<FetchResult<BaseScheduleShift, string>> => {
    const apiData = transformEditBaseScheduleShiftTimeFormDataToEditBaseScheduleShiftRequest(baseScheduleShiftFormData, baseScheduleShiftId);

    try {
        const includes = [
            'department',
            'department.group',
            'baseScheduleShiftPlannings',
            'baseScheduleShiftPlannings.user',
            'baseScheduleShiftPlannings.user.person',
            'baseScheduleShiftPlannings.user.employmentType',
            'baseScheduleShiftPlannings.user.experience',
        ];

        const url = generateApiUrl({
            endpoint: `/base-schedule-shifts/${baseScheduleShiftId}`,
            queryParams: {
                include: includes.join(','),
            },
        });

        const response = await authorizedFetch(url, {
            method: 'PATCH',
            body: JSON.stringify({ data: apiData }),
        });

        const doc: JapiDocument = await response.json();

        if (!isResourceDocument<BaseScheduleShiftResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const baseScheduleShiftResource = doc.data;
        const departmentResource = getIncluded(doc, baseScheduleShiftResource, 'department') as DepartmentResource;
        const departmentGroupResource = getIncluded(doc, departmentResource, 'group') as DepartmentGroupResource;
        const department = transformToDepartment(departmentResource, departmentGroupResource);
        const shiftPlanningsResource = getMultipleIncluded(doc, baseScheduleShiftResource, 'baseScheduleShiftPlannings') as BaseScheduleShiftPlanningResource[];
        const shiftPlannings = shiftPlanningsResource
            .map(shiftPlanningResource => getShiftPlanningResourceIncludesAndTransformToShiftPlanning(doc, shiftPlanningResource));

        const shift = transformToBaseScheduleShift(
            baseScheduleShiftResource,
            department,
            shiftPlannings,
        );

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: shift,
        };
    } catch (error) {
        console.error('[editBaseScheduleShiftTimeApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const deleteBaseScheduleShiftApiCall = async (baseScheduleShiftId: string): Promise<FetchResult<boolean, string>> => {
    try {
        const url = generateApiUrl({ endpoint: `/base-schedule-shifts/${baseScheduleShiftId}` });

        const response = await authorizedFetch(url, { method: 'DELETE' });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: true,
        };
    } catch (error) {
        console.error('[deleteBaseScheduleShiftApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
