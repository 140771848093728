import React, { Component } from 'react';

import PropTypes from 'prop-types';
import Tooltip from 'react-tooltip-lite';

import './InfoCircle.scss';

function guidGenerator() {
    return Math.random().toString(36).replace(/[^a-z]+/g, '').substr(2, 10);
}

class InfoCircle extends Component {
    state = {
        id: `tooltip-${guidGenerator()}`,
    };

    renderCircle = () => {
        const {
            text, icon, tooltip, alt, iconClass,
        } = this.props;
        const iconModifier = iconClass && `info-circle-icon--${iconClass}`;

        return (
            <div className={`info-circle ${tooltip ? 'info-circle--tooltip' : ''}`} onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave}>
                { text && <div className="info-circle-text">{text}</div> }
                { icon && <img className={`info-circle-icon ${iconModifier}`} src={icon} alt={`icon-${alt || text}`} /> }
            </div>
        );
    }

    render() {
        const { tooltip } = this.props;
        const { id } = this.state;

        return (
            <div className="info-circle-wrapper" id={id}>
                { tooltip ? (
                    <Tooltip content={tooltip} arrowSize={6} padding={7}>
                        { this.renderCircle() }
                    </Tooltip>
                ) : (
                    this.renderCircle()
                )}
            </div>
        );
    }
}

InfoCircle.propTypes = {
    iconClass: PropTypes.string,
    text: PropTypes.any,
    icon: PropTypes.string,
    tooltip: PropTypes.string,
    alt: PropTypes.string,
};

InfoCircle.defaultProps = {
    iconClass: null,
    text: null,
    icon: null,
    tooltip: null,
    alt: null,
};

export default InfoCircle;
