import axios from 'axios';

import { version } from '../../package.json';
import { localStorageRefreshTokenKey, localStorageTokenKey } from '../@paco/helpers/authorizedFetch';
import { refreshAccessToken } from '../helpers/api/authenticationApi';
import { logout } from '../redux/app/appActions';
import { setApiToken, setRefreshToken } from '../redux/authentication/authenticationActions';

export default class AuthInterceptor {
    constructor(store) {
        this.store = store;
    }

    setInterceptors = () => {
        axios.interceptors.request.use(
            (requestConfig) => {
                const headers = {
                    ...requestConfig.headers,
                    authorization: `Bearer ${localStorage.getItem(localStorageTokenKey)}`,
                    TENANT: process.env.REACT_APP_TENANT || window.location.hostname,
                    App: 'manager',
                    'App-Version': version,
                };

                return {
                    ...requestConfig,
                    headers,
                };
            },
            err => Promise.reject(err),
        );

        const { dispatch } = this.store;

        const interceptor = axios.interceptors.response.use(
            response => response,
            (error) => {
                const status = error.response?.status;

                if ((status !== 401)) {
                    return Promise.reject(error);
                }

                axios.interceptors.response.eject(interceptor);

                const apiRefreshToken = localStorage.getItem(localStorageRefreshTokenKey);
                if (!apiRefreshToken || apiRefreshToken === 'undefined') {
                    return Promise.reject(error);
                }

                return refreshAccessToken(apiRefreshToken)
                    .then((refreshResponse) => {
                        dispatch(setApiToken(refreshResponse.data.token));
                        dispatch(setRefreshToken(refreshResponse.data.refresh_token));

                        const errorConfig = { ...error.config };

                        errorConfig.headers.authorization = `Bearer ${refreshResponse.data.token}`;

                        return axios(errorConfig);
                    })
                    .catch((refreshError) => {
                        if ((refreshError.response.status !== 200)) {
                            dispatch(logout());
                            localStorage.setItem('authenticated', 'false');
                        }
                    })
                    .finally(localStorage.getItem('authenticated') === 'true' && this.setInterceptors);
            },
        );
    };
}
