import React from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import DoubleDateNavigator from '../../../../components/DoubleDateNavigator/DoubleDateNavigator';
import ConnectedDateNavigator from '../../../../connectors/ConnectedDateNavigator/ConnectedDateNavigator';
import { TIME_MODES } from '../../../../constants';
import { getRangeFromDate, startOfDay } from '../../../../helpers/date';
import { translate } from '../../../../helpers/translations/translator';
import { setTimeMode } from '../../../../redux/weekNavigator/weekNavigatorActions';
import BasicSelect from './BasicSelect/BasicSelect';

import './CalendarToolbar.scss';


const CalendarToolbar = (props) => {
    const {
        timeMode,
        yearMode,
        dispatch,
        hideTimeModeSelect,
        payrollPeriods,
        className,
    } = props;

    const options = props.options || [
        {
            label: translate('common.week'),
            value: TIME_MODES.WEEK,
        },
        {
            label: translate('common.month'),
            value: TIME_MODES.MONTH,
        },
        ...(payrollPeriods.length ? [{
            label: translate('common.period'),
            value: TIME_MODES.PERIOD,
        }] : []),
        {
            label: translate('common.custom'),
            value: TIME_MODES.CUSTOM,
        },
        ...(yearMode ? [{
            label: translate('common.year'),
            value: TIME_MODES.YEAR,
        }] : []),
    ];

    const onSelectTimemodeChange = (value) => {
        dispatch(setTimeMode(value));
        props.afterDateChange();
    };

    const afterDateChange = (selectedDate, start, end) => {
        props.afterDateChange(selectedDate, start, end);
    };

    const onTodayClick = (value) => {
        const time = new Date();
        const days = getRangeFromDate(time, value, payrollPeriods);
        const selectedDate = startOfDay(time);

        props.setStartAndEndDate(selectedDate, days[0], days[days.length - 1]);
    };

    const classNames = classnames('calendar-toolbar', {
        'calendar-toolbar--is-loading': !payrollPeriods.length,
    }, [className]);

    return (
        <div className={classNames}>
            {timeMode === TIME_MODES.CUSTOM ? (
                <DoubleDateNavigator
                    onDateChange={afterDateChange}
                    onTodayClick={onTodayClick}
                />
            ) : (
                <>
                    <button
                        type="button"
                        className="calendar-toolbar__today-button"
                        onClick={() => onTodayClick(timeMode)}
                    >
                        {translate('common.toToday')}
                    </button>
                    <ConnectedDateNavigator
                        afterDateChange={afterDateChange}
                    />
                </>
            )}
            {!hideTimeModeSelect && (
                <div className="calendar-mode-buttons">
                    <span className="calendar-mode-buttons__title">Weergave</span>
                    <BasicSelect
                        options={options}
                        value={timeMode}
                        onChange={onSelectTimemodeChange}
                        className="calendar-toolbar__dropdown"
                    />
                </div>
            )}
        </div>
    );
};

CalendarToolbar.propTypes = {
    dispatch: PropTypes.func.isRequired,
    afterDateChange: PropTypes.func,
    setStartAndEndDate: PropTypes.func.isRequired,
    timeMode: PropTypes.string.isRequired,
    payrollPeriods: PropTypes.array,
    hideTimeModeSelect: PropTypes.bool,
    yearMode: PropTypes.bool,
    options: PropTypes.array,
    className: PropTypes.string,
};


CalendarToolbar.defaultProps = {
    yearMode: false,
    hideTimeModeSelect: false,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    afterDateChange: () => {},
    options: null,
    payrollPeriods: [],
    className: undefined,
};

export default connect()(CalendarToolbar);
