import { FC, ReactElement } from 'react';

import { ShiftsCalendarFilterContainer } from '../../../containers';
import usePermissions from '../../../helpers/hooks/usePermissions';

interface ConnectedResetFiltersButtonProps {
    className?: string;
}

const ConnectedShiftsCalendarFilter: FC<ConnectedResetFiltersButtonProps> = ({ className = '' }): ReactElement => {
    const permissions = usePermissions();

    return (
        <ShiftsCalendarFilterContainer permissions={permissions} className={className} />
    );
};

export default ConnectedShiftsCalendarFilter;
