import React from 'react';

import serialize from 'form-serialize';
import PropTypes from 'prop-types';

import './Settings.scss';

class SettingsInputTime extends React.Component {
    ref = React.createRef();

    onSubmit = (e) => {
        e.preventDefault();
        this.props.onChange();
    };

    onChange = () => {
        const formData = serialize(this.ref.current, { hash: true });
        const option = {
            ...this.props.option,
            value: formData[this.props.option.id],
        };
        this.props.onChange(option);
    };

    render() {
        const { option, label } = this.props;

        if (!option) {
            return null;
        }

        return (
            <div className="settings-input-time">
                <form ref={this.ref} className="settings-input-time-form" onSubmit={this.onSubmit} onChange={this.onChange}>
                    <div className="settings-input-time-option" key={option.id}>
                        <div className="settings-card-section-title">
                            {label}
                        </div>
                        <div className="form-check">
                            <input
                                type="number"
                                className="form-control"
                                name={option.id}
                                id={option.id}
                                defaultValue={option.value}
                                placeholder={label}
                            />
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

SettingsInputTime.propTypes = {
    option: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    label: PropTypes.string,
};

SettingsInputTime.defaultProps = {
    label: null,
};

export default SettingsInputTime;
