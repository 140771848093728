import { FC, ReactElement, useState } from 'react';

import { useToggle } from 'react-use';

import { PrivateRouteGroupRoutes } from '../../../components/PrivateRouteGroup/PrivateRouteGroupRoutes';
import { SettingsRoutes } from '../../../pages/Settings/SettingsRoutes';
import {
    EmptyState,
    IconButton,
    IconNavLink,
    ModalContent,
    PacoModal,
} from '../../compositions';
import {
    ConnectedAddDistrictDivisionGroupTemplateForm,
    ConnectedDeleteDistrictDivisionGroupTemplateForm,
    ConnectedEditDistrictDivisionGroupTemplateForm,
    ConnectedHelpTooltip,
    ConnectedManageDistrictDivisionTemplateGroupsOrderForm,
} from '../../connectors';
import { DistrictDivisionGroupTemplate } from '../../entities/DistrictDivisionGroupTemplate/DistrictDivisionGroupTemplate';
import { DistrictDivisionTemplate } from '../../entities/DistrictDivisionTemplate/DistrictDivisionTemplate';
import { Permission } from '../../entities/Permission/Permission';
import checkPermission from '../../helpers/permissions/checkPermission';
import trans from '../../helpers/trans';
import { DistrictDivisionGroupTemplateTable } from '../index';

import './DistrictDivisionTemplateDetailContainer.scss';

interface DistrictDivisionTemplateDetailContainerProps {
    isLoading?: boolean;
    districtDivisionTemplate: DistrictDivisionTemplate;
    permissions: Permission[];
}

const DistrictDivisionTemplateDetailContainer: FC<DistrictDivisionTemplateDetailContainerProps> = ({
    isLoading,
    districtDivisionTemplate,
    permissions,
}): ReactElement => {
    const canAddDistrictDivisionGroupTemplates = checkPermission(permissions, 'add-new-district-division-group-templates');
    const canEditDistrictDivisionGroupTemplates = checkPermission(permissions, 'edit-all-district-division-group-templates');

    const [addDistrictDivisionGroupTemplateModalIsOpen, toggleAddDistrictDivisionGroupTemplateModalIsOpen] = useToggle(false);
    const [editDistrictDivisionGroupTemplateModalIsOpen, toggleEditDistrictDivisionGroupTemplateModalIsOpen] = useToggle(false);
    const [deleteDistrictDivisionGroupTemplateModalIsOpen, toggleDeleteDistrictDivisionGroupTemplateModalIsOpen] = useToggle(false);
    const [manageDistrictDivisionTemplateGroupOrderModalIsOpen, toggleManageDistrictDivisionTemplateGroupOrderModalIsOpen] = useToggle(false);

    const [districtDivisionGroupTemplateState, setDistrictDivisionGroupTemplateState] = useState<DistrictDivisionGroupTemplate | undefined>(undefined);
    const [districtDivisionGroupTemplateIdState, setDistrictDivisionGroupTemplateIdState] = useState<string | undefined>(undefined);

    const handleEditDistrictDivisionGroupTemplateClick = (districtDivisionGroupTemplate: DistrictDivisionGroupTemplate): void => {
        toggleEditDistrictDivisionGroupTemplateModalIsOpen();
        setDistrictDivisionGroupTemplateState(districtDivisionGroupTemplate);
    };

    const handleDeleteDistrictDivisionGroupTemplateClick = (districtDivisionGroupTemplateId: string): void => {
        toggleDeleteDistrictDivisionGroupTemplateModalIsOpen();
        setDistrictDivisionGroupTemplateIdState(districtDivisionGroupTemplateId);
    };

    return (
        <section className="district-division-template-detail-container">
            {districtDivisionTemplate.districtDivisionGroupTemplates.length !== 0 ? (
                <>
                    <ConnectedHelpTooltip
                        index={0}
                        route="districtDivisionTemplates"
                        subTitle={trans('help.settings.districtDivisionTemplateDetail.title')}
                        text={trans('help.settings.districtDivisionTemplateDetail.text')}
                        title={trans('help.settings.title')}
                        className="district-division-template-detail-container__overview-help-tooltip"
                    />
                    <ConnectedHelpTooltip
                        index={1}
                        route="districtDivisionTemplates"
                        subTitle={trans('help.settings.editDistrictDivisionGroupTemplate.title')}
                        text={trans('help.settings.editDistrictDivisionGroupTemplate.text')}
                        title={trans('help.settings.title')}
                        className="district-division-template-detail-container__edit-district-division-group-template-help-tooltip"
                    />
                </>
            ) : (
                <ConnectedHelpTooltip
                    index={0}
                    route="districtDivisionTemplates"
                    subTitle={trans('help.settings.districtDivisionTemplateDetailEmptyState.title')}
                    text={trans('help.settings.districtDivisionTemplateDetailEmptyState.text')}
                    title={trans('help.settings.title')}
                    className="district-division-template-detail-container__empty-state-help-tooltip"
                />
            )}
            {!isLoading && (
                <>
                    <div className="district-division-template-detail-container__top-bar">
                        <h3 className="district-division-template-detail-container__title">{districtDivisionTemplate.name}</h3>
                        <div className="district-division-template-detail-container__top-bar-buttons">
                            <IconNavLink
                                icon="reset"
                                text={trans('common.back')}
                                to={`${PrivateRouteGroupRoutes.settings()}/${SettingsRoutes.districtDivisionTemplates()}`}
                                className="district-division-template-detail-container__back-button"
                                iconClassName="district-division-template-detail-container__back-button-icon"
                            />
                            {canAddDistrictDivisionGroupTemplates && (
                                <IconButton
                                    icon="plus"
                                    text={trans('common.newGroup')}
                                    onClick={toggleAddDistrictDivisionGroupTemplateModalIsOpen}
                                    className="district-division-template-detail-container__add-group-button"
                                />
                            )}
                            {canEditDistrictDivisionGroupTemplates && (
                                <IconButton
                                    icon="edit"
                                    text={trans('containers.districtDivisionTemplateDetailContainer.editDistrictDivisionTemplateGroupOrder')}
                                    onClick={toggleManageDistrictDivisionTemplateGroupOrderModalIsOpen}
                                    className="district-division-template-detail-container__manage-groups-button"
                                />
                            )}
                        </div>
                    </div>
                    {districtDivisionTemplate.districtDivisionGroupTemplates.length === 0 && (
                        <EmptyState
                            showButton={canAddDistrictDivisionGroupTemplates}
                            buttonText={trans('containers.districtDivisionTemplateDetailContainer.addDistrictDivisionGroup')}
                            intro={canAddDistrictDivisionGroupTemplates ? trans('containers.districtDivisionTemplateDetailContainer.nullState.description') : undefined}
                            title={trans('containers.districtDivisionTemplateDetailContainer.nullState.title')}
                            onAddButtonClick={toggleAddDistrictDivisionGroupTemplateModalIsOpen}
                            className="district-division-template-table__empty-state"
                        />
                    )}
                    {addDistrictDivisionGroupTemplateModalIsOpen && (
                        <PacoModal>
                            <ModalContent title={trans('common.newGroup')}>
                                <ConnectedAddDistrictDivisionGroupTemplateForm onClose={toggleAddDistrictDivisionGroupTemplateModalIsOpen} />
                            </ModalContent>
                        </PacoModal>
                    )}
                    {manageDistrictDivisionTemplateGroupOrderModalIsOpen && (
                        <PacoModal>
                            <ModalContent title={trans('containers.districtDivisionTemplateDetailContainer.editDistrictDivisionTemplateGroupOrder')}>
                                <ConnectedManageDistrictDivisionTemplateGroupsOrderForm onClose={toggleManageDistrictDivisionTemplateGroupOrderModalIsOpen} />
                            </ModalContent>
                        </PacoModal>
                    )}
                    {editDistrictDivisionGroupTemplateModalIsOpen && districtDivisionGroupTemplateState && (
                        <PacoModal>
                            <ModalContent title={trans('common.editGroup')}>
                                <ConnectedEditDistrictDivisionGroupTemplateForm
                                    districtDivisionGroupTemplate={districtDivisionGroupTemplateState}
                                    onClose={toggleEditDistrictDivisionGroupTemplateModalIsOpen}
                                />
                            </ModalContent>
                        </PacoModal>
                    )}
                    {deleteDistrictDivisionGroupTemplateModalIsOpen && districtDivisionGroupTemplateIdState && (
                        <PacoModal>
                            <ModalContent title={trans('common.deleteGroup')}>
                                <ConnectedDeleteDistrictDivisionGroupTemplateForm
                                    districtDivisionGroupTemplateId={districtDivisionGroupTemplateIdState}
                                    onClose={toggleDeleteDistrictDivisionGroupTemplateModalIsOpen}
                                />
                            </ModalContent>
                        </PacoModal>
                    )}
                    {districtDivisionTemplate.districtDivisionGroupTemplates.map(districtDivisionGroupTemplate => (
                        <div
                            key={districtDivisionGroupTemplate.id}
                            className="district-division-template-detail-container__item"
                        >
                            <div className="district-division-template-detail-container__item-header">
                                <h4 className="district-division-template-detail-container__group">{trans('common.groupName', { name: districtDivisionGroupTemplate.name })}</h4>
                                <div className="district-division-template-detail-container__action-buttons">
                                    <IconButton
                                        hideLabel
                                        text="edit"
                                        icon="edit"
                                        onClick={() => handleEditDistrictDivisionGroupTemplateClick(districtDivisionGroupTemplate)}
                                        iconClassName="district-division-template-detail-container__edit-icon"
                                    />
                                    <IconButton
                                        hideLabel
                                        text="delete"
                                        icon="trash"
                                        onClick={() => handleDeleteDistrictDivisionGroupTemplateClick(districtDivisionGroupTemplate.id)}
                                        className="district-division-template-detail-container__delete"
                                        iconClassName="district-division-template-detail-container__delete-icon"
                                    />
                                </div>
                            </div>
                            <DistrictDivisionGroupTemplateTable
                                districtDivisionGroupTemplate={districtDivisionGroupTemplate}
                            />
                        </div>
                    ))}
                </>
            )}
        </section>
    );
};

export default DistrictDivisionTemplateDetailContainer;
