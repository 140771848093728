import React, { FC } from 'react';

import { Permission } from '../../../../@paco/entities/Permission/Permission';
import { checkPermission } from '../../../../helpers';
import { DocumentTypeViewModel, EmploymentType, Role } from '../../../../models';
import { AppFilter, AppFilterType } from '../../../../redux/filter-ts/filterModels';
import AvailabilityFilter from '../AvailabilityFilter/AvailabilityFilter';
import AvailabilitySorting from '../AvailabilitySorting/AvailabilitySorting';
import CalendarViewPropsFilter from '../CalendarViewPropsFilter/CalendarViewPropsFilter';
import ContractTypeFilter from '../ContractTypeFilter/ContractTypeFilter';
import DeniedLeaveOfAbsenceRequestsFilter from '../DeniedLeaveOfAbsenceRequestsFilter/DeniedLeaveOfAbsenceRequestsFilter';
import DocumentTypeFilter from '../DocumentTypeFilter/DocumentTypeFilter';
import EmployeeStatusFilter from '../EmployeeStatusFilter/EmployeeStatusFilter';
import EmploymentTypeBadgeFilter from '../EmploymentTypeBadgeFilter/EmploymentTypeBadgeFilter';
import ExchangesFilter from '../ExchangesFilter/ExchangesFilter';
import MainDepartmentFilter from '../MainDepartmentFilter/MainDepartmentFilter';
import ShowEmployeesFilter from '../ShowEmployeesFilter/ShowEmployeesFilter';
import ShowInactiveUsersFilter from '../ShowInactiveUsersFilter/ShowInactiveUsersFilter';
import ShowPublishedShiftsFilter from '../ShowPublishedShiftsFilter/ShowPublishedShiftsFilter';
import TradeRequestsFilter from '../TradeRequestsFilter/TradeRequestsFilter';
import UsersLoketFilter from '../UsersLoketFilter/UsersLoketFilter';
import UserTypesFilter from '../UserTypesFilter/UserTypesFilter';

interface CustomFilterProps {
    filter: AppFilter;
    type: AppFilterType;
    roles: Role[];
    permissions: Permission[];
    employmentTypes: EmploymentType[];
    documentTypes: DocumentTypeViewModel[];
    onChange: (newFilter: AppFilter) => void;
}

const CustomFilter: FC<CustomFilterProps> = ({
    filter,
    type,
    roles,
    permissions,
    employmentTypes,
    documentTypes,
    onChange,
}) => {
    const canViewAbsences = checkPermission(permissions, 'view-all-absences', 'custom-filter');
    const canViewLeaveOfAbsences = checkPermission(permissions, 'view-all-leave-of-absences', 'custom-filter');
    const canViewAllPreferToWorkTimeSlots = checkPermission(permissions, 'view-all-prefer-to-work-time-slots', 'custom-filter');
    const canViewUnavailableToWorkTimeSlots = checkPermission(permissions, 'view-unavailable-to-work-time-slots', 'custom-filter');

    if (type === 'show-employees') {
        return (
            <ShowEmployeesFilter
                filter={filter}
                onChange={onChange}
            />
        );
    }

    if (type === 'employee-status') {
        return (
            <EmployeeStatusFilter
                filter={filter}
                onChange={onChange}
            />
        );
    }

    if (type === 'employment-type-badge') {
        return (
            <EmploymentTypeBadgeFilter
                filter={filter}
                onChange={onChange}
            />
        );
    }

    if (type === 'availability') {
        return (
            <AvailabilityFilter
                filter={filter}
                canViewAbsences={canViewAbsences}
                canViewAllPreferToWorkTimeSlots={canViewAllPreferToWorkTimeSlots}
                canViewLeaveOfAbsences={canViewLeaveOfAbsences}
                canViewUnavailableToWorkTimeSlots={canViewUnavailableToWorkTimeSlots}
                onChange={onChange}
            />
        );
    }

    if (type === 'availability-sorting') {
        return (
            <AvailabilitySorting
                filter={filter}
                onChange={onChange}
            />
        );
    }

    if (type === 'main-department') {
        return (
            <MainDepartmentFilter
                filter={filter}
                onChange={onChange}
            />
        );
    }

    if (type === 'user-types') {
        return (
            <UserTypesFilter
                filter={filter}
                onChange={onChange}
                roles={roles}
            />
        );
    }

    if (type === 'contract-types') {
        return (
            <ContractTypeFilter
                filter={filter}
                onChange={onChange}
                employmentTypes={employmentTypes}
            />
        );
    }

    if (type === 'show-denied-leave-of-absence-requests') {
        return (
            <DeniedLeaveOfAbsenceRequestsFilter
                filter={filter}
                onChange={onChange}
            />
        );
    }

    if (type === 'calendar-view-props') {
        return (
            <CalendarViewPropsFilter
                filter={filter}
                onChange={onChange}
            />
        );
    }

    if (type === 'trade-requests') {
        return (
            <TradeRequestsFilter
                filter={filter}
                onChange={onChange}
            />
        );
    }

    if (type === 'show-inactive-users') {
        return (
            <ShowInactiveUsersFilter
                filter={filter}
                onChange={onChange}
            />
        );
    }

    if (type === 'document-types') {
        return (
            <DocumentTypeFilter
                filter={filter}
                onChange={onChange}
                documentTypes={documentTypes}
            />
        );
    }

    if (type === 'exchanges') {
        return (
            <ExchangesFilter
                filter={filter}
                onChange={onChange}
            />
        );
    }

    if (type === 'show-published-shifts') {
        return (
            <ShowPublishedShiftsFilter
                filter={filter}
                onChange={onChange}
            />
        );
    }

    if (type === 'users-loket') {
        return (
            <UsersLoketFilter
                filter={filter}
                onChange={onChange}
            />
        );
    }

    return null;
};

export default CustomFilter;
