import {
    FC,
    ReactElement,
    useEffect,
    useMemo,
} from 'react';

import { useNavigate } from 'react-router-dom';

import { PrivateRouteGroupRoutes } from '../../../../components/PrivateRouteGroup/PrivateRouteGroupRoutes';
import { ManagementRoutes } from '../../../../pages/Management/ManagementRoutes';
import { ConfirmForm } from '../../../containers';
import { getPersonFullName } from '../../../entities/Person/PersonHelpers';
import { UserProfile } from '../../../entities/User/User';
import trans from '../../../helpers/trans';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';
import { setToast } from '../../../redux/toasts/toastsReducer';
import { editUserStatus } from '../../../redux/user/userActions';
import { setIsSetUserStatusSuccessful } from '../../../redux/user/userReducer';
import { ToastType } from '../../../types';
import { UserStatus } from '../../../types/userStatus';

interface ConnectedDeleteUserFormProps {
    user: UserProfile;
    onClose: () => void;
    className?: string;
}

const ConnectedDeleteUserForm: FC<ConnectedDeleteUserFormProps> = ({ user, onClose, className = '' }): ReactElement => {
    const dispatch = useTypedDispatch();
    const navigate = useNavigate();
    const { isLoading, isSetUserStatusSuccessful } = useTypedSelector(state => state.pacoUserReducer);

    const fullName = useMemo(() => getPersonFullName(user.person), [user]);

    const handleSubmit = (): void => {
        dispatch(editUserStatus(user, UserStatus.deleted));
    };

    useEffect((): void => {
        if (isSetUserStatusSuccessful) {
            dispatch(setIsSetUserStatusSuccessful(false));

            dispatch(setToast({
                text: trans('containers.forms.deleteUserForm.deleteUserSuccess', { fullName }),
                type: ToastType.pass,
            }));

            navigate(`${PrivateRouteGroupRoutes.management()}/${ManagementRoutes.users()}`);
        }
    }, [isSetUserStatusSuccessful]);

    return (
        <ConfirmForm
            isLoading={isLoading}
            intro={trans('containers.forms.deleteUserForm.intro', { fullName })}
            submitText={trans('common.delete')}
            onCancel={onClose}
            onSubmit={handleSubmit}
            className={className}
        />
    );
};

export default ConnectedDeleteUserForm;
