import { FC, memo, ReactElement } from 'react';

import { getContractHoursLabel } from '../../entities/ContractHours/ContractHoursHelpers';
import { Permission } from '../../entities/Permission/Permission';
import { UserProfile } from '../../entities/User/User';
import { formatDate } from '../../helpers/date';
import checkPermission from '../../helpers/permissions/checkPermission';
import trans from '../../helpers/trans';
import { DateFormat } from '../../types/dateFormatTypes';
import { UserProfileCardListItem } from './subcomponents';

import './UserProfileCard.scss';

interface UserProfileCardProps {
    permissions: Permission[];
    user: UserProfile;
    className?: string;
}

const UserProfileCard: FC<UserProfileCardProps> = ({
    permissions,
    user,
    className = '',
}): ReactElement => {
    const { address, employment, person } = user;

    const canViewAddresses = checkPermission(permissions, 'view-all-addresses', 'user-profile-card');
    const canViewEmergencyContacts = checkPermission(permissions, 'view-all-emergency-contacts', 'user-profile-card');
    const canViewEmployments = checkPermission(permissions, 'view-all-employments', 'user-profile-card');
    const canViewContractHours = checkPermission(permissions, 'view-all-periodical-contract-hours', 'user-profile-card');
    const contractHours = canViewContractHours ? getContractHoursLabel(user.contractHours) : undefined;

    return (
        <div className={`user-profile-card ${className}`}>
            <UserProfileCardListItem
                title={trans('common.employeeNumber')}
                value={user.employeeNumber || ''}
            />
            <UserProfileCardListItem
                title={trans('common.firstname')}
                value={person.firstname}
            />
            <UserProfileCardListItem
                title={trans('common.lastname')}
                value={`${person.affix || ''} ${person.lastname}`}
            />
            <UserProfileCardListItem
                title={trans('common.phoneNumber')}
                value={user.phone}
            />
            <UserProfileCardListItem
                title={trans('common.email')}
                value={user.email}
            />
            <UserProfileCardListItem
                title={trans('common.gender')}
                value={person.gender ? trans(`common.${person.gender}`) : trans('common.unknown')}
            />
            <UserProfileCardListItem
                title={trans('common.birthday')}
                value={person.birthday ? formatDate(person.birthday, DateFormat.date) : ''}
            />
            {canViewAddresses && !!address && (
                <>
                    <UserProfileCardListItem
                        title={trans('compositions.userProfileCard.streetAndNumber')}
                        value={`${address.street} ${address.houseNumber}`}
                    />
                    <UserProfileCardListItem
                        title={trans('common.postalCode')}
                        value={address.postalCode || ''}
                    />
                    <UserProfileCardListItem
                        title={trans('common.city')}
                        value={address.city || ''}
                    />
                </>
            )}
            <UserProfileCardListItem
                title={trans('common.nationality')}
                value={person.nationality}
            />
            <UserProfileCardListItem
                title={trans('common.companyMedic')}
                value={trans(`common.${person.companyMedic.toLowerCase()}`)}
            />
            <UserProfileCardListItem
                title={trans('common.identification')}
                value={`${trans(`types.identification.${person.identificationType}`)} - ${formatDate(person.identificationExpirationDate, DateFormat.date)}`}
            />
            <UserProfileCardListItem
                title={trans('common.role')}
                value={trans(`entities.roles.name.${user.role.slug}`)}
            />
            <UserProfileCardListItem
                title={trans('common.level')}
                value={user.experience.name}
            />
            <UserProfileCardListItem
                title={trans('common.workdays')}
                value={person.workdays
                    .map(workday => trans(`types.workdays.${workday}`))
                    .map(workday => workday.substring(0, 2))
                    .join(', ')}
            />
            {canViewEmployments && (
                <>
                    <UserProfileCardListItem
                        title={trans('common.employmentStartDate')}
                        value={employment.start ? formatDate(employment.start, DateFormat.date) : ''}
                    />
                    <UserProfileCardListItem
                        title={trans('common.employmentEndDate')}
                        value={employment.end ? formatDate(employment.end, DateFormat.date) : ''}
                    />
                </>
            )}

            {canViewContractHours && (
                <UserProfileCardListItem
                    title={trans('common.contractHours')}
                    value={contractHours || ''}
                />
            )}

            {canViewEmployments && (
                <>
                    <UserProfileCardListItem
                        title={trans('common.divergentPreEmploymentHours')}
                        value={employment.divergentPreEmploymentHour?.contractHours || ''}
                    />
                    <UserProfileCardListItem
                        title={trans('common.divergentPostEmploymentHours')}
                        value={employment.divergentPostEmploymentHour?.contractHours || ''}
                    />
                </>
            )}
            {canViewEmergencyContacts && (
                <UserProfileCardListItem
                    title={trans('common.emergencyContact')}
                    value={user.emergencyContact.fullName || ''}
                />
            )}
            <UserProfileCardListItem
                title={trans('common.shouldTrackTime')}
                value={user.shouldTrackTime ? trans('common.yes') : trans('common.no')}
            />
            <UserProfileCardListItem
                title={trans('common.isPaidBasedOnTrackedTime')}
                value={user.isPaidBasedOnTrackedTime ? trans('common.yes') : trans('common.no')}
            />
            <UserProfileCardListItem
                title={trans('common.isEligibleForTimeForTime')}
                value={user.isEligibleForTimeForTime ? trans('common.yes') : trans('common.no')}
            />
            <UserProfileCardListItem
                title={trans('common.hasSundayAllowance')}
                value={person.hasSundayAllowance ? trans('common.yes') : trans('common.no')}
            />
        </div>
    );
};

export default memo(UserProfileCard);
