import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { ConnectedHelpTooltip } from '../../@paco/connectors';
import trans from '../../@paco/helpers/trans';
import MonthNavigator from '../../components/DateNavigator/MonthNavigator';
import FeedbackScores from '../../components/FeedbackScores/FeedbackScores';
import NewFilters from '../../components/Filters/Filters';
import ItemsList from '../../components/ItemsList/ItemsList';
import SearchBar from '../../components/SearchBar/SearchBar';
import SidebarPage from '../../components/SidebarPage/SidebarPage';
import TableContainer from '../../components/Table/TableContainer';
import UserPageLink from '../../components/UserPageLink/UserPageLink';
import { getObjProperty, getUserFullName } from '../../helpers';
import { convertDateToTime, formatDate } from '../../helpers/date';
import { translate } from '../../helpers/translations/translator';
import { getFeedback } from '../../redux/feedback/feedbackActions';
import { setEmployeeSearch, setFilter } from '../../redux/filter/filterActions';

import './Feedback.scss';


class Feedback extends Component {
    TABLE_ATTRIBUTES = [
        {
            label: translate('common.date'),
            key: 'date',
        },
        {
            label: translate('common.name'),
            customAction: feedback => <UserPageLink isPlain name={feedback.userName} id={feedback.userId} />,
        },
        {
            label: translate('common.department'),
            key: 'department',
        },
        {
            label: translate('common.time'),
            key: 'time',
        },
        {
            label: translate('common.category'),
            customAction: feedback => feedback.category && translate(`pages.feedback.${feedback.category.toLowerCase()}`),
        },
        {
            label: translate('common.note'),
            key: 'note',
        },
    ];

    state = {
        date: new Date(),
    }

    componentDidMount() {
        this.getFeedback();
    }

    componentDidUpdate(prevProps) {
        const activeDepartmentOptions = this.props.departmentOptions.filter(option => option.isChecked);
        const prevActiveDepartmentOptions = prevProps.departmentOptions.filter(option => option.isChecked);

        if (activeDepartmentOptions.length !== prevActiveDepartmentOptions.length) {
            this.getFeedback();
        }
    }

    dispatchSetFilters = (filter) => {
        this.props.dispatch(setFilter(filter, this.getFeedback));
    }

    getFeedback = () => {
        const { date } = this.state;
        this.props.dispatch(getFeedback(date));
    }

    onMonthChange = (date) => {
        this.setState({
            date,
        }, () => this.getFeedback());
    }

    onSearchBarChange = (value) => {
        this.props.dispatch(setEmployeeSearch(value));
        this.getFeedback();
    }

    renderFeedback() {
        const { date } = this.state;
        const {
            pagination, loading, filter, feedbackList, scores,
        } = this.props;

        const { employeeSearch } = filter;
        const tableData = feedbackList.map((item) => {
            const tracks = item.tracks || {};
            const name = getUserFullName(tracks.owner);
            const userId = tracks.owner && tracks.owner.id;
            const time = `${convertDateToTime(tracks.checkIn)} - ${convertDateToTime(tracks.checkOut)}`;
            const department = getObjProperty(item, 'tracks.department.name') ? getObjProperty(item, 'tracks.department.name') : '';
            const group = getObjProperty(item, 'tracks.department.group.name');
            const note = item.remark ? item.remark : '';
            const category = item.category ? item.category.name : '';

            return {
                department: `${group ? `${group} - ` : ''}${department}`,
                userName: name,
                userId,
                time,
                id: item.id,
                date: formatDate(tracks.checkIn),
                category,
                note,
            };
        });

        const scoresData = [
            {
                title: translate('common.satisfied'),
                percentage: scores.satisfied,
            },
            {
                title: translate('common.neutral'),
                percentage: scores.neutral,
            },
            {
                title: translate('common.unsatisfied'),
                percentage: scores.unsatisfied,
            },
        ];

        const searchPlaceholder = translate('common.searchEmployee');

        return (
            <>
                <div className="feedback-mobile-padding">
                    <div className="feedback-top">
                        <MonthNavigator onMonthChange={this.onMonthChange} date={date} />
                        <div className="feedback-search">
                            <SearchBar
                                placeholder={searchPlaceholder}
                                startVal={employeeSearch}
                                onChange={this.onSearchBarChange}
                            />
                        </div>
                    </div>

                    <h1 className="feedback-title">{translate('pages.feedback.intro')}</h1>
                    <FeedbackScores scores={scoresData} />

                    <h1 className="feedback-title">{translate('common.dissatisfaction')}</h1>
                </div>
                <div className="feedback-list">
                    <ItemsList
                        loading={loading}
                        pagination={pagination}
                        itemsLength={tableData.length}
                        emptyMessage={translate('pages.feedback.emptyList')}
                    >
                        <TableContainer
                            attributes={this.TABLE_ATTRIBUTES}
                            data={tableData}
                        />
                    </ItemsList>
                </div>
            </>
        );
    }

    render() {
        const { filter } = this.props;

        return (
            <SidebarPage
                sidebarContent={(
                    <NewFilters
                        filter={filter}
                        onChange={this.dispatchSetFilters}
                    />
                )}
            >
                <div className="feedback">
                    {this.renderFeedback()}
                </div>
                <ConnectedHelpTooltip
                    index={0}
                    route="satisfaction"
                    subTitle={trans('help.pz.feedback.title')}
                    text={trans('help.pz.feedback.text')}
                    title={trans('help.pz.title')}
                    className="feedback__feedback-help-tooltip"
                />
                <ConnectedHelpTooltip
                    index={1}
                    route="satisfaction"
                    subTitle={trans('help.pz.satisfactionFilters.title')}
                    text={trans('help.pz.satisfactionFilters.text')}
                    title={trans('help.pz.title')}
                    showMobileInfoWarning
                    className="feedback__filters-help-tooltip"
                />
                <ConnectedHelpTooltip
                    index={2}
                    route="satisfaction"
                    subTitle={trans('help.pz.dissatisfaction.title')}
                    text={trans('help.pz.dissatisfaction.text')}
                    title={trans('help.pz.title')}
                    className="feedback__dissatisfaction-help-tooltip"
                />
            </SidebarPage>
        );
    }
}

Feedback.propTypes = {
    dispatch: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    feedbackList: PropTypes.array.isRequired,
    scores: PropTypes.object.isRequired,
    pagination: PropTypes.object.isRequired,
    filter: PropTypes.object.isRequired,
    departmentOptions: PropTypes.array.isRequired,
};

function mapStateToProps(state) {
    return {
        feedbackList: state.feedbackReducer.feedbackList,
        scores: state.feedbackReducer.scores,
        loading: state.feedbackReducer.loading,
        filter: state.filterReducer.filter,
        pagination: state.paginationReducer,
        currentUser: state.authenticatedUserReducer.user,
        departmentOptions: state.globalFiltersReducer.departmentOptions,
    };
}

export default connect(mapStateToProps)(Feedback);
