import { FC, ReactElement, useMemo } from 'react';

import { Role } from '../../../entities/Role/Role';
import trans from '../../../helpers/trans';
import { SelectOption } from '../../../types';
import { SelectInput } from '../../index';

const generateRoleOptions = (roles: Role[]): SelectOption[] => roles.map(
    role => ({
        label: trans(`entities.roles.name.${role.slug}`),
        value: role.id,
    }),
) as SelectOption[];

interface RoleSelectInputProps {
    roles: Role[];
    role: SelectOption;
    onChange: (option: SelectOption) => void;
}

const RoleSelectInput: FC<RoleSelectInputProps> = ({
    roles,
    role,
    onChange,
}): ReactElement => {
    const roleOptions = useMemo(() => generateRoleOptions(roles), [roles]);

    const onRoleStateChange = (roleValue: SelectOption): void => {
        onChange(roleValue as SelectOption);
    };

    return (
        <SelectInput
            isSearchable={false}
            label={trans('common.role')}
            name="role"
            value={role}
            options={roleOptions}
            onChange={onRoleStateChange}
        />
    );
};

export default RoleSelectInput;
