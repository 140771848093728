import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { ConnectedHelpTooltip } from '../../@paco/connectors';
import trans from '../../@paco/helpers/trans';
import ButtonAuthenticate from '../../components/ButtonAuthenticate/ButtonAuthenticate';
import NewFilters from '../../components/Filters/Filters';
import ItemsList from '../../components/ItemsList/ItemsList';
import OpenTracksTable from '../../components/OpenTracksTable/OpenTracksTable';
import SearchBar from '../../components/SearchBar/SearchBar';
import SidebarPage from '../../components/SidebarPage/SidebarPage';
import { TRACK_TYPE_OPEN } from '../../constants';
import { translate } from '../../helpers/translations/translator';
import { setEmployeeSearch, setFilter } from '../../redux/filter/filterActions';
import { getTracks } from '../../redux/tracks/tracksActions';

import './Tracks.scss';


class OpenTracks extends Component {
    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(getTracks(TRACK_TYPE_OPEN));
    }

    componentDidUpdate(prevProps) {
        const activeDepartmentOptions = this.props.departmentOptions.filter(option => option.isChecked);
        const prevActiveDepartmentOptions = prevProps.departmentOptions.filter(option => option.isChecked);

        if (activeDepartmentOptions.length !== prevActiveDepartmentOptions.length) {
            this.getTracks();
        }
    }

    dispatchSetFilters = (filter) => {
        const { dispatch } = this.props;
        dispatch(setFilter(filter, this.getTracks));
    }

    onSearchBarChange = (value) => {
        const { dispatch } = this.props;
        dispatch(setEmployeeSearch(value));
        this.getTracks();
    }

    onStopClick = (track) => {
        const { onReviewTrackClick } = this.props;
        const newTrack = { ...track };
        newTrack.checkOut = new Date();

        onReviewTrackClick(newTrack, TRACK_TYPE_OPEN);
    }

    getTracks = () => {
        const { dispatch } = this.props;
        dispatch(getTracks(TRACK_TYPE_OPEN));
    }

    renderTracks() {
        const {
            tracks,
            loading,
            filter,
            pagination,
            permissions,
            currentUserRole,
            currentUser,
        } = this.props;
        const { employeeSearch } = filter;

        return (
            <>
                <div className="tracks-search">
                    <SearchBar placeholder={translate('common.searchEmployee')} startVal={employeeSearch} onChange={this.onSearchBarChange} />
                </div>
                <ItemsList
                    loading={loading}
                    itemsLength={tracks.length}
                    pagination={pagination}
                    emptyMessage={translate('pages.tracks.emptyList')}
                    onPagination={this.getTracks}
                >
                    <OpenTracksTable
                        tracks={tracks}
                        currentUser={currentUser}
                        permissions={permissions}
                        currentUserRole={currentUserRole}
                        onStopClick={this.onStopClick}
                    />
                </ItemsList>
            </>
        );
    }

    render() {
        const {
            filter,
            departments,
            canAddTracks,
            employmentTypes,
            onAddTrackClick,
        } = this.props;

        return (
            <SidebarPage
                sidebarContent={(
                    <NewFilters
                        departments={departments}
                        filter={filter}
                        employmentTypes={employmentTypes}
                        button={canAddTracks ? (
                            <ButtonAuthenticate onClick={onAddTrackClick} text={translate('pages.tracks.addTrack')} />
                        ) : null}
                        filterBlocks={[
                            'contract-types',
                        ]}
                        onChange={this.dispatchSetFilters}
                    />
                )}
            >
                <div className="tracks-list tracks-open">
                    {this.renderTracks()}
                </div>
                <ConnectedHelpTooltip
                    index={0}
                    route="open-tracks"
                    subTitle={trans('help.tracks.addTrack.title')}
                    text={trans('help.tracks.addTrack.text')}
                    title={trans('help.tracks.title')}
                    className="tracks__add-track-help-tooltip"
                />
                <ConnectedHelpTooltip
                    index={1}
                    route="open-tracks"
                    subTitle={trans('help.tracks.filters.title')}
                    text={trans('help.tracks.filters.text')}
                    title={trans('help.tracks.title')}
                    showMobileInfoWarning
                    className="tracks__filters-help-tooltip"
                />
            </SidebarPage>
        );
    }
}

OpenTracks.propTypes = {
    loading: PropTypes.bool.isRequired,
    pagination: PropTypes.object.isRequired,
    tracks: PropTypes.array.isRequired,
    dispatch: PropTypes.func.isRequired,
    filter: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
    onAddTrackClick: PropTypes.func.isRequired,
    onReviewTrackClick: PropTypes.func.isRequired,
    departments: PropTypes.array.isRequired,
    employmentTypes: PropTypes.array.isRequired,
    canAddTracks: PropTypes.bool.isRequired,
};

export default connect(null)(OpenTracks);
