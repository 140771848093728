import React, { Component } from 'react';

import classNames from 'classnames';
import serialize from 'form-serialize';
import PropTypes from 'prop-types';
import {
    Button,
    Col,
    FormGroup,
    Input,
    Label,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
} from 'reactstrap';

import { translate } from '../../../helpers/translations/translator';

import './DeclineLeaveOfAbsenceForm.scss';

const maxReasonLength = 255;

class DeclineLeaveOfAbsenceForm extends Component {
    state = {
        data: {},
    };

    onFormChange = (e) => {
        e.preventDefault();
        const data = serialize(e.currentTarget, { hash: true });
        this.setState({ data });
    };

    onFormSubmit = (e) => {
        e.preventDefault();
        const { reason } = this.state.data;
        this.props.onSubmit(reason);
    };

    render() {
        const { onCancel } = this.props;
        const { reason } = this.state.data;
        const reasonLength = reason?.length || 0;
        const hasReasonLengthOverflow = reasonLength > maxReasonLength;

        const reasonLengthClassName = classNames('decline-leave-of-absence-form__reason-length', {
            'decline-leave-of-absence-form__reason-length--has-warning': hasReasonLengthOverflow,
        });

        return (
            <form ref={this.formRef} onSubmit={this.onFormSubmit} onChange={this.onFormChange}>
                <ModalHeader>
                    {translate('pages.absences.declineLeaveOfAbsence')}
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col>
                            <FormGroup>
                                <div className="decline-leave-of-absence-form__form-label">
                                    <Label>{translate('pages.absences.reasonOfDecline')}</Label>
                                    <div className={reasonLengthClassName}>
                                        {`${reasonLength} / ${maxReasonLength}`}
                                    </div>
                                </div>
                                <Input
                                    type="textarea"
                                    rows={4}
                                    className="form-control"
                                    id="reason"
                                    name="reason"
                                    placeholder={translate('pages.absences.reasonOfDeclinePlaceholder')}
                                    defaultValue=""
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button
                        type="button"
                        color="link"
                        id="modal-close"
                        onClick={onCancel}
                    >
                        {translate('common.cancel')}
                    </Button>
                    <Button
                        type="submit"
                        color="orange"
                        disabled={!reason || hasReasonLengthOverflow}
                    >
                        {translate('common.save')}
                    </Button>
                </ModalFooter>
            </form>
        );
    }
}

DeclineLeaveOfAbsenceForm.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default DeclineLeaveOfAbsenceForm;
