import { FC, ReactElement } from 'react';

import { InputLabel } from '../../../components';
import { CheckboxGroupList, CheckboxList } from '../../../compositions';
import { CheckboxChange } from '../../../compositions/GroupedCheckboxes/CheckboxGroup';
import { generateGroupedDepartmentOptions } from '../../../entities/Department/helpers/departmentOptionsHelper';
import trans from '../../../helpers/trans';
import { FormOption } from '../../../types';

import './DepartmentsFilterContainer.scss';

interface DepartmentsFilterContainerProps {
    departmentOptions: FormOption[];
    onChange: (selectedDepartmentOptions: FormOption[]) => void;
    className?: string;
}

const DepartmentsFilterContainer: FC<DepartmentsFilterContainerProps> = ({
    departmentOptions,
    onChange,
    className = '',
}): ReactElement => {
    const groupedDepartmentOptions = generateGroupedDepartmentOptions(departmentOptions);
    const selectedDepartmentOptions = departmentOptions.filter(departmentOption => departmentOption.isChecked);

    const handleGroupChange = (changes: CheckboxChange[], groupValue: string) => {
        if (!changes.length) {
            return;
        }

        const changedGroupOptions = departmentOptions
            .filter(departmentOption => departmentOption.group?.value === groupValue)
            .map(departmentOption => ({
                ...departmentOption,
                isChecked: changes.some(change => (change.value === departmentOption.value && change.checked)),
            }));

        const otherOptions = departmentOptions.filter(option => option.group?.value !== groupValue);

        const changedOptions = [
            ...changedGroupOptions,
            ...otherOptions,
        ];

        onChange(changedOptions);
    };

    const handleOnSingleOptionsChange = (checkedOptionValues: string[]) => {
        const changedOptions = departmentOptions.map(departmentOption => ({
            ...departmentOption,
            isChecked: checkedOptionValues.some(checkedOptionValue => checkedOptionValue === departmentOption.value),
        }));

        onChange(changedOptions);
    };

    return (
        <div className={`departments-filter-container ${className}`}>
            <InputLabel
                text={trans('common.departments')}
                className="departments-filter-container__label"
            />
            {groupedDepartmentOptions.groups.map(groupOption => {
                const handleOnGroupChange = (changes: CheckboxChange[]) => handleGroupChange(changes, groupOption.value);

                return (
                    <CheckboxGroupList
                        key={groupOption.value}
                        label={groupOption.label}
                        hideLabel
                        name={trans('common.department')}
                        groupOption={groupOption}
                        options={groupOption.options}
                        selectedOptions={selectedDepartmentOptions}
                        onChange={handleOnGroupChange}
                    />
                );
            })}
            <CheckboxList
                label="other"
                hideLabel
                name={trans('common.department')}
                options={groupedDepartmentOptions.options}
                value={selectedDepartmentOptions.map(option => option.value)}
                onChange={handleOnSingleOptionsChange}
            />
        </div>
    );
};

export default DepartmentsFilterContainer;
