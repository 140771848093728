import React, { FC, ReactElement } from 'react';

import { Permission } from '../../@paco/entities/Permission/Permission';
import { Role } from '../../@paco/entities/Role/Role';
import { transformLegacyRoleToRole } from '../../@paco/entities/Role/RoleTransformers';
import { getObjProperty, getUserFullName } from '../../helpers';
import { getPermissionToEdit } from '../../helpers/permissions/getPermissionToAction';
import { translate } from '../../helpers/translations/translator';
import { Absence } from '../../models';
import { getAbsenceDateString, getAbsenceDurationInDays, getTotalAbsenceHours } from '../../pages/Absences/helpers';
import Icon from '../style/Icon/Icon';
import TableContainer from '../Table/TableContainer';
import UserPageLink from '../UserPageLink/UserPageLink';

interface AbsencesTableProps {
    isPeriodLocked?: boolean;
    absences: Absence[];
    currentUserRole: Role;
    permissions: Permission[];
    onAbsenceClick: (absence: Absence) => void;
    className?: string;
}

const AbsencesTable: FC<AbsencesTableProps> = ({
    isPeriodLocked,
    absences,
    currentUserRole,
    permissions,
    onAbsenceClick,
    className = '',
}) => {
    const renderEditButton = (absence: Absence): ReactElement | null => {
        const resourceUserRoles = absence.user.roles.map(transformLegacyRoleToRole);
        const canEditAbsences = getPermissionToEdit(currentUserRole, resourceUserRoles, permissions, 'absences');

        if (!absence?.user?.roles || !canEditAbsences || isPeriodLocked) {
            return null;
        }

        return (
            <button type="button" onClick={() => onAbsenceClick(absence)} className="clear-button">
                <Icon color="light-blue" kind="edit" />
            </button>
        );
    };

    return (
        <TableContainer
            data={absences}
            attributes={[
                {
                    customAction: (absence: Absence) => <UserPageLink isPlain name={getUserFullName(absence.user)} id={getObjProperty(absence, 'user.id')} />,
                    label: translate('common.name'),
                },
                {
                    customAction: (absence: Absence) => `${getObjProperty(absence, 'user.phone')}`,
                    label: translate('common.phone'),
                },
                {
                    customAction: (absence: Absence) => absence.user && absence.user.roles && absence.user.roles[0] && translate(`common.${absence.user.roles[0].slug}`),
                    label: translate('common.role'),
                },
                {
                    customAction: (absence: Absence) => getAbsenceDateString(absence),
                    label: translate('pages.management.sickLeave'),
                },
                {
                    customAction: (absence: Absence) => getAbsenceDurationInDays(absence),
                    label: translate('common.duration'),
                },
                {
                    customAction: (absence: Absence) => getTotalAbsenceHours(absence, permissions, 'absences-table'),
                    label: translate('pages.absences.absenceHoursWithWaitingDayHours'),
                },
                {
                    customAction: (absence: Absence) => renderEditButton(absence),
                    className: 'td-edit',
                },
            ]}
            className={className}
        />
    );
};

export default AbsencesTable;
