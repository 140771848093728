import { ReactElement, useEffect } from 'react';

import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { checkPermission } from '../../../helpers';
import { clearResourceLogs, getResourceLogs } from '../../../redux/logs/logsActions';
import { Reducers } from '../../../redux/reducers';
import { LoadingSpinner } from '../../components';
import { UserDetail } from '../../containers';
import trans from '../../helpers/trans';
import { fetchEmploymentTypes } from '../../redux/employmentTypes/employmentTypesActions';
import { fetchExperiences } from '../../redux/experiences/experiencesActions';
import { fetchRoles } from '../../redux/roles/rolesActions';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';
import { setToast } from '../../redux/toasts/toastsReducer';
import {
    getLoketUser,
    getUserProfileV3,
    resendUserRegistration,
    resetUserPassword,
} from '../../redux/user/userActions';
import {
    setIsResendRegistrationSuccessful,
    setIsResetUserPasswordSuccessful,
    setLoketUser,
    setUserProfile,
} from '../../redux/user/userReducer';
import { ToastType } from '../../types';

const ConnectedUserDetail = (): ReactElement => {
    const dispatch = useTypedDispatch();
    const { userId } = useParams<{ userId: string }>();

    const { isLoading: isEmploymentTypesLoading, employmentTypes } = useTypedSelector(state => state.employmentTypesReducer);
    const { isLoading: isExperiencesLoading, experiences } = useTypedSelector(state => state.experiencesReducer);
    const { isLoading: isRolesLoading, roles } = useTypedSelector(state => state.rolesReducer);
    const { user: currentUser, permissions } = useTypedSelector(state => state.authenticatedUserReducer);
    const {
        isLoading: isUserLoading,
        isUserProfileLoading,
        isLoketUserLoading,
        isResendRegistrationSuccessful,
        isResetUserPasswordSuccessful,
        userProfile,
        loketUser,
    } = useTypedSelector(state => state.pacoUserReducer);
    const { logs, loading: isLogsLoading } = useSelector((state: Reducers) => state.logsReducer);

    const canViewLoketData = checkPermission(permissions, 'manage-loket-data', 'user-detail-basic-card');

    const isUserFormDataLoading = isEmploymentTypesLoading || isExperiencesLoading || isRolesLoading;
    const isLoading = isUserLoading || isUserProfileLoading;

    useEffect((): () => void => {
        if (userId) {
            dispatch(getUserProfileV3(userId));
        }

        return () => dispatch(setUserProfile(undefined));
    }, [userId]);

    useEffect((): () => void => {
        if (canViewLoketData && userProfile?.loketEmployeeId) {
            dispatch(getLoketUser(userProfile.loketEmployeeId));
        }

        return () => dispatch(setLoketUser(undefined));
    }, [userProfile?.loketEmployeeId]);

    useEffect((): void => {
        if (!employmentTypes.length) {
            dispatch(fetchEmploymentTypes());
        }

        if (!experiences.length) {
            dispatch(fetchExperiences());
        }

        if (!roles.length) {
            dispatch(fetchRoles());
        }
    }, []);

    useEffect((): void => {
        if (isResetUserPasswordSuccessful) {
            dispatch(setIsResetUserPasswordSuccessful(false));

            dispatch(setToast({
                text: trans('containers.forms.editUserForm.sendResetPasswordAndPinSuccess'),
                type: ToastType.pass,
            }));
        }
    }, [isResetUserPasswordSuccessful]);

    useEffect((): void => {
        if (isResendRegistrationSuccessful) {
            dispatch(setIsResendRegistrationSuccessful(false));

            dispatch(setToast({
                text: trans('containers.forms.editUserForm.resendRegistrationMailSuccess'),
                type: ToastType.pass,
            }));
        }
    }, [isResendRegistrationSuccessful]);

    const handleAfterEditUserSuccess = (): void => {
        if (userProfile) {
            dispatch(getUserProfileV3(userProfile.id));
        }
    };

    const handleResendRegistrationMailButtonClick = (): void => {
        if (userProfile) {
            dispatch(resendUserRegistration(userProfile.id));
        }
    };

    const handleSendResetPasswordAndPinButtonClick = (): void => {
        if (userProfile) {
            dispatch(resetUserPassword(userProfile.id));
        }
    };

    const handleHistoryButtonClick = (): void => {
        if (userProfile) {
            dispatch(getResourceLogs(userProfile.id, 'users'));
        }
    };

    const handleLogsModalClose = (): void => {
        dispatch(clearResourceLogs());
    };

    if (!userProfile || !currentUser) {
        return (
            <LoadingSpinner />
        );
    }

    return (
        <UserDetail
            isLoading={isLoading}
            isLogsLoading={isLogsLoading}
            isUserFormDataLoading={isUserFormDataLoading}
            isLoketUserLoading={isLoketUserLoading}
            currentUser={currentUser}
            employmentTypes={employmentTypes}
            experiences={experiences}
            logs={logs}
            permissions={permissions}
            roles={roles}
            user={userProfile}
            loketUser={loketUser}
            onAfterEditUserSuccess={handleAfterEditUserSuccess}
            onHistoryButtonClick={handleHistoryButtonClick}
            onLogsModalClose={handleLogsModalClose}
            onResendRegistrationMailButtonClick={handleResendRegistrationMailButtonClick}
            onSendResetPasswordAndPinButtonClick={handleSendResetPasswordAndPinButtonClick}
        />
    );
};

export default ConnectedUserDetail;
