import { PermissionSlug } from '../../types/permissionTypes';

export interface Feature {
    slug: string;
    permissions: string[];
}

export interface PermissionsStoragePage {
    pageUrl: string;
    requestParams?: string;
    features?: Feature[],
    permissions: string[],
}

export const transformToPageForStorage = (permissionSlug: PermissionSlug, featureSlug?: string): PermissionsStoragePage => {
    const pageUrl = document.location.pathname;
    const requestParams = document.location.search;

    const pageForStorage: PermissionsStoragePage = {
        pageUrl,
        permissions: [
            permissionSlug,
        ],
    };

    if (requestParams !== '') pageForStorage.requestParams = requestParams;

    if (featureSlug) {
        pageForStorage.features = [
            {
                slug: featureSlug,
                permissions: [
                    permissionSlug,
                ],
            },
        ];
    }

    return pageForStorage;
};

export const checkFeaturePermissions = (feature: Feature, existingFeature: Feature, permissionSlug: PermissionSlug): Feature => {
    if (feature.slug === existingFeature?.slug) {
        const newFeature = { ...feature };

        if (newFeature.permissions.some(permission => permission === permissionSlug)) {
            return feature;
        }

        newFeature.permissions = [
            ...feature.permissions,
            permissionSlug,
        ];

        return newFeature;
    }

    return feature;
};

export const trackedPermission = (storage: string | null, permissionSlug: PermissionSlug, featureSlug?: string): PermissionsStoragePage[] => {
    const newPageForStorage = transformToPageForStorage(permissionSlug, featureSlug);

    if (storage === null) {
        return [newPageForStorage];
    }

    const pagesInStorage = JSON.parse(storage) as PermissionsStoragePage[];

    const existingPage = pagesInStorage.find(page => (
        page.pageUrl === newPageForStorage.pageUrl
        && page.requestParams === newPageForStorage.requestParams
    ));

    if (!existingPage) {
        return [
            ...pagesInStorage,
            newPageForStorage,
        ];
    }

    if (existingPage && featureSlug) {
        const existingPageFeatures = existingPage?.features || [];
        const existingFeature = existingPageFeatures.find(feature => feature.slug === featureSlug);

        if (!existingFeature) {
            const newFeature: Feature = { slug: featureSlug, permissions: [permissionSlug] };

            existingPage.features = [...existingPageFeatures, newFeature];
        } else {
            existingPage.features = existingPageFeatures.map(feature => checkFeaturePermissions(
                feature,
                existingFeature,
                permissionSlug,
            ));
        }
    }

    return pagesInStorage;
};
