import React, { FC, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { LoadingSpinner } from '../../../../@paco/components';
import { useTypedSelector } from '../../../../@paco/redux/store';
import { PrivateRouteGroupRoutes } from '../../../../components/PrivateRouteGroup/PrivateRouteGroupRoutes';
import { ShiftConceptViewModelOld } from '../../../../entities/ShiftConcept/ShiftConcept';
import { checkPermission } from '../../../../helpers';
import { translate } from '../../../../helpers/translations/translator';
import { EditShiftFormData, EditShiftPlanningFormData, ShiftPlanningViewModel } from '../../../../models';
import {
    addTemporaryConceptWorkerToShiftConcept,
    deleteShiftConcept,
    deleteShiftConceptPlanning,
    editShiftConcept,
    editShiftConceptPlanning,
    fetchAvailabilityData,
    fetchEmployees,
    fetchShiftConcept,
    planUserToShiftConcept,
    removeTemporaryConceptWorkerFromShiftConcept,
    setShiftConceptPlanningColor,
    setTemporaryConceptWorkerColor,
} from '../../../../redux/@toolkit/shiftConcept/shiftConceptActions';
import { setIsDeleteSuccessful, setIsEditShiftPlanningSuccessful, setIsEditSuccessful } from '../../../../redux/@toolkit/shiftConcept/shiftConceptReducer';
import { Reducers } from '../../../../redux/reducers';
import ErrorPage from '../../../ErrorPage';
import ShiftDetail from '../../containers/ShiftDetail/ShiftDetail';
import { ScheduleRoutes } from '../../ScheduleRoutes';
import { mapEmployeesToShiftConcept } from './helpers';

type TypedDispatch = ThunkDispatch<Reducers, unknown, AnyAction>;

const ConnectedShiftConceptDetail: FC = () => {
    const navigate = useNavigate();
    const { id: shiftConceptId } = useParams<{ id: string }>();
    const dispatch = useDispatch<TypedDispatch>();

    const {
        isDeleteSuccessful,
        isEditSuccessful,
        isEditShiftPlanningSuccessful,
        shiftConcept,
        absences,
        employees,
        leaveOfAbsences,
        unavailableToWorkTimeSlots,
        preferToWorkTimeSlots,
        isLoading,
        isShiftPlanningLoading,
    } = useSelector((state: Reducers) => state.shiftConceptReducer);
    const { permissions } = useTypedSelector(state => state.authenticatedUserReducer);
    const { settings } = useTypedSelector(state => state.pacoSettingsReducer);

    const canDeleteShifts = checkPermission(permissions, 'delete-all-shift-concepts', 'connected-shift-concept-detail');
    const canAddShiftPlannings = checkPermission(permissions, 'add-new-shift-concept-plannings', 'connected-shift-concept-detail');
    const canEditShiftPlannings = checkPermission(permissions, 'edit-all-shift-concept-plannings', 'connected-shift-concept-detail');
    const canDeleteShiftPlannings = checkPermission(permissions, 'delete-all-shift-concept-plannings', 'connected-shift-concept-detail');

    const [shiftConceptWithMappedUsers, setShiftConceptWithMappedUsers] = useState<ShiftConceptViewModelOld | undefined>(undefined);

    useEffect(() => {
        if (shiftConcept) {
            setShiftConceptWithMappedUsers(mapEmployeesToShiftConcept(shiftConcept, employees));
        }
    }, [shiftConcept, employees]);

    const handleEmployeeSelect = (userId: string): void => {
        if (shiftConcept) {
            dispatch(planUserToShiftConcept(shiftConcept, userId));
        }
    };

    const handleShiftPlanningColorClick = (shiftPlanning: ShiftPlanningViewModel, color?: string): void => {
        if (shiftConcept) {
            dispatch(setShiftConceptPlanningColor(shiftConcept, shiftPlanning, color));
        }
    };

    const handleTemporaryConceptWorkerColorClick = (temporaryConceptWorkerId: string, color?: string): void => {
        if (shiftConcept) {
            dispatch(setTemporaryConceptWorkerColor(shiftConcept, temporaryConceptWorkerId, color));
        }
    };

    const handleDeleteShiftPlanningClick = (shiftPlanning: ShiftPlanningViewModel): void => {
        if (shiftConcept) {
            dispatch(deleteShiftConceptPlanning(shiftConcept, shiftPlanning.id));
        }
    };

    const handleSubmitEditShiftPlanning = (formData: EditShiftPlanningFormData, shiftPlanning: ShiftPlanningViewModel): void => {
        if (shiftConcept) {
            dispatch(editShiftConceptPlanning(shiftConcept, shiftPlanning.id, formData));
        }
    };

    const handleAddTemporaryWorkerClick = (name: string): void => {
        if (shiftConcept) {
            dispatch(addTemporaryConceptWorkerToShiftConcept(shiftConcept, name));
        }
    };

    const handleDeleteTemporaryWorkerClick = (temporaryConceptWorkerId: string): void => {
        if (shiftConcept) {
            dispatch(removeTemporaryConceptWorkerFromShiftConcept(shiftConcept, temporaryConceptWorkerId));
        }
    };

    const handleDeleteButtonClick = (): void => {
        if (shiftConcept) {
            dispatch(deleteShiftConcept(shiftConcept.id));
        }
    };

    const handleSave = (formData: EditShiftFormData): void => {
        if (shiftConcept) {
            dispatch(editShiftConcept(shiftConcept.id, formData));
        }
    };

    const handleCancel = (): void => {
        navigate(`${PrivateRouteGroupRoutes.shifts()}/${ScheduleRoutes.conceptShiftsCalendar()}`);
    };

    useEffect(() => {
        if (shiftConcept?.department && shiftConcept?.start) {
            const departmentId = shiftConcept.department.id;
            dispatch(fetchAvailabilityData(departmentId, shiftConcept.start, shiftConcept.end.date));
            dispatch(fetchEmployees(departmentId, shiftConcept.start));
        }
    }, [shiftConcept?.id]);

    useEffect(() => {
        if (isEditShiftPlanningSuccessful) {
            setIsEditShiftPlanningSuccessful(false);
        }

        if (shiftConcept) {
            setShiftConceptWithMappedUsers(mapEmployeesToShiftConcept(shiftConcept, employees));
        }
    }, [isEditShiftPlanningSuccessful]);

    useEffect(() => {
        if (shiftConceptId) {
            setShiftConceptWithMappedUsers(undefined);
            dispatch(fetchShiftConcept(shiftConceptId));
        }
    }, [shiftConceptId]);

    useEffect(() => {
        if (isDeleteSuccessful || isEditSuccessful) {
            navigate(`${PrivateRouteGroupRoutes.shifts()}/${ScheduleRoutes.conceptShiftsCalendar()}`);
            dispatch(setIsDeleteSuccessful(false));
            dispatch(setIsEditSuccessful(false));
        }
    }, [isDeleteSuccessful, isEditSuccessful]);

    if (!shiftConceptWithMappedUsers && isLoading) {
        return (
            <LoadingSpinner />
        );
    }

    if (!shiftConceptWithMappedUsers) {
        return (
            <ErrorPage errorCode={404} />
        );
    }

    return (
        <ShiftDetail
            canAddTemporaryWorkers
            isLoading={isLoading}
            isEmployeeLoading={isShiftPlanningLoading}
            showComments
            hasDarkBackground
            canDeleteShift={canDeleteShifts}
            canAddShiftPlannings={canAddShiftPlannings}
            canEditShiftPlannings={canEditShiftPlannings}
            canDeleteShiftPlannings={canDeleteShiftPlannings}
            title={translate('common.editEntity', { entity: translate('pages.shifts.conceptShift') })}
            saveButtonTitle={translate('pages.shifts.saveShiftConcept')}
            shift={shiftConceptWithMappedUsers}
            absences={absences}
            leaveOfAbsences={leaveOfAbsences}
            unavailableToWorkTimeSlots={unavailableToWorkTimeSlots}
            preferToWorkTimeSlots={preferToWorkTimeSlots}
            settings={settings}
            onEmployeeSelect={handleEmployeeSelect}
            onShiftPlanningColorClick={handleShiftPlanningColorClick}
            onDeleteShiftPlanningClick={handleDeleteShiftPlanningClick}
            onSubmitEditShiftPlanning={handleSubmitEditShiftPlanning}
            onDeleteButtonClick={handleDeleteButtonClick}
            onAddTemporaryWorkerClick={handleAddTemporaryWorkerClick}
            onTemporaryConceptWorkerColorClick={handleTemporaryConceptWorkerColorClick}
            onDeleteTemporaryWorkerClick={handleDeleteTemporaryWorkerClick}
            onSaveButtonClick={handleSave}
            onCancel={handleCancel}
        />
    );
};

export default ConnectedShiftConceptDetail;
