import trans from '../../@paco/helpers/trans';

export const PrivateRouteGroupRoutes = {
    shifts: () => `/${trans('routes.shifts.link')}`,
    tracks: () => `/${trans('routes.tracks.link')}`,
    management: () => `/${trans('routes.users.link')}`,
    settings: () => `/${trans('routes.settings.link')}`,
    absences: () => `/${trans('routes.absences.link')}`,
    pz: () => `/${trans('routes.pz.link')}`,
    loketSSO: () => '/loket-authenticate',
    dashboard: () => '/',
    logs: () => trans('routes.logs.link'),
};
