import trans from '../../@paco/helpers/trans';

export const ManagementRoutes = {
    user: (id: string) => `${trans('routes.users.users.link')}/${id}`,
    users: (page?: string) => `${trans('routes.users.users.link')}${page ? `?page=${page}` : ''}`,
};

export const UserDetailRoutes = {
    personalInfo: () => `${trans('routes.user.personalInfo.link')}`,
    absences: () => `${trans('routes.absences.link')}`,
    couplings: () => `${trans('routes.user.couplings.link')}`,
    documents: () => `${trans('routes.pz.documents.link')}`,
    calendar: () => `${trans('routes.user.calendar.link')}`,
    configurablePermissions: () => `${trans('routes.user.configurablePermissions.link')}`,
    unavailableToWorkTimeSlots: () => `${trans('routes.user.unavailableToWorkTimeSlots.link')}`,
    notes: () => `${trans('routes.user.notes.link')}`,
    feedback: () => `${trans('routes.pz.satisfaction.link')}`,
};
