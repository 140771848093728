import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';

import UserDetailPage from '../../@paco/pages/UserDetailPage/UserDetailPage';
import { getCommentCategories } from '../../redux/management-ts/managementActions';
import { ManagementRoutes } from './ManagementRoutes';
import Users from './Users';

import './Management.scss';

class Management extends Component {
    componentDidMount() {
        this.props.dispatch(getCommentCategories());
    }

    render() {
        return (
            <Routes>
                <Route path={`${ManagementRoutes.user(':userId')}`} element={<UserDetailPage />} />
                <Route path={`${ManagementRoutes.users()}`} element={<Users {...this.props} />} />
                <Route path="/" element={<Navigate to={ManagementRoutes.users()} />} />
            </Routes>
        );
    }
}

Management.propTypes = {
    dispatch: PropTypes.func.isRequired,
    filter: PropTypes.object.isRequired,
    users: PropTypes.array.isRequired,
    sorting: PropTypes.object.isRequired,
    currentUser: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    pagination: PropTypes.object.isRequired,
    employmentTypes: PropTypes.array.isRequired,
    payrollPeriods: PropTypes.array.isRequired,
    roles: PropTypes.array.isRequired,
    experiences: PropTypes.array.isRequired,
    permissions: PropTypes.array.isRequired,
    exportCSVRequest: PropTypes.bool.isRequired,
};

Management.defaultProps = {
    currentUser: {
        departments: null,
    },
};

function mapStateToProps(state) {
    return {
        loading: state.managementReducer.loading,
        filter: state.filterReducer.filter,
        currentUser: state.authenticatedUserReducer.user,
        users: state.managementReducer.users,
        sorting: state.managementReducer.sorting,
        page: state.usersOverviewReducer.page,
        pagination: state.paginationReducer,
        employmentTypes: state.appReducer.employmentTypes,
        roles: state.appReducer.roles,
        experiences: state.experiencesReducer.experiences,
        permissions: state.authenticatedUserReducer.permissions,
        payrollPeriods: state.appReducer.payrollPeriods,
        exportCSVRequest: state.managementReducer.exportCSVRequest,
    };
}

export default connect(mapStateToProps)(Management);
