import { FC, useEffect } from 'react';

import { useSelector } from 'react-redux';

import { Reducers } from '../../../redux/reducers';
import { CalendarTypeFormOption } from '../../compositions/CalendarNavRadioList/CalendarTypeRadioList';
import { CalendarNavigation } from '../../containers';
import { eachDayOfInterval } from '../../helpers/date';
import usePermissions from '../../helpers/hooks/usePermissions';
import { setIncrementedSelectedDays, setIncrementedSelectedMobileDay, setTimePeriodAndSelectedDays } from '../../redux/@interface/calendar/calendarActions';
import { setSelectedDays } from '../../redux/@interface/calendar/calendarReducer';
import { getPacoPayrollPeriods } from '../../redux/payrollPeriods/payrollPeriodsActions';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';
import { TimeModeType } from '../../types';

interface ConnectedCalendarNavigationProps {
    showConceptModeButtons?: boolean;
    showMobileControls?: boolean;
    timeModes: TimeModeType[];
    onCalendarTypeChange?: () => void;
    className?: string;
}

const ConnectedCalendarNavigation: FC<ConnectedCalendarNavigationProps> = ({
    showConceptModeButtons = false,
    showMobileControls = false,
    timeModes,
    onCalendarTypeChange,
    className = '',
}) => {
    const dispatch = useTypedDispatch();
    const permissions = usePermissions();
    const { isConceptMode } = useSelector((state: Reducers) => state.appReducer);

    const { payrollPeriods } = useTypedSelector(state => state.pacoPayrollPeriodsReducer);
    const {
        activePayrollPeriod,
        selectedDays,
        selectedMobileDay,
        timeMode,
    } = useTypedSelector(state => state.calendarReducer);
    const usableTimeModes = !payrollPeriods.length ? timeModes.filter(mode => mode !== TimeModeType.period) : timeModes;

    useEffect(() => {
        if (!payrollPeriods.length) {
            dispatch(getPacoPayrollPeriods());
        }
    }, []);

    const handleDateChange = (date: Date): void => {
        dispatch(setTimePeriodAndSelectedDays(timeMode, date, payrollPeriods));
    };

    const handleCustomDatesChange = (startDate: Date, endDate: Date): void => {
        const newSelectedDays = eachDayOfInterval({
            start: startDate,
            end: endDate,
        });
        dispatch(setSelectedDays(newSelectedDays));
    };

    const handleIncrementClick = (goForward: boolean, isMobile?: boolean): void => {
        if (isMobile) {
            dispatch(setIncrementedSelectedMobileDay(timeMode, selectedMobileDay, goForward, payrollPeriods));
        } else {
            dispatch(setIncrementedSelectedDays(timeMode, selectedDays, goForward, payrollPeriods));
        }
    };

    const handleTimeModeChange = (newTimeMode: TimeModeType): void => {
        dispatch(setTimePeriodAndSelectedDays(newTimeMode, selectedDays[0], payrollPeriods));
    };

    return (
        <CalendarNavigation
            showConceptModeButtons={showConceptModeButtons}
            showMobileControls={showMobileControls}
            activePayrollPeriod={activePayrollPeriod}
            permissions={permissions}
            selectedDays={selectedDays}
            selectedMobileDay={selectedMobileDay}
            selectedCalendarType={isConceptMode ? CalendarTypeFormOption.concepts : CalendarTypeFormOption.shifts}
            timeMode={timeMode}
            timeModeOptions={usableTimeModes}
            onCustomDatesChange={handleCustomDatesChange}
            onDateChange={handleDateChange}
            onIncrementClick={handleIncrementClick}
            onCalendarTypeChange={onCalendarTypeChange}
            onTimeModeChange={handleTimeModeChange}
            className={className}
        />
    );
};

export default ConnectedCalendarNavigation;
