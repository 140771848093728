import { FC, useEffect, useState } from 'react';

import { version } from '../../../../package.json';
import { ChangeLogEntry } from '../../entities/ChangeLog/ChangeLog';
import trans from '../../helpers/trans';
import { AppUpdatesWidgetItem } from './subcomponents';
import ChangeLogWidgetSkeleton from './subcomponents/ChangeLogWidgetSkeleton/ChangeLogWidgetSkeleton';

import './ChangeLogWidget.scss';

interface ChangeLogWidgetProps {
    hasUpdatedChangeLog: boolean,
    isLoading: boolean;
    changeLog: ChangeLogEntry[];
    className?: string;
}

const ChangeLogWidget: FC<ChangeLogWidgetProps> = ({
    hasUpdatedChangeLog,
    isLoading,
    changeLog,
    className = '',
}) => {
    const [activeItem, setActiveItem] = useState<string | undefined>();

    const handleItemButtonClick = (id: string) => {
        setActiveItem(activeItem === id ? undefined : id);
    };

    useEffect(() => {
        if (hasUpdatedChangeLog) {
            setActiveItem(changeLog[0]?.id);
        }
    }, [hasUpdatedChangeLog]);

    if (isLoading) {
        return (
            <ChangeLogWidgetSkeleton className={className} />
        );
    }

    return (
        <div className={`change-log-widget ${className}`}>
            <h2 className="change-log-widget__title">
                {trans('compositions.dashboardChangeLogWidget.title')}
            </h2>
            <div className="change-log-widget__container">
                <h3 className="change-log-widget__sub-title">
                    {trans('compositions.dashboardChangeLogWidget.currentAppVersion')}
                </h3>
                {`v${version}`}

                <div className="change-log-widget__list">
                    <h3 className="change-log-widget__sub-title">
                        {trans('compositions.dashboardChangeLogWidget.recentUpdates')}
                    </h3>
                    {changeLog.map(entry => (
                        <AppUpdatesWidgetItem
                            key={entry.id}
                            isOpen={activeItem === entry.id}
                            changeLogEntry={entry}
                            onButtonClick={handleItemButtonClick}
                            className="change-log-widget__list-item"
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ChangeLogWidget;
