import axios from 'axios';
import { debounce } from 'underscore';

import { TOAST_TYPE_FAIL, TOAST_TYPE_WARNING } from '../constants';
import { getObjProperty, getTranslationForBackendErrors } from '../helpers';
import { translate } from '../helpers/translations/translator';
import { setAppError, setAppToast } from '../redux/app/appActions';

// After upgrading to Symphony 4.3 the API wouldn't accept any calls with a slash
// at the end. This interceptor will fix that.
function removeSlashAtEndOfUrl(url) {
    if (url.charAt(url.length - 1) === '/') {
        return url.substr(0, url.length - 1);
    }

    return url;
}

function setMaintenance(dispatch) {
    dispatch(setAppToast(translate('pages.error.maintenance'), TOAST_TYPE_WARNING));
}

const throttledSetMaintenance = debounce(setMaintenance.bind(this), 500);

export default class AppInterceptor {
    constructor(store) {
        this.store = store;
    }

    setInterceptors = () => {
        axios.interceptors.request.use((config) => ({
            ...config,
            url: removeSlashAtEndOfUrl(config.url),
        }));

        axios.interceptors.response.use(
            response => response,
            (error) => {
                const { dispatch } = this.store;

                const status = error.response?.status;

                if (status === 500) {
                    dispatch(setAppError({
                        code: 500,
                        message: error.response.data.meta,
                    }));
                }

                if (status === 503) {
                    throttledSetMaintenance(dispatch);
                }

                if (status === 410) {
                    dispatch(setAppError({
                        code: 410,
                        message: error.response.data.meta,
                    }));
                }

                if (status === 422) {
                    const errors = getObjProperty(error, 'response.data.errors');

                    if (Array.isArray(errors)) {
                        errors.forEach((e) => {
                            const msg = e && e.detail;
                            console.log(msg);
                            dispatch(setAppToast(getTranslationForBackendErrors(msg), TOAST_TYPE_FAIL));
                        });
                    }
                }

                return Promise.reject(error);
            },
        );
    }
}
