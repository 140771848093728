import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { ConnectedHelpTooltip } from '../../@paco/connectors';
import { getSettingEnabled } from '../../@paco/entities/Setting/SettingHelpers';
import trans from '../../@paco/helpers/trans';
import ButtonAuthenticate from '../../components/ButtonAuthenticate/ButtonAuthenticate';
import NewFilters from '../../components/Filters/Filters';
import ItemsList from '../../components/ItemsList/ItemsList';
import SearchBar from '../../components/SearchBar/SearchBar';
import SidebarPage from '../../components/SidebarPage/SidebarPage';
import TrackCardsContainer from '../../components/TrackCardsContainer/TrackCardsContainer';
import { TRACK_TYPE_REVIEW } from '../../constants';
import { translate } from '../../helpers/translations/translator';
import { setEmployeeSearch, setFilter } from '../../redux/filter/filterActions';
import { approveTrack, getTracks } from '../../redux/tracks/tracksActions';
import { setStartAndEndDate } from '../../redux/weekNavigator/weekNavigatorActions';
import { transformPayrollPeriodToLegacyPayrollPeriod } from '../../services/PayrollPeriodService/transformPayrollPeriodLegacy';
import CalendarToolbar from '../Schedule/components/CalendarToolbar/CalendarToolbar';

class ReviewTracks extends Component {
    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(getTracks(TRACK_TYPE_REVIEW));
    }

    componentDidUpdate(prevProps) {
        const activeDepartmentOptions = this.props.departmentOptions.filter(option => option.isChecked);
        const prevActiveDepartmentOptions = prevProps.departmentOptions.filter(option => option.isChecked);

        if (activeDepartmentOptions.length !== prevActiveDepartmentOptions.length) {
            this.getTracks();
        }
    }

    dispatchSetFilters = (filter) => {
        const { dispatch } = this.props;
        dispatch(setFilter(filter, this.getTracks));
    }

    approveTrack = (id) => {
        const { dispatch } = this.props;
        dispatch(approveTrack(id));
    }

    setStartAndEndDate = (selectedDate, startDate, endDate) => {
        const { dispatch } = this.props;
        dispatch(setStartAndEndDate(selectedDate, startDate, endDate));
        dispatch(getTracks(TRACK_TYPE_REVIEW));
    }

    afterDateChange = () => {
        const { dispatch } = this.props;
        dispatch(getTracks(TRACK_TYPE_REVIEW));
    };

    getTracks = () => {
        const { dispatch } = this.props;
        dispatch(getTracks(TRACK_TYPE_REVIEW));
    }

    onSearchBarChange = (value) => {
        const { dispatch } = this.props;
        dispatch(setEmployeeSearch(value));
        this.getTracks();
    }

    renderTracks() {
        const {
            loading,
            filter,
            pagination,
            permissions,
            settings,
            timeMode,
            currentUser,
            currentUserRole,
            tracks,
            payrollPeriods,
            onReviewTrackClick,
        } = this.props;
        const { employeeSearch } = filter;
        const isTimerEnableFeedback = getSettingEnabled('TIMER_ENABLE_FEEDBACK', settings);

        return (
            <>
                <div className="tracks-overview-top">
                    <CalendarToolbar
                        timeMode={timeMode}
                        payrollPeriods={payrollPeriods.map(transformPayrollPeriodToLegacyPayrollPeriod)}
                        setStartAndEndDate={this.setStartAndEndDate}
                        afterDateChange={this.afterDateChange}
                    />
                    <ConnectedHelpTooltip
                        index={2}
                        route="review-tracks"
                        subTitle={trans('help.tracks.reviewTracks.title')}
                        text={trans('help.tracks.reviewTracks.text')}
                        title={trans('help.tracks.title')}
                        className="tracks__review-tracks-help-tooltip"
                    />
                    <div className="tracks-overview-top-right">
                        <SearchBar placeholder={translate('common.searchEmployee')} startVal={employeeSearch} onChange={this.onSearchBarChange} />
                        <ConnectedHelpTooltip
                            index={0}
                            route="review-tracks"
                            subTitle={trans('help.tracks.viewOptions.title')}
                            text={trans('help.tracks.viewOptions.text')}
                            title={trans('help.tracks.title')}
                            showMobileInfoWarning
                            className="tracks__view-options-help-tooltip"
                        />
                    </div>
                </div>
                <ItemsList
                    loading={loading}
                    pagination={pagination}
                    itemsLength={tracks.length}
                    onPagination={this.getTracks}
                    emptyMessage={translate('pages.tracks.emptyList')}
                >
                    <TrackCardsContainer
                        isTimerEnableFeedback={isTimerEnableFeedback}
                        tracks={tracks}
                        onReviewTrackClick={onReviewTrackClick}
                        onApproveTrackClick={this.approveTrack}
                        permissions={permissions}
                        currentUserId={currentUser?.id}
                        currentUserRole={currentUserRole}
                        settings={settings}
                        payrollPeriods={payrollPeriods}
                        className="trackcards-container"
                    />
                </ItemsList>
            </>
        );
    }

    render() {
        const {
            filter,
            canAddTracks,
            employmentTypes,
            onAddTrackClick,
        } = this.props;

        return (
            <SidebarPage
                sidebarContent={(
                    <NewFilters
                        filter={filter}
                        employmentTypes={employmentTypes}
                        button={canAddTracks ? (
                            <ButtonAuthenticate onClick={onAddTrackClick} text={translate('pages.tracks.addTrack')} />
                        ) : (null)}
                        filterBlocks={[
                            'contract-types',
                        ]}
                        onChange={this.dispatchSetFilters}
                    />
                )}
            >
                <div className="tracks-list tracks-review">
                    {this.renderTracks()}
                </div>
                <ConnectedHelpTooltip
                    index={1}
                    route="review-tracks"
                    subTitle={trans('help.tracks.filters.title')}
                    text={trans('help.tracks.filters.text')}
                    title={trans('help.tracks.title')}
                    showMobileInfoWarning
                    className="tracks__filters-help-tooltip"
                />
            </SidebarPage>
        );
    }
}

ReviewTracks.propTypes = {
    loading: PropTypes.bool.isRequired,
    tracks: PropTypes.array.isRequired,
    dispatch: PropTypes.func.isRequired,
    filter: PropTypes.object.isRequired,
    timeMode: PropTypes.string.isRequired,
    pagination: PropTypes.object.isRequired,
    onAddTrackClick: PropTypes.func.isRequired,
    onReviewTrackClick: PropTypes.func.isRequired,
    permissions: PropTypes.array.isRequired,
    payrollPeriods: PropTypes.array.isRequired,
    currentUser: PropTypes.object.isRequired,
    employmentTypes: PropTypes.array.isRequired,
    canAddTracks: PropTypes.bool.isRequired,
};

export default connect(null)(ReviewTracks);
