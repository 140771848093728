import React, { Component } from 'react';

import PropTypes from 'prop-types';
import Select from 'react-select';
import {
    Button,
    Col,
    CustomInput,
    FormGroup,
    Label,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
} from 'reactstrap';

import { InputLabel } from '../../../@paco/components';
import { Textarea } from '../../../@paco/compositions';
import { doesPeriodOverlapWithLockedPayrollPeriod } from '../../../@paco/entities/PayrollPeriod/PayrollPeriodHelpers';
import { transformToPeriod } from '../../../@paco/entities/Period/PeriodTransformers';
import {
    addHours,
    formatDate,
    setHours,
    setMinutes,
    setSeconds,
} from '../../../@paco/helpers/date';
import { DateFormat } from '../../../@paco/types/dateFormatTypes';
import FormDatePicker from '../../../components/FormDatePicker/FormDatePicker';
import { RadioButtonsList } from '../../../components/RadioButtonsList/RadioButtonsList';
import { BREAK_TIMES, MEALS } from '../../../constants';
import { generateGroupedDepartmentsSelectList, getUserFullName } from '../../../helpers';
import { isValidDate } from '../../../helpers/date/isValidDate';
import { translate } from '../../../helpers/translations/translator';
import { TrackType } from '../../../models';
import getAddTrackError from './helpers/getAddTrackError';
import getUsersForAddTrackForm from './helpers/getUsersForAddTrackForm';

import './AddTrack.scss';


const CHECK_IN = setMinutes(new Date(), 0);
const CHECK_OUT = addHours(CHECK_IN, 6);

class AddTrack extends Component {
    state = {
        activeTrackType: TrackType.finished,
        checkInDate: CHECK_IN,
        checkOutDate: CHECK_OUT,
        checkInTime: formatDate(CHECK_IN, DateFormat.hoursMinutes),
        checkOutTime: formatDate(CHECK_OUT, DateFormat.hoursMinutes),
        comment: '',
        error: null,
        canSubmit: false,
        departmentId: null,
        userId: null,
        userDepartments: [],
        pause: 30,
        meal: MEALS[0].value,
    };

    static getDerivedStateFromProps(props, state) {
        const { payrollPeriods, users } = props;
        const {
            activeTrackType,
            departmentId,
            userId,
            checkInDate,
            checkOutDate,
            checkInTime,
            checkOutTime,
        } = state;

        const checkIn = setMinutes(setHours(checkInDate, checkInTime.split(':')[0]), checkInTime.split(':')[1]);
        const checkOut = activeTrackType === TrackType.finished
            ? setMinutes(setHours(checkOutDate, checkOutTime.split(':')[0]), checkOutTime.split(':')[1]) : undefined;
        const period = isValidDate(checkIn) && isValidDate(checkOut) ? transformToPeriod(checkIn, checkOut, !!checkOut) : undefined;

        const payrollPeriodError = period ? doesPeriodOverlapWithLockedPayrollPeriod(period, payrollPeriods) : false;

        const selectedUser = userId ? users.find(user => user.id === userId) : undefined;

        const error = getAddTrackError(
            new Date(checkIn),
            checkOut ? new Date(checkOut) : null,
            activeTrackType,
            payrollPeriodError,
        );

        const canSubmit = activeTrackType === TrackType.finished ? (
            !!departmentId
            && !!userId
            && !error
            && checkInTime
            && checkOutTime
        ) : (
            !!departmentId
            && !!userId
            && checkInTime
            && !error
        );

        return {
            ...state,
            error,
            canSubmit,
            userDepartments: selectedUser?.departments || [],
        };
    }

    componentDidUpdate(prevProps, prevState) {
        const { userId, checkInDate } = this.state;
        const { onEmployeeSelect } = this.props;

        if (prevState.userId !== userId || prevState.checkInDate !== checkInDate) {
            onEmployeeSelect(userId, checkInDate);
        }
    }

    onEmployeeSelect = (e) => {
        this.setState({
            userId: e.value,
        });
    }

    onCheckInSelect(val) {
        this.setState({
            checkInDate: new Date(val),
        });
    }

    onCheckOutSelect(val) {
        this.setState({
            checkOutDate: new Date(val),
        });
    }

    getUsers = () => {
        const { permissions, users, currentUserId } = this.props;
        return getUsersForAddTrackForm(permissions, users, currentUserId);
    }

    setStateProp = (value, prop) => {
        this.setState({
            [prop]: value,
        });
    };

    onRadioButtonClick = (val) => {
        this.changeTab(val ? TrackType.open : TrackType.finished);
    }

    changeTab = (trackType) => {
        this.setState({
            activeTrackType: trackType,
        });
    }

    handleCommentChange = (value) => {
        this.setStateProp(value, 'comment');
    }

    onSubmit = (e) => {
        e.preventDefault();
        const { onSubmit } = this.props;
        const {
            departmentId,
            comment,
            userId,
            pause,
            meal,
            checkInDate,
            checkOutDate,
            checkInTime,
            checkOutTime,
            activeTrackType,
        } = this.state;
        const checkInHours = checkInTime.split(':')[0];
        const checkInMinutes = checkInTime.split(':')[1];
        const checkIn = setSeconds(setMinutes(setHours(checkInDate, checkInHours), checkInMinutes), 0);
        const checkOutHours = checkOutTime.split(':')[0];
        const checkOutMinutes = checkOutTime.split(':')[1];
        const checkOut = activeTrackType === TrackType.finished ? (
            setSeconds(setMinutes(setHours(checkOutDate, checkOutHours), checkOutMinutes), 0)
        ) : undefined;

        onSubmit({
            departmentId,
            comment,
            userId,
            pause: activeTrackType === TrackType.finished ? pause : undefined,
            meal: activeTrackType === TrackType.finished ? meal : undefined,
            checkIn,
            checkOut,
        });
    }

    render() {
        const { onCancel, userTracksOnDate, departments } = this.props;
        const {
            canSubmit,
            checkInDate,
            checkOutDate,
            comment,
            error,
            activeTrackType,
            userDepartments,
            userId,
        } = this.state;
        const users = this.getUsers();
        const userNames = users.map(user => ({
            label: getUserFullName(user),
            value: user.id,
        }));

        const groupedDepartments = generateGroupedDepartmentsSelectList(userDepartments.length ? userDepartments : departments);

        const breaks = BREAK_TIMES.map(time => (
            <option key={time} value={time}>
                {`${time} ${translate('common.minutes')}`}
            </option>
        ));

        const meals = MEALS.map(meal => (
            <option key={meal.value} value={meal.value}>
                {meal.label}
            </option>
        ));

        return (
            <form onSubmit={this.onSubmit}>
                <ModalHeader>{translate('pages.tracks.addTrack')}</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col>
                            <FormGroup>
                                <InputLabel
                                    required
                                    text={translate('common.employee')}
                                />
                                <Select
                                    placeholder={translate('common.searchEmployee')}
                                    id="employee-search"
                                    noOptionsMessage={() => translate('common.noEmployeeFound')}
                                    options={userNames}
                                    onChange={e => this.onEmployeeSelect(e)}
                                />
                                {(userTracksOnDate.length && userId) ? <p className="warning-feedback active">{translate('pages.tracks.userHasTracksOnDate')}</p> : null}
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <InputLabel
                                    required
                                    text={translate('common.department')}
                                />
                                <Select
                                    placeholder={translate('common.searchDepartment')}
                                    id="departments-search"
                                    noOptionsMessage={() => translate('common.noDepartmentFound')}
                                    options={groupedDepartments}
                                    onChange={e => this.setStateProp(e.value, 'departmentId')}
                                    maxMenuHeight={225}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <RadioButtonsList
                                buttons={[
                                    { label: translate('pages.tracks.finishedTrack') },
                                    { label: translate('pages.tracks.runningTrack') },
                                ]}
                                checked={activeTrackType === TrackType.finished ? 0 : 1}
                                onRadioChange={this.onRadioButtonClick}
                                className="form-add-track__track-type-radio-buttons"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <InputLabel
                                    required
                                    text={translate('pages.tracks.startDate')}
                                />
                                <FormDatePicker
                                    invalid={(TrackType.open && !!error)}
                                    feedback={error}
                                    selected={formatDate(checkInDate, DateFormat.inputDate)}
                                    onChange={val => this.onCheckInSelect(val)}
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup className="mb-0">
                                <InputLabel
                                    required
                                    text={translate('pages.tracks.startTime')}
                                />
                                <CustomInput
                                    className="form-control required"
                                    type="time"
                                    name="start-time"
                                    id="start-time"
                                    invalid={(TrackType.open && !!error)}
                                    defaultValue={formatDate(checkInDate, DateFormat.hoursMinutes)}
                                    onChange={e => this.setStateProp(e.target.value, 'checkInTime')}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    {activeTrackType === TrackType.finished && (
                        <>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <InputLabel
                                            required
                                            text={translate('pages.tracks.endDate')}
                                        />
                                        <FormDatePicker
                                            selected={formatDate(checkOutDate, DateFormat.inputDate)}
                                            onChange={val => this.onCheckOutSelect(val, 'checkOutDate')}
                                            invalid={!!error}
                                            feedback={error}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup className="mb-0">
                                        <InputLabel
                                            required
                                            text={translate('pages.tracks.endTime')}
                                        />
                                        <CustomInput
                                            className="form-control required"
                                            type="time"
                                            name="end-time"
                                            id="end-time"
                                            invalid={!!error}
                                            defaultValue={formatDate(checkOutDate, DateFormat.hoursMinutes)}
                                            onChange={e => this.setStateProp(e.target.value, 'checkOutTime')}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroup className="mb-0">
                                        <InputLabel
                                            required
                                            text={translate('pages.tracks.pauseTime')}
                                        />
                                        <CustomInput
                                            type="select"
                                            name="break-time"
                                            id="break-time"
                                            defaultValue="30"
                                            onChange={e => this.setStateProp(e.target.value, 'pause')}
                                        >
                                            {breaks}
                                        </CustomInput>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup className="mb-0">
                                        <Label>{translate('common.meal')}</Label>
                                        <CustomInput
                                            type="select"
                                            name="meal"
                                            id="meal"
                                            onChange={e => this.setStateProp(e.target.value, 'meal')}
                                        >
                                            {meals}
                                        </CustomInput>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </>
                    )}

                    <Textarea
                        label={translate('common.comment')}
                        rows={4}
                        value={comment}
                        onChange={this.handleCommentChange}
                        className="form-add-track__comment"
                    />
                </ModalBody>
                <ModalFooter>
                    <Button type="button" color="link" id="modal-close" onClick={onCancel}>{translate('common.cancel')}</Button>
                    <Button type="submit" color="orange" disabled={!canSubmit}>{translate('common.add')}</Button>
                </ModalFooter>
            </form>
        );
    }
}

AddTrack.propTypes = {
    users: PropTypes.array.isRequired,
    departments: PropTypes.array,
    permissions: PropTypes.array.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onEmployeeSelect: PropTypes.func.isRequired,
    userTracksOnDate: PropTypes.array,
    // eslint-disable-next-line react/no-unused-prop-types
    payrollPeriods: PropTypes.array.isRequired,
    currentUserId: PropTypes.string,
};

AddTrack.defaultProps = {
    departments: [],
    userTracksOnDate: [],
    currentUserId: '',
};

export default AddTrack;
