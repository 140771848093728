import React, {
    FC,
    useEffect,
    useMemo,
    useState,
} from 'react';

import {
    Button,
    Col,
    CustomInput,
    FormGroup,
    Input,
    Label,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
} from 'reactstrap';

import { Permission } from '../../../../@paco/entities/Permission/Permission';
import FormDatePicker from '../../../../components/FormDatePicker/FormDatePicker';
import { checkPermission, generateWeekdayOptions } from '../../../../helpers';
import { compareAsc, formatDate } from '../../../../helpers/date';
import { translate } from '../../../../helpers/translations/translator';
import { UnavailableToWorkTimeSlot } from '../../../../models';

interface Props {
    data: UnavailableToWorkTimeSlot | null;
    permissions: Permission[];
    onCancel: () => void;
    onSubmit: (weekday: number, fromDate: string, toDate: string, comment: string) => void;
}

const dateFormat = 'yyyy-MM-dd';
const timeFormat = 'HH:mm';

const AddOrEditAvailabilityTime: FC<Props> = ({
    data,
    permissions = [],
    onCancel,
    onSubmit,
}) => {
    const [weekday, setWeekday] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [comment, setComment] = useState('');
    const [dateError, setDateError] = useState(false);
    const title = `${translate('pages.management.unavailabilityTime')} 
        ${(data ? translate('common.edit') : translate('common.add')).toLowerCase()}`;
    const canSubmit = startDate
        && endDate
        && startTime.length
        && endTime.length
        && comment.length
        && !dateError;
    const canEditUnavailableToWorkTimeSlot = useMemo(() => checkPermission(permissions, 'edit-unavailable-to-work-time-slots', 'add-or-edit-availability-time'), [permissions]);

    useEffect(() => {
        if (data) {
            setWeekday(`${data.weekday}`);
            setStartDate(formatDate(data.start, dateFormat));
            setEndDate(formatDate(data.end, dateFormat));
            setStartTime(formatDate(data.start, timeFormat));
            setEndTime(formatDate(data.end, timeFormat));
            setComment(data.comment);
        } else {
            setWeekday('1');
            setStartDate(formatDate(new Date(), dateFormat));
            setEndDate(formatDate(new Date(), dateFormat));
            setStartTime('09:00');
            setEndTime('18:00');
            setComment('');
        }
    }, [data]);

    useEffect(() => {
        setDateError(compareAsc(`${startDate} ${startTime}`, `${endDate} ${endTime}`) === 1);
    }, [startDate, startTime, endDate, endTime]);

    const onFormSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault();
        const fromDate = `${formatDate(startDate, 'yyyy-MM-dd')} ${startTime}:00`;
        const toDate = `${formatDate(endDate, 'yyyy-MM-dd')} ${endTime}:00`;
        onSubmit(parseInt(weekday, 10), fromDate, toDate, comment);
    };

    const onChangeWeekday = (e: React.ChangeEvent<HTMLInputElement>) => {
        setWeekday(e.target.value);
    };

    const onChangeStartDate = (date: Date) => {
        setStartDate(formatDate(date, dateFormat));
    };

    const onChangeEndDate = (date: Date) => {
        setEndDate(formatDate(date, dateFormat));
    };

    const onChangeStartTime = (e: React.ChangeEvent<HTMLInputElement>) => {
        setStartTime(e.target.value);
    };

    const onChangeEndTime = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEndTime(e.target.value);
    };

    const onChangeComment = (e: React.ChangeEvent<HTMLInputElement>) => {
        setComment(e.target.value);
    };

    return (
        <form onSubmit={onFormSubmit}>
            <ModalHeader>{title}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label>{translate('common.weekDay')}</Label>
                            <CustomInput
                                type="select"
                                id="weekday"
                                name="weekday"
                                value={weekday}
                                onChange={onChangeWeekday}
                            >
                                {generateWeekdayOptions()}
                            </CustomInput>
                        </FormGroup>
                    </Col>
                    <Col />
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label>{translate('pages.tracks.startTime')}</Label>
                            <Input
                                type="time"
                                className="form-control"
                                id="startTime"
                                name="startTime"
                                autoComplete="off"
                                value={startTime}
                                onChange={onChangeStartTime}
                            />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label>{translate('pages.tracks.endTime')}</Label>
                            <Input
                                type="time"
                                className="form-control"
                                id="endTime"
                                name="endTime"
                                autoComplete="off"
                                value={endTime}
                                onChange={onChangeEndTime}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label>{translate('common.startDate')}</Label>
                            <FormDatePicker
                                id="startDate"
                                selected={startDate}
                                onChange={onChangeStartDate}
                            />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label>{translate('common.endDate')}</Label>
                            <FormDatePicker
                                id="endDate"
                                selected={endDate}
                                onChange={onChangeEndDate}
                                invalid={dateError}
                                feedback={translate('pages.management.endDateNeedsToBeLaterThanStartDate')}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label>{translate('common.description')}</Label>
                            <Input
                                type="textarea"
                                rows={3}
                                className="form-control form-absence-comment"
                                id="comment"
                                name="comment"
                                placeholder={translate('pages.management.availabilityDescription')}
                                value={comment}
                                onChange={onChangeComment}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button
                    type="button"
                    color="link"
                    id="modal-close"
                    onClick={onCancel}
                >
                    {translate('common.cancel')}
                </Button>
                <Button
                    type="submit"
                    color="orange"
                    disabled={!canSubmit || !canEditUnavailableToWorkTimeSlot}
                >
                    {translate('common.save')}
                </Button>
            </ModalFooter>
        </form>
    );
};

export default AddOrEditAvailabilityTime;
