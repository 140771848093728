import React from 'react';

import PropTypes from 'prop-types';

import NoTracksIcon from '../../images/no_tracks.svg';

import './EmptyList.scss';

const EmptyList = ({ text, className }) => (
    <div className={`items-list-empty ${className}`}>
        <img alt="empty-icon" src={NoTracksIcon} />
        <p>{text}</p>
    </div>
);

EmptyList.propTypes = {
    text: PropTypes.string.isRequired,
    className: PropTypes.string,
};

EmptyList.defaultProps = {
    className: '',
};

export default EmptyList;
