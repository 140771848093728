import { FC, useEffect } from 'react';

import { useEffectOnce } from 'react-use';

import { ShiftsWidget } from '../../compositions';
import { Department } from '../../entities/Department/Department';
import { transformToPeriod } from '../../entities/Period/PeriodTransformers';
import { addHours, getEndOfWorkDay, getStartOfWorkDay } from '../../helpers/date';
import useCheckPermission from '../../helpers/hooks/useCheckPermission';
import { fetchDepartmentOptions } from '../../redux/@interface/options/optionsActions';
import { fetchDashboardAbsences, fetchDashboardShifts, fetchDashboardTracks } from '../../redux/dashboard/dashboardActions';
import { setSelectedDepartments } from '../../redux/dashboard/dashboardReducer';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';

interface ConnectedShiftsWidgetProps {
    className?: string;
}

const ConnectedShiftsWidget: FC<ConnectedShiftsWidgetProps> = ({ className }) => {
    const dispatch = useTypedDispatch();

    const canViewTracks = useCheckPermission('view-all-tracks', 'connected-shifts-widget');
    const canViewAbsences = useCheckPermission('view-all-absences', 'connected-shifts-widget');

    const {
        absences,
        shifts,
        tracks,
        selectedDepartments,
        isAbsencesLoading,
        isShiftsLoading,
        isTracksLoading,
    } = useTypedSelector(state => state.dashboardSettingsReducer);
    const { departmentOptions, isLoading: isDepartmentsLoading } = useTypedSelector(state => state.optionsReducer);

    useEffect((): void => {
        const start = getStartOfWorkDay(new Date());
        const end = getEndOfWorkDay(new Date());
        const period = transformToPeriod(start, end);

        const departmentUuids = selectedDepartments.map(department => department.id);

        dispatch(fetchDashboardShifts(start, end, departmentUuids));

        // Earliest shifts start at 04:00, so we add an extra threshold so we also get the tracks of employees who clock in early.
        const tracksStart = addHours(start, -1);

        if (canViewTracks) dispatch(fetchDashboardTracks(tracksStart, end));
        if (canViewAbsences) dispatch(fetchDashboardAbsences(period));
    }, [selectedDepartments, canViewTracks, canViewAbsences]);

    useEffectOnce((): void => {
        if (departmentOptions.length === 0) {
            dispatch(fetchDepartmentOptions());
        }
    });

    const handleDepartmentChange = (departments: Department[]): void => {
        dispatch(setSelectedDepartments(departments));
    };

    return (
        <ShiftsWidget
            isLoading={isShiftsLoading}
            isTracksLoading={isTracksLoading || isAbsencesLoading}
            isDepartmentsLoading={isDepartmentsLoading}
            absences={absences}
            shifts={shifts}
            tracks={tracks}
            selectedDepartments={selectedDepartments}
            departmentOptions={departmentOptions}
            onDepartmentChange={handleDepartmentChange}
            className={className}
        />
    );
};

export default ConnectedShiftsWidget;
