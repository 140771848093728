import React from 'react';

import PropTypes from 'prop-types';

import { formatDate, isSameDay } from '../../../../helpers/date';
import { translate } from '../../../../helpers/translations/translator';

function getSameDay(day) {
    return isSameDay(new Date(day), new Date());
}

const DayContent = ({
    day, children, showHeader,
}) => {
    const sameDay = getSameDay(day);

    return (
        <div className={`day-content day-content ${sameDay && 'day-content--today'}`}>
            {showHeader && (
                <div className={`d-flex day-content-header day-content-header-absence pr-1 ${sameDay && 'day-content-header--today'}`}>
                    <div className="day-content-header-label">
                        { sameDay && translate('common.today') }
                    </div>
                    <div className={`day-content-header-day ${sameDay && 'day-content-header-day--today'}`}>
                        {formatDate(new Date(day), 'd')}
                    </div>
                </div>
            )}
            {children}
        </div>
    );
};

DayContent.propTypes = {
    day: PropTypes.any.isRequired,
    children: PropTypes.any.isRequired,
    showHeader: PropTypes.bool,
};

DayContent.defaultProps = {
    showHeader: true,
};

export default DayContent;
