import { FC, ReactElement, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { PrivateRouteGroupRoutes } from '../../../../components/PrivateRouteGroup/PrivateRouteGroupRoutes';
import { ScheduleRoutes } from '../../../../pages/Schedule/ScheduleRoutes';
import { ConfirmForm } from '../../../containers';
import { ShiftIndex } from '../../../entities/Shift/Shift';
import trans from '../../../helpers/trans';
import { deleteShift } from '../../../redux/shift/shiftActions';
import { setIsDeleteShiftSuccessful } from '../../../redux/shift/shiftReducer';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';
import { setToast } from '../../../redux/toasts/toastsReducer';
import { ToastType } from '../../../types';

interface ConnectedDeleteShiftPlanningFormProps {
    shift: ShiftIndex;
    onClose: () => void;
    className?: string;
}

const ConnectedDeleteShiftPlanningForm: FC<ConnectedDeleteShiftPlanningFormProps> = ({ shift, onClose, className = '' }): ReactElement => {
    const dispatch = useTypedDispatch();
    const navigate = useNavigate();

    const { isLoading, isDeleteShiftSuccessful } = useTypedSelector(state => state.shiftReducer);

    const handleSubmit = (): void => {
        dispatch(deleteShift(shift.id));
    };

    useEffect((): void => {
        if (isDeleteShiftSuccessful) {
            dispatch(setToast({
                type: ToastType.pass,
                shouldFadeOutAutomatically: true,
                text: trans('containers.forms.deleteShiftForm.deleteShiftSuccess'),
            }));
            dispatch(setIsDeleteShiftSuccessful(false));

            navigate(`${PrivateRouteGroupRoutes.shifts()}/${ScheduleRoutes.calendar()}`);
        }
    }, [isDeleteShiftSuccessful]);

    return (
        <ConfirmForm
            isLoading={isLoading}
            intro={trans('containers.forms.deleteShiftForm.intro')}
            submitText={trans('common.delete')}
            onCancel={onClose}
            onSubmit={handleSubmit}
            className={className}
        />
    );
};

export default ConnectedDeleteShiftPlanningForm;
