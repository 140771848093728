import { useEffect } from 'react';

import useKeyPress from '../../../helpers/hooks/useKeypress';
import { generateDownloadURI } from '../../../helpers/tools/browser';
import initPermissionLoggingConsole from '../permissions/initPermissionLoggingConsole';

const usePermissionLogging = (): void => {
    const consoleTextStyle = `
        padding: .25rem;
        font-size: 1.125rem;
        font-weight: bold;
        color: #ff6800;
        background-color: #1f1f1d;
    `;

    const shiftPress = useKeyPress(16);
    const minusPress = useKeyPress(189);
    const keyAPress = useKeyPress(65);
    const trackPermissionsActive = localStorage.getItem('trackPermissionsHotkey') === 'trackPermissionsIsActive';

    useEffect((): void => {
        if (!shiftPress || !minusPress || !keyAPress) {
            return;
        }

        if (trackPermissionsActive) {
            const localStorageKey = 'trackPermissions';
            const uri = `data:application/json;charset=utf-8;header=present,${localStorage.getItem(localStorageKey)}`;

            generateDownloadURI(uri, localStorageKey);

            localStorage.removeItem('trackPermissionsHotkey');
            localStorage.removeItem(localStorageKey);

            console.log('%cPermission logging stopped!', consoleTextStyle);

            return;
        }

        initPermissionLoggingConsole(consoleTextStyle);
        localStorage.setItem('trackPermissionsHotkey', 'trackPermissionsIsActive');
    }, [shiftPress, minusPress, keyAPress, trackPermissionsActive]);
};

export default usePermissionLogging;
