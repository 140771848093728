import React, { FC, useMemo, useState } from 'react';

import classnames from 'classnames';
import { Modal } from 'reactstrap';
import { useElementSize } from 'usehooks-ts';

import { LoadingSpinner } from '../../../../@paco/components';
import CalendarHeader from '../../../../@paco/components/CalendarHeader/CalendarHeader';
import { CalendarTypeFormOption } from '../../../../@paco/compositions/CalendarNavRadioList/CalendarTypeRadioList';
import useWindowSize from '../../../../@paco/helpers/hooks/useWindowSize';
import { TimeModeType } from '../../../../@paco/types';
import ButtonAuthenticate from '../../../../components/ButtonAuthenticate/ButtonAuthenticate';
import Filters from '../../../../components/Filters/Filters';
import DeleteResourceForm from '../../../../components/forms/DeleteResourceForm/DeleteResourceForm';
import SidebarPage from '../../../../components/SidebarPage/SidebarPage';
import { BaseScheduleIndexViewModel } from '../../../../entities/BaseSchedule/BaseSchedule';
import { BaseScheduleShiftViewModel } from '../../../../entities/BaseScheduleShift/BaseScheduleShift';
import { DepartmentViewModel } from '../../../../entities/Departments/Departments';
import { PublishBaseScheduleFormData, ShiftConceptViewModelOld } from '../../../../entities/ShiftConcept/ShiftConcept';
import { UnavailableToWorkTimeSlotViewModel } from '../../../../entities/UnavailableToWorkTimeSlot/UnavailableToWorkTimeSlot';
import { addDays, addWeeks } from '../../../../helpers/date';
import { translate } from '../../../../helpers/translations/translator';
import {
    AbsenceViewModel,
    AddShiftFormData,
    LeaveOfAbsenceViewModel,
    PayrollPeriodViewModel,
    Role,
    ShiftDraggable,
    ShiftType,
    ShiftViewModel,
    SpecialDayViewModel,
} from '../../../../models';
import { AppFilter } from '../../../../redux/filter-ts/filterModels';
import ButtonSchedule from '../../components/ButtonSchedule/ButtonSchedule';
import CalendarAndConceptsToolbar from '../../components/CalendarAndConceptsToolbar/CalendarAndConceptsToolbar';
import ConnectedPublishShiftConceptsForm from '../../connectors/ConnectedPublishShiftConceptsForm/ConnectedPublishShiftConceptsForm';
import AddShiftForm from '../../forms/AddShiftForm/AddShiftForm';
import CopyShiftsForm from '../../forms/CopyShiftsForm/CopyShiftsForm';
import PublishBaseScheduleForm from '../../forms/PublishBaseScheduleForm/PublishBaseScheduleForm';
import ConceptsCalendar from '../ConceptsCalendar/ConceptsCalendar';

import './ConceptsCalendarOverview.scss';

interface ConceptsCalendarOverviewProps {
    isLoading: boolean;
    isMobile: boolean;
    canAddShifts: boolean;
    canEditShifts: boolean;
    canViewShiftConcepts: boolean;
    canInsertBaseSchedules: boolean;
    canEditShiftConcepts: boolean;
    canDeleteShiftConcepts: boolean;
    canPublishShiftConcepts: boolean;
    showEmploymentTypeBadge: boolean;
    showEmployees: boolean;
    selectedCalendarType: CalendarTypeFormOption;
    highlightShiftId?: string;
    filter: AppFilter;
    timeMode: TimeModeType;
    departments: DepartmentViewModel[];
    payrollPeriods: PayrollPeriodViewModel[];
    baseSchedules: BaseScheduleIndexViewModel[];
    shifts: (ShiftViewModel | ShiftConceptViewModelOld | BaseScheduleShiftViewModel)[];
    specialDays: SpecialDayViewModel[];
    leaveOfAbsences: LeaveOfAbsenceViewModel[];
    absences: AbsenceViewModel[];
    unavailableTimeSlots: UnavailableToWorkTimeSlotViewModel[];
    roles: Role[];
    selectedDays: Date[];
    onSetFilter: (filter: AppFilter) => void;
    onSetStartAndEndDate: (selectedDate: Date, startDate: Date, endDate: Date) => void;
    onSearchBarChange: (search: string) => void;
    onNavigationChange: () => void;
    onShiftClick: (shiftId: string, shiftType: ShiftType) => void;
    onSubmitDeleteShifts: (shiftIds: string[]) => void;
    onSubmitCopyShifts: (shiftIds: string[], copyEmployees: boolean, dateOffset: number) => void;
    onSubmitAddShift: (formData: AddShiftFormData) => void;
    onSubmitPublishBaseSchedules: (formData: PublishBaseScheduleFormData) => void;
}

const ConceptsCalendarOverview: FC<ConceptsCalendarOverviewProps> = ({
    isLoading,
    isMobile,
    canAddShifts,
    canEditShifts,
    canViewShiftConcepts,
    canInsertBaseSchedules,
    canEditShiftConcepts,
    canDeleteShiftConcepts,
    canPublishShiftConcepts,
    showEmploymentTypeBadge,
    showEmployees,
    selectedCalendarType,
    filter,
    timeMode,
    departments,
    payrollPeriods,
    baseSchedules,
    shifts,
    leaveOfAbsences,
    absences,
    unavailableTimeSlots,
    specialDays,
    roles,
    selectedDays,
    onSetFilter,
    onSetStartAndEndDate,
    onSearchBarChange,
    onShiftClick,
    onNavigationChange,
    onSubmitDeleteShifts,
    onSubmitCopyShifts,
    onSubmitAddShift,
    onSubmitPublishBaseSchedules,
    highlightShiftId,
}) => {
    const [elementRef, { height }] = useElementSize();
    const { height: windowHeight } = useWindowSize();

    const deleteButtonId = canDeleteShiftConcepts ? 'delete-button' : undefined;
    const [selectedShiftIds, setSelectedShiftIds] = useState<string[]>([]);
    const [dateOffset, setDateOffset] = useState(0);
    const [deleteShiftsForm, setDeleteShiftsForm] = useState(false);
    const [addShiftForm, setAddShiftForm] = useState(false);
    const [copyShiftsForm, setCopyShiftsForm] = useState(false);
    const [addShiftDate, setAddShiftDate] = useState<Date>(new Date());
    const [publishShiftConceptsForm, setPublishShiftConceptsForm] = useState(false);
    const [publishBaseScheduleForm, setPublishBaseScheduleForm] = useState(false);

    const actionBarWrapperClassNames = classnames('concepts-calendar-overview__action-bar-wrapper', {
        'concepts-calendar-overview__action-bar-wrapper--is-sticky': height > windowHeight,
    });

    const deleteShiftTitle = useMemo(() => (
        translate(
            'common.deleteResource',
            { resource: translate('pages.shifts.conceptShifts') },
        )), []);
    const deleteShiftIntro = useMemo(
        () => (
            translate(
                'common.deleteResourceIntro',
                { resource: `${selectedShiftIds.length} ${translate('pages.shifts.conceptShifts').toLowerCase()}` },
            )),
        [selectedShiftIds],
    );

    const handleShiftClick = (shift: ShiftDraggable) => {
        onShiftClick(shift.id, shift.type);
    };

    const handleAddButtonClick = (date: Date) => {
        setAddShiftDate(date);
        setAddShiftForm(true);
    };

    const handleAddTodayButtonClick = () => {
        setAddShiftDate(new Date());
        setAddShiftForm(true);
    };

    const handleAddBaseScheduleButtonClick = (date: Date) => {
        setAddShiftDate(date);
        setPublishBaseScheduleForm(true);
    };

    const handleAddBaseScheduleTodayButtonClick = () => {
        setAddShiftDate(new Date());
        setPublishBaseScheduleForm(true);
    };

    const handleDeleteShifts = (shiftIds: string[]) => {
        setSelectedShiftIds(shiftIds);
        setDeleteShiftsForm(true);
    };

    const handleDeleteButtonClick = () => {
        setDeleteShiftsForm(true);
    };

    const handleCopyShifts = (shiftIds: string[], offset: number) => {
        setSelectedShiftIds(shiftIds);
        setDateOffset(offset);
        setCopyShiftsForm(true);
    };

    const handleSelectedShiftsChange = (shiftIds: string[]) => {
        setSelectedShiftIds(shiftIds);
    };

    const handleSubmitDeleteShiftsForm = () => {
        onSubmitDeleteShifts(selectedShiftIds);
        setDeleteShiftsForm(false);
        setSelectedShiftIds([]);
    };

    const handleCancelDeleteShiftsForm = () => {
        setDeleteShiftsForm(false);
    };

    const handleSubmitCopyShiftsForm = (copyEmployees: boolean) => {
        onSubmitCopyShifts(selectedShiftIds, copyEmployees, dateOffset);
        setCopyShiftsForm(false);
        setSelectedShiftIds([]);
    };

    const handleCancelCopyShiftsForm = () => {
        setCopyShiftsForm(false);
    };

    const handleSubmitAddShiftForm = (formData: AddShiftFormData) => {
        onSubmitAddShift(formData);
        setAddShiftForm(false);
    };

    const handleCancelAddShiftForm = () => {
        setAddShiftForm(false);
        setAddShiftDate(new Date());
    };

    const handlePublishButtonClick = () => {
        setPublishShiftConceptsForm(true);
    };

    const handleSubmitPublishShiftConceptsForm = () => {
        setPublishShiftConceptsForm(false);
    };

    const handleCancelPublishShiftConceptsForm = () => {
        setPublishShiftConceptsForm(false);
    };

    const handleSubmitPublishBaseScheduleForm = (formData: PublishBaseScheduleFormData) => {
        onSubmitPublishBaseSchedules(formData);
        setPublishBaseScheduleForm(false);
    };

    const handleCancelPublishBaseScheduleForm = () => {
        setPublishBaseScheduleForm(false);
    };

    return (
        <SidebarPage
            showLegend
            sidebarContent={(
                <Filters
                    roles={roles}
                    filter={filter}
                    filterBlocks={[
                        'show-employees',
                        'employment-type-badge',
                        'show-published-shifts',
                    ]}
                    button={(
                        <>
                            <ButtonAuthenticate
                                text={translate('pages.shifts.conceptShift')}
                                onClick={handleAddTodayButtonClick}
                            />
                            <ButtonSchedule
                                text={translate('pages.shifts.addBaseSchedule')}
                                onClick={handleAddBaseScheduleTodayButtonClick}
                            />
                        </>
                    )}
                    onChange={onSetFilter}
                />
            )}
        >
            <div ref={elementRef} className="concepts-calendar-overview__content">
                <div className={actionBarWrapperClassNames}>
                    <CalendarAndConceptsToolbar
                        canViewShiftConcepts={canViewShiftConcepts}
                        onCalendarTypeChange={onNavigationChange}
                        selectedCalendarType={selectedCalendarType}
                        setStartAndEndDate={onSetStartAndEndDate}
                        onPublishButtonClick={canPublishShiftConcepts ? handlePublishButtonClick : undefined}
                        onDeleteButtonClick={canDeleteShiftConcepts ? handleDeleteButtonClick : undefined}
                        onSearchBarChange={onSearchBarChange}
                        deleteButtonId={deleteButtonId}
                        deleteButtonDisabled={!selectedShiftIds.length}
                        employeeSearch={filter.employeeSearch}
                        timeMode={timeMode}
                        payrollPeriods={payrollPeriods}
                    />
                    {!isMobile && (
                        <CalendarHeader
                            days={selectedDays}
                            mode={timeMode}
                        />
                    )}
                </div>

                <ConceptsCalendar
                    showAddButton={canAddShifts}
                    showAddConceptButton={canInsertBaseSchedules}
                    showDayInMonth
                    hasDarkBackground
                    hasMultiSelect
                    canEditShifts={canEditShifts}
                    canEditShiftConcepts={canEditShiftConcepts}
                    isMobile={isMobile}
                    timeMode={timeMode}
                    deleteButtonId={deleteButtonId}
                    highlightShiftId={highlightShiftId}
                    showEmploymentTypeBadge={showEmploymentTypeBadge}
                    showEmployees={showEmployees}
                    shifts={shifts}
                    specialDays={specialDays}
                    selectedDays={selectedDays}
                    leaveOfAbsences={leaveOfAbsences}
                    absences={absences}
                    unavailableTimeSlots={unavailableTimeSlots}
                    onAddButtonClick={handleAddButtonClick}
                    onAddBaseScheduleButtonClick={handleAddBaseScheduleButtonClick}
                    onShiftClick={handleShiftClick}
                    onSelectedShiftsChange={handleSelectedShiftsChange}
                    onDeleteShifts={handleDeleteShifts}
                    onCopyShifts={handleCopyShifts}
                />

                {isLoading && <LoadingSpinner />}

                <Modal
                    size="md"
                    isOpen={deleteShiftsForm}
                    className="concepts-calendar-overview__delete-shifts-form"
                >
                    <DeleteResourceForm
                        title={deleteShiftTitle}
                        intro={deleteShiftIntro}
                        onSubmit={handleSubmitDeleteShiftsForm}
                        onCancel={handleCancelDeleteShiftsForm}
                    />
                </Modal>
                <Modal
                    size="md"
                    isOpen={copyShiftsForm}
                    className="concepts-calendar-overview__copy-shifts-form"
                >
                    <CopyShiftsForm
                        shiftsLength={selectedShiftIds.length}
                        onSubmit={handleSubmitCopyShiftsForm}
                        onCancel={handleCancelCopyShiftsForm}
                    />
                </Modal>
                <Modal
                    size="lg"
                    isOpen={addShiftForm}
                    className="concepts-calendar-overview__add-shift-form"
                >
                    <AddShiftForm
                        title={translate('pages.shifts.addConceptShift')}
                        date={addShiftDate}
                        departments={departments}
                        onSubmit={handleSubmitAddShiftForm}
                        onCancel={handleCancelAddShiftForm}
                    />
                </Modal>
                <Modal
                    size="md"
                    isOpen={publishShiftConceptsForm}
                    className="concepts-calendar-overview__publish-shift-concepts-form"
                >
                    <ConnectedPublishShiftConceptsForm
                        onCancel={handleCancelPublishShiftConceptsForm}
                        onSubmit={handleSubmitPublishShiftConceptsForm}
                    />
                </Modal>
                <Modal
                    size="md"
                    isOpen={publishBaseScheduleForm}
                    className="concepts-calendar-overview__publish-base-schedule-form"
                >
                    <PublishBaseScheduleForm
                        onCancel={handleCancelPublishBaseScheduleForm}
                        onSubmit={handleSubmitPublishBaseScheduleForm}
                        baseSchedules={baseSchedules}
                        selectedStartDate={addShiftDate}
                        selectedEndDate={addDays(addWeeks(addShiftDate, 2), -1)}
                    />
                </Modal>
            </div>
        </SidebarPage>
    );
};

export default ConceptsCalendarOverview;
