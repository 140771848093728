import React from 'react';

import nl from 'date-fns/locale/nl';
import PropTypes from 'prop-types';
import DatePicker, { registerLocale } from 'react-datepicker';
import { FormFeedback } from 'reactstrap';

import { formatDate } from '../../helpers/date';
import { isValidDate } from '../../helpers/date/isValidDate';
import { translate } from '../../helpers/translations/translator';

import './FormDatePicker.scss';

registerLocale('nl-NL', nl);


class FormDatePicker extends React.Component {
    onInputChange = (e) => {
        this.props.onChange(e.target.value);
    };

    onDatePickerChange = (date) => {
        if (date && !isValidDate(date.toLocaleDateString('en'))) {
            return;
        }

        this.props.onChange(date);
    }

    convertDate(date) {
        if (typeof date === 'string') {
            return new Date(date);
        }
        return date;
    }

    render() {
        const {
            id, selected, disabled, invalid, showTimeSelect, feedback, showYearDropdown,
            placeholderText, minDate, maxDate, isClearable,
        } = this.props;

        const className = `form-control ${invalid ? 'invalid' : ''}`;
        const date = this.convertDate(selected);
        const min = minDate ? this.convertDate(minDate) : null;
        const max = maxDate ? this.convertDate(maxDate) : null;
        const dateIsValid = date && isValidDate(date.toLocaleDateString('en'));
        const inputValue = dateIsValid ? formatDate(date, 'yyyy-MM-dd') : selected;

        return (
            <span
                id={id}
                className="form-date-picker"
            >
                <DatePicker
                    preventOpenOnFocus
                    className={className}
                    dateFormat="dd-MM-yyyy"
                    disabled={disabled}
                    isClearable={isClearable}
                    selected={dateIsValid && date}
                    minDate={dateIsValid && min}
                    maxDate={dateIsValid && max}
                    onChange={this.onDatePickerChange}
                    showTimeSelect={showTimeSelect}
                    placeholderText={placeholderText}
                    timeIntervals={15}
                    timeFormat="HH:mm"
                    timeCaption={translate('common.time')}
                    scrollableYearDropdown={showYearDropdown}
                    showYearDropdown={showYearDropdown}
                    yearDropdownItemNumber={25}
                    locale="nl-NL"
                />
                <input
                    className="form-control form-date-picker-mobile"
                    value={inputValue || ''}
                    type="date"
                    onChange={this.onInputChange}
                />
                {(feedback && invalid) && (
                    <FormFeedback className="active">
                        {feedback}
                    </FormFeedback>
                )}
            </span>
        );
    }
}

FormDatePicker.propTypes = {
    id: PropTypes.string,
    selected: PropTypes.any,
    minDate: PropTypes.any,
    maxDate: PropTypes.any,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    invalid: PropTypes.bool,
    showTimeSelect: PropTypes.bool,
    showYearDropdown: PropTypes.bool,
    isClearable: PropTypes.bool,
    placeholderText: PropTypes.string,
    feedback: PropTypes.any,
};
FormDatePicker.defaultProps = {
    id: null,
    selected: new Date(),
    minDate: null,
    maxDate: null,
    invalid: false,
    disabled: false,
    showTimeSelect: false,
    showYearDropdown: false,
    isClearable: false,
    placeholderText: null,
    feedback: null,
};

export default FormDatePicker;
