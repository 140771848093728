import React, { FC, useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import { getCheckedFormOptionsValue } from '../../../../@paco/helpers/types/formOption';
import { useTypedSelector } from '../../../../@paco/redux/store';
import { PublishShiftConceptsFormData } from '../../../../entities/ShiftConcept/ShiftConcept';
import { getShiftConceptsApiCall } from '../../../../entities/ShiftConcept/ShiftConceptService';
import { getEndOfWorkDay, getStartOfWorkDay } from '../../../../helpers/date';
import { fetchShiftConceptsToPublish, publishShiftConcepts } from '../../../../redux/@toolkit/shiftConcepts/shiftConceptsActions';
import { setIsLoading } from '../../../../redux/@toolkit/shiftConcepts/shiftConceptsReducer';
import { Reducers } from '../../../../redux/reducers';
import { useTypedDispatch } from '../../../../redux/store';
import generateEmptyPublishShiftConceptsFormDataValues from '../../forms/PublishShiftConceptsForm/helpers/generateEmptyPublishShiftConceptsFormDataValues';
import PublishShiftConceptsForm from '../../forms/PublishShiftConceptsForm/PublishShiftConceptsForm';

interface ConnectedPublishShiftConceptsFormProps {
    onSubmit: () => void;
    onCancel: () => void;
}

const ConnectedPublishShiftConceptsForm:FC<ConnectedPublishShiftConceptsFormProps> = ({
    onCancel,
    onSubmit,
}) => {
    const dispatch = useTypedDispatch();
    const { departments } = useTypedSelector(state => state.authenticatedUserReducer);
    const { departmentOptions } = useTypedSelector(state => state.globalFiltersReducer);
    const { selectedDays } = useTypedSelector(state => state.calendarReducer);
    const { isLoading } = useSelector((state: Reducers) => state.shiftConceptsReducer);

    const checkedDepartmentIds = getCheckedFormOptionsValue(departmentOptions);
    const selectedDepartments = departments.filter(department => checkedDepartmentIds.includes(department.id));

    const [amountOfShiftConcepts, setAmountOfShiftConcepts] = useState(0);
    const [formData, setFormData] = useState<PublishShiftConceptsFormData>(generateEmptyPublishShiftConceptsFormDataValues(
        checkedDepartmentIds,
        selectedDays[0],
        selectedDays[selectedDays.length - 1],
    ));

    const handleFormDateChange = (value: PublishShiftConceptsFormData) => {
        setFormData(value);
    };

    const handleSubmitPublishShifts = async (values: PublishShiftConceptsFormData) => {
        dispatch(setIsLoading(true));
        await dispatch(fetchShiftConceptsToPublish(
            values.startDate,
            values.endDate,
            values.departments,
        ));

        dispatch(publishShiftConcepts(values.departments, values.notificationTitle, values.notificationBody));
        onSubmit();
        dispatch(setIsLoading(false));
    };

    const getShiftConceptsAmount = async () => {
        const { startDate, endDate } = formData;
        dispatch(setIsLoading(true));
        try {
            const response = await getShiftConceptsApiCall(
                getStartOfWorkDay(startDate),
                getEndOfWorkDay(endDate),
                {},
                formData.departments,
                [
                    'department',
                    'department.group',
                ],
            );
            setAmountOfShiftConcepts(response.length);
        } catch (error) {
            console.error('[getShiftConceptsAmount]', error);
        } finally {
            dispatch(setIsLoading(false));
        }
    };

    useEffect(() => {
        getShiftConceptsAmount();
    }, [formData.startDate, formData.endDate, formData.departments]);

    return (
        <PublishShiftConceptsForm
            onCancel={onCancel}
            onSubmit={handleSubmitPublishShifts}
            isLoading={isLoading}
            onFormDataChange={handleFormDateChange}
            amountOfShiftConcepts={amountOfShiftConcepts}
            formData={formData}
            departments={departments}
            selectedFilterDepartments={selectedDepartments}
        />
    );
};

export default ConnectedPublishShiftConceptsForm;
