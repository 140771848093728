import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { unique } from 'underscore';

import { IconButton, NumberSelectInput } from '../../../../@paco/compositions';
import trans from '../../../../@paco/helpers/trans';
import ButtonAuthenticate from '../../../../components/ButtonAuthenticate/ButtonAuthenticate';
import ItemsList from '../../../../components/ItemsList/ItemsList';
import Icon from '../../../../components/style/Icon/Icon';
import TableContainer from '../../../../components/Table/TableContainer';
import TableFilter from '../../../../components/Table/TableFilter';
import { checkPermission, getObjProperty } from '../../../../helpers';
import { formatDate } from '../../../../helpers/date';
import { translate } from '../../../../helpers/translations/translator';
import { downloadUserDocument } from '../../../../services/UserDocument/downloadUserDocument';

import './Documents.scss';


class Documents extends Component {
    state = {
        visibleToEmployee: 0,
        documentType: null,
        year: undefined,
    };

    setTableFilter = (value, prop) => {
        this.setState({
            [prop]: value,
        });
    }

    renderVisibilityFilterButton = () => {
        const options = [
            {
                value: 2,
                label: translate('pages.management.visibleToEmployee'),
            },
            {
                value: 1,
                label: translate('pages.management.notVisibleToEmployee'),
            }];

        return (
            <TableFilter
                placeholder={translate('pages.management.intendedFor')}
                id="visible-to-employee-filter"
                options={options}
                onChange={val => this.setTableFilter(val, 'visibleToEmployee')}
                className="documents-table-filter"
            />
        );
    }

    renderDocumentTypeFilterButton = () => {
        const { files } = this.props;
        const options = unique(files
            .reduce((total, file) => [...total, file.documentType], []))
            .map(type => ({
                label: translate(`pages.management.${type.name.toLowerCase()}`),
                value: type.id,
            }));

        return (
            <TableFilter
                placeholder={translate('pages.management.documentType')}
                id="document-type-filter"
                options={options}
                onChange={val => this.setTableFilter(val, 'documentType')}
                className="documents-table-filter"
            />
        );
    }

    renderButtons = (item) => {
        const { onEditDocumentClick, onDeleteDocumentClick, permissions } = this.props;
        const canEditFiles = checkPermission(permissions, 'edit-all-files', 'documents');
        const canDeleteFiles = checkPermission(permissions, 'delete-all-files', 'documents');

        return (
            <>
                { canEditFiles && (
                    <button type="button" onClick={() => onEditDocumentClick(item)} className="clear-button">
                        <Icon color="light-blue" kind="edit" />
                    </button>
                )}
                { canDeleteFiles && (
                    <button type="button" onClick={() => onDeleteDocumentClick(item.id)} className="clear-button">
                        <Icon color="light-blue" kind="trash" />
                    </button>
                )}
            </>
        );
    }

    render() {
        const {
            yearEndStatements,
            files,
            onAddDocumentClick,
            onDownloadYearEndStatementClick,
            permissions,
            sameUserAsCurrentUser,
        } = this.props;
        const { visibleToEmployee, documentType, year } = this.state;
        const canAddFiles = checkPermission(permissions, 'add-new-files', 'documents');
        const tableAttributes = [
            {
                label: translate('common.document'),
                customAction: item => (
                    <button
                        className="documents-table-filename"
                        type="button"
                        onClick={() => downloadUserDocument(item)}
                    >
                        {item.displayName}
                    </button>
                ),
                className: 'documents-table-td-filename',
            },
            {
                label: this.renderVisibilityFilterButton(),
                customAction: item => (item.userCanView ? translate('pages.management.visibleToEmployee') : translate('pages.management.notVisibleToEmployee')),
                className: 'documents-table-td-visible-to-employee',
            },
            {
                label: this.renderDocumentTypeFilterButton(),
                customAction: item => translate(`pages.management.${getObjProperty(item, 'documentType.name').toLowerCase()}`),
                className: 'documents-table-td-visible-to-employee',
            },
            {
                label: translate('pages.management.createdOn'),
                customAction: item => item.createdAt && formatDate(item.createdAt, 'dd-MM-yyyy'),
                className: 'documents-table-td-create-date',
            },
            {
                customAction: item => !item.isLoketFile && this.renderButtons(item),
                label: '',
                className: 'td-edit-delete',
            },
        ];

        const filteredData = files.filter((file) => {
            const { userCanView } = file;

            if ((visibleToEmployee === 1) && userCanView) {
                return false;
            }
            if ((visibleToEmployee === 2) && !userCanView) {
                return false;
            }

            if (documentType && getObjProperty(file, 'documentType.id') !== documentType) {
                return false;
            }

            return true;
        });

        const setYear = (selectedYear) => {
            this.setState({
                year: selectedYear,
            });
        };

        const yearOptions = yearEndStatements.map(yearEndStatement => yearEndStatement.year);

        return (
            <div className="documents">
                {canAddFiles
                && (
                    <div className="documents__top">
                        {sameUserAsCurrentUser
                        && (
                            <div className="documents__year-end-statement">
                                <NumberSelectInput
                                    numbers={yearOptions}
                                    number={year}
                                    onChange={setYear}
                                />
                                <IconButton
                                    disabled={!year}
                                    icon="download"
                                    text={trans('common.downloadYearEndStatement')}
                                    className="documents__download"
                                    onClick={() => onDownloadYearEndStatementClick(year)}
                                />
                            </div>
                        )}
                        <div className="documents__new-document">
                            <ButtonAuthenticate
                                text={translate('pages.management.newDocument')}
                                onClick={onAddDocumentClick}
                                responsive={false}
                            />
                        </div>
                    </div>
                )}
                <ItemsList
                    itemsLength={files.length}
                    emptyMessage={translate('pages.management.emptyFiles')}
                >
                    <TableContainer
                        className="documents-table"
                        attributes={tableAttributes}
                        data={filteredData}
                    />
                </ItemsList>
            </div>
        );
    }
}

Documents.propTypes = {
    files: PropTypes.array.isRequired,
    yearEndStatements: PropTypes.array.isRequired,
    permissions: PropTypes.array.isRequired,
    onAddDocumentClick: PropTypes.func.isRequired,
    onDeleteDocumentClick: PropTypes.func.isRequired,
    onDownloadYearEndStatementClick: PropTypes.func.isRequired,
};

export default Documents;
