import { FC, ReactElement } from 'react';

import trans from '../../../helpers/trans';
import { SelectOption, YesOrNoType } from '../../../types';
import { SelectInput } from '../../index';

const transformBooleanToSelectOption = (booleanValue?: boolean): SelectOption => {
    if (booleanValue) {
        return ({ label: trans('common.yes'), value: 'yes' });
    }

    return ({ label: trans('common.no'), value: 'no' });
};

const transformSelectOptionToBoolean = (selectOption: SelectOption): boolean => selectOption.value === 'yes';

const generateYesOrNoOptions = (): SelectOption[] => {
    const yesOrNoArray = Object.keys(YesOrNoType);

    return yesOrNoArray.map((yesOrNo) => ({ label: trans(`common.${yesOrNo}`), value: yesOrNo })) as SelectOption[];
};

const yesOrNoOptions = generateYesOrNoOptions();

interface YesOrNoSelectInputProps {
    booleanValue?: boolean;
    disabled?: boolean;
    label: string;
    name: string;
    onChange: (option: boolean) => void;
}

const YesOrNoSelectInput: FC<YesOrNoSelectInputProps> = ({
    booleanValue,
    disabled,
    label,
    name,
    onChange,
}): ReactElement => {
    const onYesOrNoStateChange = (yesOrNoValue: SelectOption): void => {
        onChange(transformSelectOptionToBoolean(yesOrNoValue));
    };

    return (
        <SelectInput
            isSearchable={false}
            disabled={disabled}
            label={label}
            name={name}
            value={transformBooleanToSelectOption(booleanValue)}
            options={yesOrNoOptions}
            onChange={onYesOrNoStateChange}
        />
    );
};

export default YesOrNoSelectInput;
