import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Button, Modal } from 'reactstrap';

import { ElementWithTooltip } from '../../@paco/compositions';
import { ConnectedHelpTooltip } from '../../@paco/connectors';
import { doesPeriodOverlapWithLockedPayrollPeriod, getPayrollPeriodFromDate } from '../../@paco/entities/PayrollPeriod/PayrollPeriodHelpers';
import { transformToPeriod } from '../../@paco/entities/Period/PeriodTransformers';
import { transformLegacyRoleToRole } from '../../@paco/entities/Role/RoleTransformers';
import { getSettingEnabled } from '../../@paco/entities/Setting/SettingHelpers';
import trans from '../../@paco/helpers/trans';
import ButtonAuthenticate from '../../components/ButtonAuthenticate/ButtonAuthenticate';
import ButtonHistory from '../../components/ButtonHistory/ButtonHistory';
import NewFilters from '../../components/Filters/Filters';
import ExportRangeForm from '../../components/forms/ExportRangeForm/ExportRangeForm';
import ItemsList from '../../components/ItemsList/ItemsList';
import { PrivateRouteGroupRoutes } from '../../components/PrivateRouteGroup/PrivateRouteGroupRoutes';
import SearchBar from '../../components/SearchBar/SearchBar';
import SidebarPage from '../../components/SidebarPage/SidebarPage';
import Icon from '../../components/style/Icon/Icon';
import TableContainer from '../../components/Table/TableContainer';
import UserPageLink from '../../components/UserPageLink/UserPageLink';
import { TRACK_TYPE_FINISHED } from '../../constants';
import { checkPermission, getObjProperty, getUserFullName } from '../../helpers';
import { convertMinsToHrsMins, formatDate } from '../../helpers/date';
import { translate } from '../../helpers/translations/translator';
import { setEmployeeSearch, setFilter } from '../../redux/filter/filterActions';
import { getResourceLogs } from '../../redux/logs/logsActions';
import { exportCSV, getTracks } from '../../redux/tracks/tracksActions';
import { setStartAndEndDate } from '../../redux/weekNavigator/weekNavigatorActions';
import { transformPayrollPeriodToLegacyPayrollPeriod } from '../../services/PayrollPeriodService/transformPayrollPeriodLegacy';
import CalendarToolbar from '../Schedule/components/CalendarToolbar/CalendarToolbar';
import { ScheduleRoutes } from '../Schedule/ScheduleRoutes';
import { renderInfoCircles, renderWorkedTotalHours } from './helpers';
import getPermissionToApproveTrack from './helpers/getPermissionToApproveTrack';
import { getShiftStartAndEndTimeOfTrack } from './helpers/getShiftStartAndEndTimeOfTrack';
import { setActiveTrackFromUrl } from './helpers/setActiveTrackFromUrl';

import './Tracks.scss';

class FinishedTracks extends Component {
    state = {
        tracks: [],
        exportForm: false,
        activeTrackId: undefined,
        legacyPayrollPeriods: [],
    };

    componentDidMount() {
        const { dispatch } = this.props;

        const trackIdFromUrl = dispatch(setActiveTrackFromUrl());
        this.setState({ activeTrackId: trackIdFromUrl });

        dispatch(getTracks(TRACK_TYPE_FINISHED));
    }

    componentDidUpdate(prevProps) {
        const activeDepartmentOptions = this.props.departmentOptions.filter(option => option.isChecked);
        const prevActiveDepartmentOptions = prevProps.departmentOptions.filter(option => option.isChecked);

        if (activeDepartmentOptions.length !== prevActiveDepartmentOptions.length) {
            this.getTracks();
        }
    }

    static getDerivedStateFromProps(props, state) {
        const { tracks, onReviewTrackClick } = props;
        const { activeTrackId } = state;

        if (props.tracks !== state.tracks) {
            const matchedTrack = tracks.find(track => track.id === activeTrackId);

            if (matchedTrack) {
                onReviewTrackClick(matchedTrack, TRACK_TYPE_FINISHED);
            }
        }

        const activeTrackNotFound = !!(props.tracks.length || state.tracks.length) && !tracks.some(track => track.id === activeTrackId);
        const legacyPayrollPeriods = props.payrollPeriods.map(transformPayrollPeriodToLegacyPayrollPeriod);

        return {
            ...state,
            legacyPayrollPeriods,
            tracks: props.tracks,
            activeTrackId: activeTrackNotFound ? undefined : state.activeTrackId,
        };
    }

    dispatchSetFilters = (filter) => {
        const { dispatch } = this.props;
        dispatch(setFilter(filter, this.getTracks));
    }

    dispatchExportTracks = (startDate, endDate, payrollPeriodIds, exportType) => {
        const { dispatch } = this.props;
        this.setState({
            exportForm: false,
        });
        dispatch(exportCSV({
            startDate,
            endDate,
            payrollPeriodIds,
            exportType,
        }));
    }

    setStartAndEndDate = (selectedDate, startDate, endDate) => {
        const { dispatch } = this.props;
        dispatch(setStartAndEndDate(selectedDate, startDate, endDate));
        dispatch(getTracks(TRACK_TYPE_FINISHED));
    }

    afterDateChange = () => {
        const { dispatch } = this.props;
        dispatch(getTracks(TRACK_TYPE_FINISHED));
    };

    onSearchBarChange = (value) => {
        const { dispatch } = this.props;
        dispatch(setEmployeeSearch(value));
        this.getTracks();
    }

    dispatchOpenLogsModal = (id) => {
        const { dispatch } = this.props;
        dispatch(getResourceLogs(id, 'tracks'));
    }

    renderEditAndDeleteButtons = (track) => {
        const {
            permissions,
            currentUser,
            currentUserRole,
            onReviewTrackClick,
            onDeleteTrackClick,
            payrollPeriods,
        } = this.props;
        const legacyTrackOwnerRoles = getObjProperty(track, 'owner.roles') || [];
        const trackOwnerId = getObjProperty(track, 'owner.id');
        const trackOwnerRoles = legacyTrackOwnerRoles.map(transformLegacyRoleToRole);

        const canEditTrack = getPermissionToApproveTrack(
            currentUserRole,
            trackOwnerRoles,
            permissions,
            trackOwnerId,
            currentUser.id,
        );
        const canDeleteTrack = checkPermission(permissions, 'delete-all-tracks', 'finished-tracks');
        const canViewLogs = checkPermission(permissions, 'view-all-logs', 'finished-tracks');
        const payrollPeriodError = doesPeriodOverlapWithLockedPayrollPeriod(
            transformToPeriod(new Date(track.billableStart), new Date(track.billableEnd.date)),
            payrollPeriods,
        );

        return (
            <>
                {canEditTrack && (
                    <ElementWithTooltip
                        className="tracks__table-tooltip"
                        tooltipIsActive={!!payrollPeriodError}
                        tooltipText={translate('common.datesOverlapWithLockedPayrollPeriod')}
                    >
                        <button
                            type="button"
                            disabled={!!payrollPeriodError}
                            onClick={() => onReviewTrackClick(track, TRACK_TYPE_FINISHED)}
                            className="tracks__table-button"
                        >
                            <Icon className="tracks__table-button-icon" kind="edit" />
                        </button>
                    </ElementWithTooltip>
                )}
                {canDeleteTrack && (
                    <ElementWithTooltip
                        className="tracks__table-tooltip"
                        tooltipIsActive={!!payrollPeriodError}
                        tooltipText={translate('common.datesOverlapWithLockedPayrollPeriod')}
                    >
                        <button
                            type="button"
                            disabled={!!payrollPeriodError}
                            onClick={() => onDeleteTrackClick(track.id)}
                            className="tracks__table-button"
                        >
                            <Icon className="tracks__table-button-icon" kind="trash" />
                        </button>
                    </ElementWithTooltip>
                )}
                {canViewLogs && (
                    <ButtonHistory
                        onClick={() => this.dispatchOpenLogsModal(track.id)}
                    />
                )}
            </>
        );
    }

    getTracks = () => {
        const { dispatch } = this.props;
        dispatch(getTracks(TRACK_TYPE_FINISHED));
    }

    renderTable() {
        const { tracks, permissions, settings } = this.props;
        const { activeTrackId } = this.state;
        // TODO: we should do sorting in API query when we have more time
        const sortedTracks = tracks.sort((a, b) => new Date(b.checkOut) - new Date(a.checkOut));
        const isTimerEnableFeedback = getSettingEnabled('TIMER_ENABLE_FEEDBACK', settings);
        const canViewSatisfaction = isTimerEnableFeedback && checkPermission(permissions, 'view-all-feedback-ratings', 'finished-tracks');
        const canViewSatisfactionFeedback = isTimerEnableFeedback && checkPermission(permissions, 'view-all-feedback', 'finished-tracks');
        const canViewOfflineTrackIcon = checkPermission(permissions, 'view-offline-tracks', 'finished-tracks');

        return (
            <TableContainer
                activeId={activeTrackId}
                data={sortedTracks}
                attributes={[
                    {
                        customAction: track => (
                            <UserPageLink
                                isPlain
                                className="tracks-user-link"
                                name={getUserFullName(track.owner)}
                                id={track.owner.id}
                            />
                        ),
                        label: translate('common.name'),
                    },
                    {
                        customAction: track => formatDate(track.checkIn, 'd MMMM yyyy'),
                        label: translate('common.date'),
                    },
                    {
                        customAction: (track) => {
                            const route = track.shift ? `${PrivateRouteGroupRoutes.shifts()}/${ScheduleRoutes.shift(track.shift.id)}` : undefined;
                            const label = getShiftStartAndEndTimeOfTrack(track);

                            if (!route) {
                                return label;
                            }

                            return (
                                <NavLink to={route}>
                                    {label}
                                </NavLink>
                            );
                        },
                        label: translate('common.shift'),
                    },
                    {
                        customAction: track => `${formatDate(track.billableStart, 'HH:mm')} - 
                        ${track.billableEnd ? formatDate(track.billableEnd.transformToUpToButExcludingDate().date, 'HH:mm') : translate('common.unknown')}`,
                        label: translate('common.track'),
                    },
                    {
                        customAction: track => `${convertMinsToHrsMins(track.deviation)} ${translate('common.hour').toLowerCase()}`,
                        label: translate('common.deviation'),
                    },
                    {
                        customAction: track => renderWorkedTotalHours(track, settings, canViewOfflineTrackIcon),
                        label: translate('pages.tracks.workedTotal'),
                    },
                    {
                        customAction: track => renderInfoCircles(track, canViewSatisfaction, canViewSatisfactionFeedback),
                        label: translate('pages.tracks.shiftInfo'),
                        className: 'td-info-circles',
                    },
                    {
                        customAction: track => this.renderEditAndDeleteButtons(track),
                        label: '',
                        className: 'tracks-td-edit-delete',
                    },
                ]}
                tableRowClassName="finished-tracks__row"
            />
        );
    }

    renderTracks() {
        const { legacyPayrollPeriods } = this.state;
        const {
            loading,
            filter,
            tracks,
            pagination,
            timeMode,
            exportCSVRequest,
        } = this.props;
        const { employeeSearch } = filter;

        return (
            <>
                <div className="tracks-overview-top">
                    <CalendarToolbar
                        timeMode={timeMode}
                        payrollPeriods={legacyPayrollPeriods}
                        setStartAndEndDate={this.setStartAndEndDate}
                        afterDateChange={this.afterDateChange}
                    />
                    <ConnectedHelpTooltip
                        index={0}
                        route="finished-tracks"
                        subTitle={trans('help.tracks.finishedTracks.title')}
                        text={trans('help.tracks.finishedTracks.text')}
                        title={trans('help.tracks.title')}
                        className="tracks__finished-tracks-help-tooltip"
                    />
                    <div className="tracks-overview-top-right">
                        <div>
                            <ConnectedHelpTooltip
                                index={4}
                                route="finished-tracks"
                                subTitle={trans('help.tracks.exportTracks.title')}
                                text={trans('help.tracks.exportTracks.text')}
                                title={trans('help.tracks.title')}
                                className="tracks__export-tracks-help-tooltip"
                            />
                            <Button
                                className="button-export tracks-button-export"
                                disabled={exportCSVRequest}
                                onClick={() => this.setState({ exportForm: true })}
                                color="orange"
                            >
                                <Icon color="white" kind="arrow_downward" />
                                {translate('common.export')}
                            </Button>
                        </div>
                        <SearchBar
                            placeholder={translate('common.searchEmployee')}
                            startVal={employeeSearch}
                            onChange={this.onSearchBarChange}
                        />
                    </div>
                </div>
                <ItemsList
                    loading={loading}
                    itemsLength={tracks.length}
                    pagination={pagination}
                    emptyMessage={translate('pages.tracks.emptyList')}
                    onPagination={this.getTracks}
                >
                    {this.renderTable()}
                </ItemsList>
            </>
        );
    }

    render() {
        const { exportForm, legacyPayrollPeriods } = this.state;
        const {
            filter,
            canAddTracks,
            payrollPeriods,
            employmentTypes,
            onAddTrackClick,
        } = this.props;
        const period = getPayrollPeriodFromDate(new Date(), payrollPeriods);

        return (
            <div className="tracks">
                <SidebarPage
                    sidebarContent={(
                        <NewFilters
                            filter={filter}
                            employmentTypes={employmentTypes}
                            button={canAddTracks ? (
                                <ButtonAuthenticate onClick={onAddTrackClick} text={translate('pages.tracks.addTrack')} />
                            ) : null}
                            filterBlocks={[
                                'contract-types',
                            ]}
                            onChange={this.dispatchSetFilters}
                        />
                    )}
                >
                    <div className="tracks-list tracks-finished">
                        {this.renderTracks()}
                    </div>
                    <ConnectedHelpTooltip
                        index={1}
                        route="finished-tracks"
                        subTitle={trans('help.tracks.filters.title')}
                        text={trans('help.tracks.filters.text')}
                        title={trans('help.tracks.title')}
                        showMobileInfoWarning
                        className="tracks__filters-help-tooltip"
                    />
                    <ConnectedHelpTooltip
                        index={2}
                        videoSrc="/help/finished-tracks-icons.webm"
                        route="finished-tracks"
                        subTitle={trans('help.tracks.trackIcons.title')}
                        text={trans('help.tracks.trackIcons.text')}
                        title={trans('help.tracks.title')}
                        showMobileInfoWarning
                        className="tracks__finished-track-icons-help-tooltip"
                    />
                    <ConnectedHelpTooltip
                        index={3}
                        route="finished-tracks"
                        subTitle={trans('help.tracks.editTrack.title')}
                        text={trans('help.tracks.editTrack.text')}
                        title={trans('help.tracks.title')}
                        showMobileInfoWarning
                        className="tracks__edit-track-help-tooltip"
                    />
                </SidebarPage>
                <Modal size="lg" isOpen={exportForm} className="form-export-range">
                    <ExportRangeForm
                        title={translate('pages.tracks.exportTracks')}
                        intro={translate('common.exportFilterCaution')}
                        onSubmit={this.dispatchExportTracks}
                        onCancel={() => this.setState({ exportForm: false })}
                        activePeriod={period && transformPayrollPeriodToLegacyPayrollPeriod(period)}
                        startDate={period?.period.start}
                        endDate={period?.period.end}
                        payrollPeriods={legacyPayrollPeriods}
                    />
                </Modal>
            </div>
        );
    }
}

FinishedTracks.propTypes = {
    loading: PropTypes.bool.isRequired,
    pagination: PropTypes.object.isRequired,
    tracks: PropTypes.array.isRequired,
    settings: PropTypes.array.isRequired,
    dispatch: PropTypes.func.isRequired,
    filter: PropTypes.object.isRequired,
    timeMode: PropTypes.string.isRequired,
    onAddTrackClick: PropTypes.func.isRequired,
    onReviewTrackClick: PropTypes.func.isRequired,
    onDeleteTrackClick: PropTypes.func.isRequired,
    exportCSVRequest: PropTypes.bool.isRequired,
    permissions: PropTypes.array.isRequired,
    payrollPeriods: PropTypes.array.isRequired,
    employmentTypes: PropTypes.array.isRequired,
    currentUser: PropTypes.object.isRequired,
    currentUserRole: PropTypes.object.isRequired,
    canAddTracks: PropTypes.bool.isRequired,
};

export default connect()(FinishedTracks);
