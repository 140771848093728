import React, { Component } from 'react';

import serialize from 'form-serialize';
import PropTypes from 'prop-types';
import {
    Button,
    Col,
    CustomInput,
    FormGroup,
    Label,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
} from 'reactstrap';

import { getObjProperty } from '../../../helpers';
import { translate } from '../../../helpers/translations/translator';

class AddOrEditDepartment extends Component {
    state = {
        data: {},
        canSubmit: false,
    };

    static getDerivedStateFromProps(props, state) {
        const {
            name,
        } = state.data;

        return {
            canSubmit: !!name,
        };
    }

    onChange = (e) => {
        e.preventDefault();

        const data = serialize(e.currentTarget, { hash: true });

        data.id = getObjProperty(this.props, 'department.id');
        data.groupId = data.groupId === 'null' ? null : data.groupId;

        this.setState({ data });
    };

    onSubmit = (e) => {
        e.preventDefault();

        const { onSubmit } = this.props;
        const { data } = this.state;

        onSubmit(data);
    }

    render() {
        const { title, department, onCancel } = this.props;
        const { canSubmit } = this.state;

        const groups = [
            <option key="0" value="null">{translate('common.none')}</option>,
            ...this.props.groups.map(group => (
                <option key={group.id} value={group.id}>
                    {group.name}
                </option>
            )),
        ];

        return (
            <form onSubmit={this.onSubmit} onChange={this.onChange}>
                <ModalHeader>{title}</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label>{translate('common.name')}</Label>
                                <CustomInput
                                    type="text"
                                    className="form-control"
                                    id="name"
                                    name="name"
                                    autoComplete="off"
                                    defaultValue={department && department.name}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label>{translate('common.group')}</Label>
                                <CustomInput
                                    type="select"
                                    name="groupId"
                                    id="groupId"
                                    defaultValue={getObjProperty(department, 'group.id')}
                                >
                                    { groups }
                                </CustomInput>
                            </FormGroup>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button type="button" color="link" id="modal-close" onClick={onCancel}>{translate('common.cancel')}</Button>
                    <Button type="submit" color="orange" disabled={!canSubmit}>{translate('common.save')}</Button>
                </ModalFooter>
            </form>
        );
    }
}

AddOrEditDepartment.propTypes = {
    title: PropTypes.string.isRequired,
    department: PropTypes.object,
    groups: PropTypes.array,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

AddOrEditDepartment.defaultProps = {
    groups: [],
    department: null,
};

export default AddOrEditDepartment;
