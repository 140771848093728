import React, { Component } from 'react';

import PropTypes from 'prop-types';
import Select from 'react-select';

import { generateEventPath } from '../../helpers';

import './TableFilter.scss';

class TableFilter extends Component {
    ref = React.createRef();

    componentDidMount() {
        const elements = generateEventPath(this.ref.current);

        elements.forEach((el) => {
            if (el.className && el.className.indexOf('table-container') !== -1) {
                if (el.className.indexOf('table-container--has-filter-select') === -1) {
                    // This one cannot be spread into a new object because it's a reassign on an HTML element
                    // eslint-disable-next-line no-param-reassign
                    el.className = `${el.className} table-container--has-filter-select`;
                }
            }
        });
    }

    render() {
        const {
            placeholder, options, onChange, id, className,
        } = this.props;

        return (
            <div ref={this.ref} className={`table-filter ${className || ''}`}>
                <Select
                    isClearable
                    id={id}
                    maxMenuHeight={120}
                    options={options}
                    placeholder={placeholder}
                    onChange={e => onChange(e?.value || null)}
                    className="react-select"
                    classNamePrefix="react-select"
                />
            </div>
        );
    }
}

TableFilter.propTypes = {
    id: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    className: PropTypes.string,
};

TableFilter.defaultProps = {
    className: null,
};

export default TableFilter;
