import { Permission } from '../../entities/Permission/Permission';
import { PermissionSlug } from '../../types/permissionTypes';
import { trackedPermission } from './trackedPermission';

const checkPermission = (permissions: Permission[], slug: PermissionSlug, featureSlug?: string): boolean => {
    const trackPermissionsIsActive = localStorage.getItem('trackPermissionsHotkey') === 'trackPermissionsIsActive';

    if (trackPermissionsIsActive) {
        const trackedPermissions = localStorage.getItem('trackPermissions');
        const updatedTrackedPermissions = trackedPermission(trackedPermissions, slug, featureSlug);

        localStorage.setItem('trackPermissions', JSON.stringify(updatedTrackedPermissions));
    }

    return permissions.some(permission => permission.slug === slug);
};

export default checkPermission;
