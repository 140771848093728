import React, { FC, ReactElement } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { useTypedSelector } from '../../../@paco/redux/store';
import ShowEmployeesFilter from '../../../components/Filters/subcomponents/ShowEmployeesFilter/ShowEmployeesFilter';
import { checkPermission } from '../../../helpers';
import { setFilter } from '../../../redux/filter/filterActions';
import { AppFilter } from '../../../redux/filter-ts/filterModels';
import { Reducers } from '../../../redux/reducers';
import { getUsersWithAvailability } from '../../../redux/shifts-ts/shiftsActions';

const ConnectedShowEmployeesFilter: FC = (): ReactElement => {
    const dispatch = useDispatch();

    const filter = useSelector((state: Reducers) => state.filterReducer.filter);
    const { permissions } = useTypedSelector(state => state.authenticatedUserReducer);

    const canViewAbsences = checkPermission(permissions, 'view-all-absences', 'connected-show-employees-filter');
    const canViewLeaveOfAbsences = checkPermission(permissions, 'view-all-leave-of-absences', 'connected-show-employees-filter');

    const getUsersData = () => {
        dispatch(getUsersWithAvailability(canViewAbsences, canViewLeaveOfAbsences));
    };

    const handleShowEmployeesChange = (changedFilter: AppFilter) => {
        dispatch(setFilter(
            changedFilter,
            getUsersData,
        ));
    };

    return (
        <ShowEmployeesFilter
            filter={filter}
            onChange={handleShowEmployeesChange}
        />
    );
};

export default ConnectedShowEmployeesFilter;
