import React, { Component } from 'react';

import serialize from 'form-serialize';
import PropTypes from 'prop-types';
import { CustomInput, Label } from 'reactstrap';

import Icon from '../../../../components/style/Icon/Icon';
import { translate } from '../../../../helpers/translations/translator';

import './Settings.scss';

class SettingsBreaks extends Component {
    state = {
        options: {},
        showAddForm: false,
    };

    componentDidMount() {
        const { option } = this.props;
        const values = option ? option.value.split(',') : [];

        const options = values.reduce((total, value) => {
            const [minutes, active] = value.split(':');
            return ({
                ...total,
                [minutes]: {
                    active: parseInt(active, 10),
                },
            });
        }, {});

        this.setState({
            options,
        });
    }

    setStateProp = (active, id) => {
        const { options } = this.state;

        const activeAmount = Object.keys(options)
            .map(key => options[key])
            .filter(option => option.active && !option.delete)
            .length;

        // There should be at least one active option
        if (!active && activeAmount === 1) {
            return null;
        }

        options[id].active = active ? 1 : 0;
        return this.setStateAndPropagate(options);
    }

    setStateAndPropagate(options) {
        this.setState({
            options,
        });

        const values = Object
            .keys(options)
            .filter((id) => (!options[id].delete))
            .map((id) => (
                `${id}:${options[id].active ? 1 : 0}`
            ));

        const option = {
            ...this.props.option,
            value: values.join(','),
        };

        this.props.onChange(option);
    }

    addOption = (e) => {
        e.preventDefault();
        const id = 'checkbox-breaks-new-option';
        const formData = serialize(e.target, { hash: true });

        if (Object.keys(formData).length === 0) {
            return;
        }

        const { options } = this.state;
        const key = formData[id];
        options[key] = {
            active: true,
        };

        document.getElementById(id).value = '';
        this.setStateAndPropagate(options);
    }

    deleteOption = (id) => {
        const { options } = this.state;
        const amount = Object.keys(options)
            .map(key => options[key])
            .filter(option => !option.delete)
            .length;

        if (amount > 1) {
            options[id].delete = true;
            this.setStateAndPropagate(options);
        }
    }

    render() {
        const { options, showAddForm } = this.state;
        const { title, buttonText, departmentId } = this.props;
        const canModifyOptions = !departmentId;

        return (
            <>
                <form className="settings-breaks" id="settings-edit-breaks" onSubmit={this.onSubmit} onChange={this.onChange}>
                    <div className="settings-card-section-title">{title}</div>
                    <div className="settings-breaks-checkboxes">
                        { options && Object.keys(options).map((key) => {
                            const option = options[key];
                            if (!options[key] || options[key].delete) {
                                return null;
                            }
                            return (
                                <div key={key} className="form-control-group" style={{ order: parseInt(key, 10) }}>
                                    <Label check>
                                        <CustomInput
                                            type="checkbox"
                                            id={`checkbox-break-${key}`}
                                            checked={option.active}
                                            onChange={() => this.setStateProp(!option.active, key)}
                                        >
                                            {`${key} ${translate('common.minutes').toLowerCase()}`}
                                        </CustomInput>
                                    </Label>
                                    { canModifyOptions && (
                                        <button
                                            type="button"
                                            className="settings-breaks-button-delete"
                                            onClick={() => this.deleteOption(key)}
                                        >
                                            <Icon color="light-blue" kind="trash" fontSize={20} />
                                        </button>
                                    ) }
                                </div>
                            );
                        })}
                    </div>
                    {canModifyOptions && (
                        <button type="button" className="button-plain button-plain-orange" onClick={() => { this.setState({ showAddForm: !showAddForm }); }}>
                            {`${showAddForm ? '-' : '+'} ${buttonText}`}
                        </button>
                    ) }
                </form>
                {showAddForm && (
                    <form className="settings-breaks-add-option" onSubmit={this.addOption}>
                        <CustomInput
                            type="number"
                            className="form-control"
                            id="checkbox-breaks-new-option"
                            name="checkbox-breaks-new-option"
                            min="0"
                        />
                    </form>
                )}
            </>

        );
    }
}

SettingsBreaks.propTypes = {
    departmentId: PropTypes.string,
    title: PropTypes.string.isRequired,
    buttonText: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    option: PropTypes.object,
};

SettingsBreaks.defaultProps = {
    departmentId: null,
    option: null,
};

export default SettingsBreaks;
