import React, { FC, ReactElement } from 'react';

import { Icon } from '../../../../../../@paco/components';
import { Period } from '../../../../../../@paco/entities/Period/Period';
import trans from '../../../../../../@paco/helpers/trans';
import { DateFormat } from '../../../../../../@paco/types/dateFormatTypes';
import { formatDate } from '../../../../../../helpers/date';

import './UserShiftTrackTime.scss';

interface UserShiftTrackTimeProps {
    shiftPeriod?: Period;
    trackPeriod?: Period;
    className?: string;
}

const UserShiftTrackTime: FC<UserShiftTrackTimeProps> = ({ shiftPeriod, trackPeriod, className = '' }): ReactElement => {
    const shiftStartTime = shiftPeriod ? formatDate(shiftPeriod.start, DateFormat.hoursMinutes) : undefined;
    const shiftEndTime = shiftPeriod ? formatDate(shiftPeriod.end, DateFormat.hoursMinutes) : undefined;
    const trackStartTime = trackPeriod ? formatDate(trackPeriod.start, DateFormat.hoursMinutes) : undefined;
    const trackEndTime = trackPeriod ? formatDate(trackPeriod.end, DateFormat.hoursMinutes) : undefined;

    return (
        <div className={`user-shift-track-time ${className}`}>
            {shiftPeriod ? (
                <div className="user-shift-track-time__time">
                    {`${shiftStartTime} - ${shiftEndTime}`}
                </div>
            ) : (
                <div className="user-shift-track-time__text">
                    {trans('common.unplanned')}
                </div>
            )}
            {trackPeriod && (
                <div className="user-shift-track-time__track-time">
                    {`${trackStartTime} - ${trackEndTime}`}
                    <Icon name="clock-outline" className="user-shift-track-time__icon" />
                </div>
            )}
        </div>
    );
};

export default UserShiftTrackTime;
