import { FC } from 'react';

import { LoadingSpinner } from '../../../../components';
import { EmptyState } from '../../../../compositions';
import { DistrictDivision } from '../../../../entities/DistrictDivision/DistrictDivision';
import { DistrictDivisionTemplate } from '../../../../entities/DistrictDivisionTemplate/DistrictDivisionTemplate';
import { formatDate } from '../../../../helpers/date';
import trans from '../../../../helpers/trans';
import { DateFormat } from '../../../../types/dateFormatTypes';
import { DistrictDivisionSelect, DistrictDivisionTemplateSelect } from '../index';

import './DistrictDivisionEmptyState.scss';

interface DistrictDivisionSelectProps {
    isAddDistrictDivisionLoading: boolean;
    isDistrictDivisionTemplateLoading: boolean;
    isDistrictDivisionLoading: boolean;
    showDistrictDivisionTemplatesLink: boolean;
    showTemplateSelector: boolean;
    showDistrictDivisionSelector: boolean;
    date: Date;
    districtDivisions: DistrictDivision[];
    districtDivisionTemplates: DistrictDivisionTemplate[];
    onSelectDistrictDivision: (districtDivision: DistrictDivision) => void;
    onSelectDistrictDivisionTemplate: (districtDivisionTemplate: DistrictDivisionTemplate) => void;
    className?: string;
}

const DistrictDivisionEmptyState: FC<DistrictDivisionSelectProps> = ({
    isAddDistrictDivisionLoading,
    isDistrictDivisionLoading,
    isDistrictDivisionTemplateLoading,
    showDistrictDivisionTemplatesLink,
    showTemplateSelector,
    showDistrictDivisionSelector,
    date,
    districtDivisions,
    districtDivisionTemplates,
    onSelectDistrictDivision,
    onSelectDistrictDivisionTemplate,
    className = '',
}) => {
    if (isAddDistrictDivisionLoading) {
        return (
            <EmptyState
                title={trans('containers.districtDivisionContainer.addingNewDistrictDivisionTitle')}
                intro={trans('containers.districtDivisionContainer.addingNewDistrictDivisionIntro', { date: formatDate(date, DateFormat.dateLabel) })}
                className={`district-division-empty-state ${className}`}
            >
                <LoadingSpinner isSmall className="district-division-empty-state__loader" />
            </EmptyState>
        );
    }

    if (isDistrictDivisionLoading || isDistrictDivisionTemplateLoading) {
        return (
            <EmptyState
                title={trans('containers.districtDivisionContainer.loadingTitle')}
                intro={trans('containers.districtDivisionContainer.loadingIntro', { date: formatDate(date, DateFormat.dateLabel) })}
                className={`district-division-empty-state ${className}`}
            >
                <LoadingSpinner isSmall className="district-division-empty-state__loader" />
            </EmptyState>
        );
    }

    if (districtDivisions.length === 0) {
        return (
            <DistrictDivisionTemplateSelect
                isLoading={isDistrictDivisionTemplateLoading}
                showDistrictDivisionTemplatesLink={showDistrictDivisionTemplatesLink}
                showSelector={showTemplateSelector}
                districtDivisionTemplates={districtDivisionTemplates}
                onSelectDistrictDivisionTemplate={onSelectDistrictDivisionTemplate}
                className={className}
            />
        );
    }

    return (
        <div className={`district-division-empty-state ${className}`}>
            <DistrictDivisionSelect
                isLoading={isDistrictDivisionLoading}
                showSelector={showDistrictDivisionSelector}
                date={date}
                districtDivisions={districtDivisions}
                onSelectDistrictDivision={onSelectDistrictDivision}
                className="district-division-empty-state__empty-state"
            />
            <DistrictDivisionTemplateSelect
                hasSelectSecondaryStyling
                isLoading={isDistrictDivisionTemplateLoading}
                showDistrictDivisionTemplatesLink={showDistrictDivisionTemplatesLink}
                showSelector={showTemplateSelector}
                districtDivisionTemplates={districtDivisionTemplates}
                onSelectDistrictDivisionTemplate={onSelectDistrictDivisionTemplate}
                className="district-division-empty-state__empty-state--is-inconspicuous"
            />
        </div>
    );
};

export default DistrictDivisionEmptyState;
