import {
    FC,
    FormEvent,
    ReactElement,
    useRef,
    useState,
} from 'react';

import { FormActionButtons, TextInput } from '../../../compositions';
import { AddCommentFormData, commentRelationshipKeys, commentRelationshipTypes } from '../../../entities/Comment/Comment';
import trans from '../../../helpers/trans';
import { ModalFormProps } from '../../../types/modalFormTypes';

import './AddCommentForm.scss';

interface AddCommentFormProps extends ModalFormProps<AddCommentFormData> {
    relationshipKey: commentRelationshipKeys;
    relationshipType: commentRelationshipTypes;
    relationshipId: string;
}

const AddCommentForm: FC<AddCommentFormProps> = ({
    isLoading,
    relationshipKey,
    relationshipType,
    relationshipId,
    onSubmit,
    onCancel,
}): ReactElement => {
    const [comment, setComment] = useState('');
    const [formError, setFormError] = useState<boolean>();

    const formRef = useRef<HTMLFormElement>(null);

    const handleSubmit = (e: FormEvent<HTMLFormElement>): void => {
        e.preventDefault();

        setFormError(!comment.length);

        if (comment.length) {
            onSubmit({
                relationshipKey,
                relationshipType,
                relationshipId,
                comment,
            });
        }
    };

    return (
        <form ref={formRef} onSubmit={handleSubmit} className="add-comment-form">
            <TextInput
                label={trans('common.comment')}
                value={comment}
                placeholder={trans('containers.forms.addCommentForm.addComment')}
                type="text"
                error={formError ? trans('containers.forms.addCommentForm.addComment') : undefined}
                onChange={setComment}
                className="add-comment-form__col"
            />
            <FormActionButtons
                isLoading={isLoading}
                submitText={trans('common.modify')}
                onCancelClick={onCancel}
            />
        </form>
    );
};

export default AddCommentForm;
