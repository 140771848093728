import React, { FC, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'reactstrap';

import { IconButton } from '../../@paco/compositions';
import { ConnectedHelpTooltip } from '../../@paco/connectors';
import trans from '../../@paco/helpers/trans';
import { useTypedSelector } from '../../@paco/redux/store';
import DeleteResourceForm from '../../components/forms/DeleteResourceForm/DeleteResourceForm';
import ItemsList from '../../components/ItemsList/ItemsList';
import Icon from '../../components/style/Icon/Icon';
import TableContainer from '../../components/Table/TableContainer';
import { checkPermission, getYearsFromPeriods } from '../../helpers';
import { formatDate } from '../../helpers/date';
import { translate } from '../../helpers/translations/translator';
import { PayrollPeriodFormData, PayrollPeriodViewModel } from '../../models';
import { getPayrollPeriods } from '../../redux/app/appActions';
import { Reducers } from '../../redux/reducers';
import { deletePeriod, setPeriod } from '../../redux/settings/settingsActions';
import getPayrollPeriodEndDateLabel from '../../services/PayrollPeriodService/getPayrollPeriodEndDateLabel';
import { BasicSelectOption } from '../Schedule/components/CalendarToolbar/BasicSelect/BasicSelect';
import { PeriodYearSelect } from './components/PeriodYearSelect/PeriodYearSelect';
import { SettingsCardTitle } from './components/SettingsCard/SettingsCardTitle';
import AddOrEditPeriodForm from './forms/AddOrEditPeriodForm';

import './Periods.scss';


const Periods: FC = () => {
    const dispatch = useDispatch();
    const periods = useSelector((state: Reducers) => state.appReducer.payrollPeriods);
    const { permissions } = useTypedSelector(state => state.authenticatedUserReducer);
    const loading = useSelector((state: Reducers) => (
        state.appReducer.payrollPeriodsLoading || state.settingsReducer.loading));

    const [activeYear, setActiveYear] = useState(new Date().getUTCFullYear());
    const [years, setYears] = useState<number[]>([]);
    const [addOrEditPeriodForm, setAddOrEditPeriodForm] = useState(false);
    const [deletePeriodForm, setDeletePeriodForm] = useState(false);
    const [activePeriod, setActivePeriod] = useState<PayrollPeriodViewModel | null>(null);

    const filteredPeriods = periods
        .filter((period: PayrollPeriodViewModel) => period.year === activeYear)
        .sort((
            a: PayrollPeriodViewModel,
            b: PayrollPeriodViewModel,
        ) => a.periodNumber - b.periodNumber);
    const yearOptions: BasicSelectOption[] = years.map(year => ({ label: year, value: year }));
    const periodTranslation = translate('common.period');
    const canEditPeriods = checkPermission(permissions, 'edit-all-payroll-periods', 'periods');
    const canAddPeriods = checkPermission(permissions, 'add-new-payroll-periods', 'periods');

    useEffect(() => {
        dispatch(getPayrollPeriods());
    }, []);

    useEffect(() => {
        setYears(getYearsFromPeriods(periods));
    }, [periods]);

    const onEditPeriodClick = (period: PayrollPeriodViewModel) => {
        setActivePeriod(period);
        setAddOrEditPeriodForm(true);
    };

    const onPeriodYearChange = (value: number) => {
        setActiveYear(value);
    };

    const onAddPeriodClick = () => {
        setAddOrEditPeriodForm(true);
    };

    const onDeletePeriodClick = (period: PayrollPeriodViewModel) => {
        setActivePeriod(period);
        setDeletePeriodForm(true);
    };

    const cancelPeriodForm = () => {
        setAddOrEditPeriodForm(false);
        setDeletePeriodForm(false);
        setActivePeriod(null);
    };

    const dispatchDeletePeriod = () => {
        if (activePeriod) {
            dispatch(deletePeriod(activePeriod.id));
        }
        cancelPeriodForm();
    };

    const dispatchSetPeriod = (data: PayrollPeriodFormData, id?: string) => {
        dispatch(setPeriod(data, id));
        cancelPeriodForm();
    };

    const generateButtons = (period: PayrollPeriodViewModel) => {
        if (period.locked || !canEditPeriods) {
            return null;
        }

        return (
            <>
                <button type="button" onClick={() => onEditPeriodClick(period)} className="clear-button">
                    <Icon color="light-blue" kind="edit" />
                </button>
                <button type="button" onClick={() => onDeletePeriodClick(period)} className="clear-button">
                    <Icon color="light-blue" kind="trash" />
                </button>
            </>
        );
    };

    const renderTable = () => {
        const dateFormat = 'EEEEEE d MMM yyyy';
        const tableAttributes = [
            {
                label: translate('common.year'),
                customAction: (period: PayrollPeriodViewModel) => period.year,
                className: 'settings-period-table-td-year',
            },
            {
                label: translate('common.number'),
                customAction: (period: PayrollPeriodViewModel) => period.periodNumber,
                className: 'settings-period-table-td-number',
            },
            {
                label: translate('pages.tracks.startDate'),
                customAction: (period: PayrollPeriodViewModel) => formatDate(period.start, dateFormat),
                className: 'settings-period-table-td-start',
            },
            {
                label: translate('pages.tracks.endDate'),
                customAction: (period: PayrollPeriodViewModel) => getPayrollPeriodEndDateLabel(period, dateFormat),
                className: 'settings-period-table-td-end',
            },
            {
                label: '',
                customAction: (period: PayrollPeriodViewModel) => generateButtons(period),
                className: 'settings-period-table-td-edit-delete',
            },
        ];

        return (
            <>
                <div className={`card settings-card settings-period settings-period--length-${filteredPeriods.length}`}>
                    <SettingsCardTitle title={translate('pages.settings.periods')}>
                        <PeriodYearSelect
                            selected={activeYear}
                            options={yearOptions}
                            onSelectChange={onPeriodYearChange}
                        />
                    </SettingsCardTitle>
                    <ItemsList
                        itemsLength={periods.length}
                        loading={loading}
                    >
                        <TableContainer
                            className="settings-period-table"
                            attributes={tableAttributes}
                            data={filteredPeriods}
                        />
                    </ItemsList>
                </div>
                <Modal size="lg" isOpen={addOrEditPeriodForm} className="form-settings-add-or-edit-period">
                    <AddOrEditPeriodForm
                        data={activePeriod}
                        activeYear={activeYear}
                        onCancel={cancelPeriodForm}
                        onSubmit={dispatchSetPeriod}
                    />
                </Modal>
                <Modal size="lg" isOpen={deletePeriodForm} className="form-settings-delete-period">
                    <DeleteResourceForm
                        title={translate('common.deleteResource', { resource: periodTranslation })}
                        intro={translate('common.deleteResourceIntro', { resource: periodTranslation.toLowerCase() })}
                        onSubmit={dispatchDeletePeriod}
                        onCancel={cancelPeriodForm}
                    />
                </Modal>
            </>
        );
    };

    return (
        <div className="periods-page">
            <ConnectedHelpTooltip
                index={0}
                route="periods"
                subTitle={trans('help.settings.periods.title')}
                text={trans('help.settings.periods.text')}
                title={trans('help.settings.title')}
                className="periods-page__help-tooltip"
            />
            <div className="periods-page__top-bar">
                <div className="periods-page__title">
                    {translate('pages.settings.setPeriods')}
                </div>
                {canAddPeriods && (
                    <>
                        <IconButton
                            icon="plus"
                            text={translate('pages.settings.addPeriod')}
                            onClick={onAddPeriodClick}
                            className="periods-page__add-button"
                        />
                        <IconButton
                            hideLabel
                            icon="plus"
                            text={translate('pages.settings.addPeriod')}
                            onClick={onAddPeriodClick}
                            className="periods-page__mobile-add-button"
                        />
                    </>
                )}
            </div>
            {renderTable()}
        </div>
    );
};

export default Periods;
