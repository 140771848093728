import { PrivateRouteGroupRoutes } from '../../../components/PrivateRouteGroup/PrivateRouteGroupRoutes';
import { ManagementRoutes } from '../../../pages/Management/ManagementRoutes';
import { ScheduleRoutes } from '../../../pages/Schedule/ScheduleRoutes';
import { TrackRoutes } from '../../../pages/Tracks/TrackRoutes';
import { formatDate } from '../../helpers/date';
import trans from '../../helpers/trans';
import { DateFormat } from '../../types/dateFormatTypes';
import { EventLog, EventLogEntityType } from './EventLog';

export const getEventLogEntityLink = (log: EventLog): string | undefined => {
    if (log.entityType === EventLogEntityType.shift) {
        return `${PrivateRouteGroupRoutes.shifts()}/${ScheduleRoutes.shift(log.entityUuid)}`;
    }

    if (log.entityType === EventLogEntityType.track) {
        const dateString = formatDate(log.updatedAt, DateFormat.apiDate);

        return `${PrivateRouteGroupRoutes.tracks()}/${TrackRoutes.finished(log.entityUuid, dateString)}`;
    }

    if (log.entityType === EventLogEntityType.user) {
        return `${PrivateRouteGroupRoutes.management()}/${ManagementRoutes.user(log.entityUuid)}`;
    }

    return undefined;
};

export const getEventLogEntityTranslation = (type: EventLogEntityType): string => {
    if (type === EventLogEntityType.shift) {
        return trans('common.shift');
    }

    if (type === EventLogEntityType.track) {
        return trans('common.track');
    }

    if (type === EventLogEntityType.user) {
        return trans('common.user');
    }

    return trans('common.entity');
};
