import { FC, useEffect } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import { PrivateRouteGroupRoutes } from '../../../components/PrivateRouteGroup/PrivateRouteGroupRoutes';
import { ScheduleRoutes } from '../../../pages/Schedule/ScheduleRoutes';
import { ShiftsCalendar } from '../../containers';
import { addDays, getEndOfWorkDay, getStartOfWorkDay } from '../../helpers/date';
import useCheckPermission from '../../helpers/hooks/useCheckPermission';
import usePermissions from '../../helpers/hooks/usePermissions';
import { setDeletedShiftId, setIsDeleteShiftSuccessful } from '../../redux/shift/shiftReducer';
import {
    fetchShifts,
    fetchShiftsCalendarAbsences,
    fetchShiftsCalendarLeaveOfAbsences,
    fetchShiftsCalendarUnavailableToWorkTimeSlots,
} from '../../redux/shiftsCalendar/shiftsCalendarActions';
import { setShifts } from '../../redux/shiftsCalendar/shiftsCalendarReducer';
import { fetchSpecialDays } from '../../redux/specialDays/specialDaysActions';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';

interface ConnectedShiftsCalendarProps {
    onNavigationChange: () => void;
}

const ConnectedShiftsCalendar: FC<ConnectedShiftsCalendarProps> = ({ onNavigationChange }) => {
    const navigate = useNavigate();
    const dispatch = useTypedDispatch();
    const location = useLocation();
    const permissions = usePermissions();

    const canViewAbsences = useCheckPermission('view-all-absences', 'connected-shifts-calendar');
    const canViewLeaveOfAbsences = useCheckPermission('view-all-leave-of-absences', 'connected-shifts-calendar');
    const canViewUnavailableToWorkTimeSlots = useCheckPermission('view-unavailable-to-work-time-slots', 'connected-shifts-calendar');
    const canViewSpecialDays = useCheckPermission('view-all-special-days', 'connected-shifts-calendar');

    const { selectedDays, selectedMobileDay, timeMode } = useTypedSelector(state => state.calendarReducer);
    const {
        departmentOptions,
        userSearch,
        showEmployees,
        showEmploymentTypes,
        onlyShowShiftsWithAbsence,
        onlyShowShiftsWithOpenLeaveOfAbsence,
    } = useTypedSelector(state => state.globalFiltersReducer);

    const {
        isLoading: shiftIsLoading,
        isCopyShiftSuccessful,
        isDeleteShiftSuccessful,
        deletedShiftId,
    } = useTypedSelector(state => state.shiftReducer);
    const {
        isLoading: shiftsIsLoading,
        shifts,
        shiftsCalendarAbsences,
        shiftsCalendarLeaveOfAbsences,
        shiftsCalendarUnavailableToWorkTimeSlots,
    } = useTypedSelector(state => state.shiftsCalendarReducer);
    const { specialDays } = useTypedSelector(state => state.pacoSpecialDaysReducer);

    const isLoading = shiftIsLoading || shiftsIsLoading;
    const startDate = getStartOfWorkDay(selectedDays[0]);
    const endDate = getEndOfWorkDay(selectedDays[selectedDays.length - 1]);
    const departments = departmentOptions
        .filter(department => department.isChecked)
        .map(filteredDepartment => filteredDepartment.value);

    const getShifts = (): void => {
        dispatch(fetchShifts(
            startDate,
            endDate,
            departments,
            userSearch,
        ));
    };

    useEffect((): void => {
        getShifts();
    }, [
        selectedDays,
        departmentOptions,
        userSearch,
        location,
    ]);

    useEffect((): void => {
        if (isCopyShiftSuccessful) {
            getShifts();
        }
    }, [isCopyShiftSuccessful]);

    useEffect((): void => {
        if (canViewSpecialDays) dispatch(fetchSpecialDays(addDays(startDate, -1), endDate));

        if (!shifts.length) {
            return;
        }

        if (canViewLeaveOfAbsences) dispatch(fetchShiftsCalendarLeaveOfAbsences(startDate, endDate));
        if (canViewAbsences) dispatch(fetchShiftsCalendarAbsences(startDate, endDate));
        if (canViewUnavailableToWorkTimeSlots) dispatch(fetchShiftsCalendarUnavailableToWorkTimeSlots(startDate, endDate));
    }, [shifts]);

    useEffect((): void => {
        if (isDeleteShiftSuccessful) {
            dispatch(setShifts(shifts.filter(shift => shift.id !== deletedShiftId)));
            dispatch(setDeletedShiftId(undefined));
            dispatch(setIsDeleteShiftSuccessful(false));
        }
    }, [isDeleteShiftSuccessful]);

    const handleShiftClick = (shiftId: string): void => {
        navigate(`${PrivateRouteGroupRoutes.shifts()}/${ScheduleRoutes.shift(shiftId)}`);
    };

    return (
        <ShiftsCalendar
            isLoading={isLoading}
            onlyShowShiftsWithAbsence={onlyShowShiftsWithAbsence}
            onlyShowShiftsWithOpenLeaveOfAbsence={onlyShowShiftsWithOpenLeaveOfAbsence}
            showEmployees={showEmployees}
            showEmploymentTypes={showEmploymentTypes}
            days={selectedDays}
            mobileDay={selectedMobileDay}
            absences={shiftsCalendarAbsences}
            leaveOfAbsences={shiftsCalendarLeaveOfAbsences}
            permissions={permissions}
            shifts={shifts}
            specialDays={specialDays}
            timeMode={timeMode}
            unavailableToWorkTimeSlots={shiftsCalendarUnavailableToWorkTimeSlots}
            onShiftClick={handleShiftClick}
            onNavigationChange={onNavigationChange}
        />
    );
};

export default ConnectedShiftsCalendar;
