import React, { FC } from 'react';

import { ConnectedDepartmentsFilter } from '../../@paco/connectors';
import { Permission } from '../../@paco/entities/Permission/Permission';
import { DocumentTypeViewModel, EmploymentType, Role } from '../../models';
import { initialState } from '../../redux/filter/filterReducer';
import { AppFilter, AppFilterType } from '../../redux/filter-ts/filterModels';
import ButtonReset from './subcomponents/ButtonReset/ButtonReset';
import CustomFilter from './subcomponents/CustomFilter/CustomFilter';
import FilterBlock from './subcomponents/FilterBlock/FilterBlock';

import './Filters.scss';

interface FiltersProps {
    showDepartments?: boolean;
    showResetButton?: boolean;
    filter: AppFilter;
    roles?: Role[];
    permissions?: Permission[];
    employmentTypes?: EmploymentType[];
    documentTypes?: DocumentTypeViewModel[];
    filterBlocks?: AppFilterType[];
    button?: React.ReactNode;
    onChange: (filter: AppFilter) => void;
    children?: React.ReactNode;
}

const Filter: FC<FiltersProps> = ({
    showDepartments = true,
    showResetButton = true,
    filter,
    roles = [],
    permissions = [],
    employmentTypes = [],
    documentTypes = [],
    filterBlocks = [],
    button,
    onChange,
    children,
}) => {
    const onButtonResetClick = () => {
        const departmentOptions = filter.departmentOptions.map(option => ({
            ...option,
            isChecked: false,
        }));

        // @ts-ignore
        onChange({
            ...initialState.filter,
            departmentOptions,
        });
    };

    const onFilterChange = (newFilter: AppFilter) => {
        onChange(newFilter);
    };

    return (
        <div className="filters">
            { button && (
                <FilterBlock hideMobile>
                    {button}
                </FilterBlock>
            )}
            { showResetButton && (
                <FilterBlock>
                    <ButtonReset onButtonResetClick={onButtonResetClick} />
                </FilterBlock>
            )}
            { children }
            { filterBlocks.map((type) => (
                <CustomFilter
                    key={type}
                    filter={filter}
                    type={type}
                    roles={roles}
                    permissions={permissions}
                    employmentTypes={employmentTypes}
                    documentTypes={documentTypes}
                    onChange={onFilterChange}
                />
            ))}
            { showDepartments && (
                <FilterBlock>
                    <ConnectedDepartmentsFilter />
                </FilterBlock>
            )}
        </div>
    );
};

export default Filter;
