import { UserDetailRoutes } from '../../../pages/Management/ManagementRoutes';
import trans from '../../helpers/trans';
import { SubNavRoute } from '../routes';

export type UserDetailNavigationRouteKey =
    'user-personal-info' |
    'user-absences' |
    'user-documents' |
    'user-loket-details' |
    'user-calendar' |
    'user-configurable-permissions' |
    'user-notes' |
    'user-feedback';

const userNavigationRoutes: SubNavRoute[] = [
    {
        key: 'user-personal-info',
        path: UserDetailRoutes.personalInfo(),
        label: trans('routes.user.personalInfo.label'),
        permissions: ['view-all-user-profiles'],
    },
    {
        key: 'user-absences',
        path: UserDetailRoutes.absences(),
        label: trans('routes.absences.label'),
        permissions: ['view-all-absences', 'view-all-absence-hours'],
    },
    {
        key: 'user-documents',
        path: UserDetailRoutes.documents(),
        label: trans('routes.pz.documents.label'),
        permissions: ['view-all-files'],
    },
    {
        key: 'user-loket-details',
        path: UserDetailRoutes.couplings(),
        label: trans('routes.user.couplings.label'),
        permissions: ['manage-loket-data'],
    },
    {
        key: 'user-calendar',
        path: UserDetailRoutes.calendar(),
        label: trans('routes.user.calendar.label'),
        permissions: [
            'view-all-absences',
            'view-all-leave-of-absences',
            'view-unavailable-to-work-time-slots',
            'view-all-prefer-to-work-time-slots',
            'view-all-tracks',
        ],
    },
    {
        key: 'user-configurable-permissions',
        path: UserDetailRoutes.configurablePermissions(),
        label: trans('routes.user.configurablePermissions.label'),
        permissions: ['assign-all-permissions'],
    },
    {
        key: 'user-notes',
        path: UserDetailRoutes.notes(),
        label: trans('routes.user.notes.label'),
        permissions: ['view-all-comment-categories'],
    },
    {
        key: 'user-feedback',
        path: UserDetailRoutes.feedback(),
        label: trans('routes.user.feedback.label'),
        permissions: ['view-all-feedback-ratings'],
    },
];

export default userNavigationRoutes;
