import { useEffect, useMemo } from 'react';

import { useEffectOnce } from 'react-use';

import { checkPermission } from '../../../helpers';
import { DistrictDivisionContainer } from '../../containers';
import { Department } from '../../entities/Department/Department';
import { DistrictDivision } from '../../entities/DistrictDivision/DistrictDivision';
import {
    AddDistrictDivisionDistrictPlanningFormData,
    EditDistrictDivisionDistrictPlanningFormData,
    SwapDistrictDivisionDistrictPlanningsFormData,
} from '../../entities/DistrictDivisionDistrictPlanning/DistrictDivisionDistrictPlanning';
import { DistrictDivisionTemplate } from '../../entities/DistrictDivisionTemplate/DistrictDivisionTemplate';
import { transformToPeriod } from '../../entities/Period/PeriodTransformers';
import { getEndOfWorkDay, getStartOfWorkDay, isSameDay } from '../../helpers/date';
import usePermissions from '../../helpers/hooks/usePermissions';
import trans from '../../helpers/trans';
import { setIncrementedSelectedMobileDay, setTimePeriodAndSelectedDays } from '../../redux/@interface/calendar/calendarActions';
import { fetchDepartmentOptions } from '../../redux/@interface/options/optionsActions';
import {
    addDistrictDivision,
    addDistrictDivisionComment,
    deleteDistrictDivision,
    fetchDistrictDivisionAbsences,
    fetchDistrictDivisions,
    fetchShifts,
} from '../../redux/districtDivision/districtDivisionActions';
import {
    setDistrictDivision,
    setDistrictDivisions,
    setIsAddDistrictDivisionSuccessful,
    setIsDeleteDistrictDivisionSuccessful,
    setSelectedDepartments,
} from '../../redux/districtDivision/districtDivisionReducer';
import {
    addDistrictDivisionDistrictPlanning,
    addDistrictDivisionDistrictPlanningComment,
    editDistrictDivisionDistrictPlanning,
    removeDistrictDivisionDistrictPlanning,
    swapDistrictDivisionDistrictPlannings,
} from '../../redux/districtDivisionDistrictPlanning/districtDivisionDistrictPlanningActions';
import { setIsAddPlanningSuccessful, setIsDeletePlanningSuccessful } from '../../redux/districtDivisionDistrictPlanning/districtDivisionDistrictPlanningReducer';
import {
    addDistrictDivisionDistrictTemporaryWorker,
    addDistrictDivisionDistrictTemporaryWorkerComment,
    editDistrictDivisionDistrictTemporaryWorker,
    removeDistrictDivisionDistrictTemporaryWorker,
} from '../../redux/districtDivisionDistrictTemporaryWorker/districtDivisionDistrictTemporaryWorkerActions';
import { getDistrictDivisionTemplates } from '../../redux/districtDivisionTemplates/districtDivisionTemplatesActions';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';
import { setToast } from '../../redux/toasts/toastsReducer';
import { TimeModeType, ToastType } from '../../types';

const ConnectedDistrictDivision = () => {
    const dispatch = useTypedDispatch();
    const permissions = usePermissions();

    const {
        isAddDistrictDivisionCommentLoading,
        isAddDistrictDivisionCommentSuccessful,
        isAddDistrictDivisionLoading,
        isAddDistrictDivisionSuccessful,
        isDeleteDistrictDivisionSuccessful,
        isLoading: isDistrictDivisionLoading,
        isShiftsLoading,
        absences,
        districtDivision,
        districtDivisions,
        selectedDepartments,
        shifts,
    } = useTypedSelector(state => state.districtDivisionReducer);
    const {
        isAddPlanningSuccessful,
        isDeletePlanningSuccessful,
        isLoading: isPlanningLoading,
        districtDivisionDistrictPlanning,
    } = useTypedSelector(state => state.districtDivisionDistrictPlanningReducer);
    const { selectedMobileDay } = useTypedSelector(state => state.calendarReducer);
    const { payrollPeriods } = useTypedSelector(state => state.pacoPayrollPeriodsReducer);
    const { settings } = useTypedSelector(state => state.pacoSettingsReducer);
    const { isLoading: isDistrictDivisionTemplatesLoading, districtDivisionTemplates } = useTypedSelector(state => state.districtDivisionTemplatesReducer);
    const { departmentOptions, isLoading: isDepartmentsLoading } = useTypedSelector(state => state.optionsReducer);

    const canViewAllShifts = useMemo(() => checkPermission(permissions, 'view-all-shifts'), [permissions]);
    const canViewAllAbsences = useMemo(() => checkPermission(permissions, 'view-all-absences'), [permissions]);

    useEffectOnce((): void => {
        if (departmentOptions.length === 0) {
            dispatch(fetchDepartmentOptions());
        }

        dispatch(getDistrictDivisionTemplates());
    });

    useEffect((): void => {
        if (canViewAllShifts) {
            dispatch(fetchShifts(
                getStartOfWorkDay(selectedMobileDay),
                getEndOfWorkDay(selectedMobileDay),
                selectedDepartments.map(department => department.id),
            ));
        }
    }, [selectedMobileDay, selectedDepartments]);

    useEffect((): void => {
        // When user returns to page and districtDivision and date is the same. Then don't clear districtDivision
        if (districtDivision?.date && isSameDay(districtDivision.date, selectedMobileDay)) {
            return;
        }

        dispatch(setDistrictDivision(undefined));
        dispatch(fetchDistrictDivisions(selectedMobileDay));
    }, [selectedMobileDay]);

    useEffect((): void => {
        const period = transformToPeriod(getStartOfWorkDay(selectedMobileDay), getEndOfWorkDay(selectedMobileDay));

        if (canViewAllAbsences && shifts.length) {
            dispatch(fetchDistrictDivisionAbsences(period));
        }
    }, [shifts]);

    useEffect((): void => {
        if (isAddDistrictDivisionSuccessful) {
            dispatch(setToast({
                text: trans('containers.districtDivisionContainer.addDistrictDivisionSuccess'),
                type: ToastType.pass,
                shouldFadeOutAutomatically: true,
            }));
            dispatch(fetchDistrictDivisions(selectedMobileDay));
            dispatch(setIsAddDistrictDivisionSuccessful(false));
        }
    }, [isAddDistrictDivisionSuccessful]);

    useEffect((): void => {
        if (isAddPlanningSuccessful) {
            dispatch(setIsAddPlanningSuccessful(false));

            if (districtDivisionDistrictPlanning?.comments.length) {
                if (districtDivisionDistrictPlanning.type === 'districtDivisionDistrictPlannings') {
                    dispatch(addDistrictDivisionDistrictPlanningComment(districtDivisionDistrictPlanning.id, districtDivisionDistrictPlanning.comments[0].body, true));
                }

                if (districtDivisionDistrictPlanning.type === 'districtDivisionDistrictTemporaryWorkers') {
                    dispatch(addDistrictDivisionDistrictTemporaryWorkerComment(districtDivisionDistrictPlanning.id, districtDivisionDistrictPlanning.comments[0].body, true));
                }
            }
        }
    }, [isAddPlanningSuccessful]);

    useEffect((): void => {
        if (isDeletePlanningSuccessful) {
            dispatch(setIsDeletePlanningSuccessful(false));
        }
    }, [isDeletePlanningSuccessful]);

    useEffect((): void => {
        if (isDeleteDistrictDivisionSuccessful) {
            dispatch(setToast({
                text: trans('containers.districtDivisionContainer.deleteDistrictDivisionSuccess'),
                type: ToastType.pass,
                shouldFadeOutAutomatically: true,
            }));
            dispatch(setIsDeleteDistrictDivisionSuccessful(false));
        }
    }, [isDeleteDistrictDivisionSuccessful]);

    const handleBackButtonClick = (): void => {
        if (!districtDivision) {
            return;
        }

        const districtDivisionsIndex = districtDivisions.findIndex(division => division.id === districtDivision.id);
        const updatedDistrictDivisions = [...districtDivisions];
        updatedDistrictDivisions.splice(districtDivisionsIndex, 1, districtDivision);

        dispatch(setDistrictDivision(undefined));
        dispatch(setDistrictDivisions(updatedDistrictDivisions));
    };

    const handleClearDistrictDivisionButtonClick = (): void => {
        if (districtDivision) {
            dispatch(deleteDistrictDivision(districtDivision.id));
        }
    };

    const handleDateChange = (date: Date): void => {
        dispatch(setTimePeriodAndSelectedDays(TimeModeType.date, date, payrollPeriods));
    };

    const handleDepartmentChange = (departments: Department[]): void => {
        dispatch(setSelectedDepartments(departments));
    };

    const handleSubmitAddDistrictDivisionDistrictPlanning = (formData: AddDistrictDivisionDistrictPlanningFormData): void => {
        if (formData.type === 'shiftPlannings') {
            dispatch(addDistrictDivisionDistrictPlanning(formData));
        }

        if (formData.type === 'temporaryWorkers') {
            dispatch(addDistrictDivisionDistrictTemporaryWorker(formData));
        }
    };

    const handleSubmitEditDistrictDivisionDistrictPlanningOrder = (formData: EditDistrictDivisionDistrictPlanningFormData): void => {
        if (formData.type === 'districtDivisionDistrictPlannings') {
            dispatch(editDistrictDivisionDistrictPlanning(formData));
        }

        if (formData.type === 'districtDivisionDistrictTemporaryWorkers') {
            dispatch(editDistrictDivisionDistrictTemporaryWorker(formData));
        }
    };

    const handleSubmitSwapDistrictDivisionDistrictsPlanningOrder = (formData: SwapDistrictDivisionDistrictPlanningsFormData): void => {
        dispatch(swapDistrictDivisionDistrictPlannings(formData));
    };

    const handleDistrictDivisionDistrictPlanningDeleteButtonClick = (districtDivisionDistrictPlanningId: string, type: string): void => {
        if (type === 'districtDivisionDistrictPlannings') {
            dispatch(removeDistrictDivisionDistrictPlanning(districtDivisionDistrictPlanningId));
        }

        if (type === 'districtDivisionDistrictTemporaryWorkers') {
            dispatch(removeDistrictDivisionDistrictTemporaryWorker(districtDivisionDistrictPlanningId));
        }
    };

    const handleSelectDistrictDivision = (district: DistrictDivision): void => {
        dispatch(setDistrictDivision(district));
    };

    const handleSelectDistrictDivisionTemplate = (district: DistrictDivisionTemplate): void => {
        dispatch(addDistrictDivision(district.id, selectedMobileDay));
    };

    const handleDateIncrementClick = (goForward: boolean): void => {
        dispatch(setIncrementedSelectedMobileDay(TimeModeType.date, selectedMobileDay, goForward, payrollPeriods));
    };

    const handleSaveDistrictDivisionButtonClick = (comment: string): void => {
        if (districtDivision) {
            dispatch(addDistrictDivisionComment(districtDivision, comment));
        }
    };

    return (
        <DistrictDivisionContainer
            isAddDistrictDivisionLoading={isAddDistrictDivisionLoading}
            isDepartmentsLoading={isDepartmentsLoading}
            isDistrictDivisionLoading={isDistrictDivisionLoading || isPlanningLoading}
            isDistrictDivisionTemplateLoading={isDistrictDivisionTemplatesLoading}
            isAddDistrictDivisionCommentLoading={isAddDistrictDivisionCommentLoading}
            isAddDistrictDivisionCommentSuccessful={isAddDistrictDivisionCommentSuccessful}
            isShiftsLoading={isShiftsLoading}
            absences={absences}
            activeDistrictDivision={districtDivision}
            departmentOptions={departmentOptions}
            districtDivisions={districtDivisions}
            districtDivisionTemplates={districtDivisionTemplates}
            permissions={permissions}
            selectedDate={selectedMobileDay}
            selectedDepartments={selectedDepartments}
            settings={settings}
            shifts={shifts}
            onBackButtonClick={handleBackButtonClick}
            onClearDistrictDivisionButtonClick={handleClearDistrictDivisionButtonClick}
            onDateChange={handleDateChange}
            onDateIncrementClick={handleDateIncrementClick}
            onDepartmentChange={handleDepartmentChange}
            onDistrictDivisionDistrictPlanningDeleteButtonClick={handleDistrictDivisionDistrictPlanningDeleteButtonClick}
            onSelectDistrictDivision={handleSelectDistrictDivision}
            onSelectDistrictDivisionTemplate={handleSelectDistrictDivisionTemplate}
            onSaveDistrictDivisionButtonClick={handleSaveDistrictDivisionButtonClick}
            onSubmitAddDistrictDivisionDistrictPlanning={handleSubmitAddDistrictDivisionDistrictPlanning}
            onSubmitEditDistrictDivisionDistrictPlanningOrder={handleSubmitEditDistrictDivisionDistrictPlanningOrder}
            onSubmitSwapDistrictDivisionDistrictPlanningsOrder={handleSubmitSwapDistrictDivisionDistrictsPlanningOrder}
        />
    );
};

export default ConnectedDistrictDivision;
