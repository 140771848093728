import { FC, ReactElement } from 'react';

import { useToggle } from 'react-use';

import { FilterSection } from '../../../components';
import { IconButton, ModalContent, PacoModal } from '../../../compositions';
import {
    ConnectedAddShiftForm,
    ConnectedDepartmentsFilter,
    ConnectedEmploymentStatusFilter,
    ConnectedResetFiltersButton,
    ConnectedShowEmployeesFilter,
    ConnectedShowEmploymentTypesFilter,
} from '../../../connectors';
import { Permission } from '../../../entities/Permission/Permission';
import checkPermission from '../../../helpers/permissions/checkPermission';
import trans from '../../../helpers/trans';

import './ShiftsCalendarFilterContainer.scss';

interface ShiftsCalendarFilterContainerProps {
    permissions: Permission[];
    className?: string;
}

const ShiftsCalendarFilterContainer: FC<ShiftsCalendarFilterContainerProps> = ({ permissions, className = '' }): ReactElement => {
    const [addShiftModalIsOpen, toggleAddShiftModalIsOpen] = useToggle(false);
    const canAddNewShifts = checkPermission(permissions, 'add-new-shifts', 'shifts-calendar-filter-container');

    return (
        <>
            <form className={`shifts-calendar-filter-container ${className}`}>
                {canAddNewShifts && (
                    <FilterSection>
                        <IconButton
                            icon="plus"
                            text={trans('common.addShift')}
                            onClick={toggleAddShiftModalIsOpen}
                            className="shifts-calendar-filter-container__add-shift-button"
                        />
                    </FilterSection>
                )}
                <FilterSection>
                    <ConnectedResetFiltersButton />
                </FilterSection>
                <FilterSection>
                    <ConnectedShowEmployeesFilter />
                </FilterSection>
                <FilterSection>
                    <ConnectedEmploymentStatusFilter />
                </FilterSection>
                <FilterSection>
                    <ConnectedShowEmploymentTypesFilter />
                </FilterSection>
                <FilterSection>
                    <ConnectedDepartmentsFilter />
                </FilterSection>
            </form>
            {addShiftModalIsOpen && (
                <PacoModal>
                    <ModalContent title={trans('common.addShift')}>
                        <ConnectedAddShiftForm onClose={toggleAddShiftModalIsOpen} />
                    </ModalContent>
                </PacoModal>
            )}
        </>
    );
};

export default ShiftsCalendarFilterContainer;
