import { formatDate } from '../../../../helpers/date';
import { PayrollPeriod } from '../../../entities/PayrollPeriod/PayrollPeriod';
import { getPayrollPeriodEndDateLabel } from '../../../entities/PayrollPeriod/PayrollPeriodHelpers';
import trans from '../../../helpers/trans';
import { SelectOption } from '../../../types';

export const generateNumberSelectOptions = (
    periods: PayrollPeriod[],
    hasPeriodTitle = false,
): SelectOption[] => periods
    .map(payrollPeriod => {
        const title = hasPeriodTitle ? (
            `${trans('common.period')} ${payrollPeriod.periodNumber}:`
        ) : (payrollPeriod.periodNumber);

        return {
            value: payrollPeriod.id,
            label: `${title} (${formatDate(payrollPeriod.period.start, 'd MMMM')} - ${getPayrollPeriodEndDateLabel(payrollPeriod, 'd MMMM')})`,
        };
    });

export const generateYearSelectOptions = (years: number[]): SelectOption[] => years.map(year => ({
    value: year.toString(),
    label: year.toString(),
}));
