import React, { FC, ReactElement, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useTypedSelector } from '../../../../@paco/redux/store';
import { PrivateRouteGroupRoutes } from '../../../../components/PrivateRouteGroup/PrivateRouteGroupRoutes';
import { BaseScheduleFormData } from '../../../../entities/BaseSchedule/BaseSchedule';
import { checkPermission } from '../../../../helpers';
import { createBaseSchedule } from '../../../../redux/@toolkit/baseSchedule/baseScheduleActions';
import { setIsSuccessful } from '../../../../redux/@toolkit/baseSchedule/baseScheduleReducer';
import { Reducers } from '../../../../redux/reducers';
import { TypedDispatch } from '../../../../redux/store';
import BaseSchedulesSidebar from '../../containers/BaseSchedules/BaseSchedulesSidebar/BaseSchedulesSidebar';
import { ScheduleRoutes } from '../../ScheduleRoutes';

const ConnectedBaseSchedulesSidebar: FC = (): ReactElement => {
    const navigate = useNavigate();
    const dispatch = useDispatch<TypedDispatch>();

    const { isSuccessful, baseSchedule } = useSelector((state: Reducers) => state.baseScheduleReducer);
    const { permissions } = useTypedSelector(state => state.authenticatedUserReducer);

    const canAddBaseSchedules = checkPermission(permissions, 'add-new-base-schedules', 'connected-base-schedules-sidebar');

    const handleAddBaseSchedule = (formData: BaseScheduleFormData) => {
        dispatch(createBaseSchedule(formData));
    };

    useEffect((): void => {
        if (isSuccessful && baseSchedule) {
            dispatch(setIsSuccessful(false));
            navigate(`${PrivateRouteGroupRoutes.shifts()}/${ScheduleRoutes.baseSchedule(baseSchedule.id)}`);
        }
    }, [isSuccessful]);

    return (
        <BaseSchedulesSidebar
            canAddBaseSchedules={canAddBaseSchedules}
            onBaseScheduleSubmit={handleAddBaseSchedule}
        />
    );
};

export default ConnectedBaseSchedulesSidebar;
