import { FC, ReactElement } from 'react';

import { Checkbox, InputLabel } from '../../../components';
import trans from '../../../helpers/trans';

import './EmploymentStatusFilterContainer.scss';

interface EmploymentStatusFilterContainerProps {
    showAllShiftsWithAbsences: boolean;
    showAllShiftsWithOpenLeaveOfAbsences: boolean;
    onShowAllShiftsWithAbsencesChange: (show: boolean) => void;
    onShowAllShiftsWithOpenLeaveOfAbsencesChange: (show: boolean) => void;
    className?: string;
}

const EmploymentStatusFilterContainer: FC<EmploymentStatusFilterContainerProps> = ({
    showAllShiftsWithAbsences,
    showAllShiftsWithOpenLeaveOfAbsences,
    onShowAllShiftsWithAbsencesChange,
    onShowAllShiftsWithOpenLeaveOfAbsencesChange,
    className = '',
}): ReactElement => (
    <div className={`employment-status-filter-container ${className}`}>
        <InputLabel text={trans('common.employees')} />
        <Checkbox
            label={trans('containers.filters.employmentStatusFilterContainer.showAllShiftsWithLeaveOfAbsences')}
            checked={showAllShiftsWithOpenLeaveOfAbsences}
            onChange={onShowAllShiftsWithOpenLeaveOfAbsencesChange}
            checkClassName="employment-status-filter-container__check-box"
            labelClassName="employment-status-filter-container__check-label"
            className="employment-status-filter-container__check"
        />
        <Checkbox
            label={trans('containers.filters.employmentStatusFilterContainer.showAllShiftsWithAbsences')}
            checked={showAllShiftsWithAbsences}
            onChange={onShowAllShiftsWithAbsencesChange}
            labelClassName="employment-status-filter-container__check-label"
            checkClassName="employment-status-filter-container__check-box"
            className="employment-status-filter-container__check"
        />
    </div>
);

export default EmploymentStatusFilterContainer;
