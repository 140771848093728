import {
    all,
    call,
    put,
    select,
    takeLatest,
} from 'redux-saga/effects';

import { getDivergentPostEmploymentHoursBasedOnPeriod } from '../../@paco/entities/DivergentPostEmploymentHour/DivergentPostEmploymentHourHelpers';
import { getDivergentPreEmploymentHoursBasedOnPeriod } from '../../@paco/entities/DivergentPreEmploymentHour/DivergentPreEmploymentHourHelpers';
import { doesEmploymentOverlapWithPeriod } from '../../@paco/entities/Employment/EmploymentHelpers';
import { doesPeriodOverlapWithWorkday } from '../../@paco/entities/Period/PeriodHelpers';
import { ShiftPlanningWithShift } from '../../@paco/entities/ShiftPlanning/ShiftPlanning';
import { getShiftPlanningsWithShiftApiCall } from '../../@paco/entities/ShiftPlanning/ShiftPlanningService';
import { UserWithHourBalances as PacoUserWithHourBalances } from '../../@paco/entities/User/User';
import { getUsersWithHourBalancesApiCall } from '../../@paco/entities/User/UserService';
import { roundNumberDecimals, roundNumberToRoundedNumberToQuarter } from '../../@paco/helpers/math';
import { getCheckedFormOptionsValue } from '../../@paco/helpers/types/formOption';
import { shiftPlanningSlice } from '../../@paco/redux/shiftPlanning/shiftPlanningReducer';
import { TimeModeType } from '../../@paco/types';
import {
    LEAVE_OF_ABSENCES_REQUEST_APPROVED,
    LEAVE_OF_ABSENCES_REQUEST_CLOSED,
    LEAVE_OF_ABSENCES_REQUEST_OPEN,
    LIST_VIEW,
    PAGE_SIZES,
    TOAST_TYPE_PASS,
} from '../../constants';
import { checkPermission, getObjProperty, getUserFullName } from '../../helpers';
import { getLeaveOfAbsenceRequestIncludes } from '../../helpers/api/absencesApi';
import { delShift, getPreferToWorkTimeSlots, getUnavailableWorkTimeSlots } from '../../helpers/api/shiftsApi';
import { getAbsencesInDateRange, getAbsencesInDateRangeLegacy } from '../../helpers/api-ts/absences';
import { getLoketLeaveBalance } from '../../helpers/api-ts/leaveBalance';
import {
    getLeaveOfAbsences,
    getLeaveOfAbsencesLegacy,
    leaveOfAbsenceStatus,
} from '../../helpers/api-ts/leaveOfAbsence';
import { postShift } from '../../helpers/api-ts/shift';
import { copyShiftPlanning, postShiftPlanning } from '../../helpers/api-ts/shiftPlanning';
import { copyTempWorker } from '../../helpers/api-ts/temporaryWorker';
import { getTracksInDateRangeLegacy } from '../../helpers/api-ts/track';
import { getUsers } from '../../helpers/api-ts/userWithAvailability';
import {
    addSeconds,
    eachDayOfInterval,
    formatDate,
    getEndOfWorkDay,
    getStartOfWorkDay,
} from '../../helpers/date';
import { translate } from '../../helpers/translations/translator';
import {
    AbsenceViewModel,
    BasicUser,
    CopyShiftPlanningData,
    CopyTemporaryWorkerData,
    DataWithPagination,
    LeaveType,
    LoketBalancesViewModel,
    Shift,
    TrackType,
    TrackViewModel,
} from '../../models';
import { UserWithHourBalances } from '../../models/UserWithHourBalances';
import getHoursFromAbsences from '../../services/AbsenceService/getHoursFromAbsences';
import getHoursFromLeaveOfAbsences from '../../services/LeaveOfAbsenceHoursService/getHoursFromLeaveOfAbsences';
import getTotalBillableTimeFromTracks from '../../services/TrackService/getTotalBillableTimeFromTracks';
import getUsersPlannedHours, { UserWithPlannedHours } from '../../services/UserService/getUsersPlannedHours';
// eslint-disable-next-line import/no-cycle
import { deductOneSecondFromLOARequests } from '../absences-ts/absencesHelpers';
import {
    APP_SAGA_ADD_TOAST,
    SHIFTS_ADD_SHIFT_REQUEST,
    SHIFTS_ADD_SHIFT_SUCCESS,
    SHIFTS_CALENDAR_COPY_SHIFT_REQUEST,
    SHIFTS_FAILURE,
    SHIFTS_GET_AVAILABILITY_FAILURE,
    SHIFTS_GET_AVAILABILITY_REQUEST,
    SHIFTS_GET_AVAILABILITY_SUCCESS,
    SHIFTS_GET_USERS_WITH_AVAILABILITY_FAILURE,
    SHIFTS_GET_USERS_WITH_AVAILABILITY_REQUEST,
    SHIFTS_GET_USERS_WITH_AVAILABILITY_SUCCESS,
    SHIFTS_GET_USERS_WITH_LOKET_BALANCES_FAILURE,
    SHIFTS_GET_USERS_WITH_LOKET_BALANCES_REQUEST,
    SHIFTS_GET_USERS_WITH_LOKET_BALANCES_SUCCESS,
    SHIFTS_PLAN_AVAILABILITY_USER_TO_SHIFT_FAILURE,
    SHIFTS_PLAN_AVAILABILITY_USER_TO_SHIFT_REQUEST,
    SHIFTS_PLAN_AVAILABILITY_USER_TO_SHIFT_SUCCESS,
    SHIFTS_SAGA_CALENDAR_COPY_SHIFT,
    SHIFTS_SAGA_CREATE_SHIFT,
    SHIFTS_SAGA_GET_AVAILABILITY,
    SHIFTS_SAGA_GET_SHIFTS,
    SHIFTS_SAGA_GET_USERS_WITH_AVAILABILITY,
    SHIFTS_SAGA_GET_USERS_WITH_LOKET_BALANCES,
    SHIFTS_SAGA_PLAN_AVAILABILITY_USER_TO_SHIFT,
    SHIFTS_UPDATE_USERS_WITH_AVAILABILITY,
} from '../actionTypes';
import { setPagination } from '../app/appSaga';
import { Reducers } from '../reducers';
import removeShiftPlanningFromUsersWithAvailability from './helpers/removeShiftPlanningFromUsersWithAvailability';
import transformAvailabilityDataToUserWithAvailability from './helpers/transformAvailabilityDataToUserWithAvailability';
import transformShiftCloneToCopyTemporaryWorkerDataArray
    from './helpers/transformShiftCloneToCopyTemporaryWorkerDataArray';
import transformShiftCloneToShiftFormData from './helpers/transformShiftCloneToShiftFormData';
import transformShiftToCopyShiftPlanningDataArray from './helpers/transformShiftToCopyShiftPlanningDataArray';
import transformShiftToShiftClone from './helpers/transformShiftToShiftClone';
import updateShiftPlanningInUsersWithAvailability from './helpers/updateShiftPlanningInUsersWithAvailability';
import {
    AddShiftAction,
    CopyShiftAction,
    GetUsersWithAvailabilityAction,
    PlanAvailabilityUserToShiftAction,
} from './shiftsModels';

export function* addShift(action: AddShiftAction): any {
    yield put({ type: SHIFTS_ADD_SHIFT_REQUEST });
    try {
        const response = yield call(() => postShift(action.formData));
        yield put({ type: SHIFTS_ADD_SHIFT_SUCCESS, shiftId: getObjProperty(response, 'data.id') });
        yield put({ type: SHIFTS_SAGA_GET_SHIFTS });
        yield put({ type: APP_SAGA_ADD_TOAST, toast: translate('pages.shifts.addShiftSuccess'), toastType: TOAST_TYPE_PASS });
    } catch (error) {
        yield put({ type: SHIFTS_FAILURE, error });
    }
}

function* initCopyShiftSuccessSequence(shiftId: string) {
    yield put({ type: SHIFTS_ADD_SHIFT_SUCCESS, shiftId });
    yield put({ type: SHIFTS_SAGA_GET_SHIFTS });
    yield put({
        type: APP_SAGA_ADD_TOAST,
        toast: translate('pages.shifts.copyShiftSuccess'),
        toastType: TOAST_TYPE_PASS,
    });
}

function* copyShiftEmployees(
    shiftPlanningData: CopyShiftPlanningData[],
    temporaryWorkerData: CopyTemporaryWorkerData[],
    shiftId: string,
) {
    try {
        yield all(shiftPlanningData.map((data) => copyShiftPlanning(data)));
        yield all(temporaryWorkerData.map((data) => copyTempWorker(data)));
        yield initCopyShiftSuccessSequence(shiftId);
    } catch (error) {
        yield delShift(shiftId);
        yield put({ type: SHIFTS_FAILURE, error });
        yield put({ type: SHIFTS_SAGA_GET_SHIFTS });
    }
}

function* copyShiftAndShiftEmployees(shift: Shift, copyEmployees: boolean): any {
    yield put({ type: SHIFTS_ADD_SHIFT_REQUEST });
    try {
        const state = yield select();
        const { user } = state.authenticatedUserReducer;
        const shiftFormData = transformShiftCloneToShiftFormData(shift, user?.id);
        const response = yield call(() => postShift(shiftFormData));
        const newShiftId = getObjProperty(response, 'data.id');
        const shiftPlanningData = transformShiftToCopyShiftPlanningDataArray(shift, newShiftId);
        const tempWorkerData = transformShiftCloneToCopyTemporaryWorkerDataArray(shift, newShiftId);

        if (copyEmployees) {
            yield copyShiftEmployees(shiftPlanningData, tempWorkerData, newShiftId);
        } else {
            yield initCopyShiftSuccessSequence(newShiftId);
        }
    } catch (error) {
        yield put({ type: SHIFTS_FAILURE, error });
    }
}

export function* copyShift(action: CopyShiftAction) {
    yield put({ type: SHIFTS_CALENDAR_COPY_SHIFT_REQUEST });
    const { shift, copyEmployees } = action;
    const newDate = formatDate(action.newDate, 'yyyy-MM-dd');
    const clone = transformShiftToShiftClone(shift, newDate);
    if (clone) {
        yield copyShiftAndShiftEmployees(clone, copyEmployees);
    }
}

export function* getAvailability(): any {
    yield put({ type: SHIFTS_GET_AVAILABILITY_REQUEST });
    try {
        const state: Reducers = yield select();
        const {
            filterReducer,
            authenticatedUserReducer,
            weekNavigatorReducer,
            globalFiltersReducer,
        } = state;
        const { availability, employeeSearch } = filterReducer.filter;
        const { departmentOptions } = globalFiltersReducer;
        const { startDate, endDate } = weekNavigatorReducer;
        const {
            available,
            unavailable,
            approvedLeaveOfAbsence,
            openLeaveOfAbsence,
            deniedLeaveOfAbsence,
            absence,
        } = availability;

        const { user, permissions } = authenticatedUserReducer;
        const { departments: userDepartments = [] } = user || {};
        const filteredDepartmentIds = getCheckedFormOptionsValue(departmentOptions);
        const userDepartmentIds = userDepartments.map(department => department.id);
        const departments = filteredDepartmentIds.length > 0 ? filteredDepartmentIds : userDepartmentIds;

        const status = [
            ...(openLeaveOfAbsence ? [LEAVE_OF_ABSENCES_REQUEST_OPEN] : []),
            ...(approvedLeaveOfAbsence ? [LEAVE_OF_ABSENCES_REQUEST_APPROVED] : []),
            ...(deniedLeaveOfAbsence ? [LEAVE_OF_ABSENCES_REQUEST_CLOSED] : []),
        ] as leaveOfAbsenceStatus[];
        const leaveOfAbsence = openLeaveOfAbsence || approvedLeaveOfAbsence || deniedLeaveOfAbsence;

        const canViewAbsences = checkPermission(permissions, 'view-all-absences', 'get-availability');
        const canViewLeaveOfAbsences = checkPermission(permissions, 'view-all-leave-of-absences', 'get-availability');
        const canViewAllPreferToWorkTimeSlots = checkPermission(permissions, 'view-all-prefer-to-work-time-slots', 'get-availability');
        const canViewUnavailableToWorkTimeSlots = checkPermission(permissions, 'view-unavailable-to-work-time-slots', 'get-availability');

        const [
            preferToWorkTimeSlots,
            unavailableToWorkTimeSlots,
            absences,
            leaveOfAbsences,
        ] = yield all([
            ...[(available && canViewAllPreferToWorkTimeSlots) ? getPreferToWorkTimeSlots(
                startDate,
                endDate,
                [],
                { departments, search: employeeSearch },
            ) : { data: [] }],
            ...[(unavailable && canViewUnavailableToWorkTimeSlots) ? getUnavailableWorkTimeSlots(
                startDate,
                endDate,
                [],
                { departments, search: employeeSearch },
            ) : { data: [] }],
            ...[(absence && canViewAbsences) ? getAbsencesInDateRangeLegacy(
                startDate,
                endDate,
                { departments, search: employeeSearch },
                undefined,
                [
                    'user',
                    'user.experience',
                    'user.roles',
                    'comments',
                    'absenceHours',
                    'absenceHours.payrollPeriod',
                ],
            ) : { data: [] }],
            ...[(leaveOfAbsence && canViewLeaveOfAbsences) ? getLeaveOfAbsencesLegacy({
                startDate,
                endDate,
                status,
                filter: { departments, search: employeeSearch },
                includes: [
                    'user',
                    'user.experience',
                    'user.person',
                    'user.roles',
                    'comments',
                    'leaveOfAbsenceHours',
                    'leaveOfAbsenceHours.payrollPeriod',
                ],
            }) : { data: [] }],
        ]);

        const leaveOfAbsencesWithUpToAndIncludingDate = deductOneSecondFromLOARequests(leaveOfAbsences.data);

        yield put({
            preferToWorkTimeSlots: preferToWorkTimeSlots.data,
            unavailableToWorkTimeSlots: unavailableToWorkTimeSlots.data,
            absences: absences.data,
            leaveOfAbsences: leaveOfAbsencesWithUpToAndIncludingDate,
            type: SHIFTS_GET_AVAILABILITY_SUCCESS,
        });
    } catch (error) {
        yield put({ type: SHIFTS_GET_AVAILABILITY_FAILURE });
    }
}

export function* getUsersWithAvailability(action: GetUsersWithAvailabilityAction): any {
    yield put({ type: SHIFTS_GET_USERS_WITH_AVAILABILITY_REQUEST });

    try {
        const state: Reducers = yield select();
        const {
            calendarReducer,
            filterReducer,
            paginationReducer,
            authenticatedUserReducer,
            globalFiltersReducer,
        } = state;
        const {
            availability,
            employeeSearch,
            onlyShowMainDepartment,
        } = filterReducer.filter;
        const { departmentOptions } = globalFiltersReducer;
        const { selectedDays } = calendarReducer;
        const {
            available,
            unavailable,
            approvedLeaveOfAbsence,
            openLeaveOfAbsence,
            deniedLeaveOfAbsence,
            absence,
            sortByExperience,
        } = availability;
        const { departments: userDepartments } = authenticatedUserReducer;

        const filteredDepartmentIds = getCheckedFormOptionsValue(departmentOptions);
        const userDepartmentIds = userDepartments.map(department => department.id);
        const departments = filteredDepartmentIds.length > 0 ? filteredDepartmentIds : userDepartmentIds;

        const {
            canViewAbsences,
            canViewLeaveOfAbsences,
        } = action;

        const startDate = getStartOfWorkDay(selectedDays[0]);
        const endDate = getEndOfWorkDay(selectedDays[selectedDays.length - 1]);

        const { data: users, pagination } = yield getUsers({
            sortByExperience,
            workweek: startDate,
            filter: { departments, onlyShowMainDepartment, search: employeeSearch },
            pagination: { number: paginationReducer.number, size: PAGE_SIZES.SHIFTS.LIST_VIEW },
            includes: [
                'person',
                'departments',
                'mainDepartment',
                'experience',
                'employmentType',
            ],
        }) as unknown as DataWithPagination<BasicUser[]>;
        const ids = users.map((user: BasicUser) => user.id);

        const status = [
            ...(openLeaveOfAbsence ? [LEAVE_OF_ABSENCES_REQUEST_OPEN] : []),
            ...(approvedLeaveOfAbsence ? [LEAVE_OF_ABSENCES_REQUEST_APPROVED] : []),
            ...(deniedLeaveOfAbsence ? [LEAVE_OF_ABSENCES_REQUEST_CLOSED] : []),
        ] as leaveOfAbsenceStatus[];

        const [
            preferToWorkTimeSlots,
            unavailableToWorkTimeSlots,
            absences,
            leaveOfAbsences,
            shiftPlannings,
        ] = yield all([
            ...[available ? getPreferToWorkTimeSlots(startDate, endDate, ids) : { data: [] }],
            ...[unavailable ? getUnavailableWorkTimeSlots(startDate, endDate, ids) : { data: [] }],
            ...[(absence && canViewAbsences) ? getAbsencesInDateRangeLegacy(
                getStartOfWorkDay(startDate),
                getEndOfWorkDay(endDate),
                { userIds: ids },
                undefined,
                [
                    'user',
                    'user.experience',
                    'user.roles',
                    'comments',
                    'absenceHours',
                    'absenceHours.payrollPeriod',
                ],
            ) : { data: [] }],
            ...[(status.length && canViewLeaveOfAbsences) ? getLeaveOfAbsencesLegacy({
                startDate: getStartOfWorkDay(startDate),
                endDate: getEndOfWorkDay(endDate),
                filter: { userIds: ids },
                status,
                includes: getLeaveOfAbsenceRequestIncludes(LIST_VIEW),
            }) : { data: [] }],
            getShiftPlanningsWithShiftApiCall({
                startDate: getStartOfWorkDay(startDate),
                endDate: getEndOfWorkDay(endDate),
                userUuids: ids,
            }),
        ]);

        const usersWithAvailability = users
            .map((user: BasicUser) => transformAvailabilityDataToUserWithAvailability(
                user,
                absences.data,
                deductOneSecondFromLOARequests(leaveOfAbsences.data),
                preferToWorkTimeSlots.data,
                unavailableToWorkTimeSlots.data,
                shiftPlannings.data,
            ));

        yield call(() => setPagination({ pagination }));
        yield put({
            type: SHIFTS_GET_USERS_WITH_AVAILABILITY_SUCCESS,
            usersWithAvailability,
            page: pagination.number,
        });
    } catch (error) {
        console.error(error);
        yield put({ type: SHIFTS_GET_USERS_WITH_AVAILABILITY_FAILURE });
    }
}

export function* planAvailabilityUserToShift(action: PlanAvailabilityUserToShiftAction): any {
    yield put({ type: SHIFTS_PLAN_AVAILABILITY_USER_TO_SHIFT_REQUEST });

    try {
        const { shiftId, userId } = action;
        const shiftPlanning = yield call(() => postShiftPlanning({ shiftId, userId }));
        const userName = getUserFullName(shiftPlanning.user);
        yield put({ type: SHIFTS_PLAN_AVAILABILITY_USER_TO_SHIFT_SUCCESS });
        yield put({
            type: APP_SAGA_ADD_TOAST,
            toast: translate('pages.shifts.userPlannedOnShift', { userName }),
            toastType: TOAST_TYPE_PASS,
        });
    } catch (error) {
        yield put({ type: SHIFTS_PLAN_AVAILABILITY_USER_TO_SHIFT_FAILURE, error });
    }
}

export function* getUsersWithEmployeeBalances(): any {
    yield put({ type: SHIFTS_GET_USERS_WITH_LOKET_BALANCES_REQUEST });

    try {
        const state: Reducers = yield select();
        const {
            authenticatedUserReducer,
            filterReducer,
            globalFiltersReducer,
            paginationReducer,
            weekNavigatorReducer,
        } = state;

        const {
            onlyShowMainDepartment,
            employeeSearch,
            userTypes,
            employeeContractTypes,
        } = filterReducer.filter;

        const { departmentOptions } = globalFiltersReducer;
        const { startDate, endDate, mode } = weekNavigatorReducer;
        const timeMode = mode as TimeModeType;

        const filteredDepartmentIds = getCheckedFormOptionsValue(departmentOptions);
        const userDepartments = authenticatedUserReducer.departments.map(department => department.id);
        const departments = filteredDepartmentIds.length > 0 ? filteredDepartmentIds : userDepartments;

        const { data: users, amountOfPages } = yield getUsersWithHourBalancesApiCall({
            onlyShowMainDepartment,
            departments,
            employmentTypes: employeeContractTypes,
            employmentPeriod: { start: startDate, end: endDate },
            pagination: { number: paginationReducer.number, size: PAGE_SIZES.SHIFTS.LIST_VIEW },
            roles: userTypes,
            showInactiveUsers: false,
            userSearch: employeeSearch,
        });

        const pagination = { number: paginationReducer.number, pages: amountOfPages, size: PAGE_SIZES.SHIFTS.LIST_VIEW };
        const userIds = users.map((user: BasicUser) => user.id);
        const loketEmployeeIds = users
            .filter((user: BasicUser) => user.loketEmployeeId)
            .map((user: BasicUser) => user.loketEmployeeId);

        const [
            absences,
            leaveOfAbsences,
            usersPlannedHours,
            tracks,
            loketLeaveBalances,
        ] = yield all([
            getAbsencesInDateRange(
                getStartOfWorkDay(startDate),
                getEndOfWorkDay(endDate),
                { userIds },
                undefined,
                ['absenceHours', 'absenceHours.payrollPeriod', 'user'],
            ),
            getLeaveOfAbsences({
                startDate: getStartOfWorkDay(startDate),
                endDate: getEndOfWorkDay(endDate),
                filter: { userIds },
                status: [1, 2],
                includes: ['leaveOfAbsenceHours', 'leaveOfAbsenceHours.payrollPeriod', 'user'],
            }),
            getUsersPlannedHours(startDate, userIds, timeMode),
            getTracksInDateRangeLegacy(
                startDate,
                endDate,
                TrackType.finished,
                { userIds },
                undefined,
                ['owner'],
            ),
            Promise.all(
                loketEmployeeIds
                    .map((loketEmployeeId: string) => getLoketLeaveBalance(loketEmployeeId)),
            ),
        ]);

        const startOfWorkday = getStartOfWorkDay(startDate);
        const endOfWorkday = getEndOfWorkDay(addSeconds(endDate, -1));

        const data: UserWithHourBalances[] = users.map((user: PacoUserWithHourBalances) => {
            const loketLeaveBalance: LoketBalancesViewModel = loketLeaveBalances
                .find((balance: LoketBalancesViewModel | null) => balance
                    && balance.loketEmployeeId === user.loketEmployeeId);
            const userAbsences = absences
                .filter((absence: AbsenceViewModel) => absence.user && absence.user.id === user.id);
            const absenceHours = getHoursFromAbsences(userAbsences, startOfWorkday, endOfWorkday);
            const userLeaveOfAbsences = leaveOfAbsences
                .filter((absence: AbsenceViewModel) => absence.user && absence.user.id === user.id);
            const loaHours = getHoursFromLeaveOfAbsences(
                userLeaveOfAbsences,
                startOfWorkday,
                endOfWorkday,
                [LeaveType.normal, LeaveType.special],
            );
            const userTracks = tracks.data
                .filter((track: TrackViewModel) => track.owner && track.owner.id === user.id);
            const workedHours = getTotalBillableTimeFromTracks(userTracks);

            const usedTvtHours = getHoursFromLeaveOfAbsences(
                userLeaveOfAbsences,
                startOfWorkday,
                endOfWorkday,
                [LeaveType.tvt],
            );
            const userWithPlannedHours = usersPlannedHours
                .find((userWithWorkedHours: UserWithPlannedHours) => userWithWorkedHours.userId === user.id) as UserWithPlannedHours;
            const { isEligibleForTimeForTime } = user;

            const totalDays = eachDayOfInterval(startDate, endDate).length;
            const matchingContractHours = user.contractHours.find(contractHours => doesPeriodOverlapWithWorkday(startOfWorkday, contractHours.period));
            const contractHoursPerDay = matchingContractHours ? matchingContractHours.hours / 7 : 0;
            const contractHoursPerMonth = contractHoursPerDay * (timeMode === 'week' ? 7 : totalDays);
            const employmentOverlapsWithPeriod = doesEmploymentOverlapWithPeriod(user.employment, { start: startDate, end: endDate });
            const divergentPostEmploymentHours = getDivergentPostEmploymentHoursBasedOnPeriod(user.employment, { start: startDate, end: endDate });
            const divergentPreEmploymentHours = getDivergentPreEmploymentHoursBasedOnPeriod(user.employment, { start: startDate, end: endDate });
            const divergentEmploymentHours = timeMode === 'period' ? (divergentPostEmploymentHours || divergentPreEmploymentHours) : undefined;
            const contractHours = divergentEmploymentHours === undefined ? contractHoursPerMonth : divergentEmploymentHours;
            const contractHoursInPeriod = employmentOverlapsWithPeriod ? contractHours : 0;
            const mutatedTvtHours = loaHours + workedHours + absenceHours - contractHoursInPeriod;

            return {
                userId: user.id,
                userName: getUserFullName(user),
                plannedHours: roundNumberDecimals(userWithPlannedHours.plannedHours),
                contractHours: roundNumberDecimals(contractHoursInPeriod),
                workedHours: roundNumberToRoundedNumberToQuarter(workedHours),
                absenceHours: roundNumberDecimals(absenceHours),
                leaveOfAbsenceHours: roundNumberDecimals(loaHours),
                usedTvtHours: roundNumberDecimals(usedTvtHours),
                mutatedTvtHours: isEligibleForTimeForTime ? roundNumberToRoundedNumberToQuarter(mutatedTvtHours) : 0,
                leaveOfAbsenceBalance: loketLeaveBalance ? roundNumberToRoundedNumberToQuarter(loketLeaveBalance.leaveBalanceTotal) : 0,
                tvtBalance: loketLeaveBalance ? roundNumberToRoundedNumberToQuarter(loketLeaveBalance.timeForTimeTotal) : 0,
                employmentType: user.employmentType,
            };
        });

        yield call(() => setPagination({ pagination }));

        yield put({
            type: SHIFTS_GET_USERS_WITH_LOKET_BALANCES_SUCCESS,
            usersWithLoketBalances: data,
            page: pagination.number,
        });
    } catch (error) {
        console.error(error);
        yield put({ type: SHIFTS_GET_USERS_WITH_LOKET_BALANCES_FAILURE, error });
    }
}

export function* onShiftPlanningUpdate(action: { type: 'shiftPlanningReducer/setShiftPlanning', payload: ShiftPlanningWithShift }): any {
    try {
        const state = yield select();
        const { shiftsReducer } = state;
        const { usersWithAvailability } = shiftsReducer;
        const updatedUsersWithAvailability = updateShiftPlanningInUsersWithAvailability(usersWithAvailability, action.payload);

        yield put({ type: SHIFTS_UPDATE_USERS_WITH_AVAILABILITY, usersWithAvailability: updatedUsersWithAvailability });
    } catch (error) {
        console.error(error);
    }
}

export function* onShiftPlanningDelete(action: { type: 'shiftPlanningReducer/setLastDeletedShiftPlanningId', payload: string }): any {
    try {
        const state = yield select();
        const { shiftsReducer } = state;
        const { usersWithAvailability } = shiftsReducer;
        const updatedUsersWithAvailability = removeShiftPlanningFromUsersWithAvailability(usersWithAvailability, action.payload);

        yield put({ type: SHIFTS_UPDATE_USERS_WITH_AVAILABILITY, usersWithAvailability: updatedUsersWithAvailability });
    } catch (error) {
        console.error(error);
    }
}

export default function* shiftsWatcher() {
    yield takeLatest(SHIFTS_SAGA_CREATE_SHIFT, addShift);
    yield takeLatest(SHIFTS_SAGA_CALENDAR_COPY_SHIFT, copyShift);
    yield takeLatest(SHIFTS_SAGA_GET_AVAILABILITY, getAvailability);
    yield takeLatest(SHIFTS_SAGA_GET_USERS_WITH_AVAILABILITY, getUsersWithAvailability);
    yield takeLatest(SHIFTS_SAGA_PLAN_AVAILABILITY_USER_TO_SHIFT, planAvailabilityUserToShift);
    yield takeLatest(SHIFTS_SAGA_GET_USERS_WITH_LOKET_BALANCES, getUsersWithEmployeeBalances);
    yield takeLatest(shiftPlanningSlice.actions.setShiftPlanning, onShiftPlanningUpdate);
    yield takeLatest(shiftPlanningSlice.actions.setLastDeletedShiftPlanningId, onShiftPlanningDelete);
}
