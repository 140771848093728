import { useMemo } from 'react';

import { useLocation } from 'react-use';

import { PrivateRouteGroupRoutes } from '../../../components/PrivateRouteGroup/PrivateRouteGroupRoutes';
import { NavigationRouteKey, subRoutes } from '../../routes/routes';

const useActiveNavigationRouteKey = (): NavigationRouteKey | undefined => {
    const location = useLocation();

    return useMemo(() => {
        if (location.pathname === PrivateRouteGroupRoutes.dashboard()) {
            return 'dashboard';
        }

        const routeSplits = location?.pathname?.split('/') || [];
        const childRoute = routeSplits[2] || '';
        const activeRoute = subRoutes.find(route => childRoute === route.path);

        return activeRoute?.key;
    }, [location]);
};

export default useActiveNavigationRouteKey;
