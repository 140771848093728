import React, { useMemo, useState } from 'react';

import PropTypes from 'prop-types';
import { difference } from 'underscore';

import { CheckboxGroup } from '../../@paco/compositions/GroupedCheckboxes';
import { getUniqueArrayList } from '../../@paco/helpers/array';
import { getObjProperty } from '../../helpers';
import AllCheckerCheckbox from './AllCheckerCheckbox';
import Checkbox from './Checkbox';

const buildDepartmentList = (departments) => {
    if (!departments) {
        return [];
    }

    const groups = getUniqueArrayList(
        departments
            .map(department => department.departmentGroup)
            .filter(Boolean),
        'id',
    );

    const grouped = groups.map((group) => {
        const children = departments.filter(department => {
            const groupId = getObjProperty(department, 'departmentGroup.id');
            return groupId === group.id;
        });

        return {
            ...group,
            type: 'groups',
            children,
        };
    })
        .filter(group => group.children.length)
        .sort((department, departmentToCompare) => (department.name).localeCompare(departmentToCompare.name));

    const unassignedDepartments = departments.filter((department) => {
        const groupId = getObjProperty(department, 'departmentGroup.id');
        return !groupId;
    });

    return [...grouped, ...unassignedDepartments];
};

const GroupedCheckboxes = ({
    departments = [],
    selectedIds = [],
    expandable = false,
    onChange,
}) => {
    const [randomId] = useState(Math.round(Math.random() * 100000));
    const departmentList = useMemo(() => buildDepartmentList(departments), [departments]);

    const onCheckboxesChange = (newSelectedIds) => {
        if (difference(newSelectedIds, selectedIds).length
            || difference(selectedIds, newSelectedIds).length) {
            onChange(newSelectedIds);
        }
    };

    const onCheckboxChange = (departmentId, checked) => {
        let newSelectedIds = [...selectedIds];

        if (checked && !selectedIds.find(id => id === departmentId)) {
            newSelectedIds.push(departmentId);
        }

        if (!checked) {
            newSelectedIds = newSelectedIds.filter(id => id !== departmentId);
        }

        onCheckboxesChange(newSelectedIds);
    };

    const onCheckboxGroupChange = (options) => {
        let newSelectedIds = [...selectedIds];

        Object.keys(options).forEach((key) => {
            if (options[key].checked && !selectedIds.find(id => id === options[key].value)) {
                newSelectedIds.push(options[key].value);
            }

            if (!options[key].checked) {
                newSelectedIds = newSelectedIds.filter(id => id !== options[key].value);
            }
        });

        onCheckboxesChange(newSelectedIds);
    };

    const renderCheckbox = (department, triggersChangeEvent) => {
        const checked = selectedIds.filter(d => d === department.id).length > 0;
        const id = `${randomId}-${department.id}`;

        if (department.hidden) {
            return null;
        }

        return (
            <Checkbox
                key={`checkbox-${department.id}`}
                onChange={triggersChangeEvent && onCheckboxChange}
                id={id}
                name={id}
                checked={checked}
                value={department.id}
                disabled={department.disabled}
                label={getObjProperty(department, 'name')}
            />
        );
    };

    return (
        <>
            {
                departmentList.map((department) => {
                    if (department.type === 'groups') {
                        const label = getObjProperty(department, 'name');
                        const id = `${randomId}-${department.id}`;

                        const departmentChildrenIdList = department.children.map(child => child.id);
                        const areAllChildrenChecked = departmentChildrenIdList.every(childId => selectedIds.some(selectedId => childId === selectedId));

                        return (
                            <CheckboxGroup key={`${id}-group`} onChange={onCheckboxGroupChange}>
                                <AllCheckerCheckbox
                                    expandable={expandable}
                                    key={department.id}
                                    id={id}
                                    label={label}
                                    value={label}
                                    checked={areAllChildrenChecked}
                                />
                                <div className="children">
                                    { department.children.map(
                                        child => renderCheckbox(child, false),
                                    )}
                                </div>
                            </CheckboxGroup>
                        );
                    }
                    return renderCheckbox(department, true);
                })
            }
        </>
    );
};

GroupedCheckboxes.propTypes = {
    departments: PropTypes.array,
    selectedIds: PropTypes.array,
    expandable: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
};

GroupedCheckboxes.defaultProps = {
    departments: [],
    selectedIds: [],
    expandable: false,
};

export default GroupedCheckboxes;
