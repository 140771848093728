import { FC, useMemo, useState } from 'react';

import classnames from 'classnames';
import { useToggle } from 'react-use';
import { useElementSize } from 'usehooks-ts';

import { LoadingSpinner } from '../../components';
import CalendarHeader from '../../components/CalendarHeader/CalendarHeader';
import {
    CalendarShift,
    ModalContent,
    PacoModal,
} from '../../compositions';
import {
    ConnectedAddShiftForm,
    ConnectedCopyShiftForm,
    ConnectedExportShiftsForm,
    ConnectedHelpTooltip,
    ConnectedLegacyNotifyEmployeesForm,
} from '../../connectors';
import { Permission } from '../../entities/Permission/Permission';
import { ShiftIndex } from '../../entities/Shift/Shift';
import { ShiftsCalendarAbsence } from '../../entities/ShiftsCalendarAbsence/ShiftsCalendarAbsence';
import { ShiftsCalendarLeaveOfAbsence } from '../../entities/ShiftsCalendarLeaveOfAbsence/ShiftsCalendarLeaveOfAbsence';
import { ShiftsCalendarUnavailableToWorkTimeSlot } from '../../entities/ShiftsCalendarUnavailableToWorkTimeSlot/ShiftsCalendarUnavailableToWorkTimeSlot';
import { SpecialDay } from '../../entities/SpecialDay/SpecialDay';
import { setHours } from '../../helpers/date';
import useWindowSize from '../../helpers/hooks/useWindowSize';
import checkPermission from '../../helpers/permissions/checkPermission';
import trans from '../../helpers/trans';
import { TimeModeType } from '../../types';
import { CalendarItem } from '../CalendarDays/CalenderDays';
import { CalendarDays } from '../index';
import { filterShiftsCalendarShifts, getDifferenceInDaysBetweenShiftAndDate, getPreviewCalendarItem } from './helpers';
import ShiftsCalendarActionBar from './subcomponents/ShiftsCalendarActionBar';

import './ShiftsCalendar.scss';

interface ShiftsCalendarProps {
    isLoading: boolean;
    onlyShowShiftsWithAbsence: boolean;
    onlyShowShiftsWithOpenLeaveOfAbsence: boolean;
    showEmployees: boolean;
    showEmploymentTypes: boolean;
    days: Date[];
    absences: ShiftsCalendarAbsence[];
    leaveOfAbsences: ShiftsCalendarLeaveOfAbsence[];
    permissions: Permission[];
    mobileDay: Date;
    shifts: ShiftIndex[];
    specialDays: SpecialDay[];
    timeMode: TimeModeType;
    unavailableToWorkTimeSlots: ShiftsCalendarUnavailableToWorkTimeSlot[];
    onShiftClick: (shiftId: string) => void;
    onNavigationChange: () => void;
}

const ShiftsCalendar: FC<ShiftsCalendarProps> = ({
    isLoading,
    onlyShowShiftsWithAbsence,
    onlyShowShiftsWithOpenLeaveOfAbsence,
    showEmployees,
    showEmploymentTypes,
    days,
    absences,
    leaveOfAbsences,
    permissions,
    mobileDay,
    shifts,
    specialDays,
    timeMode,
    unavailableToWorkTimeSlots,
    onShiftClick,
    onNavigationChange,
}) => {
    const [elementRef, { height }] = useElementSize();
    const { height: windowHeight } = useWindowSize();

    const [addShiftModalIsOpen, setAddShiftModalIsOpen] = useState<boolean>(false);
    const [addShiftModalDate, setAddShiftModalDate] = useState<Date>(new Date());
    const [copyShiftModalIsOpen, setCopyShiftModalIsOpen] = useState<boolean>(false);
    const [exportModalIsOpen, toggleExportModalIsOpen] = useToggle(false);
    const [notifyEmployeesModalIsOpen, setNotifyEmployeesModalIsOpen] = useState<boolean>(false);
    const [isDragging, setIsDragging] = useState<boolean>(false);
    const [dragDate, setDragDate] = useState<Date | undefined>();
    const [dragShift, setDragShift] = useState<ShiftIndex | undefined>();

    const actionBarWrapperClassNames = classnames('shifts-calendar__action-bar-wrapper', {
        'shifts-calendar__action-bar-wrapper--is-sticky': height > windowHeight,
    });

    const dragDateOffset: number = useMemo(() => (
        getDifferenceInDaysBetweenShiftAndDate(dragShift, dragDate)
    ), [dragShift, dragDate]);

    const closeAddShiftModal = (): void => setAddShiftModalIsOpen(false);
    const closeCopyShiftModal = (): void => setCopyShiftModalIsOpen(false);
    const closeExportModal = (): void => toggleExportModalIsOpen(false);
    const closeNotifyEmployeesModal = (): void => setNotifyEmployeesModalIsOpen(false);

    const handleAddShiftButtonClick = (date: Date): void => {
        setAddShiftModalDate(setHours(date, new Date().getHours()));
        setAddShiftModalIsOpen(true);
    };

    const handleCalendarShiftClick = (shift: ShiftIndex): void => {
        onShiftClick(shift.id);
    };

    const handleDragStart = (shift: ShiftIndex): void => {
        setIsDragging(true);
        setDragShift(shift);
    };

    const handleDragEnd = (): void => {
        setIsDragging(false);
    };

    const handleDayDrop = (): void => {
        if (dragShift && dragDate && dragDateOffset) {
            setCopyShiftModalIsOpen(true);
        }
    };

    const handleCloseCopyShiftForm = (): void => {
        closeCopyShiftModal();
        setDragDate(undefined);
    };

    const handleNotifyEmployeesButtonClick = (): void => {
        setNotifyEmployeesModalIsOpen(true);
    };

    const canAddShift = useMemo(() => checkPermission(permissions, 'add-new-shifts', 'shifts-calendar'), [permissions]);
    const canEditShift = useMemo(() => checkPermission(permissions, 'edit-all-shifts', 'shifts-calendar'), [permissions]);
    const filteredShifts = useMemo(() => filterShiftsCalendarShifts(
        shifts,
        absences,
        leaveOfAbsences,
        onlyShowShiftsWithAbsence,
        onlyShowShiftsWithOpenLeaveOfAbsence,
    ), [
        shifts,
        absences,
        leaveOfAbsences,
        onlyShowShiftsWithAbsence,
        onlyShowShiftsWithOpenLeaveOfAbsence,
    ]);

    const items: CalendarItem[] = useMemo(() => {
        const previewShift = (dragShift && dragDate) ? getPreviewCalendarItem(shifts, dragShift.id, dragDate) : undefined;

        return [
            ...filteredShifts,
            ...(previewShift ? [previewShift] : []),
        ].map(shift => ({
            end: shift.period.end,
            element: (
                <CalendarShift
                    canEditShift={canEditShift}
                    draggable={canAddShift && !shift.isPreview}
                    isPreview={shift.isPreview}
                    showEmployees={showEmployees}
                    showEmployeeEmploymentType={showEmploymentTypes}
                    absences={absences}
                    key={shift.id}
                    leaveOfAbsences={leaveOfAbsences}
                    shift={shift}
                    unavailableToWorkTimeSlots={unavailableToWorkTimeSlots}
                    onClick={handleCalendarShiftClick}
                    onDragStart={handleDragStart}
                    onDragEnd={handleDragEnd}
                />
            ),
            start: shift.period.start,
        }));
    }, [
        showEmployees,
        showEmploymentTypes,
        absences,
        leaveOfAbsences,
        filteredShifts,
        unavailableToWorkTimeSlots,
        dragShift,
        dragDate,
    ]);

    return (
        <div ref={elementRef} className="shifts-calendar">
            {isLoading && (
                <LoadingSpinner className="shifts-calendar__loader" />
            )}
            <div className={actionBarWrapperClassNames}>
                <ShiftsCalendarActionBar
                    onNavigationChange={onNavigationChange}
                    handleNotifyEmployeesButtonClick={handleNotifyEmployeesButtonClick}
                    toggleExportModalIsOpen={toggleExportModalIsOpen}
                />
                <CalendarHeader
                    days={days}
                    mode={timeMode}
                    className="shifts-calendar__calendar-header"
                />
            </div>
            <CalendarDays
                isDragging={!!isDragging}
                showHeader={false}
                showAddItemButton={canAddShift}
                showDayInMonth
                days={days}
                items={items}
                specialDays={specialDays}
                timeMode={timeMode}
                onAddItemButtonClick={handleAddShiftButtonClick}
                onDayDragEnter={setDragDate}
                onDayDrop={handleDayDrop}
                className="shifts-calendar__calendar-days"
            />
            <CalendarDays
                showAddItemButton={canAddShift}
                showDayInMonth
                showHeader
                days={[mobileDay]}
                items={items}
                specialDays={specialDays}
                onAddItemButtonClick={handleAddShiftButtonClick}
                className="shifts-calendar__calendar-days-mobile"
            />
            {exportModalIsOpen && (
                <PacoModal>
                    <ModalContent title={trans('common.exportShifts')}>
                        <ConnectedExportShiftsForm onClose={closeExportModal} />
                    </ModalContent>
                </PacoModal>
            )}
            {addShiftModalIsOpen && (
                <PacoModal>
                    <ModalContent title={trans('common.addShift')}>
                        <ConnectedAddShiftForm defaultDate={addShiftModalDate} onClose={closeAddShiftModal} />
                    </ModalContent>
                </PacoModal>
            )}
            {notifyEmployeesModalIsOpen && (
                <PacoModal>
                    <ModalContent title={trans('common.notifyEmployees')}>
                        <ConnectedLegacyNotifyEmployeesForm onClose={closeNotifyEmployeesModal} />
                    </ModalContent>
                </PacoModal>
            )}
            {(copyShiftModalIsOpen && dragShift) && (
                <PacoModal>
                    <ModalContent title={trans('containers.forms.copyShiftForm.copyShift')}>
                        <ConnectedCopyShiftForm
                            shift={dragShift}
                            dateOffset={dragDateOffset}
                            onClose={handleCloseCopyShiftForm}
                        />
                    </ModalContent>
                </PacoModal>
            )}
            <ConnectedHelpTooltip
                index={0}
                route="calendar"
                subTitle={trans('help.shiftsCalendar.addShift.title')}
                text={trans('help.shiftsCalendar.addShift.text')}
                title={trans('help.shiftsCalendar.title')}
                className="shifts-calendar__add-shift-help-tooltip"
            />
            <ConnectedHelpTooltip
                index={1}
                route="calendar"
                subTitle={trans('help.shiftsCalendar.filters.title')}
                text={trans('help.shiftsCalendar.filters.text')}
                title={trans('help.shiftsCalendar.title')}
                showMobileInfoWarning
                className="shifts-calendar__filters-help-tooltip"
            />
        </div>
    );
};

export default ShiftsCalendar;
