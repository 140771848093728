import React from 'react';

import PropTypes from 'prop-types';

import { capitalizeFirstLetter, getObjProperty, getUserFullName } from '../../helpers';
import { differenceInDays, formatDate } from '../../helpers/date';
import { transformEndDateOrStringToFormDate, transformStartDateOrStringToFormDate } from '../../helpers/date/transformStartDateOrStringToFormDate';
import { translate } from '../../helpers/translations/translator';
import ButtonHistory from '../ButtonHistory/ButtonHistory';
import Icon from '../style/Icon/Icon';
import UserProfileInfo from '../UserProfileInfo/UserProfileInfo';

import './LeaveOfAbsenceInfo.scss';


function generateComments(comments) {
    return comments.map((comment) => {
        const name = getUserFullName(comment.user);
        return (
            <span key={comment.id}>
                {name}
                {' '}
                {comment.body}
            </span>
        );
    });
}

function renderEditButton(onEditClick) {
    return (
        <button
            type="button"
            onClick={() => onEditClick()}
            className="loa-info__button-edit"
        >
            <Icon color="light-blue" kind="edit" />
        </button>
    );
}

function renderDateString(startDate, endDate, time, absence) {
    if (!endDate) {
        return `${translate('pages.absences.from')} ${startDate}`;
    }

    if (differenceInDays(absence.end, absence.start) === 0) {
        return `${capitalizeFirstLetter(startDate)} ${time}`;
    }

    return `${capitalizeFirstLetter(startDate)} ${translate('common.until')} ${endDate}`;
}

const LeaveOfAbsenceInfo = ({
    absence,
    onShowLogsClick,
    onEditClick,
    hasRoleToSeeAbsenceHours = false,
}) => {
    if (!absence) {
        return null;
    }

    const name = getUserFullName(absence.user);
    const userId = getObjProperty(absence, 'user.id');
    const experience = getObjProperty(absence, 'user.experience.name');
    const cardDate = absence.createdAt ? capitalizeFirstLetter(formatDate(absence.createdAt, 'dd-MM-yyyy')) : '';
    const startDate = formatDate(transformStartDateOrStringToFormDate(new Date(absence.start)), 'EEEE dd-MM-yyyy');
    const time = `${formatDate(absence.start, 'HH:mm')} - ${formatDate(absence.end, 'HH:mm')}`;
    const endDate = absence.end ? formatDate(transformEndDateOrStringToFormDate(new Date(absence.end)), 'EEEE dd-MM-yyyy') : '';
    const reviewedByUser = getUserFullName(absence.reviewedByUser);
    const comments = absence.comments || [];

    return (
        <div className={`loa-info ${onShowLogsClick ? 'loa-info--has-history-button' : ''} ${reviewedByUser ? 'loa-info--has-reviewed-by-user' : ''}`}>
            <div className="loa-info-top">
                <div className="loa-info-left">
                    <UserProfileInfo
                        date={new Date(absence.start)}
                        experience={experience}
                        name={name}
                        userId={userId}
                    />
                </div>
                { absence.createdAt && (
                    <div className="loa-info-right">
                        <div className="request-card-date font-italic">
                            {translate('pages.absences.requestedOn')}
                            {' '}
                            {cardDate}
                        </div>
                        {reviewedByUser && <span className="request-card-reviewed-by">{`${translate('pages.management.reviewedBy')} ${reviewedByUser}`}</span>}
                        <div className="loa-info-right-buttons">
                            {hasRoleToSeeAbsenceHours && onEditClick && renderEditButton(onEditClick)}
                            {onShowLogsClick && <ButtonHistory className="loa-info__button-history" onClick={onShowLogsClick} />}
                        </div>
                    </div>
                )}
            </div>
            <div className="request-card-title loa-request-card-title">
                { absence.reason && (
                    <>
                        {' '}
                        {absence.reason}
                        {' '}
                        <br />
                        {' '}
                    </>
                ) }
                { renderDateString(startDate, endDate, time, absence) }
            </div>
            <p className="loa-request-card-comments">
                { generateComments(comments) }
            </p>
        </div>
    );
};

LeaveOfAbsenceInfo.propTypes = {
    absence: PropTypes.object,
    onShowLogsClick: PropTypes.func,
    onEditClick: PropTypes.func,
};

LeaveOfAbsenceInfo.defaultProps = {
    absence: null,
    onShowLogsClick: null,
    onEditClick: null,
};

export default LeaveOfAbsenceInfo;
