import React, { Component } from 'react';

import PropTypes from 'prop-types';
import DayPicker from 'react-day-picker';
import { connect } from 'react-redux';

import { TIME_MODES } from '../../constants';
import { generateEventPath } from '../../helpers';
import {
    addDays,
    eachDayOfInterval,
    getRangeFromDate,
    getWeekRange,
    incrementFromDate,
    startOfDay,
} from '../../helpers/date';
import { translate } from '../../helpers/translations/translator';
import { setSelectedDate, setStartAndEndDate } from '../../redux/weekNavigator/weekNavigatorActions';
import Controls from './Controls/Controls';

import './WeekNavigator.scss';

const locale = 'nl';
const firstDayOfWeek = 1;

class WeekNavigator extends Component {
    state = {
        dayPickerIsOpen: false,
        hoverRange: undefined,
        selectedDays: [],
    };

    months = [
        translate('common.months.jan'),
        translate('common.months.feb'),
        translate('common.months.mar'),
        translate('common.months.apr'),
        translate('common.months.may'),
        translate('common.months.jun'),
        translate('common.months.jul'),
        translate('common.months.aug'),
        translate('common.months.sep'),
        translate('common.months.oct'),
        translate('common.months.nov'),
        translate('common.months.dec'),
    ];

    weekDays = [
        translate('common.weekDays.sun'),
        translate('common.weekDays.mon'),
        translate('common.weekDays.tue'),
        translate('common.weekDays.wed'),
        translate('common.weekDays.thu'),
        translate('common.weekDays.fri'),
        translate('common.weekDays.sat'),
    ];

    static getDerivedStateFromProps(props, state) {
        const { startDate, endDate } = props;
        const selectedDays = eachDayOfInterval(startDate, endDate);

        return {
            ...state,
            selectedDays,
        };
    }

    getSnapshotBeforeUpdate() {
        if (this.state.dayPickerIsOpen) {
            window.addEventListener('mouseup', this.onWindowClick);
        } else {
            window.removeEventListener('mouseup', this.onWindowClick);
        }

        return null;
    }

    componentWillUnmount() {
        window.removeEventListener('mouseup', this.onWindowClick);
    }

    onWindowClick = (e) => {
        const paths = generateEventPath(e.target);
        const dayPicker = paths.filter(path => ((path.id === 'date-controls-toggle') || (path.className === 'DayPicker')));
        if (!dayPicker.length && this.state.dayPickerIsOpen) {
            this.setState({ dayPickerIsOpen: false });
        }
    }

    toggleDayPicker = () => {
        this.setState(state => ({ dayPickerIsOpen: !state.dayPickerIsOpen }));
    };

    handleDayPickerEnter = (date) => {
        this.setState({
            hoverRange: getWeekRange(date),
        });
    };

    handleDayPickerLeave = () => {
        this.setState({
            hoverRange: undefined,
        });
    };

    handleDayPickerChange = (date) => {
        const { dispatch, afterDateChange, mode } = this.props;
        const days = getRangeFromDate(date, mode);
        const selectedDate = startOfDay(date);

        this.toggleDayPicker();
        dispatch(setStartAndEndDate(selectedDate, days[0], days[days.length - 1]));
        afterDateChange(selectedDate, days[0], days[days.length - 1]);
    };

    handleDayPickerWeekClick = (weekNumber, selectedDays) => {
        const { dispatch, afterDateChange, mode } = this.props;
        const days = getRangeFromDate(selectedDays[0], mode);
        dispatch(setStartAndEndDate(days[0], days[0], days[days.length - 1]));
        afterDateChange(days[0], days[0], days[days.length - 1]);
    };

    onIncrementClick = (next, day) => {
        const {
            startDate, endDate, afterDateChange, mode, afterIncrementChange,
        } = this.props;

        const selectedDate = day && addDays(this.props.selectedDate, next ? 1 : -1);
        if (day) {
            this.props.dispatch(
                setSelectedDate(selectedDate, startDate, endDate),
            );
            if (afterIncrementChange) {
                afterIncrementChange(selectedDate, startDate, endDate);
            }

            // We only trigger afterDateChange when selectedDate reaches a new week / month / period
            if ((selectedDate > endDate) || (selectedDate < startDate)) {
                const [start, end] = this.calculateIncrementDates(day, startDate, endDate, mode, next);
                afterDateChange(selectedDate, start, end);
            }
        } else {
            const [start, end] = this.calculateIncrementDates(day, startDate, endDate, mode, next);
            this.props.dispatch(setStartAndEndDate(start, start, end));
            afterDateChange(selectedDate, start, end);
        }
    }

    calculateIncrementDates(day, startDate, endDate, mode, next) {
        let start;
        let end;

        if (mode === TIME_MODES.MONTH || mode === TIME_MODES.YEAR) {
            const increment = incrementFromDate(startDate, mode, !next);
            const days = getRangeFromDate(increment, mode);
            [start] = days;
            end = days[days.length - 1];
        } else {
            start = incrementFromDate(startDate, mode, !next);
            end = incrementFromDate(endDate, mode, !next);
        }

        return [start, end];
    }

    render() {
        const {
            startDate, endDate, selectedDate, mode,
        } = this.props;
        const { dayPickerIsOpen, hoverRange, selectedDays } = this.state;
        const daysAreSelected = selectedDays.length > 0;

        const modifiers = {
            hoverRange,
            selectedRange: daysAreSelected && {
                from: selectedDays[0],
                to: selectedDays[6],
            },
            hoverRangeStart: hoverRange && hoverRange.from,
            hoverRangeEnd: hoverRange && hoverRange.to,
            selectedRangeStart: daysAreSelected && selectedDays[0],
            selectedRangeEnd: daysAreSelected && selectedDays[6],
        };

        return (
            <>
                <div className="week-navigator">
                    <div className="week-navigator__controls-desktop">
                        <Controls
                            day={false}
                            startDate={startDate}
                            endDate={endDate}
                            selectedDate={selectedDate}
                            mode={mode}
                            onIncrement={this.onIncrementClick}
                            toggleDayPicker={this.toggleDayPicker}
                        />
                    </div>
                    <div className="week-navigator__controls-mobile">
                        <Controls
                            day
                            startDate={startDate}
                            endDate={endDate}
                            selectedDate={selectedDate}
                            onIncrement={this.onIncrementClick}
                            toggleDayPicker={this.toggleDayPicker}
                        />
                    </div>
                </div>
                {dayPickerIsOpen
                && (
                    <>
                        <div className="week-navigator__daypicker-desktop">
                            <DayPicker
                                locale={locale}
                                firstDayOfWeek={firstDayOfWeek}
                                weekdaysShort={this.weekDays.map((d => d.substr(0, 2)))}
                                weekdaysLong={this.weekDays}
                                months={this.months}
                                month={selectedDate}
                                selectedDays={selectedDays}
                                showWeekNumbers
                                showOutsideDays
                                modifiers={modifiers}
                                onDayClick={this.handleDayPickerChange}
                                onDayMouseEnter={this.handleDayPickerEnter}
                                onDayMouseLeave={this.handleDayPickerLeave}
                                onWeekClick={this.handleDayPickerWeekClick}
                                position="right"
                            />
                        </div>
                        <div className="week-navigator__daypicker-mobile">
                            <DayPicker
                                locale={locale}
                                firstDayOfWeek={firstDayOfWeek}
                                weekdaysShort={this.weekDays.map((d => d.substr(0, 2)))}
                                weekdaysLong={this.weekDays}
                                months={this.months}
                                month={selectedDate}
                                selectedDays={selectedDate}
                                showWeekNumbers
                                showOutsideDays
                                modifiers={modifiers}
                                onDayClick={this.handleDayPickerChange}
                                onDayMouseEnter={this.handleDayPickerEnter}
                                onDayMouseLeave={this.handleDayPickerLeave}
                                onWeekClick={this.handleDayPickerWeekClick}
                                position="right"
                            />
                        </div>
                    </>
                )}
            </>
        );
    }
}

WeekNavigator.propTypes = {
    mode: PropTypes.string.isRequired,
    startDate: PropTypes.object.isRequired,
    endDate: PropTypes.object.isRequired,
    selectedDate: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    afterDateChange: PropTypes.func.isRequired,
    afterIncrementChange: PropTypes.func,
};

WeekNavigator.defaultProps = {
    afterIncrementChange: null,
};

function mapStateToProps(state) {
    return {
        startDate: state.weekNavigatorReducer.startDate,
        endDate: state.weekNavigatorReducer.endDate,
        selectedDate: state.weekNavigatorReducer.selectedDate,
    };
}

export default connect(mapStateToProps)(WeekNavigator);
