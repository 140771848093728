import { CSSProperties, FC, useMemo } from 'react';

import classNames from 'classnames';

import { EmploymentTypeSlug } from '../../../entities/EmploymentType/EmploymentType';
import { getFirstLetterOfEmploymentType } from '../../../entities/EmploymentType/EmploymentTypeHelpers';
import { CompanyMedicType, ShiftPlanningColor } from '../../../types';
import ShiftEmployeeIcon, { ShiftEmployeeIconType } from '../ShiftEmployeeIcon/ShiftEmployeeIcon';

import './CalendarShiftEmployee.scss';

interface CalendarShiftEmployeeProps {
    isCompanyMedic?: CompanyMedicType;
    isPreviouslyPlannedUser?: boolean;
    showEmploymentType?: boolean;
    color: ShiftPlanningColor | null;
    deviatedTime?: string;
    employmentTypeSlug?: EmploymentTypeSlug;
    icons?: ShiftEmployeeIconType[];
    name: string;
    className?: string;
    employmentTypeClassName?: string;
    iconClassName?: string;
}

const CalendarShiftEmployee: FC<CalendarShiftEmployeeProps> = ({
    isCompanyMedic,
    isPreviouslyPlannedUser,
    showEmploymentType,
    color,
    deviatedTime,
    employmentTypeSlug,
    icons = [],
    name,
    className = '',
    employmentTypeClassName = '',
    iconClassName = '',
}) => {
    const employmentTypeInitial = useMemo<string>(() => (
        employmentTypeSlug ? getFirstLetterOfEmploymentType(employmentTypeSlug) : '?'), [employmentTypeSlug]);

    const cssVariables = { ...(color && { '--c-calender-shift-employee': `#${color}` }) } as CSSProperties;

    const calendarShiftEmployeeClassNames = classNames('paco-calendar-shift-employee', {
        'paco-calendar-shift-employee--is-previously-planned-user': isPreviouslyPlannedUser,
        'paco-calendar-shift-employee--is-company-medic': isCompanyMedic === CompanyMedicType.yes,
    }, className);

    return (
        <li style={cssVariables} className={calendarShiftEmployeeClassNames}>
            {showEmploymentType && (
                <div className={`paco-calendar-shift-employee__employment-type ${employmentTypeClassName}`}>
                    {employmentTypeInitial}
                </div>
            )}
            {color && (
                <div className="paco-calendar-shift-employee__color" />
            )}
            <span className="paco-calendar-shift-employee__name">
                {name}
            </span>
            {deviatedTime && (
                <span className="paco-calendar-shift-employee__time">
                    {deviatedTime}
                </span>
            )}
            {icons.map(icon => (
                <ShiftEmployeeIcon
                    key={`${icon.tooltipText}-${icon.iconName}`}
                    iconName={icon.iconName}
                    tooltipText={icon.tooltipText}
                    className={iconClassName}
                />
            ))}
        </li>
    );
};

export default CalendarShiftEmployee;
