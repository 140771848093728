import React, { FC, ReactElement, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { Modal } from 'reactstrap';

import { ConnectedCalendarNavigation } from '../../../../@paco/connectors';
import { PayrollPeriod } from '../../../../@paco/entities/PayrollPeriod/PayrollPeriod';
import { Permission } from '../../../../@paco/entities/Permission/Permission';
import { Role } from '../../../../@paco/entities/Role/Role';
import { TimeModeType } from '../../../../@paco/types';
import { PzLeaveType } from '../../../../@paco/types/leaveType';
import CalendarLegend from '../../../../components/CalendarLegend/CalendarLegend';
import ItemsList from '../../../../components/ItemsList/ItemsList';
import LogsModal from '../../../../components/Logs/LogsModal';
import { PrivateRouteGroupRoutes } from '../../../../components/PrivateRouteGroup/PrivateRouteGroupRoutes';
import SearchBar from '../../../../components/SearchBar/SearchBar';
import { CONCEPTS_LIST_VIEW } from '../../../../constants';
import { ShiftConceptViewModelOld } from '../../../../entities/ShiftConcept/ShiftConcept';
import { translate } from '../../../../helpers/translations/translator';
import {
    Absence,
    LeaveOfAbsence,
    LeaveOfAbsenceEditType,
    LeaveOfAbsenceFormData,
    LeaveType,
    Log,
    Pagination,
    PayrollPeriodViewModel,
    PayrollPeriodWithHours,
    SpecialDayViewModel,
    UnavailableToWorkTimeSlot,
    UserWithConceptAvailability,
    WeekWithHours,
} from '../../../../models';
import { AppFilter } from '../../../../redux/filter-ts/filterModels';
import DeclineLeaveOfAbsenceForm from '../../../Absences/forms/DeclineLeaveOfAbsenceForm';
import EditAbsenceForm from '../../../Absences/forms/EditAbsenceForm/EditAbsenceForm';
import ResolveLeaveOfAbsenceForm from '../../../Absences/forms/ResolveLeaveOfAbsenceForm/ResolveLeaveOfAbsenceForm';
import AddOrEditAvailabilityTime from '../../../Management/forms/AddOrEditAvailabilityTime/AddOrEditAvailabilityTime';
import ConceptsListViewTable, { ResourceTypes } from '../../components/ConceptsListViewTable/ConceptsListViewTable';
import AssignShiftConceptToUserForm from '../../forms/AsignShiftConceptToUserForm/AssignShiftConceptToUserForm';
import { ScheduleRoutes } from '../../ScheduleRoutes';

import './ConceptsListView.scss';

interface ConceptsListViewProps {
    canAddPlannings: boolean;
    canViewShiftConcepts: boolean;
    loading: boolean;
    logsLoading: boolean;
    days: Date[];
    filter: AppFilter;
    users: UserWithConceptAvailability[];
    pagination: Pagination;
    pacoPayrollPeriods: PayrollPeriod[];
    payrollPeriods: PayrollPeriodViewModel[];
    specialDays: SpecialDayViewModel[];
    startDate: Date;
    endDate: Date;
    permissions: Permission[];
    currentUserRole?: Role;
    logs: Log[];
    onSearchBarChange: (search: string) => void;
    onCalendarTypeChange: () => void;
    onCloseLogsModalClick: () => void;
    onPagination: (page: number) => void;
    onShowLeaveOfAbsencesLogsClick: (leaveOfAbsenceId: string) => void;
    dispatchPlanUserToShiftConcept: (userId: string, shiftConcept: ShiftConceptViewModelOld) => void;
    dispatchEditLeaveOfAbsence: (
        editType: LeaveOfAbsenceEditType,
        data: LeaveOfAbsenceFormData,
        payrollPeriodsWithHours: PayrollPeriodWithHours[],
        weeksWithHours: WeekWithHours[],
        activeLeaveOfAbsence: LeaveOfAbsence,
        leaveType?: LeaveType | PzLeaveType | null,
    ) => void;
    dispatchDeclineLeaveOfAbsence: (
        declineComment: string,
        editLOAData: LeaveOfAbsenceFormData,
        editLOAPayrollPeriodHours: PayrollPeriodWithHours[],
        editLOAWeekdayHours: WeekWithHours[],
        activeLeaveOfAbsence: LeaveOfAbsence,
    ) => void;
    dispatchEditAbsence: (
        absence: Absence,
        newStartDate: string,
        newEndDate: string | null,
        payrollPeriodsWithHours: PayrollPeriodWithHours[],
        weeksWithHours: WeekWithHours[],
        waitingDayHours: number,
    ) => void;
    dispatchDeleteAbsence: (absenceId: string, page: string) => void;
    dispatchAvailabilityTime: (
        weekday: number,
        fromDate: string,
        toDate: string,
        comment: string,
        userId: string,
        id: string,
    ) => void;
}

const ConceptsListView: FC<ConceptsListViewProps> = ({
    canAddPlannings,
    canViewShiftConcepts,
    loading,
    logsLoading,
    days,
    filter,
    users,
    pagination,
    pacoPayrollPeriods,
    payrollPeriods,
    specialDays,
    startDate,
    endDate,
    permissions,
    currentUserRole,
    logs,
    onCalendarTypeChange,
    onCloseLogsModalClick,
    onPagination,
    onSearchBarChange,
    onShowLeaveOfAbsencesLogsClick,
    dispatchPlanUserToShiftConcept,
    dispatchEditLeaveOfAbsence,
    dispatchDeclineLeaveOfAbsence,
    dispatchEditAbsence,
    dispatchDeleteAbsence,
    dispatchAvailabilityTime,
}): ReactElement => {
    const navigate = useNavigate();
    const [addShiftConceptForUserData, setAddShiftConceptForUserData] = useState<{ user: UserWithConceptAvailability, date: Date } | null>(null);

    const [resolveLeaveOfAbsenceForm, setResolveLeaveOfAbsenceForm] = useState(false);
    const [activeLeaveOfAbsence, setActiveLeaveOfAbsence] = useState<LeaveOfAbsence | null>(null);
    const [declineLeaveOfAbsenceForm, setDeclineLeaveOfAbsenceForm] = useState(false);
    const [editLOAData, setEditLOAData] = useState<LeaveOfAbsenceFormData | null>(null);
    const [editLOAPayrollPeriodHours, setEditLOAPayrollPeriodHours] = useState<PayrollPeriodWithHours[]>([]);
    const [editLOAWeekdayHours, setEditLOAWeekdayHours] = useState<WeekWithHours[]>([]);
    const [editAbsenceForm, setEditAbsenceForm] = useState(false);
    const [activeAbsence, setActiveAbsence] = useState<Absence | null>(null);
    const [editPlanningPreferenceForm, setEditPlanningPreferenceForm] = useState(false);
    const [activePlanningPreference, setActivePlanningPreference] = useState<UnavailableToWorkTimeSlot | null>(null);

    const onResourceClick = (resource: ResourceTypes) => {
        if (resource.type === 'shiftConceptPlannings') {
            navigate(`${PrivateRouteGroupRoutes.shifts()}/${ScheduleRoutes.shiftConcepts(resource.shiftConcept.id)}`);
        }

        if (resource.type === 'leaveOfAbsences') {
            setResolveLeaveOfAbsenceForm(true);
            setActiveLeaveOfAbsence(resource as unknown as LeaveOfAbsence);
        }

        if (resource.type === 'absences') {
            setEditAbsenceForm(true);
            setActiveAbsence(resource);
        }

        if (resource.type === 'UnavailableToWorkTimeSlots') {
            setEditPlanningPreferenceForm(true);
            setActivePlanningPreference(resource);
        }
    };

    const onAddButtonClick = (date: Date, user: UserWithConceptAvailability): void => {
        setAddShiftConceptForUserData({ user, date });
    };

    const planUserToShiftConcept = (userId: string, shiftConcept: ShiftConceptViewModelOld): void => {
        dispatchPlanUserToShiftConcept(userId, shiftConcept);
        setAddShiftConceptForUserData(null);
    };

    const addDeclineLeaveOfAbsenceForm = (
        data: LeaveOfAbsenceFormData,
        payrollPeriodsWithHours: PayrollPeriodWithHours[],
        weeksWithHours: WeekWithHours[],
    ): void => {
        setResolveLeaveOfAbsenceForm(false);
        setDeclineLeaveOfAbsenceForm(true);
        setEditLOAPayrollPeriodHours(payrollPeriodsWithHours);
        setEditLOAWeekdayHours(weeksWithHours);
        setEditLOAData(data);
    };

    const onSubmitLeaveOfAbsenceForm = (
        editType: LeaveOfAbsenceEditType,
        data: LeaveOfAbsenceFormData,
        payrollPeriodsWithHours: PayrollPeriodWithHours[],
        weeksWithHours: WeekWithHours[],
        leaveType?: LeaveType | PzLeaveType | null,
    ): void => {
        if (editType === 'deny') {
            addDeclineLeaveOfAbsenceForm(data, payrollPeriodsWithHours, weeksWithHours);
        } else {
            setResolveLeaveOfAbsenceForm(false);
            setActiveLeaveOfAbsence(null);

            dispatchEditLeaveOfAbsence(
                editType,
                data,
                payrollPeriodsWithHours,
                weeksWithHours,
                activeLeaveOfAbsence as LeaveOfAbsence,
                leaveType,
            );
        }
    };

    const onDeclineLeaveOfAbsence = (declineComment: string) => {
        setResolveLeaveOfAbsenceForm(false);
        setDeclineLeaveOfAbsenceForm(false);
        setActiveLeaveOfAbsence(null);
        setEditLOAData(null);
        setEditLOAPayrollPeriodHours([]);
        setEditLOAWeekdayHours([]);

        if (!editLOAData || !activeLeaveOfAbsence) {
            return;
        }

        dispatchDeclineLeaveOfAbsence(
            declineComment,
            editLOAData,
            editLOAPayrollPeriodHours,
            editLOAWeekdayHours,
            activeLeaveOfAbsence,
        );
    };

    const onEditAbsence = (
        absence: Absence,
        newStartDate: string,
        newEndDate: string | null,
        payrollPeriodsWithHours: PayrollPeriodWithHours[],
        weeksWithHours: WeekWithHours[],
        waitingDayHours: number,
    ): void => {
        dispatchEditAbsence(
            absence,
            newStartDate,
            newEndDate,
            payrollPeriodsWithHours,
            weeksWithHours,
            waitingDayHours,
        );

        setEditAbsenceForm(false);
    };

    const onDeleteAbsence = (absenceId: string): void => {
        dispatchDeleteAbsence(absenceId, CONCEPTS_LIST_VIEW);
        setEditAbsenceForm(false);
    };

    const onCancelLeaveOfAbsenceForm = (): void => {
        setResolveLeaveOfAbsenceForm(false);
    };

    const onCancelDeclineLeaveOfAbsenceForm = (): void => {
        setDeclineLeaveOfAbsenceForm(false);
        setActiveLeaveOfAbsence(null);
        setEditLOAPayrollPeriodHours([]);
        setEditLOAData(null);
    };

    const onCancelEditAbsence = (): void => {
        setEditAbsenceForm(false);
    };

    const onAddOrEditAvailabilityTime = (
        weekday: number,
        fromDate: string,
        toDate: string,
        comment: string,
    ): void => {
        const { user, id } = activePlanningPreference as UnavailableToWorkTimeSlot;
        setEditPlanningPreferenceForm(false);
        setActivePlanningPreference(null);

        dispatchAvailabilityTime(
            weekday,
            fromDate,
            toDate,
            comment,
            user.id,
            id,
        );
    };

    const cancelPlanningPreferenceForm = (): void => {
        setEditPlanningPreferenceForm(false);
        setActivePlanningPreference(null);
    };

    const onCancelAddShiftForUserData = (): void => {
        setAddShiftConceptForUserData(null);
    };

    return (
        <div className="concepts-list-view">
            <div className="concepts-list-view__calendar-overview-top">
                <ConnectedCalendarNavigation
                    showConceptModeButtons={canViewShiftConcepts}
                    timeModes={[TimeModeType.week, TimeModeType.custom]}
                    onCalendarTypeChange={onCalendarTypeChange}
                    className="concepts-list-view__navigation"
                />

                <div className="concepts-list-view__search-bar">
                    <SearchBar
                        placeholder={translate('common.searchEmployee')}
                        startVal={filter.employeeSearch}
                        onChange={onSearchBarChange}
                    />
                </div>
            </div>
            <CalendarLegend
                plannedShift
                registeredShift
                absence
                leaveOfAbsence
                available
                notAvailable
                conceptShift
                className="concepts-list-view__calendar-legend"
            />
            <div>
                <ItemsList
                    loading={loading}
                    itemsLength={users.length}
                    pagination={pagination}
                    emptyMessage={translate('pages.management.emptyList')}
                    onPagination={onPagination}
                >
                    <ConceptsListViewTable
                        loading={loading}
                        days={days}
                        specialDays={specialDays}
                        startDate={startDate}
                        endDate={endDate}
                        users={users}
                        isFilterEmploymentTypeBadgeActive={filter.employmentTypeBadge}
                        canAddPlannings={canAddPlannings}
                        onAvailabilityClick={onResourceClick}
                        onAddButtonClick={onAddButtonClick}
                    />
                </ItemsList>
            </div>
            <Modal size="lg" isOpen={!!addShiftConceptForUserData} className="form-assign-concept-shift-to-user">
                {addShiftConceptForUserData && (
                    <AssignShiftConceptToUserForm
                        user={addShiftConceptForUserData.user}
                        date={addShiftConceptForUserData.date}
                        onSubmit={planUserToShiftConcept}
                        onCancel={onCancelAddShiftForUserData}
                    />
                )}
            </Modal>
            <Modal size="lg" isOpen={resolveLeaveOfAbsenceForm} className="form-resolve-leave-of-absence">
                <ResolveLeaveOfAbsenceForm
                    absence={activeLeaveOfAbsence}
                    permissions={permissions}
                    currentUserRole={currentUserRole}
                    pacoPayrollPeriods={pacoPayrollPeriods}
                    payrollPeriods={payrollPeriods}
                    onSubmit={onSubmitLeaveOfAbsenceForm}
                    onCancel={onCancelLeaveOfAbsenceForm}
                    onShowLogsClick={onShowLeaveOfAbsencesLogsClick}
                />
            </Modal>
            <Modal size="lg" isOpen={declineLeaveOfAbsenceForm} className="form-decline-leave-of-absence">
                <DeclineLeaveOfAbsenceForm
                    onSubmit={onDeclineLeaveOfAbsence}
                    onCancel={onCancelDeclineLeaveOfAbsenceForm}
                />
            </Modal>
            <Modal size="lg" isOpen={editAbsenceForm} className="form-resolve-absence">
                <EditAbsenceForm
                    data={activeAbsence as Absence}
                    permissions={permissions}
                    currentUserRole={currentUserRole}
                    pacoPayrollPeriods={pacoPayrollPeriods}
                    payrollPeriods={payrollPeriods}
                    onSubmit={onEditAbsence}
                    onDelete={onDeleteAbsence}
                    onCancel={onCancelEditAbsence}
                />
            </Modal>
            <Modal size="lg" isOpen={editPlanningPreferenceForm} className="form-add-time">
                <AddOrEditAvailabilityTime
                    data={activePlanningPreference}
                    permissions={permissions}
                    onCancel={cancelPlanningPreferenceForm}
                    onSubmit={onAddOrEditAvailabilityTime}
                />
            </Modal>
            <LogsModal
                loading={logsLoading}
                isOpen={logsLoading || !!logs}
                title={translate('logs.leaveOfAbsenceLog')}
                logs={logs}
                onClose={onCloseLogsModalClick}
            />
        </div>
    );
};

export default ConceptsListView;
