import {
    call,
    put,
    select,
    takeLatest,
} from 'redux-saga/effects';

import { getCheckedFormOptionsValue } from '../../@paco/helpers/types/formOption';
import { DOCUMENTS, PAGE_SIZES, TOAST_TYPE_PASS } from '../../constants';
import { checkPermission } from '../../helpers';
import {
    delDocument,
    getAllDocuments,
    patchDocument,
    postDocument,
} from '../../helpers/api-ts/document';
import { getDocumentTypes } from '../../helpers/api-ts/documentType';
import { getLoketInfo } from '../../helpers/api-ts/loket';
import { translate } from '../../helpers/translations/translator';
import { DataWithPagination, DocumentResource } from '../../models';
import {
    APP_SAGA_ADD_TOAST,
    PZ_ADD_DOCUMENT_FAILURE,
    PZ_ADD_DOCUMENT_REQUEST,
    PZ_ADD_DOCUMENT_SUCCESS,
    PZ_DELETE_DOCUMENT_FAILURE,
    PZ_DELETE_DOCUMENT_REQUEST,
    PZ_DELETE_DOCUMENT_SUCCESS,
    PZ_EDIT_DOCUMENT_FAILURE,
    PZ_EDIT_DOCUMENT_REQUEST,
    PZ_EDIT_DOCUMENT_SUCCESS,
    PZ_GET_DOCUMENT_TYPES_FAILURE,
    PZ_GET_DOCUMENT_TYPES_REQUEST,
    PZ_GET_DOCUMENT_TYPES_SUCCESS,
    PZ_GET_DOCUMENTS_FAILURE,
    PZ_GET_DOCUMENTS_REQUEST,
    PZ_GET_DOCUMENTS_SUCCESS,
    PZ_GET_LOKET_INFO_FAILURE,
    PZ_GET_LOKET_INFO_REQUEST,
    PZ_GET_LOKET_INFO_SUCCESS,
    PZ_SAGA_ADD_DOCUMENT,
    PZ_SAGA_DELETE_DOCUMENT,
    PZ_SAGA_EDIT_DOCUMENT,
    PZ_SAGA_GET_DOCUMENT_TYPES,
    PZ_SAGA_GET_DOCUMENTS,
    PZ_SAGA_GET_LOKET_INFO,
} from '../actionTypes';
import { setPagination } from '../app/appSaga';
import {
    AddDocumentAction,
    DeleteDocumentAction,
    EditDocumentAction,
} from './pzModels';

function* fetchDocumentTypes(): any {
    yield put({ type: PZ_GET_DOCUMENT_TYPES_REQUEST });

    try {
        const documentTypes = yield call(() => getDocumentTypes());
        const state = yield select();
        const { permissions } = state.authenticatedUserReducer;

        if (checkPermission(permissions, 'view-all-document-types', 'pz-saga')) {
            yield put({ type: PZ_GET_DOCUMENT_TYPES_SUCCESS, documentTypes });
        }
    } catch (error) {
        yield put({ type: PZ_GET_DOCUMENT_TYPES_FAILURE, errors: [error] });
    }
}

function* fetchDocuments(): any {
    yield put({ type: PZ_GET_DOCUMENTS_REQUEST });

    try {
        const state = yield select();
        const { filterReducer, globalFiltersReducer } = state;
        const {
            onlyShowMainDepartment,
            documentSearch,
            userTypes,
        } = filterReducer.filter;
        const { departmentOptions } = globalFiltersReducer;
        const departments = getCheckedFormOptionsValue(departmentOptions);
        const { documentTypes } = filterReducer.filter.pz;
        const { paginationReducer } = state;

        const { data, pagination } = yield call(() => getAllDocuments({
            filter: {
                onlyShowMainDepartment,
                search: documentSearch,
                userTypes,
                documentTypes,
            },
            departments,
            pagination: { number: paginationReducer.number, size: PAGE_SIZES.PZ[DOCUMENTS] },
        })) as unknown as DataWithPagination<DocumentResource[]>;

        yield call(() => setPagination({ pagination }));
        yield put({ type: PZ_GET_DOCUMENTS_SUCCESS, documents: data, page: pagination.number });
    } catch (error) {
        yield put({ type: PZ_GET_DOCUMENTS_FAILURE, errors: [error] });
    }
}

function* addDocument(action: AddDocumentAction) {
    yield put({ type: PZ_ADD_DOCUMENT_REQUEST, documentFormData: action.formData });
    try {
        yield call(() => postDocument({ ...action.formData }));

        yield fetchDocuments();
        yield put({ type: PZ_ADD_DOCUMENT_SUCCESS, lastAddedDocument: action.formData });
        yield put({ type: APP_SAGA_ADD_TOAST, toast: translate('pages.management.addDocumentSuccess'), toastType: TOAST_TYPE_PASS });
    } catch (error) {
        yield put({ type: PZ_ADD_DOCUMENT_FAILURE });
    }
}

function* editDocument(action: EditDocumentAction) {
    yield put({ type: PZ_EDIT_DOCUMENT_REQUEST });

    try {
        yield call(() => patchDocument({ ...action.formData }));

        yield* fetchDocuments();
        yield put({ type: PZ_EDIT_DOCUMENT_SUCCESS, lastAddedDocument: action.formData });
        yield put({ type: APP_SAGA_ADD_TOAST, toast: translate('pages.management.editDocumentSuccess'), toastType: TOAST_TYPE_PASS });
    } catch (error) {
        yield put({ type: PZ_EDIT_DOCUMENT_FAILURE, errors: [error] });
    }
}

function* deleteDocument(action: DeleteDocumentAction) {
    yield put({ type: PZ_DELETE_DOCUMENT_REQUEST });

    try {
        yield call(() => delDocument(action.documentId));

        yield* fetchDocuments();
        yield put({ type: PZ_DELETE_DOCUMENT_SUCCESS });
        yield put({ type: APP_SAGA_ADD_TOAST, toast: translate('pages.management.deleteDocumentSuccess'), toastType: TOAST_TYPE_PASS });
    } catch (error) {
        yield put({ type: PZ_DELETE_DOCUMENT_FAILURE, errors: [error] });
    }
}

function* getLoketData(): any {
    yield put({ type: PZ_GET_LOKET_INFO_REQUEST });

    try {
        const response = yield call(() => getLoketInfo());

        yield put({ type: PZ_GET_LOKET_INFO_SUCCESS, loketData: response.data });
    } catch (error) {
        yield put({ type: PZ_GET_LOKET_INFO_FAILURE, errors: [error] });
    }
}


export default function* pzWatcher() {
    yield takeLatest(PZ_SAGA_GET_DOCUMENT_TYPES, fetchDocumentTypes);
    yield takeLatest(PZ_SAGA_GET_DOCUMENTS, fetchDocuments);
    yield takeLatest(PZ_SAGA_ADD_DOCUMENT, addDocument);
    yield takeLatest(PZ_SAGA_EDIT_DOCUMENT, editDocument);
    yield takeLatest(PZ_SAGA_DELETE_DOCUMENT, deleteDocument);
    yield takeLatest(PZ_SAGA_GET_LOKET_INFO, getLoketData);
}
