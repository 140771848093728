import React, { FC, useEffect, useState } from 'react';

import { Table } from 'reactstrap';

import { ShiftConceptPlanningResource } from '../../../../entities/ShiftConceptPlanning/ShiftConceptPlanning';
import {
    Absence,
    LeaveOfAbsenceResource,
    PreferToWorkTimeSlot,
    ShiftPlanning,
    SpecialDayViewModel,
    UnavailableToWorkTimeSlot,
    UserWithConceptAvailability,
} from '../../../../models';
import ListViewTableHead from '../ListViewTable/subcomponents/ListViewTableHead/ListViewTableHead';
import ConceptsListViewUser from './subcomponents/ConceptsListViewUser/ConceptsListViewUser';

import './ConceptsListViewTable.scss';

export type ResourceTypes = LeaveOfAbsenceResource | Absence | PreferToWorkTimeSlot | UnavailableToWorkTimeSlot
| ShiftConceptPlanningResource | ShiftPlanning;

interface UserConceptAvailabilityOnDateProps {
    loading: boolean;
    days: Date[];
    specialDays?: SpecialDayViewModel[];
    startDate: Date;
    endDate: Date;
    users: UserWithConceptAvailability[];
    isFilterEmploymentTypeBadgeActive: boolean;
    canAddPlannings: boolean;
    onAvailabilityClick: (resources: ResourceTypes) => void;
    onAddButtonClick: (date: Date, user: UserWithConceptAvailability) => void;
}

const ConceptsListViewTable: FC<UserConceptAvailabilityOnDateProps> = ({
    loading,
    days,
    specialDays = [],
    isFilterEmploymentTypeBadgeActive,
    canAddPlannings,
    onAvailabilityClick,
    onAddButtonClick,
    ...props
}) => {
    const [users, setUsers] = useState<UserWithConceptAvailability[]>(props.users || []);

    useEffect(() => {
        if (!loading && props.users.length) {
            setUsers(props.users);
        }
    }, [props.users, loading]);

    return (
        <div className="concepts-list-view-table table-container-responsive">
            <div className="table-container">
                <Table className="concepts-list-view-table__table">
                    <ListViewTableHead
                        dates={days}
                        specialDays={specialDays}
                        className="concepts-list-view-table__table-head"

                    />
                    <tbody className="concepts-list-view-table-body">
                        { users.map((user: UserWithConceptAvailability) => (
                            <ConceptsListViewUser
                                key={user.id}
                                loading={loading}
                                user={user}
                                range={days}
                                isFilterEmploymentTypeBadgeActive={isFilterEmploymentTypeBadgeActive}
                                canAddPlannings={canAddPlannings}
                                onAvailabilityClick={onAvailabilityClick}
                                onAddButtonClick={onAddButtonClick}
                            />
                        )) }
                    </tbody>
                </Table>
            </div>
        </div>
    );
};

export default ConceptsListViewTable;
