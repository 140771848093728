import { FC, ReactElement, useState } from 'react';

import { useToggle } from 'react-use';

import { ModalContent, PacoModal } from '../../compositions';
import { ConnectedAddDistrictDivisionTemplateForm, ConnectedEditDistrictDivisionTemplateForm, ConnectedHelpTooltip } from '../../connectors';
import ConnectedDeleteDistrictDivisionTemplateForm from '../../connectors/@forms/ConnectedDeleteDistrictDivisionTemplateForm/ConnectedDeleteDistrictDivisionTemplateForm';
import { DistrictDivisionTemplate } from '../../entities/DistrictDivisionTemplate/DistrictDivisionTemplate';
import { Permission } from '../../entities/Permission/Permission';
import trans from '../../helpers/trans';
import { DistrictDivisionTemplatesTable } from '../index';

import './DistrictDivisionTemplatesContainer.scss';

interface DistrictDivisionTemplateContainerProps {
    isLoading?: boolean;
    districtDivisionTemplates: DistrictDivisionTemplate[];
    permissions: Permission[];
    onDistrictDivisionTemplateClick: (districtDivisionTemplate: DistrictDivisionTemplate) => void;
}

const DistrictDivisionTemplatesContainer: FC<DistrictDivisionTemplateContainerProps> = ({
    isLoading,
    districtDivisionTemplates,
    permissions,
    onDistrictDivisionTemplateClick,
}): ReactElement => {
    const [addDistrictDivisionTemplateModalIsOpen, toggleAddDistrictDivisionTemplateModalIsOpen] = useToggle(false);
    const [editDistrictDivisionTemplateModalIsOpen, toggleEditDistrictDivisionTemplateModalIsOpen] = useToggle(false);
    const [deleteDistrictDivisionTemplateModalIsOpen, toggleDeleteDistrictDivisionTemplateModalIsOpen] = useToggle(false);
    const [districtDivisionTemplateState, setDistrictDivisionTemplateState] = useState<DistrictDivisionTemplate | undefined>(undefined);
    const [districtDivisionTemplateIdState, setDistrictDivisionTemplateIdState] = useState<string | undefined>(undefined);

    const handleEditDistrictDivisionTemplateClick = (districtDivisionTemplate: DistrictDivisionTemplate): void => {
        toggleEditDistrictDivisionTemplateModalIsOpen();
        setDistrictDivisionTemplateState(districtDivisionTemplate);
    };

    const handleDeleteDistrictDivisionTemplateClick = (districtDivisionTemplateId: string): void => {
        toggleDeleteDistrictDivisionTemplateModalIsOpen();
        setDistrictDivisionTemplateIdState(districtDivisionTemplateId);
    };

    return (
        <>
            <DistrictDivisionTemplatesTable
                isLoading={isLoading}
                districtDivisionTemplates={districtDivisionTemplates}
                permissions={permissions}
                onAddDistrictDivisionTemplateClick={toggleAddDistrictDivisionTemplateModalIsOpen}
                onEditDistrictDivisionTemplateClick={handleEditDistrictDivisionTemplateClick}
                onDeleteDistrictDivisionTemplateClick={handleDeleteDistrictDivisionTemplateClick}
                onDistrictDivisionTemplateClick={onDistrictDivisionTemplateClick}
            />
            {addDistrictDivisionTemplateModalIsOpen && (
                <PacoModal>
                    <ModalContent title={trans('common.addDistrictDivision')}>
                        <ConnectedAddDistrictDivisionTemplateForm onClose={toggleAddDistrictDivisionTemplateModalIsOpen} />
                    </ModalContent>
                </PacoModal>
            )}
            {editDistrictDivisionTemplateModalIsOpen && districtDivisionTemplateState && (
                <PacoModal>
                    <ModalContent title={trans('common.editDistrictDivision')}>
                        <ConnectedEditDistrictDivisionTemplateForm
                            districtDivisionTemplate={districtDivisionTemplateState}
                            onClose={toggleEditDistrictDivisionTemplateModalIsOpen}
                        />
                    </ModalContent>
                </PacoModal>
            )}
            {deleteDistrictDivisionTemplateModalIsOpen && districtDivisionTemplateIdState && (
                <PacoModal>
                    <ModalContent title={trans('common.deleteDistrictDivision')}>
                        <ConnectedDeleteDistrictDivisionTemplateForm
                            districtDivisionTemplateId={districtDivisionTemplateIdState}
                            onClose={toggleDeleteDistrictDivisionTemplateModalIsOpen}
                        />
                    </ModalContent>
                </PacoModal>
            )}
            {districtDivisionTemplates.length ? (
                <>
                    <ConnectedHelpTooltip
                        index={0}
                        route="districtDivisionTemplates"
                        subTitle={trans('help.settings.districtDivisionTemplates.title')}
                        text={trans('help.settings.districtDivisionTemplates.text')}
                        title={trans('help.settings.title')}
                        className="district-division-templates-container__overview-help-tooltip"
                    />
                    <ConnectedHelpTooltip
                        index={1}
                        route="districtDivisionTemplates"
                        subTitle={trans('help.settings.addDistrictDivisionTemplate.title')}
                        text={trans('help.settings.addDistrictDivisionTemplate.text')}
                        title={trans('help.settings.title')}
                        className="district-division-templates-container__add-district-division-template-help-tooltip"
                    />
                    <ConnectedHelpTooltip
                        index={2}
                        route="districtDivisionTemplates"
                        subTitle={trans('help.settings.editDistrictDivisionTemplate.title')}
                        text={trans('help.settings.editDistrictDivisionTemplate.text')}
                        title={trans('help.settings.title')}
                        showMobileInfoWarning
                        className="district-division-templates-container__edit-district-division-template-help-tooltip"
                    />
                </>
            ) : (
                <ConnectedHelpTooltip
                    index={0}
                    route="districtDivisionTemplates"
                    subTitle={trans('help.settings.districtDivisionTemplatesEmptyState.title')}
                    text={trans('help.settings.districtDivisionTemplatesEmptyState.text')}
                    title={trans('help.settings.title')}
                    className="district-division-templates-container__empty-state-help-tooltip"
                />
            )}
        </>
    );
};

export default DistrictDivisionTemplatesContainer;
