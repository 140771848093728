import React, { FC } from 'react';

import './FilterBlock.scss';


interface FilterBlockProps {
    title?: string;
    hideMobile?: boolean;
    className?: string;
    children: React.ReactNode;
}

const FilterBlock: FC<FilterBlockProps> = ({
    title,
    hideMobile = false,
    className = '',
    children,
}) => (
    <div className={`filter-block ${className} ${hideMobile ? 'filter-block--hide-mobile' : ''}`}>
        {title && <div className="filter-block__title">{title}</div>}
        {children}
    </div>
);

export default FilterBlock;
