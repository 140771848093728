import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

import './ButtonAuthenticate.scss';

const ButtonAuthenticate = ({
    onClick,
    text,
    mobile,
    disabled,
    responsive,
    className,
}) => {
    if (mobile) {
        return (
            <button
                type="button"
                disabled={disabled}
                onClick={onClick}
                className={`button-authenticate-mobile ${className}`}
            >
                <span className="button-authenticate-plus-icon">+</span>
            </button>
        );
    }

    const buttonClassNames = classNames('button-authenticate', {
        'button-authenticate--not-responsive': !responsive,
    }, className);

    return (
        <Button
            color="orange"
            disabled={disabled}
            onClick={onClick}
            className={buttonClassNames}
        >
            <span className="button-authenticate-plus-icon">+</span>
            <span>{text}</span>
        </Button>
    );
};

ButtonAuthenticate.propTypes = {
    onClick: PropTypes.func,
    text: PropTypes.string,
    mobile: PropTypes.bool,
    responsive: PropTypes.bool,
    disabled: PropTypes.bool,
    className: PropTypes.string,
};
ButtonAuthenticate.defaultProps = {
    onClick: null,
    text: null,
    mobile: false,
    responsive: true,
    disabled: false,
    className: '',
};

export default ButtonAuthenticate;
