import React, { FC, ReactElement } from 'react';

import { NavLink, useNavigate } from 'react-router-dom';

import { PayrollPeriod } from '../../../@paco/entities/PayrollPeriod/PayrollPeriod';
import { PrivateRouteGroupRoutes } from '../../../components/PrivateRouteGroup/PrivateRouteGroupRoutes';
import Icon from '../../../components/style/Icon/Icon';
import { translate } from '../../../helpers/translations/translator';
import CompletePeriodTopBar from '../../../pages/PZ/components/CompletePeriodTopBar/CompletePeriodTopBar';
import { PZRoutes } from '../../../pages/PZ/PZRoutes';

import './CompletePeriodHeaderContainer.scss';

interface ConnectedCompletePeriodHeaderProps {
    activePeriod?: PayrollPeriod;
    payrollPeriods: PayrollPeriod[];
    onPeriodSelect: (period: PayrollPeriod) => void;
    className?: string;
}

const CompletePeriodHeaderContainer: FC<ConnectedCompletePeriodHeaderProps> = ({
    activePeriod,
    payrollPeriods,
    onPeriodSelect,
    className = '',
}): ReactElement => {
    const navigate = useNavigate();

    const handlePayrollPeriodSelect = (period: PayrollPeriod) => {
        const { id } = period;
        navigate(`${PrivateRouteGroupRoutes.pz()}/${PZRoutes.completePeriodDetail(id)}`);

        onPeriodSelect(period);
    };

    return (
        <div className={`complete-period-detail-header-container ${className}`}>
            <CompletePeriodTopBar
                activePeriod={activePeriod}
                payrollPeriods={payrollPeriods}
                onPeriodSelect={handlePayrollPeriodSelect}
            />
            <NavLink
                className="complete-period-detail-header-container__back-button"
                to={{ pathname: `/${translate('nav.pz.link')}/${translate('nav.pz.completePeriods.link')}` }}
            >
                <Icon kind="arrow_back" />
                {translate('common.backToOverview')}
            </NavLink>
        </div>
    );
};

export default CompletePeriodHeaderContainer;
