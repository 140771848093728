import axios from 'axios';

import { formatDate, getEndOfWorkDay, getStartOfWorkDay } from '../../@paco/helpers/date';
import { DateFormat } from '../../@paco/types/dateFormatTypes';
import { TRACK_TYPE_FINISHED, TRACK_TYPE_REVIEW } from '../../constants';
import { getApproved } from '../api-ts/track';
import { convertDateToApiParamDateTime } from '../date';
import { getLoketApiUrl } from '../index';

export function getIncludes(trackType) {
    const includes = [
        'department',
        'department.group',
        'owner.roles',
        'owner.departments',
        'owner.employmentType',
        'shift.department',
        'shift.shiftPlannings',
        'shift.shiftPlannings.user',
        'shift.shiftPlannings.user.roles',
        'shift.shiftPlannings.user.departments',
    ];

    if (trackType === TRACK_TYPE_REVIEW || trackType === TRACK_TYPE_FINISHED) {
        return [
            ...includes,
            'feedback',
            'assessor',
            'assessor.roles',
            'comments',
            'comments.owner',
        ];
    }

    return includes;
}

export const getUserTracks = (userId, date) => {
    const startDate = getStartOfWorkDay(date);
    const endDate = getEndOfWorkDay(date);

    return axios.get(
        `${process.env.REACT_APP_API_URL}/tracks`,
        {
            params: {
                startDate: formatDate(startDate, DateFormat.apiDateTime),
                endDate: formatDate(endDate, DateFormat.apiDateTime),
                userUuids: JSON.stringify(userId),
            },
        },
    );
};

export const updateTrack = (id, track) => axios.patch(`${process.env.REACT_APP_API_URL}/tracks/${id}`, track);

export function createApproveTrackData(action) {
    return {
        data: {
            type: 'tracks',
            id: action.id,
            attributes: {
                accepted: 1,
            },
            // Required in back-end as key
            relationships: {},
        },
    };
}

export function createEditTrackData(action) {
    const { department } = action.data;
    const newData = { ...action.data };

    delete newData.department;

    return {
        data: {
            type: 'tracks',
            id: action.id,
            attributes: {
                ...newData,
                checkIn: convertDateToApiParamDateTime(newData.checkIn),
                checkOut: convertDateToApiParamDateTime(newData.checkOut),
                billableStart: convertDateToApiParamDateTime(newData.checkIn),
                billableEnd: convertDateToApiParamDateTime(newData.checkOut),
            },
            ...(department && {
                relationships: {
                    department: {
                        data: {
                            id: department,
                            type: 'departments',
                        },
                    },
                },
            }),
        },
    };
}

export const addTrackCall = (data) => {
    const body = {
        type: 'tracks',
        attributes: {
            checkIn: convertDateToApiParamDateTime(data.checkIn),
            ...(data.checkOut && { checkOut: convertDateToApiParamDateTime(data.checkOut) }),
            billableStart: convertDateToApiParamDateTime(data.checkIn),
            ...(data.checkOut && { billableEnd: convertDateToApiParamDateTime(data.checkOut) }),
            ...(data.meal && { meal: data.meal }),
            ...(data.pause && { pause: data.pause }),
            ...(data.checkOut && { accepted: true }),
        },
        relationships: {
            owner: {
                data: {
                    type: 'users',
                    id: data.userId,
                },
            },
            department: {
                data: {
                    type: 'departments',
                    id: data.departmentId,
                },
            },
        },
    };

    return axios.post(`${process.env.REACT_APP_API_URL}/tracks/`, { data: body });
};

export const deleteTrackCall = (id) => axios.delete(`${process.env.REACT_APP_API_URL}/tracks/${id}`);

export const getTracksCSV = (filter, departments, data) => axios.get(
    `${process.env.REACT_APP_API_URL}/tracks/csv${getApproved(TRACK_TYPE_FINISHED)}`,
    {
        params: {
            departments,
            search: filter.employeeSearch,
            employmentTypes: (filter.employeeContractTypes || []).length > 0 ? JSON.stringify(filter.employeeContractTypes) : undefined,
            startDate: formatDate(data.startDate, DateFormat.apiDateTime),
            endDate: formatDate(data.endDate, DateFormat.apiDateTime),
            'page[number]': 1,
        },
    },
);

export const getUserTracksCSV = (filter, departments, data) => {
    const { payrollPeriodIds } = data;
    const { employeeContractTypes } = filter;

    return axios.get(
        `${process.env.REACT_APP_API_URL}/users/export/csv`,
        {
            params: {
                payrollPeriods: (payrollPeriodIds || []).length > 0 ? JSON.stringify(payrollPeriodIds) : undefined,
                departments,
                employmentTypes: (employeeContractTypes || []).length > 0 ? JSON.stringify(employeeContractTypes) : undefined,
                search: filter.employeeSearch,
                'page[number]': 1,
            },
        },
    );
};

export const getLoketCSV = (filter, departments, data) => {
    const { payrollPeriodIds } = data;
    const payrollPeriodId = payrollPeriodIds.length ? payrollPeriodIds[0] : undefined;

    return axios.get(
        `${process.env.REACT_APP_API_URL}/users/export/loket-csv`,
        {
            params: {
                payrollPeriod: payrollPeriodId,
                departments,
                search: filter.employeeSearch,
                'page[number]': 1,
            },
        },
    );
};

export const postSyncTvt = (payrollPeriod) => {
    const { id } = payrollPeriod;

    return axios.put(
        `${getLoketApiUrl()}/leave/tvt/sync?payrollId=${id}`,
    );
};
