import { FC, useEffect } from 'react';

import { useEffectOnce } from 'react-use';

import { Dashboard } from '../../containers';
import checkPermission from '../../helpers/permissions/checkPermission';
import {
    fetchIncompleteUsers,
    fetchNotifications,
    fetchOpenAbsenceHours,
    fetchOpenLeaveOfAbsenceHours,
    initializeIncompleteUsers,
    initializeOpenAbsenceHours,
    initializeOpenLeaveOfAbsenceHours,
} from '../../redux/@interface/notifications/notificationsActions';
import { getNotificationsInitializedState } from '../../redux/@interface/notifications/notificationsReducer';
import { fetchDashboardSettings } from '../../redux/dashboard/dashboardActions';
import { getPacoPayrollPeriods } from '../../redux/payrollPeriods/payrollPeriodsActions';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';
import { DashboardSettingType } from '../../types';

const ConnectedDashboard: FC = () => {
    const dispatch = useTypedDispatch();

    const { isLoading: shiftsIsLoading } = useTypedSelector(state => state.shiftsCalendarReducer);
    const { user, isLoading: userIsLoading } = useTypedSelector(state => state.authenticatedUserReducer);
    const {
        notifications,
        isLoading: notificationsIsLoading,
        isOpenLeaveOfAbsenceHoursInitialized,
        isOpenAbsenceHoursInitialized,
    } = useTypedSelector(state => state.notificationsReducer);
    const notificationsIsInitialized = useTypedSelector(getNotificationsInitializedState);
    const { payrollPeriods } = useTypedSelector(state => state.pacoPayrollPeriodsReducer);

    const userWidgets: DashboardSettingType[] = user ? user.dashboardSettings.map(dashboardSetting => dashboardSetting.type) : [];
    const permissions = user?.permissions || [];
    const isInitialized = notificationsIsInitialized && isOpenLeaveOfAbsenceHoursInitialized && isOpenAbsenceHoursInitialized;

    const canViewAbsenceHours = checkPermission(permissions, 'view-all-absence-hours');
    const canViewLeaveOfAbsenceHours = checkPermission(permissions, 'view-all-leave-of-absence-hours');
    const canViewUserProfiles = checkPermission(permissions, 'view-all-user-profiles');
    const canViewEmployments = checkPermission(permissions, 'view-all-employments');

    useEffectOnce(() => {
        // If notificationsIsInitialized is false then the store is still initializing and fetching notifications data
        // (see getGeneralData in appSaga.js).
        if (notificationsIsInitialized) {
            dispatch(fetchNotifications());
        }
    });

    useEffect(() => {
        if (notificationsIsInitialized && !!payrollPeriods.length) {
            if (canViewAbsenceHours) {
                dispatch(fetchOpenAbsenceHours());
            } else {
                dispatch(initializeOpenAbsenceHours());
            }

            if (canViewLeaveOfAbsenceHours) {
                dispatch(fetchOpenLeaveOfAbsenceHours());
            } else {
                dispatch(initializeOpenLeaveOfAbsenceHours());
            }

            if (canViewEmployments && canViewUserProfiles) {
                dispatch(fetchIncompleteUsers());
            } else {
                dispatch(initializeIncompleteUsers());
            }
        }
    }, [notificationsIsInitialized, payrollPeriods]);

    useEffect(() => {
        dispatch(getPacoPayrollPeriods());
        dispatch(fetchDashboardSettings());
    }, []);

    return (
        <Dashboard
            shiftsIsLoading={shiftsIsLoading}
            userIsLoading={userIsLoading || !user}
            notificationsIsLoading={notificationsIsLoading || !isInitialized}
            notifications={notifications}
            payrollPeriods={payrollPeriods}
            permissions={permissions}
            user={user}
            userWidgetTypes={userWidgets}
        />
    );
};

export default ConnectedDashboard;
