import { FC, useMemo, useRef } from 'react';

import classNames from 'classnames';
import {
    NavLink,
    Route,
    Routes,
    useMatch,
} from 'react-router-dom';
import { useToggle } from 'react-use';

import { PrivateRouteGroupRoutes } from '../../../components/PrivateRouteGroup/PrivateRouteGroupRoutes';
import { ScheduleRoutes } from '../../../pages/Schedule/ScheduleRoutes';
import { SettingsRoutes } from '../../../pages/Settings/SettingsRoutes';
import { Button, Icon } from '../../components';
import { IconButton, ModalContent, PacoModal } from '../../compositions';
import { ConnectedAddDistrictDivisionTemplateForm, ConnectedAddShiftForm } from '../../connectors';
import { Notifications } from '../../entities/Notifications/Notifications';
import { Permission } from '../../entities/Permission/Permission';
import { Setting } from '../../entities/Setting/Setting';
import { AuthenticatedUser } from '../../entities/User/User';
import checkPermission from '../../helpers/permissions/checkPermission';
import trans from '../../helpers/trans';
import { NavRoute, SubNavRoute } from '../../routes/routes';
import { getActiveRoute, getUserRoutes } from './helpers';
import {
    MainNavigation,
    MobileAddButton,
    MobileNavigation,
    SubNavigation,
    UserMenu,
} from './subcomponents';

import './Navigation.scss';

export interface ResourcesLength {
    absences: number;
    exchangeRequests: number;
    leaveOfAbsenceRequests: number;
    tracksToReview: number;
    runningTracks: number;
}

export interface SubNavRouteWithCounter extends SubNavRoute {
    counter?: number;
}

export interface NavRouteWithCounter extends NavRoute {
    counter?: number;
    children: SubNavRouteWithCounter[];
}

interface NavigationProps {
    showHelpButton?: boolean;
    showHelpModal?: boolean;
    currentUser?: AuthenticatedUser;
    notifications: Notifications;
    pathname?: string;
    permissions: Permission[];
    settings: Setting[];
    onHelpButtonClick: () => void;
    onStopHelpButtonClick: () => void;
    onLogoutClick: () => void;
    className?: string;
}

const Navigation: FC<NavigationProps> = ({
    showHelpButton,
    showHelpModal,
    currentUser,
    notifications,
    pathname,
    permissions,
    settings,
    onHelpButtonClick,
    onStopHelpButtonClick,
    onLogoutClick,
    className = '',
}) => {
    const [showAddForm, toggleShowAddForm] = useToggle(false);
    const [addDistrictDivisionTemplateModalIsOpen, toggleAddDistrictDivisionTemplateModalIsOpen] = useToggle(false);
    const isDashboard = !!useMatch(PrivateRouteGroupRoutes.dashboard());
    const role = currentUser?.role;
    const userRoutes = useMemo(() => getUserRoutes(permissions, settings, notifications, role), [permissions, notifications, settings, role]);
    const activeRoute = useMemo(() => getActiveRoute(userRoutes, pathname), [userRoutes, pathname]);
    const canAddShifts = useMemo(() => checkPermission(permissions, 'add-new-shifts', 'navigation'), [permissions]);
    const canAddDistrictDivisionTemplates = useMemo(() => checkPermission(permissions, 'add-new-district-division-templates', 'navigation'), [permissions]);
    const hasSingleChildRoute = activeRoute?.children?.length === 1;

    const userInitials = currentUser?.person
        ? `${currentUser.person.firstname[0]}${currentUser.person.lastname[0]}`
        : '';
    const showDevelopmentIndicator = ['local', 'dev'].includes(process.env.REACT_APP_APP_ENV || '');

    const toggleUserMenuButtonRef = useRef<HTMLButtonElement>(null);

    const [userMenuIsOpen, toggleUserMenuIsOpen] = useToggle(false);
    const [mobileMenuIsOpen, toggleMobileMenuIsOpen] = useToggle(false);

    const navigationClassNames = useMemo(() => classNames('navigation', {
        'navigation--show-mobile-menu': mobileMenuIsOpen,
        'navigation--show-user-menu': userMenuIsOpen,
        'navigation--is-dashboard': isDashboard,
        'navigation--hide-sub-navigation': isDashboard || hasSingleChildRoute,
    }, className), [
        mobileMenuIsOpen,
        userRoutes,
        userMenuIsOpen,
        isDashboard,
        hasSingleChildRoute,
    ]);

    return (
        <div className={navigationClassNames}>
            <div className="navigation__header">
                <NavLink
                    to={PrivateRouteGroupRoutes.dashboard()}
                    className="navigation__dashboard-button"
                >
                    <Icon name="home" />
                </NavLink>
                <div className="navigation__scroll-container">
                    <MainNavigation
                        routes={userRoutes}
                        className="navigation__main-navigation"
                    />
                    {showDevelopmentIndicator && (
                        <div className="navigation__development-indicator">
                            {trans('containers.navigation.development')}
                        </div>
                    )}
                    {showHelpModal && (
                        <IconButton
                            text={trans('containers.navigation.closeHelpMenu')}
                            icon="close-circle"
                            onClick={onStopHelpButtonClick}
                            className="navigation__stop-help-button"
                            iconClassName="navigation__stop-help-button-icon"
                        />
                    )}
                    <Routes>
                        {canAddShifts && (
                            <Route
                                path={`${PrivateRouteGroupRoutes.shifts()}/${ScheduleRoutes.calendar()}`}
                                element={<MobileAddButton onClick={toggleShowAddForm} label={trans('common.addShift')} />}
                            />
                        )}
                    </Routes>
                    <Routes>
                        {canAddDistrictDivisionTemplates && (
                            <Route
                                path={`${PrivateRouteGroupRoutes.settings()}/${SettingsRoutes.districtDivisionTemplates()}`}
                                element={<MobileAddButton onClick={toggleAddDistrictDivisionTemplateModalIsOpen} label={trans('common.addShift')} />}
                            />
                        )}
                    </Routes>
                    <IconButton
                        hideLabel
                        text={trans('containers.navigation.openMobileMenu')}
                        icon="hamburger"
                        onClick={toggleMobileMenuIsOpen}
                        className="navigation__toggle-mobile-menu-button"
                    />
                    <Button
                        ref={toggleUserMenuButtonRef}
                        text={userInitials}
                        onClick={toggleUserMenuIsOpen}
                        className="navigation__toggle-user-menu-button"
                    />
                    {showHelpButton && (
                        <IconButton
                            hideLabel
                            icon="help-circle-outline"
                            text={trans('containers.navigation.help')}
                            onClick={onHelpButtonClick}
                            className="navigation__help-button"
                            iconClassName="navigation__help-button-icon"
                        />
                    )}
                    {userMenuIsOpen && (
                        <UserMenu
                            userId={currentUser?.id}
                            onClose={toggleUserMenuIsOpen}
                            onLogoutClick={onLogoutClick}
                            className="navigation__user-menu"
                        />
                    )}
                </div>
            </div>
            <SubNavigation
                navRoute={activeRoute}
                className="navigation__sub-navigation"
            />
            <MobileNavigation
                activeRoute={activeRoute?.key || 'shifts'}
                routes={userRoutes}
                userId={currentUser?.id}
                userInitials={userInitials}
                onLinkClick={toggleMobileMenuIsOpen}
                onLogoutClick={onLogoutClick}
                className="navigation__mobile-navigation"
            />

            {showAddForm && (
                <PacoModal>
                    <Routes>
                        <Route
                            path={`${PrivateRouteGroupRoutes.shifts()}/${ScheduleRoutes.calendar()}`}
                            element={(
                                <ModalContent title={trans('common.addShift')}>
                                    <ConnectedAddShiftForm onClose={toggleShowAddForm} />
                                </ModalContent>
                            )}
                        />
                    </Routes>
                </PacoModal>
            )}
            {addDistrictDivisionTemplateModalIsOpen && (
                <PacoModal>
                    <Routes>
                        <Route
                            path={`${PrivateRouteGroupRoutes.settings()}/${SettingsRoutes.districtDivisionTemplates()}`}
                            element={(
                                <ModalContent title={trans('common.addDistrictDivision')}>
                                    <ConnectedAddDistrictDivisionTemplateForm onClose={toggleAddDistrictDivisionTemplateModalIsOpen} />
                                </ModalContent>
                            )}
                        />
                    </Routes>
                </PacoModal>
            )}

            {showHelpModal && <PacoModal onModalClick={onStopHelpButtonClick} className="navigation__help-modal" />}
        </div>
    );
};

export default Navigation;
