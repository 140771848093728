import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal } from 'reactstrap';

import { LoadingSpinner } from '../../@paco/components';
import { UserProfileCard } from '../../@paco/compositions';
import { ConnectedHelpTooltip, ConnectedPermissionsList, ConnectedUserCalendar } from '../../@paco/connectors';
import { transformLegacyResourceToPermissionGroup } from '../../@paco/entities/PermissionGroup/PermissionGroupTransformers';
import trans from '../../@paco/helpers/trans';
import { getPacoPayrollPeriods } from '../../@paco/redux/payrollPeriods/payrollPeriodsActions';
import { downloadYearEndStatement } from '../../@paco/redux/yearEndStatement/yearEndStatementActions';
import { RoleType } from '../../@paco/types/roleTypes';
import {
    HR,
    TYPE_ABSENCE,
    TYPE_LEAVE_OF_ABSENCE,
    TYPE_UNAVAILABLE_TO_WORK_TIME_SLOT,
    USER,
} from '../../constants';
import { checkPermission, getObjProperty } from '../../helpers';
import { translate } from '../../helpers/translations/translator';
import { withRouter } from '../../helpers/WithRouter';
import { deleteAbsence, editAbsence, editLeaveOfAbsence } from '../../redux/absences-ts/absencesActions';
import { getPayrollPeriods } from '../../redux/app/appActions';
import { getResourceLogs } from '../../redux/logs/logsActions';
import {
    addDocument,
    deleteDocument,
    deleteUserPlanningPreference,
    editDocument,
    getPermissionGroups,
    linkUserToLoket,
    setUserPlanningPreference,
} from '../../redux/management/managementActions';
import {
    addUserNote,
    deleteUserNote,
    editUserNote,
    getUser,
} from '../../redux/management-ts/managementActions';
import DeclineLeaveOfAbsenceForm from '../Absences/forms/DeclineLeaveOfAbsenceForm';
import EditAbsenceForm from '../Absences/forms/EditAbsenceForm/EditAbsenceForm';
import ResolveLeaveOfAbsenceForm from '../Absences/forms/ResolveLeaveOfAbsenceForm/ResolveLeaveOfAbsenceForm';
import Absences from './components/Absences/Absences';
import Availability from './components/Availability/Availability';
import Contracts from './components/Contracts/Contracts';
import Couplings from './components/Couplings/Couplings';
import Documents from './components/Documents/Documents';
import Notes from './components/Notes/Notes';
import Satisfaction from './components/Satisfaction/Satisfaction';
import UserNavigation from './components/UserNavigation/UserNavigation';
import AddOrEditAvailabilityTime from './forms/AddOrEditAvailabilityTime/AddOrEditAvailabilityTime';
import AddOrEditDocument from './forms/AddOrEditDocument';
import DeleteDocumentForm from './forms/DeleteDocument';

import './User.scss';

class User extends Component {
    constructor(props) {
        super(props);

        this.state = {
            addDocumentForm: false,
            deleteDocumentForm: false,
            deleteDocumentId: null,
            editDocumentData: null,
            activeLink: translate('nav.userManagement.users.personalData.link'),
            activeAbsence: null,
            resolveLOAForm: false,
            editLOAData: null,
            editAbsenceForm: false,
            declineForm: false,
            unavailableWorkTimeSlotForm: false,
            unavailableWorkTimeSlotData: null,
        };
    }

    componentDidMount() {
        const { location } = this.props;
        const { search } = location;
        const activeLink = search.substring(1);
        this.getUser();

        if (activeLink.length > 0) {
            this.setState({
                activeLink,
            });
        }
    }

    getUser = () => {
        const { dispatch, userId } = this.props;

        dispatch(getUser(userId));
        dispatch(getPayrollPeriods());
        dispatch(getPacoPayrollPeriods());
        dispatch(getPermissionGroups());

        window.scrollTo(0, 0);
    };

    dispatchAddDocument = (data) => {
        const { users } = this.props;
        const { editDocumentData } = this.state;
        const { user, dispatch } = this.props;
        const newData = { ...data };

        newData.userId = user.id;
        newData.hr = users
            .filter(usr => usr.roles.filter(role => role.slug === HR).length)
            .map(usr => usr.id);

        if (editDocumentData) {
            dispatch(editDocument(newData, user.id));
        } else {
            dispatch(addDocument(newData, user.id));
        }
        this.setState({
            addDocumentForm: false,
        });
    }

    dispatchOpenLogsModal = (id, type) => {
        const { dispatch } = this.props;
        dispatch(getResourceLogs(id, type));
    }

    dispatchDeleteDocument = (documentId) => {
        const { dispatch, user } = this.props;
        dispatch(deleteDocument(documentId, user.id));
        this.setState({
            deleteDocumentForm: false,
        });
    }

    dispatchLinkLoket = () => {
        const { dispatch, user } = this.props;
        dispatch(linkUserToLoket(user.id));
    }

    dispatchEditLeaveOfAbsence = (
        editType,
        data,
        periodsWithHours,
        weeksWithHours,
        leaveType,
    ) => {
        const { activeAbsence } = this.state;
        const { dispatch } = this.props;

        this.setState({
            resolveLOAForm: false,
            activeAbsence: null,
            editLOAData: null,
        });

        dispatch(editLeaveOfAbsence({
            editType,
            data,
            payrollPeriodsWithHours: periodsWithHours,
            weeksWithHours,
            leaveType,
            leaveOfAbsence: activeAbsence,
            page: USER,
        }));
    };

    dispatchDeclineLeaveOfAbsence = (declineComment) => {
        const { activeAbsence, editLOAData } = this.state;
        const { dispatch } = this.props;

        this.setState({
            resolveLOAForm: false,
            declineForm: false,
            activeAbsence: null,
            editLOAData: null,
        });

        dispatch(editLeaveOfAbsence({
            editType: 'deny',
            data: editLOAData,
            declineComment,
            payrollPeriodsWithHours: editLOAData.payrollPeriodsWithHours,
            weeksWithHours: editLOAData.weeksWithHours,
            leaveOfAbsence: activeAbsence,
            page: USER,
        }));
    };

    dispatchEditAbsence = (
        absence,
        start,
        end,
        periodsWithHours,
        weeksWithHours,
        waitingDayHours,
    ) => {
        const { dispatch } = this.props;

        this.setState({
            editAbsenceForm: false,
        });

        dispatch(editAbsence(
            absence,
            start,
            end,
            periodsWithHours,
            weeksWithHours,
            waitingDayHours,
            USER,
        ));
    }

    dispatchDeleteAbsence = (absenceId) => {
        const { dispatch } = this.props;

        this.setState({
            editAbsenceForm: false,
            activeAbsence: null,
        });

        dispatch(deleteAbsence(absenceId, USER));
    }

    dispatchSetPlanningPreference = (weekday, fromDate, toDate, comment, planningPreferenceId) => {
        const { user, dispatch } = this.props;
        const { id: userId } = user;
        const { unavailableWorkTimeSlotData } = this.state;
        const id = unavailableWorkTimeSlotData ? unavailableWorkTimeSlotData.id : planningPreferenceId;

        this.setState({
            unavailableWorkTimeSlotForm: false,
            unavailableWorkTimeSlotData: null,
        });

        dispatch(setUserPlanningPreference(
            weekday,
            fromDate,
            toDate,
            comment,
            userId,
            id,
        ));
    }

    dispatchDeletePlanningPreference = (planningPreferenceId) => {
        const { dispatch } = this.props;
        dispatch(deleteUserPlanningPreference(planningPreferenceId));
    }

    onSubmitLeaveOfAbsenceForm = (
        editType,
        data,
        payrollPeriodsWithHours,
        weeksWithHours,
        leaveType,
    ) => {
        if (editType === 'deny') {
            this.addDeclineLeaveOfAbsenceForm(data, payrollPeriodsWithHours, weeksWithHours);
        } else {
            this.dispatchEditLeaveOfAbsence(
                editType,
                data,
                payrollPeriodsWithHours,
                weeksWithHours,
                leaveType,
            );
        }
    }

    addDeclineLeaveOfAbsenceForm = (data, payrollPeriodsWithHours, weekdayWithHours) => {
        this.setState({
            resolveLOAForm: false,
            editLOAData: {
                ...data,
                payrollPeriodsWithHours,
                weekdayWithHours,
            },
            declineForm: true,
        });
    }

    onAddDocumentClick = () => {
        this.setState({
            addDocumentForm: true,
            editDocumentData: null,
        });
    }

    onEditDocumentClick = (data) => {
        this.setState({
            addDocumentForm: true,
            editDocumentData: data,
        });
    }

    onDeleteDocumentClick = (id) => {
        this.setState({
            deleteDocumentForm: true,
            deleteDocumentId: id,
        });
    }

    onDownloadYearEndStatementClick = (yearSelectOption) => {
        const { dispatch, yearEndStatements } = this.props;

        const yearEndStatementsToDownload = yearEndStatements.filter(yearEndStatement => yearEndStatement.year.toString() === yearSelectOption.value);

        yearEndStatementsToDownload.map(givenYearEndStatement => dispatch(downloadYearEndStatement(givenYearEndStatement.employment.id, givenYearEndStatement.year)));
    }

    onLinkClick = (link) => {
        const { user } = this.props;
        const pathBase = `/${translate('nav.userManagement.link')}`;
        const pathUsers = `${pathBase}/${translate('nav.userManagement.users.link')}`;
        window.history.pushState(link, link, `${pathUsers}/${user.id}?${link}`);

        this.setState({
            activeLink: link,
        });
    }

    onResourceClick = (resource) => {
        if (resource.type === TYPE_LEAVE_OF_ABSENCE) {
            this.setState({
                resolveLOAForm: true,
                activeAbsence: resource,
            });
        } else if (resource.type === TYPE_ABSENCE) {
            this.setState({
                editAbsenceForm: true,
                activeAbsence: resource,
            });
        } else if (resource.type === TYPE_UNAVAILABLE_TO_WORK_TIME_SLOT) {
            this.setState({
                unavailableWorkTimeSlotForm: true,
                unavailableWorkTimeSlotData: resource,
            });
        }
    };

    onAddNote = (formData) => {
        const { dispatch } = this.props;
        dispatch(addUserNote(formData));
    }

    onEditNote = (formData) => {
        const { dispatch } = this.props;
        dispatch(editUserNote(formData));
    }

    onDeleteNote = (formData) => {
        const { dispatch } = this.props;
        dispatch(deleteUserNote(formData));
    }

    renderPersonalContent = () => {
        const { activeLink } = this.state;
        const {
            user,
            legacyUser,
            userLoketHours,
            currentUser,
            userFiles,
            yearEndStatements,
            userNotes,
            legacyUserTracks,
            permissions,
            roles,
            userShifts,
            shiftsLoading,
            isUserTracksLoading,
            permissionGroups,
            commentCategories,
        } = this.props;
        const sameUserAsCurrentUser = currentUser.id === user.id;

        const canViewUserProfiles = checkPermission(permissions, 'view-all-user-profiles', 'user');
        const hasRoleToSeeAbsences = user.role.priority <= currentUser.role.priority;
        const canViewAllAbsences = hasRoleToSeeAbsences && checkPermission(permissions, 'view-all-absences', 'user');

        switch (activeLink) {
            case translate('nav.userManagement.users.documents.link'):
                return (
                    <Documents
                        sameUserAsCurrentUser={sameUserAsCurrentUser}
                        userId={user.id}
                        files={userFiles}
                        yearEndStatements={yearEndStatements}
                        permissions={permissions}
                        onAddDocumentClick={this.onAddDocumentClick}
                        onDeleteDocumentClick={this.onDeleteDocumentClick}
                        onEditDocumentClick={this.onEditDocumentClick}
                        onDownloadYearEndStatementClick={this.onDownloadYearEndStatementClick}
                    />
                );
            case translate('nav.userManagement.users.contractInfo.link'):
                return <Contracts contracts={[]} />;
            case translate('nav.userManagement.users.absences.link'):
                return (
                    <Absences
                        permissions={permissions}
                        roles={roles}
                        leaveOfAbsences={legacyUser?.leaveOfAbsences || []}
                        absences={legacyUser?.absences || []}
                        currentUser={currentUser}
                        userLoketHours={userLoketHours}
                        onAbsenceClick={this.onResourceClick}
                    />
                );
            case translate('nav.userManagement.users.couplings.link'):
                return (
                    <Couplings
                        onLinkLoketClick={this.dispatchLinkLoket}
                    />
                );
            case translate('nav.userManagement.users.calendar.link'):
                return (
                    <ConnectedUserCalendar
                        isLoading={shiftsLoading || isUserTracksLoading}
                        userId={user.id}
                        legacyUser={legacyUser}
                        shifts={userShifts}
                        onItemClick={this.onResourceClick}
                    />
                );
            case translate('nav.userManagement.users.permissions.link'):
                return (
                    <ConnectedPermissionsList
                        user={user}
                        permissionGroups={permissionGroups.map(transformLegacyResourceToPermissionGroup)}
                    />
                );
            case translate('nav.userManagement.users.unavailability.link'):
                return (
                    <Availability
                        permissions={permissions}
                        unavailableToWorkTimeSlots={legacyUser?.unavailableToWorkTimeSlots || []}
                        onSetPlanningPreference={this.dispatchSetPlanningPreference}
                        onDeletePlanningPreference={this.dispatchDeletePlanningPreference}
                    />
                );
            case translate('nav.userManagement.users.notes.link'):
                return (
                    <Notes
                        comments={userNotes}
                        commentCategories={commentCategories}
                        onAddNote={this.onAddNote}
                        onEditNote={this.onEditNote}
                        onDeleteNote={this.onDeleteNote}
                        userId={user.id}
                    />
                );
            case translate('nav.userManagement.users.satisfaction.link'):
                return (
                    <Satisfaction
                        tracks={legacyUserTracks}
                    />
                );
            default:
                return (
                    // eslint-disable-next-line no-nested-ternary
                    canViewUserProfiles ? (
                        <UserProfileCard
                            user={user}
                            permissions={permissions}
                        />
                    ) : canViewAllAbsences ? (
                        <Absences
                            permissions={permissions}
                            roles={roles}
                            leaveOfAbsences={user.leaveOfAbsences}
                            absences={user.absences}
                            currentUser={currentUser}
                            userLoketHours={userLoketHours}
                            onAbsenceClick={this.onResourceClick}
                        />
                    ) : (
                        <Documents
                            sameUserAsCurrentUser={sameUserAsCurrentUser}
                            userId={user.id}
                            files={userFiles}
                            yearEndStatements={yearEndStatements}
                            permissions={permissions}
                            onAddDocumentClick={this.onAddDocumentClick}
                            onDeleteDocumentClick={this.onDeleteDocumentClick}
                            onEditDocumentClick={this.onEditDocumentClick}
                            onDownloadYearEndStatementClick={this.onDownloadYearEndStatementClick}
                        />
                    )
                );
        }
    }

    render() {
        const {
            activeLink,
            addDocumentForm,
            deleteDocumentForm,
            deleteDocumentId,
            editDocumentData,
            resolveLOAForm,
            declineForm,
            editAbsenceForm,
            activeAbsence,
            unavailableWorkTimeSlotForm,
            unavailableWorkTimeSlotData,
        } = this.state;
        const {
            user,
            loading,
            roles,
            permissions,
            documentTypes,
            currentUser,
            pacoPayrollPeriods,
            payrollPeriods,
            className,
        } = this.props;

        if (!user) {
            return (
                <div className={`legacy-user-detail-page ${className}`}>
                    <LoadingSpinner className="legacy-user-detail-page__loading-spinner" />
                </div>
            );
        }

        const isLoketAuthorized = getObjProperty(currentUser, 'isLoketAuthorized');
        const userIsEmployee = user.role.slug === RoleType.employee;

        return (
            <div className={`legacy-user-detail-page ${className}`}>
                <div className="legacy-user-detail-page-bottom">
                    <UserNavigation
                        activeLink={activeLink || undefined}
                        isLoketAuthorized={isLoketAuthorized}
                        permissions={permissions}
                        userId={user.id}
                        userRole={user.role}
                        currentUser={currentUser}
                        onLinkClick={this.onLinkClick}
                    />
                    <div className="legacy-user-detail-page__content-wrapper">
                        {!userIsEmployee && (
                            <ConnectedHelpTooltip
                                index={1}
                                route="users-overview"
                                subTitle={trans('help.users.userAdditionalPermissions.title')}
                                text={trans('help.users.userAdditionalPermissions.text')}
                                title={trans('help.users.title')}
                                showMobileInfoWarning
                                className="legacy-user-detail-page__user-additional-permissions-help-tooltip"
                            />
                        )}
                        <div className="legacy-user-detail-page-content">
                            {this.renderPersonalContent()}
                        </div>
                    </div>
                    {loading && <LoadingSpinner className="legacy-user-detail-page__loading-spinner" />}
                </div>
                <Modal size="lg" isOpen={addDocumentForm}>
                    <AddOrEditDocument
                        documentTypes={documentTypes}
                        document={editDocumentData}
                        onSubmit={this.dispatchAddDocument}
                        onCancel={() => this.setState({ addDocumentForm: false })}
                    />
                </Modal>
                <Modal size="lg" isOpen={deleteDocumentForm} className="form-delete-document">
                    <DeleteDocumentForm
                        onSubmit={() => this.dispatchDeleteDocument(deleteDocumentId)}
                        onCancel={() => this.setState({ deleteDocumentForm: false })}
                    />
                </Modal>
                <Modal size="lg" isOpen={resolveLOAForm} className="form-resolve-leave-of-absence">
                    {activeAbsence && (
                        <ResolveLeaveOfAbsenceForm
                            absence={activeAbsence}
                            permissions={permissions}
                            currentUserRole={currentUser.role}
                            pacoPayrollPeriods={pacoPayrollPeriods}
                            payrollPeriods={payrollPeriods}
                            onSubmit={this.onSubmitLeaveOfAbsenceForm}
                            onCancel={() => this.setState({ resolveLOAForm: false })}
                            onShowLogsClick={() => this.dispatchOpenLogsModal(activeAbsence.id, 'leave-of-absences')}
                        />
                    )}
                </Modal>
                <Modal size="lg" isOpen={declineForm} className="form-decline-leave-of-absence">
                    <DeclineLeaveOfAbsenceForm
                        onSubmit={this.dispatchDeclineLeaveOfAbsence}
                        onCancel={() => this.setState({ declineForm: false, activeAbsence: null })}
                    />
                </Modal>
                <Modal size="lg" isOpen={editAbsenceForm} className="form-edit-absence">
                    {activeAbsence && (
                        <EditAbsenceForm
                            data={activeAbsence}
                            permissions={permissions}
                            currentUserRole={roles}
                            pacoPayrollPeriods={pacoPayrollPeriods}
                            payrollPeriods={payrollPeriods}
                            onSubmit={this.dispatchEditAbsence}
                            onDelete={this.dispatchDeleteAbsence}
                            onCancel={() => this.setState({ editAbsenceForm: false })}
                        />
                    )}
                </Modal>
                <Modal size="lg" isOpen={unavailableWorkTimeSlotForm} className="form-add-time">
                    <AddOrEditAvailabilityTime
                        data={unavailableWorkTimeSlotData}
                        permissions={permissions}
                        onSubmit={this.dispatchSetPlanningPreference}
                        onCancel={() => this.setState({ unavailableWorkTimeSlotForm: false })}
                    />
                </Modal>
            </div>
        );
    }
}

User.propTypes = {
    location: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    user: PropTypes.object,
    legacyUser: PropTypes.object,
    isUserTracksLoading: PropTypes.bool.isRequired,
    userFiles: PropTypes.array.isRequired,
    yearEndStatements: PropTypes.array.isRequired,
    userShifts: PropTypes.array.isRequired,
    userLoketHours: PropTypes.object,
    userNotes: PropTypes.array.isRequired,
    legacyUserTracks: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    shiftsLoading: PropTypes.bool.isRequired,
    documentTypes: PropTypes.array.isRequired,
    roles: PropTypes.array.isRequired,
    permissions: PropTypes.array.isRequired,
    permissionGroups: PropTypes.array.isRequired,
    commentCategories: PropTypes.array.isRequired,
    users: PropTypes.array.isRequired,
    pacoPayrollPeriods: PropTypes.array.isRequired,
    payrollPeriods: PropTypes.array.isRequired,
    currentUser: PropTypes.object,
    userId: PropTypes.string.isRequired,
    className: PropTypes.string,
};

User.defaultProps = {
    user: null,
    legacyUser: undefined,
    currentUser: null,
    userLoketHours: null,
    className: '',
};

function mapStateToProps(state) {
    return {
        loading: state.managementReducer.loading,
        shiftsLoading: state.managementReducer.shiftsLoading,
        filter: state.filterReducer.filter,
        currentUser: state.authenticatedUserReducer.user,
        user: state.pacoUserReducer.userProfile,
        legacyUser: state.managementReducer.user,
        isFullUserLoading: state.pacoUserReducer.isFullUserLoading,
        isUserTracksLoading: state.pacoUserReducer.isUserTracksLoading,
        userFormData: state.managementReducer.userFormData,
        userFiles: state.managementReducer.userFiles,
        yearEndStatements: state.managementReducer.yearEndStatements,
        userLoketHours: state.managementReducer.userLoketHours,
        users: state.managementReducer.users,
        userShifts: state.managementReducer.userShifts,
        userNotes: state.managementReducer.userNotes,
        userTracks: state.pacoUserReducer.userTracks,
        legacyUserTracks: state.managementReducer.userTracks,
        sorting: state.managementReducer.sorting,
        documentTypes: state.managementReducer.documentTypes,
        pagination: state.paginationReducer,
        roles: state.appReducer.roles,
        departments: state.authenticatedUserReducer.departments,
        permissions: state.authenticatedUserReducer.permissions,
        permissionGroups: state.managementReducer.permissionGroups,
        commentCategories: state.managementReducer.commentCategories,
        absences: state.absencesReducer.leaveOfAbsencesRequests,
        pacoPayrollPeriods: state.pacoPayrollPeriodsReducer.payrollPeriods,
        payrollPeriods: state.appReducer.payrollPeriods,
        exportCSVRequest: state.managementReducer.exportCSVRequest,
        logs: state.logsReducer.logs,
        logsLoading: state.logsReducer.loading,
    };
}

export default withRouter(connect(mapStateToProps)(User));
