import React, { FC, useMemo, useState } from 'react';

import classnames from 'classnames';
import { useToggle } from 'react-use';
import { Modal } from 'reactstrap';

import { ModalContent, PacoModal } from '../../../../@paco/compositions';
import { ConnectedExportLeaveOfAbsencesForm, ConnectedHelpTooltip } from '../../../../@paco/connectors';
import { PayrollPeriod } from '../../../../@paco/entities/PayrollPeriod/PayrollPeriod';
import { transformLegacyPayrollPeriodToPayrollPeriod } from '../../../../@paco/entities/PayrollPeriod/PayrollPeriodTransformers';
import { PayrollPeriodWeek } from '../../../../@paco/entities/PayrollPeriodWeek/PayrollPeriodWeek';
import trans from '../../../../@paco/helpers/trans';
import { TrackExportType } from '../../../../@paco/types/trackExportType';
import Card from '../../../../components/Card/Card';
import ExportRangeForm from '../../../../components/forms/ExportRangeForm/ExportRangeForm';
import SyncTvtForm from '../../../../components/forms/SyncTvtForm/SyncTvtForm';
import { translate } from '../../../../helpers/translations/translator';
import { PayrollPeriodViewModel } from '../../../../models';
import { transformPayrollPeriodToLegacyPayrollPeriod } from '../../../../services/PayrollPeriodService/transformPayrollPeriodLegacy';
import CompletePeriodStep from '../../components/CompletePeriodStep/CompletePeriodStep';
import CompletePeriodTopBar from '../../components/CompletePeriodTopBar/CompletePeriodTopBar';

import './CompletePeriods.scss';

interface CompletePeriodsProps {
    isLoading: boolean;
    isPayrollPeriodsLoading?: boolean;
    activePeriod?: PayrollPeriod;
    canManageLoketData: boolean;
    payrollPeriods: PayrollPeriod[];
    openPeriodTasks: number;
    onPeriodSelect: (period: PayrollPeriod) => void;
    onExportTracks: (
        startDate: Date,
        endDate: Date,
        payrollPeriodId: string[],
        exportType: TrackExportType,
    ) => void;
    onSyncTvt: (period: PayrollPeriod) => void;
    onLockPeriodButtonClick: () => void;
    onLockPeriodWeekButtonClick: (periodWeek: PayrollPeriodWeek) => void;
}

const CompletePeriods: FC<CompletePeriodsProps> = ({
    isLoading,
    isPayrollPeriodsLoading,
    activePeriod,
    payrollPeriods,
    openPeriodTasks,
    canManageLoketData,
    onExportTracks,
    onSyncTvt,
    onLockPeriodButtonClick,
    onLockPeriodWeekButtonClick,
    onPeriodSelect,
}) => {
    const [exportForm, setExportForm] = useState(false);
    const [syncTvtForm, setSyncTvtForm] = useState(false);
    const [syncLeaveOfAbsencesFormModalIsOpen, toggleSyncLeaveOfAbsencesFormModalIsOpen] = useToggle(false);

    const legacyActivePeriod = activePeriod ? transformPayrollPeriodToLegacyPayrollPeriod(activePeriod) : undefined;
    const legacyPayrollPeriods = useMemo(() => payrollPeriods.map(transformPayrollPeriodToLegacyPayrollPeriod), [payrollPeriods]);

    const classNames = useMemo(() => classnames('complete-periods', {
        'complete-periods--is-loading': isLoading,
    }), [isLoading]);

    const onTracksExportButtonClick = () => {
        setExportForm(true);
    };

    const onTvTSyncButtonClick = () => {
        setSyncTvtForm(true);
    };

    const onSubmitExportTracks = (
        startDate: Date,
        endDate: Date,
        payrollPeriodIds: string[],
        exportType: TrackExportType,
    ) => {
        onExportTracks(
            startDate,
            endDate,
            payrollPeriodIds,
            exportType,
        );
        setExportForm(false);
    };

    const onSubmitSyncTvt = (payrollPeriod: PayrollPeriodViewModel) => {
        onSyncTvt(transformLegacyPayrollPeriodToPayrollPeriod(payrollPeriod));
        setSyncTvtForm(false);
    };

    const onCancelExportTracks = () => {
        setExportForm(false);
    };

    const onCancelSyncTvt = () => {
        setSyncTvtForm(false);
    };

    return (
        <div className={classNames}>
            <ConnectedHelpTooltip
                index={1}
                route="complete-periods"
                subTitle={trans('help.pz.choosePeriod.title')}
                text={trans('help.pz.choosePeriod.text')}
                title={trans('help.pz.title')}
                className="complete-periods__choose-period-help-tooltip"
            />
            <CompletePeriodTopBar
                isLoading={isPayrollPeriodsLoading}
                activePeriod={activePeriod}
                payrollPeriods={payrollPeriods}
                onPeriodSelect={onPeriodSelect}
            />
            <ConnectedHelpTooltip
                index={0}
                route="complete-periods"
                subTitle={trans('help.pz.completePeriods.title')}
                text={trans('help.pz.completePeriods.text')}
                title={trans('help.pz.title')}
                className="complete-periods__complete-periods-help-tooltip"
            />
            {activePeriod && (
                <div className="complete-periods__cards">
                    <div className="complete-periods__cards-left">
                        <Card
                            isLoading={isLoading}
                            isLocked={activePeriod.isLocked}
                            className="complete-periods__card"
                            index={1}
                            title={translate('pages.pz.completePeriodStep1.card')}
                        >
                            <CompletePeriodStep
                                step={1}
                                isLoading={isLoading}
                                isLocked={(activePeriod.isLocked || openPeriodTasks === 0) && !isLoading}
                                openTasks={openPeriodTasks}
                                payrollPeriod={activePeriod}
                            />
                        </Card>
                        <ConnectedHelpTooltip
                            index={2}
                            route="complete-periods"
                            subTitle={trans('help.pz.checkOpenTasks.title')}
                            text={trans('help.pz.checkOpenTasks.text')}
                            title={trans('help.pz.title')}
                            className="complete-periods__check-open-tasks-help-tooltip"
                        />
                        <Card
                            className="complete-periods__card"
                            index={2}
                            isLocked={activePeriod.isLocked}
                            title={translate('pages.pz.completePeriodStep2.card')}
                        >
                            <CompletePeriodStep
                                step={2}
                                isLocked={activePeriod.isLocked}
                                payrollPeriod={activePeriod}
                                onTracksExportButtonClick={onTracksExportButtonClick}
                            />
                        </Card>
                        <ConnectedHelpTooltip
                            index={3}
                            route="complete-periods"
                            subTitle={trans('help.pz.exportTracks.title')}
                            text={trans('help.pz.exportTracks.text')}
                            title={trans('help.pz.title')}
                            className="complete-periods__export-tracks-help-tooltip"
                        />
                        <Card
                            className="complete-periods__card"
                            index={3}
                            title={translate('pages.pz.completePeriodStep3.card')}
                        >
                            <CompletePeriodStep
                                isLoading={isPayrollPeriodsLoading}
                                step={3}
                                payrollPeriod={activePeriod}
                                onLockPeriodButtonClick={onLockPeriodButtonClick}
                                onLockPeriodWeekButtonClick={onLockPeriodWeekButtonClick}
                            />
                        </Card>
                        <ConnectedHelpTooltip
                            index={4}
                            route="complete-periods"
                            subTitle={trans('help.pz.completePeriod.title')}
                            text={trans('help.pz.completePeriod.text')}
                            title={trans('help.pz.title')}
                            className="complete-periods__complete-period-help-tooltip"
                        />
                    </div>
                    <div className="complete-periods__cards-right">
                        <Card
                            className="complete-periods__card"
                            index={4}
                            title={translate('pages.pz.completePeriodStep4.card')}
                        >
                            <CompletePeriodStep
                                canManageLoketData={canManageLoketData}
                                payrollPeriod={activePeriod}
                                step={4}
                                onTvTSyncButtonClick={onTvTSyncButtonClick}
                                onLeaveOfAbsencesSyncButtonClick={toggleSyncLeaveOfAbsencesFormModalIsOpen}
                                onTracksExportButtonClick={onTracksExportButtonClick}
                            />
                        </Card>
                        <ConnectedHelpTooltip
                            index={5}
                            route="complete-periods"
                            subTitle={trans('help.pz.synchronize.title')}
                            text={trans('help.pz.synchronize.text')}
                            title={trans('help.pz.title')}
                            className="complete-periods__synchronize-help-tooltip"
                        />
                    </div>
                    <Modal size="lg" isOpen={exportForm} className="form-export-range">
                        <ExportRangeForm
                            activePeriod={legacyActivePeriod}
                            endDate={activePeriod.period.end}
                            exportTypes={[
                                TrackExportType.loket,
                                TrackExportType.hourOverview,
                                TrackExportType.tracks,
                                TrackExportType.workRelatedCostsScheme,
                            ]}
                            startDate={activePeriod.period.start}
                            title={translate('pages.tracks.exportTracks')}
                            payrollPeriods={legacyPayrollPeriods}
                            onSubmit={onSubmitExportTracks}
                            onCancel={onCancelExportTracks}
                        />
                    </Modal>
                    <Modal size="lg" isOpen={syncTvtForm} className="form-sync-tvt">
                        <SyncTvtForm
                            payrollPeriods={legacyPayrollPeriods}
                            activePeriod={legacyActivePeriod}
                            title={translate('pages.tracks.syncTvt')}
                            onSubmit={onSubmitSyncTvt}
                            onCancel={onCancelSyncTvt}
                        />
                    </Modal>
                    {syncLeaveOfAbsencesFormModalIsOpen && (
                        <PacoModal cardClassName="form-synchronize-leave-of-absences">
                            <ModalContent title={trans('common.syncLeaveOfAbsences')}>
                                <ConnectedExportLeaveOfAbsencesForm
                                    activePeriod={activePeriod}
                                    onClose={toggleSyncLeaveOfAbsencesFormModalIsOpen}
                                />
                            </ModalContent>
                        </PacoModal>
                    )}
                </div>
            )}
        </div>
    );
};

export default CompletePeriods;
