import React, {
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import { useSelector } from 'react-redux';
import { Modal } from 'reactstrap';

import { getCheckedFormOptionsValue } from '../../../../@paco/helpers/types/formOption';
import { useTypedDispatch, useTypedSelector } from '../../../../@paco/redux/store';
import DeleteResourceForm from '../../../../components/forms/DeleteResourceForm/DeleteResourceForm';
import { BaseScheduleFormData, BaseScheduleViewModel } from '../../../../entities/BaseSchedule/BaseSchedule';
import { checkPermission } from '../../../../helpers';
import { translate } from '../../../../helpers/translations/translator';
import {
    deleteBaseSchedule,
    editBaseSchedule,
    fetchBaseSchedules,
} from '../../../../redux/@toolkit/baseSchedules/baseSchedulesActions';
import { Reducers } from '../../../../redux/reducers';
import BaseSchedulesTable from '../../containers/BaseSchedules/BaseSchedulesTable/BaseSchedulesTable';
import BaseScheduleForm from '../../forms/BaseScheduleForm/BaseScheduleForm';

const ConnectedBaseSchedules: FC = (): ReactElement => {
    const dispatch = useTypedDispatch();

    const { baseSchedules, isLoading, isLazyLoading } = useSelector((state: Reducers) => state.baseSchedulesReducer);
    const { departmentOptions } = useTypedSelector(state => state.globalFiltersReducer);

    const [deleteBaseScheduleForm, setDeleteBaseScheduleForm] = useState(false);
    const [editBaseScheduleForm, setEditBaseScheduleForm] = useState(false);
    const [activeBaseSchedule, setActiveBaseSchedule] = useState<BaseScheduleViewModel | undefined>();
    const { permissions } = useTypedSelector(state => state.authenticatedUserReducer);

    const canEditBaseSchedules = checkPermission(permissions, 'edit-all-base-schedules', 'connected-base-schedule-overview');
    const canViewBaseScheduleShifts = checkPermission(permissions, 'view-all-base-schedule-shifts', 'connected-base-schedule-overview');
    const canDeleteBaseSchedules = checkPermission(permissions, 'delete-all-base-schedules', 'connected-base-schedule-overview');

    const handleEditBaseScheduleButtonClick = (baseSchedule: BaseScheduleViewModel) => {
        setEditBaseScheduleForm(true);
        setActiveBaseSchedule(baseSchedule);
    };

    const handleDeleteBaseScheduleButtonClick = (baseSchedule: BaseScheduleViewModel) => {
        setDeleteBaseScheduleForm(true);
        setActiveBaseSchedule(baseSchedule);
    };

    const handleSubmitDeleteBaseScheduleForm = () => {
        if (activeBaseSchedule) {
            dispatch(deleteBaseSchedule(activeBaseSchedule.id, baseSchedules));
        }
        setDeleteBaseScheduleForm(false);
    };

    const handleCancelDeleteBaseScheduleForm = () => {
        setDeleteBaseScheduleForm(false);
    };

    const handleSubmitEditBaseSchedule = (formData: BaseScheduleFormData) => {
        if (activeBaseSchedule) {
            dispatch(editBaseSchedule(formData));
        }
        setEditBaseScheduleForm(false);
    };

    const handleCancelEditBaseSchedule = () => {
        setEditBaseScheduleForm(false);
    };

    useEffect((): void => {
        dispatch(fetchBaseSchedules(getCheckedFormOptionsValue(departmentOptions)));
    }, [departmentOptions]);

    return (
        <>
            <BaseSchedulesTable
                isLoading={isLoading}
                isLazyLoading={isLazyLoading}
                baseSchedules={baseSchedules}
                canEditBaseSchedules={canEditBaseSchedules}
                canViewBaseScheduleShifts={canViewBaseScheduleShifts}
                canDeleteBaseSchedules={canDeleteBaseSchedules}
                onEditBaseScheduleButtonClick={handleEditBaseScheduleButtonClick}
                onDeleteBaseScheduleButtonClick={handleDeleteBaseScheduleButtonClick}
            />
            {canDeleteBaseSchedules && (
                <Modal
                    size="md"
                    isOpen={deleteBaseScheduleForm}
                >
                    <DeleteResourceForm
                        title={translate('common.deleteResource', { resource: translate('common.baseSchedule') })}
                        intro={translate('common.deleteResourceIntro', { resource: translate('common.baseSchedule').toLowerCase() })}
                        onSubmit={handleSubmitDeleteBaseScheduleForm}
                        onCancel={handleCancelDeleteBaseScheduleForm}
                    />
                </Modal>
            )}
            {canEditBaseSchedules && (
                <Modal
                    size="md"
                    isOpen={editBaseScheduleForm}
                >
                    <BaseScheduleForm
                        title={translate('common.editEntity', { entity: translate('common.baseSchedule') })}
                        baseSchedule={activeBaseSchedule}
                        onSubmit={handleSubmitEditBaseSchedule}
                        onCancel={handleCancelEditBaseSchedule}
                    />
                </Modal>
            )}
        </>
    );
};

export default ConnectedBaseSchedules;
