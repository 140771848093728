import { FC, ReactElement, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { PrivateRouteGroupRoutes } from '../../../../components/PrivateRouteGroup/PrivateRouteGroupRoutes';
import { ScheduleRoutes } from '../../../../pages/Schedule/ScheduleRoutes';
import { SaveSequenceForm } from '../../../containers';
import { EditShiftFormData, Shift, ShiftIndex } from '../../../entities/Shift/Shift';
import { ShiftSnapshot } from '../../../entities/ShiftSnapshot/ShiftSnapshot';
import { saveShiftSequence } from '../../../redux/@forms/shiftSnapshotForm/shiftSnapshotActions';
import { setIsSuccessful } from '../../../redux/@forms/shiftSnapshotForm/shiftSnapshotReducer';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';
import { setToast } from '../../../redux/toasts/toastsReducer';
import { ToastType } from '../../../types';
import { getSaveSequenceSuccessfulToastText } from './helpers';

interface ConnectedSaveSequenceFormProps {
    editShiftFormData: EditShiftFormData;
    shift: ShiftIndex;
    shiftSnapshot: ShiftSnapshot;
    similarShifts: Shift[];
    onClose: () => void;
    className?: string;
}

const ConnectedSaveSequenceForm: FC<ConnectedSaveSequenceFormProps> = ({
    editShiftFormData,
    shift,
    shiftSnapshot,
    similarShifts,
    onClose,
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();
    const navigate = useNavigate();

    const { isLoading, isSuccessful } = useTypedSelector(state => state.shiftSnapshotFormReducer);

    const handleSubmit = (): void => {
        if (shiftSnapshot) {
            dispatch(saveShiftSequence(shiftSnapshot, editShiftFormData, shift.id));
        }
    };

    useEffect((): void => {
        if (isSuccessful) {
            navigate(`${PrivateRouteGroupRoutes.shifts()}/${ScheduleRoutes.calendar()}`);

            dispatch(setIsSuccessful(false));
            dispatch(setToast({
                type: ToastType.pass,
                text: getSaveSequenceSuccessfulToastText(similarShifts),
            }));
        }
    }, [isSuccessful]);

    return (
        <SaveSequenceForm
            isLoading={isLoading}
            shift={shift}
            similarShifts={similarShifts}
            onCancel={onClose}
            onSubmit={handleSubmit}
            className={className}
        />
    );
};

export default ConnectedSaveSequenceForm;
