import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { Modal } from 'reactstrap';

import DeleteChangeForm from '../../forms/DeleteChange';
import Contract from './Contract';

import './Contracts.scss';

const DUMMY_DATA = [
    {
        id: '123',
        name: 'Tijdelijk contract 1: 23-7-2018 - 23-7-2019 ',
        changes: [
            {
                id: '121212',
                value: '12-07-2019: Salaris is € 2400,00',
            },
        ],
    },
    {
        id: '124',
        name: 'Vast contract 1: 23-7-2018',
    },
];

class ChangesHistory extends Component {
    state = {
        activeId: null,
        deleteChangeForm: false,
        deleteChangeId: null,
    };

    componentDidMount() {
        this.setState({
            activeId: DUMMY_DATA[0].id,
        });
    }

    onDeleteChangeClick = (id) => {
        this.setState({
            deleteChangeForm: true,
            deleteChangeId: id,
        });
    }

    render() {
        const { contracts } = this.props;
        const { deleteChangeForm, deleteChangeId } = this.state;

        return (
            <div className="contracts">
                { contracts }
                { DUMMY_DATA.map((contract) => {
                    const active = contract.id === this.state.activeId;
                    return (
                        <Contract
                            key={contract.id}
                            data={contract}
                            active={active}
                            onActivateClick={
                                id => this.setState({ activeId: id })
                            }
                            onDeleteChangeClick={this.onDeleteChangeClick}
                        />
                    );
                })}
                <Modal size="lg" isOpen={deleteChangeForm} className="form-delete-change">
                    <DeleteChangeForm
                        onSubmit={() => this.dispatchDeleteDocument(deleteChangeId)}
                        onCancel={() => this.setState({ deleteChangeForm: false })}
                    />
                </Modal>
            </div>
        );
    }
}

ChangesHistory.propTypes = {
    contracts: PropTypes.array.isRequired,
};

export default ChangesHistory;
