import { checkPermission } from '../../helpers';
import { Permission, UserCallType } from '../../models';

function getIncludes(type: UserCallType): string[] {
    if (type === 'usersQuick') {
        return [];
    }

    if (type === 'users') {
        return [
            'departments',
            'departments.group',
            'person.experience',
            'mainDepartment',
            'roles',
        ];
    }

    if (type === 'usersEditModal') {
        return [
            'departments',
            'departments.group',
            'departmentGroups',
            'roles',
            'person.experience',
            'person.emergencyContact',
            'person.addresses',
            'employmentType',
            'mainDepartment',
            'employments',
        ];
    }

    return [
        'departments',
        'departments.group',
        'departmentGroups',
        'roles',
        'roles.permissions',
        'roles.permissions.permissionGroup',
        'roles.configurablePermissions.permissionGroup',
        'permissions',
        'leaveOfAbsences',
        'leaveOfAbsences.user',
        'leaveOfAbsences.comments',
        'leaveOfAbsences.comments.owner',
        'leaveOfAbsences.reviewedByUser',
        'leaveOfAbsences.leaveOfAbsenceHours',
        'leaveOfAbsences.leaveOfAbsenceHours.payrollPeriod',
        'person.addresses',
        'person.experience',
        'person.emergencyContact',
        'employmentType',
        'absences',
        'absences.user',
        'absences.comments',
        'absences.comments.owner',
        'absences.absenceHours',
        'absences.absenceHours.payrollPeriod',
        'mainDepartment',
        'unavailableToWorkTimeSlots',
        'employments',
        'employments.divergentPostEmploymentHours',
        'employments.divergentPreEmploymentHours',
    ];
}

export default function getUserCallIncludes(type: UserCallType, permissions: Permission[]): string[] {
    const includes = getIncludes(type);

    const canViewAllAddresses = checkPermission(permissions, 'view-all-addresses', 'get-user-call-includes');

    if (type === 'user' && canViewAllAddresses) {
        return [...includes, 'person.addresses'];
    }

    return includes;
}
